import React from "react";
import { ROUTES } from "../../routes/routes";
import NavbarLink from "./components/NavbarLink";
import NavbarLinkTopic from "./components/NavbarLinkTopic";
import NavbarHeader from "./components/NavbarHeader";
import MasterArea from "./components/MasterArea";
import { useStore } from "../../store/useStore";
import { PERMISSIONS } from "../../utils/constants/permissions";
import { Arrow, BookSaved, LogoutCurve, PresentionChart, Profile2User, TableDocument, Timer1, Truck, Watch } from "iconsax-react";

const Navbar: React.FC<{}> = () => {
  const isNavbarOpen = useStore((state) => state.navbar.isNavbarOpen);
  const logout = useStore((state) => state.auth.logout);
  const hasPermissions = useStore((state) => state.admin.hasPermissions);
  const isMaster = useStore((state) => state.admin.isMaster);
  const iconSize = 24;
  const variant = "TwoTone";

  return (
    <nav
      className={`bg-gradient-to-b from-[#3E3D45] to-[#202020] min-h-screen transition-all linear duration-300 ease-in ease-out ${
        isNavbarOpen
          ? "w-[0px] translate-x-[-266px] "
          : "w-[266px] translate-x-[0px]  px-4 "
      }`}
    >
      <NavbarHeader />
      {isMaster() ? <MasterArea /> : null}
      <NavbarLinkTopic title="">
        <NavbarLink
          to={ROUTES.DASHBOARD.PATH}
          icon={<PresentionChart size={iconSize} variant={variant} />}
          text={ROUTES.DASHBOARD.NAME}
          show={hasPermissions([PERMISSIONS.GET_DASH_MACROS])}
        />
      </NavbarLinkTopic>
      <NavbarLinkTopic title="LOGÍSTICA">
        <NavbarLink
          to={ROUTES.COLLABORATORS.PATH}
          icon={<Profile2User size={iconSize} variant={variant} />}
          text={ROUTES.COLLABORATORS.NAME}
          show={hasPermissions([PERMISSIONS.LIST_USERS])}
        />
        <NavbarLink
          to={ROUTES.FLEET.PATH}
          icon={<Truck size={iconSize} variant={variant} />}
          text={ROUTES.FLEET.NAME}
          show={hasPermissions([PERMISSIONS.LIST_VEHICLES])}
        />
        <NavbarLink
          to={ROUTES.CONVENTIONS.PATH}
          icon={<BookSaved size={iconSize} variant={variant} />}
          text={ROUTES.CONVENTIONS.NAME}
          show={hasPermissions([PERMISSIONS.LIST_HOLIDAYS])}
        />
        {/* <NavbarLink
          to={ROUTES.CONVENTIONS.PATH}
          icon={<ListIcon />}
          text={ROUTES.CONVENTIONS.NAME}
          // TODO: HANDLE PERMISSION
        /> */}
      </NavbarLinkTopic>
      <NavbarLinkTopic title="FINANCEIRO">
        <NavbarLink
          to={ROUTES.TIME_MANAGER.PATH}
          icon={<Watch size={iconSize} variant={variant} />}
          text={ROUTES.TIME_MANAGER.NAME}
          // TODO: HANDLE PERMISSION
        />
      </NavbarLinkTopic>
      <NavbarLinkTopic title="PARAMETRIZAÇÃO">
        <NavbarLink
          to={ROUTES.MACROS.PATH}
          icon={<Timer1 size={iconSize} variant={variant} />}
          text={ROUTES.MACROS.NAME}
          show={hasPermissions([PERMISSIONS.LIST_MACROS])}
        />
        <NavbarLink
          to={ROUTES.REPORTS.PATH}
          icon={<TableDocument size={iconSize} variant={variant} />}
          text={ROUTES.REPORTS.NAME}
          show={
            hasPermissions([PERMISSIONS.CREATE_MANUAL_MACRO_REPORT]) ||
            hasPermissions([PERMISSIONS.CREATE_ALTERED_MACRO_REPORT])
          }
        />
        <NavbarLink
          to={ROUTES.JOURNEY_RULES.PATH}
          icon={<Arrow size={iconSize} variant={variant} />}
          text={ROUTES.JOURNEY_RULES.NAME}
          show={hasPermissions([PERMISSIONS.LIST_JOURNEY_EVENTS])}
        />

        {/* <NavbarLink
          to={ROUTES.SESSIONS.PATH}
          icon={<ListIcon />}
          text={ROUTES.SESSIONS.NAME}
          show={hasPermissions([PERMISSIONS.LIST_SECTIONS])}
        /> */}
      </NavbarLinkTopic>
      <NavbarLinkTopic title="">
        <div
          onClick={() => logout()}
          className="cursor-pointer flex items-center gap-3 px-4 py-8 text-white border-t border-gray_2"
        >
          <LogoutCurve size={iconSize} variant={variant} />
          Sair
        </div>
      </NavbarLinkTopic>
    </nav>
  );
};

export default Navbar;
