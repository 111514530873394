interface UserIconProps {
    width?: string | number;
    height?: string | number;
    color?: string;
  }
  
  
const UserIcon: React.FC<UserIconProps> = ({
    width = 32,
    height = 32,
    color = "white",
  }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 12a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM12 14.5c-5.01 0-9.09 3.36-9.09 7.5 0 .28.22.5.5.5h17.18c.28 0 .5-.22.5-.5 0-4.14-4.08-7.5-9.09-7.5Z"
        fill={color}
      ></path>
    </svg>
  );
};

export default UserIcon;
