const StepsProgress: React.FC<{ totalSteps: number; currentStep: number }> = ({
  totalSteps,
  currentStep,
}) => {
  return (
    <div className="flex">
      {Array.from({ length: totalSteps }, (v, i) => i + 1).map(
        (step, index, array) => (
          <div className="flex">
            <div
              className={`rounded-full font-bold flex items-center justify-center w-[25px] h-[25px] ${
                step <= currentStep
                  ? "bg-red text-white"
                  : "border border-gray_1 text-gray_1"
              }`}
            >
              {step}
            </div>
            {array.length !== step ? (
              <div className="flex items-center mx-2">
                <div className={`${step < currentStep ? 'bg-red' : ''} w-[70px] h-[1px] bg-gray_1`}></div>
              </div>
            ) : null}
          </div>
        )
      )}
    </div>
  );
};

export default StepsProgress;
