import '../clients/clients-style.css';
import { LogoMantiqueira, LogoSanpetro, LogoRodoborges, LogoTransmarreco, LogoVitran } from "../../../../assets/imgs";
import { Fade } from "react-awesome-reveal";

export default function Clients(props: { contentClass: string;}) {
  if(props.contentClass == "content-mobile") {
    return (
      <section id="clients">
        <div className="margin-t-half margin-b-half">
          <div className="clients">
            <ul className="clients-list-mobile">
                <Fade cascade damping={0.3}>
                  <li>
                      <img
                        src={LogoMantiqueira}
                        alt="Logotipo Mantiqueira"
                        width={120}
                      />
                  </li>
                  <li>
                      <img
                        src={LogoSanpetro}
                        alt="Logotipo Sanpedro"
                        width={120}
                      />
                  </li>
                  <li>
                      <img
                        src={LogoRodoborges}
                        alt="Logotipo Rodoborges"
                        width={120}
                      />
                  </li>
                  <li>
                      <img
                        src={LogoTransmarreco}
                        alt="Logotipo Transmarreco"
                        width={60}
                      />
                  </li>
                  <li>
                      <img
                        src={LogoVitran}
                        alt="Logotipo Vitran"
                        width={120}
                      />
                  </li>
                </Fade>
            </ul>
          </div>
        </div>
      </section>
    )
  }
  return (
    <section id="clients">
      <div className="margin-t-half margin-b-half">
        <div className="clients">
          <ul className="clients-list">
              <Fade cascade damping={0.3}>
                <li>
                    <img
                      src={LogoMantiqueira}
                      alt="Logotipo Mantiqueira"
                      width={120}
                    />
                </li>
                <li>
                    <img
                      src={LogoSanpetro}
                      alt="Logotipo Sanpedro"
                      width={120}
                    />
                </li>
                <li>
                    <img
                      src={LogoRodoborges}
                      alt="Logotipo Rodoborges"
                      width={120}
                    />
                </li>
                <li>
                    <img
                      src={LogoTransmarreco}
                      alt="Logotipo Transmarreco"
                      width={60}
                    />
                </li>
                <li>
                    <img
                      src={LogoVitran}
                      alt="Logotipo Vitran"
                      width={120}
                    />
                </li>
              </Fade>
          </ul>
        </div>
      </div>
    </section>
  );
}
