import { useEffect } from "react";
import DataPanel from "../../../components/DataPanel/DataPanel";
import Pagination from "../../../components/Pagination/Pagination";
import SearchInput from "../../../components/SearchInput/SeachInput";
import { useStore } from "../../../store/useStore";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import Skeleton from "react-loading-skeleton";
import JourneyRulesTable from "./JourneyRulesTable";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import { PlusIcon } from "../../../assets/imgs";
import useModal from "../../../hooks/useModal";
import CreateJourneyRuleModal from "./CreateJourneyRuleModal";
import { AddCircle } from "iconsax-react";

const JourneyRulesGeneral: React.FC<{}> = () => {
  const { openModal } = useModal();
  const getJourneyRules = useStore((state) => state.journeyRules.getJourneyRules);
  const journeyRulesList = useStore(
    (state) => state.journeyRules.journeyRulesList
  );
  const isLoadingJourneyRulesList = useStore(
    (state) => state.journeyRules.isLoadingJourneyRulesList
  );

  const openCreateJourneyRuleModal = () => openModal(<CreateJourneyRuleModal />);

  useEffect(() => {
    getJourneyRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mt-9">
        <DataPanel className="px-3 pt-8 flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="pl-1">
              {/* <SearchInput placeholder="Buscar registro" onChange={() => {}} /> */}
            </div>
            <div className="pr-1 flex gap-4">
              <RoundedFilledButton
                onClick={openCreateJourneyRuleModal}
                icon={<AddCircle />}
                text="Novo"
              />
              </div>
          </div>
          <JourneyRulesTable />
          <div className="flex justify-between items-center">
            <div className="text-gray_1">
              Mostrando{" "}
              {!isLoadingJourneyRulesList ? (journeyRulesList.data.length + journeyRulesList.offset * journeyRulesList.limitPerPage) : <Skeleton inline width={20} />}{" "}
              de {journeyRulesList.totalItems} registros
            </div>
            <div className="flex gap-5 items-center">
              <LimitPerPage
                onLimitChange={(e: any) =>
                  journeyRulesList.setLimitPerPage(e.target.value)
                }
              />
              <Pagination
                currentPage={journeyRulesList.currentPage}
                totalPages={journeyRulesList.totalPages}
                onPageChange={journeyRulesList.setCurrentPage}
              />
            </div>
          </div>
        </DataPanel>
      </div>
    </>
  );
};

export default JourneyRulesGeneral;
