import { LogoWppTransparente } from "../../../assets/imgs";

const NavbarHeader: React.FC<{}> = () => {
  return (
    <div className="py-4 border-b border-gray_2">
      <div className="flex items-center gap-3">
        <img width={40} src={LogoWppTransparente} alt="jornadasat logo" />
        <span className="text-white font-bold whitespace-nowrap">Jornada SAT</span>
      </div>
    </div>
  );
};

export default NavbarHeader;
