import { LastJourneyEvent } from "./fleet";

export interface LastMacrosListFilters {
  user_id: string;
  sort_key: string;
  sort_value: string;
}

export interface MacrosListFilters {
  sort_key: string;
  sort_value: string;
  vehicle: string;
  user: string;
  dash_status: keyof Status;
}

export interface LastMacrosListData {
  data: any[];
  currentPage: number;
  totalItems: number;
  totalPages: number;
  limitPerPage: number;
  offset: number;
  filters: LastMacrosListFilters;
  setLimitPerPage: (limitPerPage: number) => void;
  setCurrentPage: (page: number) => void;
  setFilters: (filters: LastMacrosListFilters) => void;
}
export interface MacrosListData {
  data: any[];
  currentPage: number;
  totalItems: number;
  totalPages: number;
  limitPerPage: number;
  offset: number;
  filters: MacrosListFilters;
  setLimitPerPage: (limitPerPage: number) => void;
  setCurrentPage: (page: number) => void;
  setFilters: (filters: MacrosListFilters) => void;
}

export type Position = [number, number];

export interface MacroData {
  position: Position;
  tag: string;
  journeyEvent: string;
  user: string;
  startedAt: string;
}

export interface Status {
  WORKED_HOURS_LOW: number;
  WORKED_HOURS_MEDIUM: number;
  WORKED_HOURS_HIGH: number;
  INTRADAY_REST_LOW: number;
  INTRADAY_REST_MEDIUM: number;
  INTRADAY_REST_HIGH: number;
  MEAL_LOW: number;
  MEAL_MEDIUM: number;
  MEAL_HIGH: number;
  INTERDAY_REST_LOW: number;
  INTERDAY_REST_MEDIUM: number;
  INTERDAY_REST_HIGH: number;
  INTERDAY_COMPENSATION_LOW: number;
  INTERDAY_COMPENSATION_MEDIUM: number;
  INTERDAY_COMPENSATION_HIGH: number;
  WAITING_HOURS_LOW: number;
  WAITING_HOURS_MEDIUM: number;
  WAITING_HOURS_HIGH: number;
  EXTRA_HOUR_LOW: number;
  EXTRA_HOUR_MEDIUM: number;
  EXTRA_HOUR_HIGH: number;
  AVAILABLE_LOW: number;
  AVAILABLE_MEDIUM: number;
  AVAILABLE_HIGH: number;
  STOPPED_LOW: number;
  STOPPED_MEDIUM: number;
  STOPPED_HIGH: number;
  NO_SIGNAL_LOW: number;
  NO_SIGNAL_MEDIUM: number;
  NO_SIGNAL_HIGH: number;
}

export interface ChartFilters {
  time_range: TIME_RANGE;
  granularity: GRANULARITY;
}

export interface ChartsData {
  lastMacroPositions: MacroData[];
  fleetEvolution: any[];
  timeAggregator: any[];
  macroAlterations: any[];
}

export interface Charts {
  data: ChartsData;
  filters: ChartFilters;
}

export enum STATUS_COLOR {
  WORKED_HOURS = "#4CAF50",
  WAITING_HOURS = "#FFE2D2",
  INTRADAY_REST = "#FB8C00",
  INTERDAY_REST = "#7B809A",
  AVAILABLE = "#FFE2D2",
  EXTRA_HOUR = "#DA1E27",
  INTERDAY_COMPENSATION = "#7B809A",
  MEAL = "#4fa8e3",
}

export type JourneyChartDataType = {
  type: keyof typeof STATUS_COLOR;
  start_time: string;
  end_time: string;
  hours: number;
};

export interface DashboardState {
  dashboard: {
    statusPanel: {
      selectedMacro: {
        geolocations: [],
        lastMacrosList: LastMacrosListData;
        position?: Position;
        journeyChartData?: Array<JourneyChartDataType>;
        vehicle: string;
        user: string;
        user_id: string;
        vehicle_id: string;
        journey_event?: LastJourneyEvent;
      };
      status: Status;
      macrosList: MacrosListData;
    };
    charts: Charts;
    isLoadingLastMacros: boolean;
    isLoadingUserJourney: boolean;
    isLoadingVehiclePositions: boolean;
    isLoadingDashboardMacro: boolean;
    isLoadingDashboardStatus: boolean;
    isLoadingFleetEvoluition: boolean;
    isLoadingTimeAggregator: boolean;
    isLoadingLastMacroPositions: boolean;
    isLoadingMacroAlterations: boolean;
    setSelectedMacro: (macro: any) => void;
    resetSelectedMacro: () => void;
    resetDashboardStatus: () => Promise<void>;
    setChartFilters: (timeRange: TIME_RANGE) => void;
    getFleetEvolution: () => Promise<void>;
    getTimeAggregator: () => Promise<void>;
    getLastMacroPositions: () => Promise<void>;
    getMacroAlterations: () => Promise<void>;
    getDashboardStatusAndMacros: () => Promise<void>;
    getUserJourney: (id: string) => Promise<void>;
    getVehiclePositions: (id: string) => Promise<void>;
    getLastMacrosList: () => void;
  };
}

export enum TIME_RANGE {
  last_week = "last_week",
  last_month = "last_month",
  last_quarter = "last_quarter",
  last_year = "last_year",
}

export enum GRANULARITY {
  days = "days",
  months = "months",
}
