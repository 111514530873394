import {
  JourneyEvent,
  Macro,
  MacroDataToUpdate,
  MacroStatus,
} from "../../../store/types/macroState";
import StatusText from "../../../components/StatusText/StatusText";
import Divider from "../../../components/Divider/Divider";
import CustomDatePicker from "../../../components/FormComponents/CustomDateTimePicker/CustomDateTimePicker";
import StepsProgress from "../../../components/Steps/StepsProgress";
import { Field, Formik, Form } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { useEffect } from "react";
import * as Yup from "yup";
import { useStore } from "../../../store/useStore";
import AsyncOutlinedSelect from "../../../components/AsyncOutlinedSelect/AsyncOutlinedSelect";
import { Collaborator } from "../../../store/types/collaborator";
import { Fleet } from "../../../store/types/fleet";
import {
  sortMacroHistoryByCreatedAt,
} from "../../../utils/functions/functions";
import Skeleton from "react-loading-skeleton";

export interface Step1Props {
  selectedMacro: Macro | null;
  isLoadingMacro: boolean;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  totalSteps: number;
  show: boolean;
}

const Step1: React.FC<Step1Props> = ({
  selectedMacro,
  isLoadingMacro,
  currentStep,
  setCurrentStep,
  totalSteps,
  show,
}) => {
  const setMacroDataToUpdate = useStore(
    (state) => state.macro.setMacroDataToUpdate
  );
  const getCollaboratorOptions = useStore(
    (state) => state.collaborator.getCollaboratorOptions
  );
  const getFleetOptions = useStore((state) => state.fleet.getFleetOptions);
  const getJourneyEventOptions = useStore(
    (state) => state.macro.getJourneyEventOptions
  );
  const getCollaboratorOptionsCallback = async (inputValue: string) => {
    const options = (await getCollaboratorOptions(inputValue)).map(
      (c: Collaborator) => {
        return { label: c.name, value: c._id };
      }
    );
    return options;
  };
  const getFleetOptionsCallback = async (inputValue: string) => {
    const options = (await getFleetOptions(inputValue)).map((c: Fleet) => {
      return { label: c.tag, value: c._id };
    });
    return options;
  };
  const getJourneyEventOptionsCallback = async (inputValue: string) => {
    const options = (await getJourneyEventOptions(inputValue)).map(
      (c: JourneyEvent) => {
        return { label: c.name, value: c.type };
      }
    );
    return options;
  };

  const description =
    selectedMacro?.alteration_flow?.history
      ?.filter((item) => item.type === MacroStatus.ALTERATION_REQUESTED)
      .sort(sortMacroHistoryByCreatedAt)
      .shift()?.description ?? "-";

  return (
    <Formik
      initialValues={
        {
          journey_event: {
            value: "",
            label: "",
          },
          user: {
            value: "",
            label: "",
          },
          vehicle: {
            value: "",
            label: "",
          },
          started_at: "",
        } as MacroDataToUpdate
      }
      validationSchema={Yup.object().shape({
        journey_event: Yup.object().shape({
          value: Yup.string().required("Campo obrigatório"),
          label: Yup.string().required("Campo obrigatório"),
        }),
        user: Yup.object().shape({
          value: Yup.string().required("Campo obrigatório"),
          label: Yup.string().required("Campo obrigatório"),
        }),
        vehicle: Yup.object().shape({
          value: Yup.string().required("Campo obrigatório"),
          label: Yup.string().required("Campo obrigatório"),
        }),
        started_at: Yup.string()
          .required("Campo obrigatório")
          .test(
            "is-before",
            "Deve ser pelo menos 1 minuto antes do término",
            function (value) {
              const { ended_at } = this.parent;
              if (!value || !ended_at) {
                return true;
              }

              const oneMinuteBeforeEndedAt = new Date(ended_at);
              oneMinuteBeforeEndedAt.setSeconds(
                oneMinuteBeforeEndedAt.getSeconds()
              );
              return new Date(value) < oneMinuteBeforeEndedAt;
            }
          )
      })}
      onSubmit={(values) => {
        setCurrentStep(2);
        setMacroDataToUpdate(values);
      }}
    >
      {(formik) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (selectedMacro) {
            const macroDataToUpdate: MacroDataToUpdate = {
              journey_event: {
                value: selectedMacro.journey_event_type,
                label: selectedMacro.journey_event,
              },
              user: {
                value: selectedMacro.user_id,
                label: selectedMacro.user,
              },
              vehicle: {
                value: selectedMacro.vehicle_id,
                label: selectedMacro.vehicle,
              },
              started_at: selectedMacro.started_at
            };
            formik.setValues(macroDataToUpdate);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedMacro]);

        return (
          <div className={`${!show ? "hidden" : ""}`}>
            <h1 className="font-bold text-dark-blue pb-5">
              Solicitação do Motorista
            </h1>
            <p className="text-sm pl-5">
              {isLoadingMacro ? <Skeleton width={200} /> : description}
            </p>
            <Divider className="my-5" />
            <h1 className="font-bold text-dark-blue">Alteração da Macro</h1>
            <div className="flex justify-center py-5">
              <StepsProgress
                currentStep={currentStep}
                totalSteps={totalSteps}
              />
            </div>
            <StatusText
              status="warning"
              text="A alteração de macros impacta diretamente na duração das macros adjacentes. Você poderá confirmar a alteração antes de enviar ao motorista"
            />
            <Form>
              <div className="flex flex-col gap-8 pt-8">
                <Field
                  loading={isLoadingMacro}
                  name="journey_event"
                  component={AsyncOutlinedSelect}
                  callback={getJourneyEventOptionsCallback}
                  value={{
                    value: formik.values.journey_event.value,
                    label: formik.values.journey_event.label,
                  }}
                  onChange={(option: any) =>
                    formik.setFieldValue("journey_event", option)
                  }
                  menuPlacement="top"
                  placeholder="Evento"
                />
                <div className="flex gap-5">
                  <div className="flex-1">
                    <Field
                      loading={isLoadingMacro}
                      placeholder="Inicio"
                      name="started_at"
                      component={CustomDatePicker}
                    />
                  </div>
                  <div className="flex-1">
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="flex-1">
                    <Field
                      loading={isLoadingMacro}
                      name="user"
                      component={AsyncOutlinedSelect}
                      callback={getCollaboratorOptionsCallback}
                      value={{
                        value: formik.values.user.value,
                        label: formik.values.user.label,
                      }}
                      onChange={(option: any) =>
                        formik.setFieldValue("user", option)
                      }
                      menuPlacement="top"
                      placeholder="Motorista"
                    />
                  </div>
                  <div className="flex-1">
                    <Field
                      loading={isLoadingMacro}
                      name="vehicle"
                      component={AsyncOutlinedSelect}
                      callback={getFleetOptionsCallback}
                      value={{
                        value: formik.values.vehicle.value,
                        label: formik.values.vehicle.label,
                      }}
                      onChange={(option: any) =>
                        formik.setFieldValue("vehicle", option)
                      }
                      menuPlacement="top"
                      placeholder="Placa do veículo"
                    />
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="flex-1"></div>
                  <div className="flex-1">
                    <FilledButton className="w-full" type="submit">
                      Próximo passo
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default Step1;
