import { useEffect, useState } from "react";
import { useStore } from "../../store/useStore";
import ShadowBox from "../ShadowBox/ShadowBox";
import SimpleSelect from "../SimpleSelect/SimpleSelect";
import Skeleton from "react-loading-skeleton";
import LimitPerPage from "../LimitPerPage/LimitPerPage";
import Pagination from "../Pagination/Pagination";
import DashboardMacrosTable from "./components/DashboardMacrosTable";
import StatusFilter from "./components/StatusFilter";
import { Status } from "../../store/types/dashboard";
import { extraHoursStatusParam } from "../../utils/functions/functions";
import { PERMISSIONS } from "../../utils/constants/permissions";
import { CalendarTick, Coffee, Moon, Refresh, Refresh2, Setting2, TimerPause, TimerStart, Truck, TruckFast, TruckTime } from "iconsax-react";
import { Colors } from "../../utils/constants/style";

const DashboardStatusPanel: React.FC<{}> = () => {
  const getDashboardStatusAndMacros = useStore(
    (state) => state.dashboard.getDashboardStatusAndMacros
  );
  const resetDashboardStatus = useStore(
    (state) => state.dashboard.resetDashboardStatus
  );
  const isLoadingDashboardStatus = useStore(
    (state) => state.dashboard.isLoadingDashboardStatus
  );
  const status = useStore((state) => state.dashboard.statusPanel.status);
  const macrosList = useStore(
    (state) => state.dashboard.statusPanel.macrosList
  );
  const filters = useStore(
    (state) => state.dashboard.statusPanel.macrosList.filters
  );
  const setFilters = useStore(
    (state) => state.dashboard.statusPanel.macrosList.setFilters
  );
  const isLoadingDashboardMacro = useStore(
    (state) => state.dashboard.isLoadingDashboardMacro
  );
  const adminData = useStore(
    (state) => state.admin.data
  );
  const hasPermissions = useStore(
    (state) => state.admin.hasPermissions
  );
  const [statusAndMacrosRequestInterval, setStatusAndMacrosRequestInterval] =
    useState<NodeJS.Timer>();
  
  const iconWidth = 24;
  const iconVariant = "Linear";

  useEffect(() => {
    getDashboardStatusAndMacrosAndCreateInterval(5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDashboardStatusAndMacrosAndCreateInterval = (
    intervalInMinutes: number
  ) => {
    getDashboardStatusAndMacros();
    createStatusAndMacrosRequestInterval(intervalInMinutes);
  };

  const createStatusAndMacrosRequestInterval = (intervalInMinutes: number) => {
    clearInterval(statusAndMacrosRequestInterval);
    const newRequestInverval = setInterval(
      getDashboardStatusAndMacros,
      intervalInMinutes * 60 * 1000 // convert to ms
    );
    setStatusAndMacrosRequestInterval(newRequestInverval);
  };

  const onSetMacrosListFilter = (status: keyof Status) => {
    setFilters({
      ...filters,
      dash_status: status,
    });
  };

  return (
    <div>
      <div className="flex justify-end">
        {hasPermissions([PERMISSIONS.RESET_MACRO_DASH_STATUS]) && (
          <div className="shadow-base py-2 px-5 rounded-[12px] mr-2 bg-white -mt-6">
            <div className="flex gap-2 items-center">
              <button
                className="rounded-[11px] bg-gray_0 w-fit p-2 hover:bg-gray_05"
                onClick={async () => {
                  await resetDashboardStatus();
                  await getDashboardStatusAndMacros();
                }}
              >
                <div className="flex gap-2 items-center"><Refresh/>Resetar Dashboard</div>
              </button>
            </div>
          </div>
        )}
        <div className="shadow-base py-2 px-5 rounded-[12px] bg-white -mt-6">
          <div className="flex gap-2 items-center">
            <button
              className="rounded-[11px] bg-gray_0 w-fit p-2 hover:bg-gray_05"
              onClick={() => getDashboardStatusAndMacros()}
            >
              <Refresh2 />
            </button>
            <SimpleSelect
              className="bg-white border-b border-b-gray_2 text-gray_2"
              options={[
                { label: "5 minutos", value: 5 },
                { label: "15 minutos", value: 15 },
                { label: "30 minutos", value: 30 },
              ]}
              onChange={(e) => {
                getDashboardStatusAndMacrosAndCreateInterval(
                  parseInt(e.target.value)
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4 mt-4">
        <ShadowBox>
          <div>
            <h1 className="text-2xl text-dark-blue font-bold">Status</h1>
            <StatusFilter
              label="Dirigindo"
              icon={<TruckFast size={iconWidth} variant={iconVariant} color={Colors.gray_2} />}
              values={[
                status.WORKED_HOURS_LOW,
                status.WORKED_HOURS_MEDIUM,
                status.WORKED_HOURS_HIGH,
              ]}
              keys={[
                "WORKED_HOURS_LOW",
                "WORKED_HOURS_MEDIUM",
                "WORKED_HOURS_HIGH",
              ]}
              limitTimes={["00:00", "05:00", "05:30"]}
              loading={isLoadingDashboardStatus}
              selectedKey={filters.dash_status}
              onClick={onSetMacrosListFilter}
            />
            <div className="pt-2">
              <StatusFilter
                label="Refeição"
                icon={<Coffee size={iconWidth} variant={iconVariant} color={Colors.gray_2} />}
                values={[
                  status.MEAL_LOW,
                  status.MEAL_MEDIUM,
                  status.MEAL_HIGH,
                ]}
                keys={[
                  "MEAL_LOW",
                  "MEAL_MEDIUM",
                  "MEAL_HIGH",
                ]}
                limitTimes={["00:00", "00:45", "01:00"]}
                loading={isLoadingDashboardStatus}
                selectedKey={filters.dash_status}
                onClick={onSetMacrosListFilter}
              />
            </div>
            <div className="pt-2">
              <StatusFilter
                label="Intrajornada"
                icon={<Truck width={iconWidth} variant={iconVariant} color={Colors.gray_2} />}
                values={[
                  status.INTRADAY_REST_LOW,
                  status.INTRADAY_REST_MEDIUM,
                  status.INTRADAY_REST_HIGH,
                ]}
                keys={[
                  "INTRADAY_REST_LOW",
                  "INTRADAY_REST_MEDIUM",
                  "INTRADAY_REST_HIGH",
                ]}
                limitTimes={["00:00", "00:45", "01:00"]}
                loading={isLoadingDashboardStatus}
                selectedKey={filters.dash_status}
                onClick={onSetMacrosListFilter}
              />
            </div>
            <div className="pt-2">
              <StatusFilter
                label="Interjornada"
                icon={<Moon width={iconWidth} variant={iconVariant} color={Colors.gray_2} />}
                values={[
                  status.INTERDAY_REST_LOW,
                  status.INTERDAY_REST_MEDIUM,
                  status.INTERDAY_REST_HIGH,
                ]}
                keys={[
                  "INTERDAY_REST_LOW",
                  "INTERDAY_REST_MEDIUM",
                  "INTERDAY_REST_HIGH",
                ]}
                limitTimes={["00:00", "09:00", "11:00"]}
                loading={isLoadingDashboardStatus}
                selectedKey={filters.dash_status}
                onClick={onSetMacrosListFilter}
              />
            </div>
            <div className="pt-2">
              <StatusFilter
                label="Compensação"
                icon={<Moon width={iconWidth} variant={iconVariant} color={Colors.gray_2} />}
                values={[
                  status.INTERDAY_COMPENSATION_LOW,
                  status.INTERDAY_COMPENSATION_MEDIUM,
                  status.INTERDAY_COMPENSATION_HIGH,
                ]}
                keys={[
                  "INTERDAY_COMPENSATION_LOW",
                  "INTERDAY_COMPENSATION_MEDIUM",
                  "INTERDAY_COMPENSATION_HIGH",
                ]}
                limitTimes={["00:00", "09:00", "11:00"]}
                loading={isLoadingDashboardStatus}
                selectedKey={filters.dash_status}
                onClick={onSetMacrosListFilter}
              />
            </div>
            <div className="pt-2">
              <StatusFilter
                label="Disponível"
                icon={<CalendarTick width={iconWidth} variant={iconVariant} color={Colors.gray_2} />}
                values={[
                  status.AVAILABLE_LOW,
                  status.AVAILABLE_MEDIUM,
                  status.AVAILABLE_HIGH,
                ]}
                keys={["AVAILABLE_LOW", "AVAILABLE_MEDIUM", "AVAILABLE_HIGH"]}
                limitTimes={["00:00", "00:30", "01:00"]}
                loading={isLoadingDashboardStatus}
                selectedKey={filters.dash_status}
                onClick={onSetMacrosListFilter}
              />
            </div>
            <div className="pt-2">
              <StatusFilter
                label="Hora Extra"
                icon={<TimerStart width={iconWidth} variant={iconVariant} color={Colors.gray_2} />}
                values={[
                  status.EXTRA_HOUR_LOW,
                  status.EXTRA_HOUR_MEDIUM,
                  status.EXTRA_HOUR_HIGH,
                ]}
                keys={[
                  "EXTRA_HOUR_LOW",
                  "EXTRA_HOUR_MEDIUM",
                  "EXTRA_HOUR_HIGH",
                ]}
                limitTimes={extraHoursStatusParam(adminData.company)}
                loading={isLoadingDashboardStatus}
                selectedKey={filters.dash_status}
                onClick={onSetMacrosListFilter}
              />
            </div>
            <div className="pt-2">
              <StatusFilter
                label="Em Espera"
                icon={<TimerPause width={iconWidth} variant={iconVariant} color={Colors.gray_2} />}
                values={[
                  status.WAITING_HOURS_LOW,
                  status.WAITING_HOURS_MEDIUM,
                  status.WAITING_HOURS_HIGH,
                ]}
                keys={[
                  "WAITING_HOURS_LOW",
                  "WAITING_HOURS_MEDIUM",
                  "WAITING_HOURS_HIGH",
                ]}
                limitTimes={["00:00", "00:30", "01:00"]}
                loading={isLoadingDashboardStatus}
                selectedKey={filters.dash_status}
                onClick={onSetMacrosListFilter}
              />
            </div>
            {/* <div className="pt-2">
              <StatusFilter
                label="Parado"
                icon={<WarningIcon width={iconWidth} variant={iconVariant} color={Colors.gray_2} />}
                values={[
                  status.STOPPED_LOW,
                  status.STOPPED_MEDIUM,
                  status.STOPPED_HIGH,
                ]}
                keys={["STOPPED_LOW", "STOPPED_MEDIUM", "STOPPED_HIGH"]}
                limitTimes={["00:00", "00:30", "01:00"]}
                loading={isLoadingDashboardStatus}
                selectedKey={filters.dash_status}
                onClick={onSetMacrosListFilter}
              />
            </div>
            <div className="pt-2">
              <StatusFilter
                label="Sem Sinal"
                icon={<NoSignalIcon width={iconWidth} variant={iconVariant} color={Colors.gray_2} />}
                values={[
                  status.NO_SIGNAL_LOW,
                  status.NO_SIGNAL_MEDIUM,
                  status.NO_SIGNAL_HIGH,
                ]}
                keys={["NO_SIGNAL_LOW", "NO_SIGNAL_MEDIUM", "NO_SIGNAL_HIGH"]}
                limitTimes={["00:00", "00:30", "01:00"]}
                loading={isLoadingDashboardStatus}
                selectedKey={filters.dash_status}
                onClick={onSetMacrosListFilter}
              />
            </div>
            <div className="flex flex-col gap-4 pt-4">
              <SearchInput
                onChange={(vehicle: string) =>
                  setFilters({ ...filters, vehicle })
                }
                placeholder="Placa"
                labelClassName="w-full"
                showIcon={false}
              />
              <SearchInput
                onChange={(user: string) => setFilters({ ...filters, user })}
                placeholder="Motorista"
                labelClassName="w-full"
                showIcon={false}
              />
            </div> */}
          </div>
        </ShadowBox>
        <div className="shadow-base py-5 px-8 rounded-[12px] bg-white flex flex-col justify-between w-full">
          <DashboardMacrosTable />
          <div className="flex justify-between items-center">
            <div className="text-gray_1 text-sm">
              Mostrando{" "}
              {!isLoadingDashboardMacro ? (
                macrosList.data.length +
                macrosList.offset * macrosList.limitPerPage
              ) : (
                <Skeleton inline width={20} />
              )}{" "}
              de {macrosList.totalItems} registros
            </div>
            <div className="flex gap-5 items-center">
              <LimitPerPage
                onLimitChange={(e: any) =>
                  macrosList.setLimitPerPage(e.target.value)
                }
              />
              <Pagination
                currentPage={macrosList.currentPage}
                totalPages={macrosList.totalPages}
                onPageChange={macrosList.setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardStatusPanel;
