import { JourneyRule } from "./journeyRule";
import { JourneyEvent } from "./macroState";

export interface Holiday {
  _id?: string;
  name: string;
  type: string;
  date: string;
  company?: string;
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

export interface Syndicate {
  _id?: string;
  name: string;
  operation_area: string;
  company?: string;
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

export interface Section {
  _id?: string;
  name: string;
  description: string;
  company?: string;
  syndicate: Syndicate;
  journey_rule: JourneyRule;
  users: string[];
  holidays: Holiday[];
  journey_events: JourneyEvent[];
  config: {
    hour_value: number;
    meal_value: number;
    overnight_value: number;
  }
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

export interface ConventionState {
  conventions: {
    holidaysList: {
      data: Holiday[];
      holidaysSelected: string[];
      currentPage: number;
      totalItems: number;
      totalPages: number;
      limitPerPage: number;
      offset: number;
      columnsVisibility: HolidaysVisibleColumns;
      filters: HolidayFilter;
      toggleColumnVisibility: (key: string, value: boolean) => void;
      setLimitPerPage: (limitPerPage: number) => void;
      setCurrentPage: (page: number) => void;
      setFilters: (filters: HolidayFilter) => void;
    };
    syndicatesList: {
      data: Syndicate[];
      currentPage: number;
      totalItems: number;
      totalPages: number;
      limitPerPage: number;
      offset: number;
      columnsVisibility: SyndicatesVisibleColumns;
      filters: SyndicateFilter;
      toggleColumnVisibility: (key: string, value: boolean) => void;
      setLimitPerPage: (limitPerPage: number) => void;
      setCurrentPage: (page: number) => void;
      setFilters: (filters: SyndicateFilter) => void;
    };
    sectionsList: {
      data: Section[];
      currentPage: number;
      totalItems: number;
      totalPages: number;
      limitPerPage: number;
      offset: number;
      columnsVisibility: SectionsVisibleColumns;
      filters: SectionFilter;
      toggleColumnVisibility: (key: string, value: boolean) => void;
      setLimitPerPage: (limitPerPage: number) => void;
      setCurrentPage: (page: number) => void;
      setFilters: (filters: SectionFilter) => void;
    };
    selectedSyndicate: Syndicate | null;
    isLoadingSyndicatesList: boolean;
    isLoadingSyndicate: boolean;
    isCreatingSyndicate: boolean;
    isDeletingSyndicate: boolean;
    isUpdatingSyndicate: boolean;
    isExportingSyndicate: { csv: boolean; pdf: boolean };

    selectedHoliday: Holiday | null;
    isLoadingHolidaysList: boolean;
    isLoadingHoliday: boolean;
    isCreatingHoliday: boolean;
    isDeletingHoliday: boolean;
    isUpdatingHoliday: boolean;
    isExportingHoliday: { csv: boolean; pdf: boolean };

    selectedSection: Section | null;
    isLoadingSectionsList: boolean;
    isLoadingSection: boolean;
    isCreatingSection: boolean;
    isDeletingSection: boolean;
    isUpdatingSection: boolean;
    isExportingSection: { csv: boolean; pdf: boolean };
    isLoadingJourneyEventsList: boolean;
    journeyEventsList: JourneyEvent[];
    journeyEventsSelected: string[];

    getHolidays: () => void;
    getHoliday: (id: string) => void;
    createHoliday: (holiday: Holiday) => void;
    updateHoliday: (holidayId: string, holiday: Holiday) => void;
    deleteHoliday: (holidayId: string) => void;
    exportHolidayTable: (format: "csv" | "pdf") => void;
    resetHolidaysListState: () => void;
    resetSelectedHoliday: () => void;
    selectHoliday: (holidayId: string | undefined) => void;
    unselectHoliday: (holidayId: string | undefined) => void;

    getSyndicates: () => void;
    getSyndicate: (id: string) => void;
    createSyndicate: (syndicate: Syndicate) => void;
    updateSyndicate: (syndicateId: string, syndicate: Syndicate) => void;
    deleteSyndicate: (syndicateId: string) => void;
    exportSyndicateTable: (format: "csv" | "pdf") => void;
    resetSyndicatesListState: () => void;
    resetSelectedSyndicate: () => void;

    getSections: () => void;
    getSection: (id: string) => void;
    createSection: (section: CreateSectionData) => void;
    updateSection: (sectionId: string, section: CreateSectionData) => void;
    deleteSection: (sectionId: string) => void;
    exportSectionTable: (format: "csv" | "pdf") => void;
    resetSectionsListState: () => void;
    resetSelectedSection: () => void;
    
    getJourneyEvents: () => void;
    selectJourneyEvent: (journeyEventType: string | undefined) => void;
    unselectJourneyEvent: (journeyEventType: string | undefined) => void;
  };
}

export enum HOLIDAY_TYPE {
  NATIONAL="NATIONAL",
  STATE="STATE",
  CITY="CITY",
}

export enum HOLIDAY_TYPE_TRANSLATION {
  NATIONAL="Nacional",
  STATE="Estadual",
  CITY="Municipal",
}

export interface HolidayFilter {
  name: string;
  type: string;
  date: string;
  sort_key: string;
  sort_value: string;
}

export type HolidaysVisibleColumns = {
  name: boolean;
  type: boolean;
  date: boolean;
  created_at: boolean;
};

export type HolidaysVisibleColumnsKey = keyof HolidaysVisibleColumns;

export interface SyndicateFilter {
  name: string;
  operation_area: string;
  sort_key: string;
  sort_value: string;
}

export type SyndicatesVisibleColumns = {
  name: boolean;
  operation_area: boolean;
  created_at: boolean;
};

export type SyndicatesVisibleColumnsKey = keyof SyndicatesVisibleColumns;

export interface SectionFilter {
  name: string;
  description: string;
  sort_key: string;
  sort_value: string;
}

export type SectionsVisibleColumns = {
  name: boolean;
  description: boolean;
  created_at: boolean;
};

export type SectionsVisibleColumnsKey = keyof SectionsVisibleColumns;

export interface CreateSectionData {
  name: string;
  syndicate: string;
  journey_rule: string;
  users: any[];
  holidays: any[];
  journey_events: any[];
  config?: {
    hour_value?: number;
  }
}