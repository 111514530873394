interface TabProps {
  icon: JSX.Element;
  label: string;
}

const Tab: React.FC<TabProps> = ({ icon, label }) => {
  return (
    <div className="flex gap-2 items-center">
      <div className="text-dark-blue">{icon}</div>
      <div>{label}</div>
    </div>
  );
};

export default Tab;
