import { SearchNormal1 } from "iconsax-react";
import InfoField from "../../../components/InfoField/InfoField";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import Modal from "../../../components/Modal/Modal";
import { REGIME_TRANSLATION } from "../../../store/types/journeyRule";
import { useStore } from "../../../store/useStore";
import { Colors } from "../../../utils/constants/style";
import { formatDateTime } from "../../../utils/functions/formatters";

interface DetailsJourneyRuleModalProps {
  onClose: () => void;
}

const DetailsJourneyRuleModal: React.FC<DetailsJourneyRuleModalProps> = ({
  onClose,
}) => {
  const selectedJourneyRule = useStore(
    (state) => state.journeyRules.selectedJourneyRule
  );
  const isLoadingJourneyRule = useStore(
    (state) => state.journeyRules.isLoadingJourneyRule
  );

  return (
    <Modal
      title="Detalhes de regra de jornada"
      icon={<SearchNormal1 size="30" color={Colors.white} variant="Linear" />}
      className="min-w-[479px] max-w-[579px]"
      onClose={onClose}
    >
      <div className="flex flex-col gap-5">
        <InfoFieldBox>
          <InfoField
            loading={isLoadingJourneyRule}
            label="Nome"
            content={selectedJourneyRule?.name}
          />
          <InfoField
            loading={isLoadingJourneyRule}
            label="Descrição"
            content={selectedJourneyRule?.description}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingJourneyRule}
            label="Regime"
            content={
              selectedJourneyRule?.regime
                ? REGIME_TRANSLATION[selectedJourneyRule?.regime]
                : "-"
            }
          />
          <InfoField
            loading={isLoadingJourneyRule}
            label="Criado em"
            content={formatDateTime(selectedJourneyRule?.created_at ?? "")}
          />
        </InfoFieldBox>
      </div>
    </Modal>
  );
};

export default DetailsJourneyRuleModal;
