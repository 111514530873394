import { EditIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import { Colors } from "../../../utils/constants/style";
import { useEffect } from "react";
import { useStore } from "../../../store/useStore";
import OutlinedMaskedInput from "../../../components/FormComponents/OutlinedMaskedInput/OutlinedMaskedInput";
import {
  formatCPF,
  formatPhoneNumber,
  removeNumberMask,
} from "../../../utils/functions/formatters";
import { Collaborator } from "../../../store/types/collaborator";

const EditProfileModal: React.FC<{}> = () => {
  const profile = useStore((state) => state.profile.profile);
  const updateProfile = useStore((state) => state.profile.updateProfile);
  const isUpdatingProfile = useStore(
    (state) => state.profile.isUpdatingProfile
  );
  const isLoadingProfile = useStore((state) => state.profile.isLoadingProfile);

  return (
    <Modal
      title={"Atualizar perfil"}
      icon={<EditIcon width={30} height={30} color={Colors.white} />}
      className="w-[447px]"
    >
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          registration: "",
          cpf: "",
          rg: "",
          cnh: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Campo obrigatório"),
          email: Yup.string().email("Formato de e-mail inválido"),
          phone: Yup.string()
            .length(19, "O telefone deve conter exatamente 13 números")
            .required("Campo obrigatório"),
          registration: Yup.string(),
          cpf: Yup.string()
            .matches(
              /\d{2}\.\d{3}\.\d{3}-\d{2}$/,
              "O CPF deve conter exatamente 11 números"
            )
            .required("Campo obrigatório"),
          rg: Yup.string().length(9, "O RG deve conter exatamente 9 números"),
          cnh: Yup.string().max(12, "A CNH deve conter no máximo 12 números"),
        })}
        onSubmit={(values) => {
          if (profile?._id) {
            const data = {
              ...values,
              ...(values.cpf ? { cpf: removeNumberMask(values.cpf) } : {}),
              ...(values.phone
                ? { phone: removeNumberMask(values.phone) }
                : {}),
            } as Collaborator;

            updateProfile(profile._id, data);
          }
        }}
      >
        {(formik) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (profile) {
              const selectedCollaboratorCopy = {
                ...profile,
                ...(profile.phone
                  ? { phone: formatPhoneNumber(profile.phone) }
                  : {}),
                ...(profile.cpf ? { cpf: formatCPF(profile.cpf) } : {}),
              };
              delete selectedCollaboratorCopy._id;
              delete selectedCollaboratorCopy.company;
              delete selectedCollaboratorCopy.deleted_at;
              delete selectedCollaboratorCopy.updated_at;
              delete selectedCollaboratorCopy.created_at;
              formik.setValues(selectedCollaboratorCopy);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [profile]);

          return (
            <Form>
              <div className="flex flex-col gap-8 pt-5">
                <div className="flex gap-8">
                  <div className="flex-1 flex flex-col gap-8">
                    <Field
                      loading={isLoadingProfile}
                      placeholder="Nome"
                      name="name"
                      component={OutlinedInput}
                    />
                    <Field
                      loading={isLoadingProfile}
                      placeholder="E-mail"
                      name="email"
                      component={OutlinedInput}
                    />
                    <Field
                      loading={isLoadingProfile}
                      placeholder="Telefone"
                      name="phone"
                      mask={"+99 (99) 99999-9999"}
                      alwaysShowMask={false}
                      component={OutlinedMaskedInput}
                    />
                    <Field
                      loading={isLoadingProfile}
                      placeholder="Matrícula"
                      name="registration"
                      component={OutlinedInput}
                    />
                  </div>
                  <div className="flex-1 flex flex-col gap-8">
                    <Field
                      loading={isLoadingProfile}
                      placeholder="CPF"
                      name="cpf"
                      component={OutlinedMaskedInput}
                      mask="999.999.999-99"
                    />
                    <Field
                      loading={isLoadingProfile}
                      placeholder="RG"
                      name="rg"
                      component={OutlinedMaskedInput}
                      mask="999999999"
                    />
                    <Field
                      loading={isLoadingProfile}
                      placeholder="CNH"
                      name="cnh"
                      component={OutlinedMaskedInput}
                      mask="999999999999"
                    />
                  </div>
                </div>

                <div className="flex gap-5">
                  <div className="flex-1"></div>
                  <div className="flex-1">
                    <FilledButton
                      loading={isUpdatingProfile}
                      type="submit"
                      className="w-full"
                    >
                      Atualizar
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditProfileModal;
