import Feature from '../../components/feature';
import './features-style.css';
import { Fade } from 'react-awesome-reveal';

export default function Features(props: { contentClass: string;}) {
  const masterClass = props.contentClass + " padding-features";

  if(props.contentClass == "content-mobile") {
    return (
      <section id="features">
        <div className={masterClass}>
          <Fade cascade damping={0.4}>
            <h1 className="feature-h1-mobile">Conheça as funcionalidades</h1>
            <div className="features-list">
                <Feature icon='first' label='Múltiplas frentes' description='Aplicativo voltado aos motoristas e interface web para os gestores' mobile={true}/>
                <Feature icon='seccond' label='Tempo real' description='Gerencie a jornada de toda a frota em tempo real e saiba onde estão os motoristas' mobile={true}/>
            </div>
            <div className="features-list">
              <Feature icon='fourth' label='Uso fácil' description='Tanto a interface web quanto o aplicativo foram pensados para facilitar a jornada' mobile={true}/>
              <Feature icon='third' label='Parametrize' description='Configure a jornada de trabalho de acordo com as demandas de sua empresa' mobile={true}/>
            </div>
            <div className="features-list">
              <Feature icon='fifth' label='Portaria 671' description='Estamos adaptado às demandas da mais nova lei referente ao transporte, Lei 13.103/2015' mobile={true}/>
              <Feature icon='sixth' label='Pagamento' description='Gerencie os pagamentos com a assinatura dos motoristas' mobile={true}/>
            </div>
          </Fade>
        </div>
      </section>
    )
  }
  return (
    <section id="features">
      <div className={masterClass}>
        <Fade cascade damping={0.4}>
          <h1 className="style-h1">Conheça as funcionalidades</h1>
          <div className="features-list">
              <Feature icon='first' label='Múltiplas frentes' description='Aplicativo voltado aos motoristas e interface web para os gestores' mobile={false}/>
              <Feature icon='seccond' label='Tempo real' description='Gerencie a jornada de toda a frota em tempo real e saiba onde estão os motoristas' mobile={false}/>
              <Feature icon='third' label='Parametrize' description='Configure a jornada de trabalho de acordo com as demandas de sua empresa' mobile={false}/>
          </div>
          <div className="features-list">
            <Feature icon='fourth' label='Uso fácil' description='Tanto a interface web quanto o aplicativo foram pensados para facilitar a jornada' mobile={false}/>
            <Feature icon='fifth' label='Portaria 671' description='Estamos adaptado às demandas da mais nova lei referente ao transporte, Lei 13.103/2015' mobile={false}/>
            <Feature icon='sixth' label='Pagamento' description='Gerencie os pagamentos com a assinatura dos motoristas' mobile={false}/>
          </div>
        </Fade>
      </div>
    </section>
  );
}
