import { StateCreator } from "zustand";
import { ProfileState } from "../types/profile";
import { Collaborator } from "../types/collaborator";
import { toast } from "react-toastify";
import { useStore } from "../useStore";
import collaboratorService from "../../services/collaboratorService";
import { setLoadingState } from "../../utils/functions/functions";
import { produce } from "immer";
import companyService from "../../services/companyService";

export const createProfileSlice: StateCreator<ProfileState> = (set, get) => ({
  profile: {
    company: null,
    profile: null,
    isUpdatingProfile: false,
    isUpdatingPassword: false,
    isLoadingProfile: false,
    isLoadingCompany: false,
    getProfile: () => {
      set(setLoadingState("profile", "isLoadingProfile", true));
      set(setLoadingState("profile", "isLoadingCompany", true));

      const adminData = useStore.getState().admin.data;

      collaboratorService
        .getCollaborator(adminData._id)
        .then((data) => {
          set(
            produce((state) => {
              state.profile.profile = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(setLoadingState("profile", "isLoadingProfile", false));
        });

      companyService
        .getCompany(adminData.company)
        .then((data) => {
          set(
            produce((state) => {
              state.profile.company = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(setLoadingState("profile", "isLoadingCompany", false));
        });
    },
    updateProfile: (
      collaboratorId: string,
      collaborator: Partial<Collaborator>
    ) => {
      set(setLoadingState("profile", "isUpdatingProfile", true));

      collaboratorService
        .updateCollaborator(collaboratorId, collaborator)
        .then((data) => {
          toast.success("Usuário atualizado com sucesso");
          get().profile.getProfile();
          useStore.getState().modal.setIsModalOpen(false);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(setLoadingState("profile", "isUpdatingProfile", false));
        });
    },
    updatePassword: (passwords: {
      currentPassword: string;
      newPassword: string;
    }) => {
      set(setLoadingState("profile", "isUpdatingPassword", true));

      const adminId = useStore.getState().admin.data._id;

      collaboratorService
        .updatePassword(adminId, passwords)
        .then(() => {
          toast.success("Senha atualizada com sucesso");
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(setLoadingState("profile", "isUpdatingPassword", false));
        });
    },
  },
});
