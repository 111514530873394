import { useEffect } from "react";
import DataPanel from "../../../components/DataPanel/DataPanel";
import Pagination from "../../../components/Pagination/Pagination";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import useModal from "../../../hooks/useModal";
import { useStore } from "../../../store/useStore";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import Skeleton from "react-loading-skeleton";
import FilterMacroLinkModal from "./FilterMacroLinkModal";
import OptionsMacroLinkModal from "./OptionsMacroLinkModal";
import MacrosLinkSelectTable from "./MacrosLinkTable";
import LinkMacroModal from "./MacroLinkModal";
import { Filter, Setting2, UserAdd } from "iconsax-react";

const MacroLink: React.FC<{}> = () => {
  const { openModal } = useModal();
  const getMacrosLink = useStore((state) => state.macro.getMacrosLink);
  const macrosLinkList = useStore((state) => state.macro.macrosLinkList);
  const isLoadingmacrosLinkList = useStore(
    (state) => state.macro.isLoadingMacrosLinkList
  );
  const macrosSelected = useStore((state) => state.macro.macrosLinkList.macrosSelected);

  useEffect(() => {
    getMacrosLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openFilterMacroModal = () => openModal(<FilterMacroLinkModal />);
  const openOptionsMacroModal = () => openModal(<OptionsMacroLinkModal />);

  const openLinkMacroModal = () =>
    openModal(<LinkMacroModal />);

  return (
      <div className="mt-9">
        <DataPanel className="px-3 pt-8 flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="pl-1">
              {/* <SearchInput placeholder="Buscar registro" onChange={() => {}} /> */}
            </div>
            <div className="pr-1 flex gap-4">
              <RoundedFilledButton
                type="button"
                onClick={openLinkMacroModal}
                disabled={macrosSelected.length === 0}
                icon={
                  <span className="mt-[-1px]">
                    <UserAdd />
                  </span>
                }
                text="Vincular"
              />
              <RoundedFilledButton
                onClick={openFilterMacroModal}
                type="button"
                icon={<Filter size={24} />}
                text="Filtros"
              />
              <RoundedFilledButton
                onClick={openOptionsMacroModal}
                type="button"
                icon={<Setting2 />}
                text="Opções"
              />
            </div>
          </div>
          <MacrosLinkSelectTable />
          <div className="flex justify-between items-center">
            <div className="text-gray_1">
              Mostrando{" "}
              {!isLoadingmacrosLinkList ? (
                macrosLinkList.data.length +
                macrosLinkList.offset * macrosLinkList.limitPerPage
              ) : (
                <Skeleton inline width={20} />
              )}{" "}
              de {macrosLinkList.totalItems} registros
            </div>
            <div className="flex gap-5 items-center">
              <LimitPerPage
                onLimitChange={(e: any) =>
                  macrosLinkList.setLimitPerPage(e.target.value)
                }
              />
              <Pagination
                currentPage={macrosLinkList.currentPage}
                totalPages={macrosLinkList.totalPages}
                onPageChange={macrosLinkList.setCurrentPage}
              />
            </div>
          </div>
        </DataPanel>
      </div>
  );
};

export default MacroLink;
