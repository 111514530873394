import { SearchNormal1 } from "iconsax-react";
import { SearchIcon } from "../../../assets/imgs";
import InfoField from "../../../components/InfoField/InfoField";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import Modal from "../../../components/Modal/Modal";
import { useStore } from "../../../store/useStore";
import { Colors } from "../../../utils/constants/style";
import { formatCPF, formatPhoneNumber, formatDateTime } from "../../../utils/functions/formatters";

interface DetailsCollaboratorModalProps {
  onClose: () => void;
}

const DetailsCollaboratorModal: React.FC<DetailsCollaboratorModalProps> = ({
  onClose,
}) => {
  const selectedCollaborator = useStore(
    (state) => state.collaborator.selectedCollaborator
  );
  const isLoadingCollaborator = useStore(
    (state) => state.collaborator.isLoadingCollaborator
  );
  const userPropertiesProjection = useStore(
    (state) => state.admin.data.projection.user
  );

  return (
    <Modal
      title="Detalhes do colaborador"
      icon={<SearchNormal1 size="30" color={Colors.white} variant="Linear" />} 
      className="min-w-[479px]"
      onClose={onClose}
    >
      <div className="flex flex-col gap-5">
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCollaborator}
            label="Nome"
            content={selectedCollaborator?.name}
            show={userPropertiesProjection.includes("name")}
          />
          <InfoField
            loading={isLoadingCollaborator}
            label="E-mail"
            content={selectedCollaborator?.email}
            show={userPropertiesProjection.includes("email")}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCollaborator}
            label="Telefone"
            content={formatPhoneNumber(selectedCollaborator?.phone ?? '')}
            show={userPropertiesProjection.includes("phone")}
          />
          <InfoField
            loading={isLoadingCollaborator}
            label="Registro"
            content={selectedCollaborator?.registration}
            show={userPropertiesProjection.includes("registration")}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCollaborator}
            label="Cargo"
            content={selectedCollaborator?.position?.name}
            show={userPropertiesProjection.includes("position")}
          />
          <InfoField
            loading={isLoadingCollaborator}
            label="CPF"
            content={formatCPF(selectedCollaborator?.cpf ?? '')}
            show={userPropertiesProjection.includes("cpf")}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCollaborator}
            label="RG"
            content={selectedCollaborator?.rg}
            show={userPropertiesProjection.includes("rg")}
          />
          <InfoField
            loading={isLoadingCollaborator}
            label="CNH"
            content={selectedCollaborator?.cnh}
            show={userPropertiesProjection.includes("cnh")}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCollaborator}
            label="PIS"
            content={selectedCollaborator?.pis}
            show={userPropertiesProjection.includes("pis")}
          />
	      </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCollaborator}
            label="Criado em"
            content={formatDateTime(selectedCollaborator?.created_at ?? '')}
            show={userPropertiesProjection.includes("rg")}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCollaborator}
            label="Admitido em"
            content={selectedCollaborator?.hired_at ? formatDateTime(selectedCollaborator.hired_at) : '-'}
            show={userPropertiesProjection.includes("hired_at")}
          />
        </InfoFieldBox>
      </div>
    </Modal>
  );
};

export default DetailsCollaboratorModal;
