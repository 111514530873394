import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik, FormikProps } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import { useStore } from "../../../store/useStore";
import { useEffect } from "react";
import { HOLIDAY_TYPE } from "../../../store/types/convention";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import DayAndMonthPicker from "../../../components/FormComponents/DayAndMonthPicker/DayAndMonthPicker";
import { Filter } from "iconsax-react";
import { Colors } from "../../../utils/constants/style";

const initialValues = {
  name: "",
  type: "",
  date: ""
};

const FilterHolidayModal: React.FC<{}> = () => {
  const setFilters = useStore(
    (state) => state.conventions.holidaysList.setFilters
  );
  const filters = useStore((state) => state.conventions.holidaysList.filters);
  const clearFilter = (formik: FormikProps<typeof initialValues>) => {
    formik.resetForm();
  };

  return (
    <Modal
      title="Filtros de feriado"
      icon={<Filter size="32" variant="Bold" color={Colors.white}/>}
      className="w-[447px]"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string(),
          type: Yup.string(),
          date: Yup.string(),
        })}
        onSubmit={(values) => {
          setFilters({ ...filters, ...values });
        }}
      >
        {(formik) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (filters) formik.setValues(filters);
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [filters]);

          return (
            <Form>
              <div className="flex flex-col gap-8 pt-5">
                <Field
                  placeholder="Nome"
                  name="name"
                  component={OutlinedInput}
                />
                <Field
                  name="type"
                  component={OutlinedSelect}
                  placeholder="Tipo"
                  options={[
                    { label: "Nacional", value: HOLIDAY_TYPE.NATIONAL },
                    { label: "Estadual", value: HOLIDAY_TYPE.STATE },
                    { label: "Municipal", value: HOLIDAY_TYPE.CITY },
                  ]}
                />
                <Field
                  placeholder="Data"
                  name="date"
                  component={DayAndMonthPicker}
                />
                <div className="flex gap-5">
                  <div className="flex-1">
                    <OutlinedButton
                      onClick={() => clearFilter(formik)}
                      className="w-full"
                      type="button"
                    >
                      Limpar Filtros
                    </OutlinedButton>
                  </div>
                  <div className="flex-1">
                    <FilledButton className="w-full" type="submit">
                      Filtrar
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default FilterHolidayModal;
