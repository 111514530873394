import Modal from "../../../components/Modal/Modal";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { Colors } from "../../../utils/constants/style";
import { useStore } from "../../../store/useStore";
import { Danger, Trash } from "iconsax-react";

const DeleteCollaboratorModal: React.FC<{}> = () => {
  const selectedCollaborator = useStore(
    (state) => state.collaborator.selectedCollaborator
  );
  const deleteCollaborator = useStore(
    (state) => state.collaborator.deleteCollaborator
  );
  const isDeletingCollaborator = useStore(
    (state) => state.collaborator.isDeletingCollaborator
  );

  const handleDeleteCollaborator = () => {
    if(selectedCollaborator?._id)
      deleteCollaborator(selectedCollaborator._id);
  };

  return (
    <Modal
      title="Exclusão de colaborador"
      icon={<Trash size={30} variant="Bold" color={Colors.white} />}
      className="w-[361px]"
    >
      <div className="text-center flex flex-col items-center gap-5">
        <Danger size="72" variant="Bulk" color={Colors.red}/>
        <div className="px-5">
          Tem certeza que deseja remover o colaborador de nome {selectedCollaborator?.name ?? '-'}?
        </div>
        <FilledButton loading={isDeletingCollaborator} onClick={handleDeleteCollaborator} className="px-14">
          Excluir
        </FilledButton>
      </div>
    </Modal>
  );
};

export default DeleteCollaboratorModal;
