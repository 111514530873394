import { PlusIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import { useStore } from "../../../store/useStore";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import DayAndMonthPicker from "../../../components/FormComponents/DayAndMonthPicker/DayAndMonthPicker";
import { HOLIDAY_TYPE } from "../../../store/types/convention";

const CreateHolidayModal: React.FC<{}> = () => {
  const createHoliday = useStore((state) => state.conventions.createHoliday);
  const isCreatingHoliday = useStore(
    (state) => state.conventions.isCreatingHoliday
  );

  return (
    <Modal
      title="Cadastro de novo feriado"
      icon={<PlusIcon width={30} height={30} />}
      className="w-[447px]"
    >
      <Formik
        initialValues={{
          name: "",
          type: "",
          date: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Campo obrigatório"),
          type: Yup.string().required("Campo obrigatório"),
          date: Yup.string().required("Campo obrigatório"),
        })}
        onSubmit={(values) => {
          createHoliday(values);
        }}
      >
        <Form>
          <div className="flex flex-col gap-8 pt-5">
            <Field placeholder="Nome" name="name" component={OutlinedInput} />
            <Field
              name="type"
              component={OutlinedSelect}
              placeholder="Tipo"
              options={[
                { label: "Nacional", value: HOLIDAY_TYPE.NATIONAL },
                { label: "Estadual", value: HOLIDAY_TYPE.STATE },
                { label: "Municipal", value: HOLIDAY_TYPE.CITY },
              ]}
            />
            <Field
              placeholder="Data"
              name="date"
              component={DayAndMonthPicker}
            />
            <div className="flex gap-5">
              <div className="flex-1"></div>
              <div className="flex-1">
                <FilledButton
                  loading={isCreatingHoliday}
                  className="w-full"
                  type="submit"
                >
                  Cadastrar
                </FilledButton>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default CreateHolidayModal;
