interface TabPanelProps {
  tab: number;
  index: number;
  children: JSX.Element;
}

const TabPanel: React.FC<TabPanelProps> = ({ tab, index, children }) => {
  return tab === index ? children : null;
};

export default TabPanel;
