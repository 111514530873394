import { Airdrop, Building4, MoneyRecive, MonitorMobbile, Truck } from 'iconsax-react';

export default function Conquest(props: { icon: string; title: string; description: string; mobile: boolean}) {
  let icon;
  const iconSize = 48;
  const color = "#ED3A3A";

  switch(props.icon) {
    case "first" :
      icon = <Airdrop size={iconSize} color={color} variant='Bulk' />;
      break;

    case "seccond" :
      icon = <MoneyRecive size={iconSize} color={color} variant='Bulk' />;
      break;

    case "third" :
      icon = <Truck size={iconSize} color={color} variant='Bulk' />;
      break;
        
    case "fourth" :
      icon = <Building4 size={iconSize} color={color} variant='Bulk' />;
      break;
      
    default :
      icon = <MonitorMobbile size={iconSize} color={color} variant='Bold' />;
      break;
  }

  if(props.mobile) {
      return (
        <div style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 20
        }}>
          <div style={{
            marginRight: 20,
            position: 'relative',
            margin: 'auto'
          }}>
            {icon}
          </div>
          <div style={{
            width: 200,
            position: 'relative',
            top: '10%',
            textAlign: 'initial'
          }}>
            <p style={{
              fontSize: 24,
              fontWeight: 'bold'
            }}>{props.title}</p>
            <p style={{ fontSize: 12 }}>{props.description}</p>
          </div>
        </div>
    );
  }

  return (
      <div style={{
        display: "flex",
      }}>
        <div style={{
          marginRight: 20,
          position: 'relative',
          top: '25%',
        }}>
          {icon}
        </div>
        <div style={{
          width: 200,
          position: 'relative',
          top: '10%',
        }}>
          <p style={{
            fontSize: 32,
            fontWeight: 'bold'
          }}>{props.title}</p>
          <p style={{ fontSize: 12 }}>{props.description}</p>
        </div>
      </div>
  );
}
