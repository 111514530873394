import { StateCreator } from "zustand";
import { produce } from "immer";
import { toast } from "react-toastify";
import { useStore } from "../useStore";
import {
  ConventionState,
  CreateSectionData,
  Holiday,
  HolidaysVisibleColumnsKey,
  SectionsVisibleColumnsKey,
  Syndicate,
  SyndicatesVisibleColumnsKey,
} from "../types/convention";
import conventionService from "../../services/conventionService";
import { downloadURI } from "../../utils/functions/download";
import macroService from "../../services/macroService";

const initialHolidaysListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  columnsVisibility: {
    name: true,
    type: true,
    date: true,
    created_at: true,
  },
  filters: {
    name: "",
    type: "",
    date: "",
    sort_key: "name",
    sort_value: "asc",
  },
  holidaysSelected: []
};

const initialSyndicatesListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  columnsVisibility: {
    name: true,
    operation_area: true,
    created_at: true,
  },
  filters: {
    name: "",
    operation_area: "",
    sort_key: "name",
    sort_value: "asc",
  },
};

const initialSectionsListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  columnsVisibility: {
    name: true,
    description: true,
    created_at: true,
  },
  filters: {
    name: "",
    description: "",
    sort_key: "name",
    sort_value: "asc",
  },
};

export const createConventionSlice: StateCreator<ConventionState> = (
  set,
  get
) => ({
  conventions: {
    holidaysList: {
      ...initialHolidaysListData,
      toggleColumnVisibility: (key: string, value: boolean) => {
        set(
          produce((state) => {
            state.conventions.holidaysList.columnsVisibility[key] = !value;
          })
        );
      },
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.conventions.holidaysList.limitPerPage = limitPerPage;
            state.conventions.holidaysList.offset = 0;
            state.conventions.holidaysList.currentPage = 1;
          })
        );
        get().conventions.getHolidays();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.conventions.holidaysList.currentPage = page;
            state.conventions.holidaysList.offset = page - 1;
          })
        );
        get().conventions.getHolidays();
      },
      setFilters: (filters) => {
        set(
          produce((state) => {
            state.conventions.holidaysList.filters = filters;
            state.conventions.holidaysList.offset = 0;
            state.conventions.holidaysList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().conventions.getHolidays();
      },
    },
    syndicatesList: {
      ...initialSyndicatesListData,
      toggleColumnVisibility: (key: string, value: boolean) => {
        set(
          produce((state) => {
            state.conventions.syndicatesList.columnsVisibility[key] = !value;
          })
        );
      },
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.conventions.syndicatesList.limitPerPage = limitPerPage;
            state.conventions.syndicatesList.offset = 0;
            state.conventions.syndicatesList.currentPage = 1;
          })
        );
        get().conventions.getSyndicates();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.conventions.syndicatesList.currentPage = page;
            state.conventions.syndicatesList.offset = page - 1;
          })
        );
        get().conventions.getSyndicates();
      },
      setFilters: (filters) => {
        set(
          produce((state) => {
            state.conventions.syndicatesList.filters = filters;
            state.conventions.syndicatesList.offset = 0;
            state.conventions.syndicatesList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().conventions.getSyndicates();
      },
    },
    sectionsList: {
      ...initialSectionsListData,
      toggleColumnVisibility: (key: string, value: boolean) => {
        set(
          produce((state) => {
            state.conventions.sectionsList.columnsVisibility[key] = !value;
          })
        );
      },
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.conventions.sectionsList.limitPerPage = limitPerPage;
            state.conventions.sectionsList.offset = 0;
            state.conventions.sectionsList.currentPage = 1;
          })
        );
        get().conventions.getSections();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.conventions.sectionsList.currentPage = page;
            state.conventions.sectionsList.offset = page - 1;
          })
        );
        get().conventions.getSections();
      },
      setFilters: (filters) => {
        set(
          produce((state) => {
            state.conventions.sectionsList.filters = filters;
            state.conventions.sectionsList.offset = 0;
            state.conventions.sectionsList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().conventions.getSections();
      },
    },
    selectedHoliday: null,
    isLoadingHoliday: false,
    isCreatingHoliday: false,
    isDeletingHoliday: false,
    isUpdatingHoliday: false,
    isLoadingHolidaysList: false,
    isExportingHoliday: { csv: false, pdf: false },

    selectedSyndicate: null,
    isLoadingSyndicate: false,
    isCreatingSyndicate: false,
    isDeletingSyndicate: false,
    isUpdatingSyndicate: false,
    isLoadingSyndicatesList: false,
    isExportingSyndicate: { csv: false, pdf: false },

    selectedSection: null,
    isLoadingSection: false,
    isCreatingSection: false,
    isDeletingSection: false,
    isUpdatingSection: false,
    isLoadingSectionsList: false,
    isExportingSection: { csv: false, pdf: false },
    isLoadingJourneyEventsList: false,
    journeyEventsList: [],
    journeyEventsSelected: [],

    resetSelectedHoliday: () => {
      set(
        produce((state) => {
          state.conventions.selectedHoliday = null;
          state.conventions.holidaysList.selectedHoliday = [];
        })
      );
    },

    selectHoliday: (holidayId: string | undefined) => {
      if (holidayId === undefined) return;
      set(
        produce((state) => {
          state.conventions.holidaysList.holidaysSelected.push(holidayId);
        })
      );
    },
    unselectHoliday: (holidayId: string | undefined) => {
      if (holidayId === undefined) return;
      set(
        produce((state) => {
          state.conventions.holidaysList.holidaysSelected =
            state.conventions.holidaysList.holidaysSelected.filter(
              (id: string) => id !== holidayId
            );
        })
      );
    },
    getHoliday: (holidayId: string) => {
      set(
        produce((state) => {
          state.conventions.isLoadingHoliday = true;
        })
      );

      conventionService
        .getHoliday(holidayId)
        .then((data) => {
          set(
            produce((state) => {
              state.conventions.selectedHoliday = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isLoadingHoliday = false;
            })
          );
        });
    },
    getHolidays: () => {
      set(
        produce((state) => {
          state.conventions.isLoadingHolidaysList = true;
        })
      );

      const filters = get().conventions.holidaysList.filters;
      const limit = get().conventions.holidaysList.limitPerPage;
      const offset = get().conventions.holidaysList.offset;

      conventionService
        .getHolidays({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.conventions.holidaysList.data = data;
              state.conventions.holidaysList.totalItems = totalItems;
              state.conventions.holidaysList.totalPages = Math.ceil(
                totalItems / state.conventions.holidaysList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isLoadingHolidaysList = false;
            })
          );
        });
    },
    deleteHoliday: (holidayId: string) => {
      set(
        produce((state) => {
          state.conventions.isDeletingHoliday = true;
        })
      );

      conventionService
        .deleteHoliday(holidayId) // Assuming you have a deleteHoliday method in your service
        .then(() => {
          toast.success("Feriado excluído com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().conventions.getHolidays();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isDeletingHoliday = false;
            })
          );
        });
    },
    updateHoliday: (holidayId: string, holiday: Holiday) => {
      set(
        produce((state) => {
          state.conventions.isUpdatingHoliday = true;
        })
      );

      conventionService
        .updateHoliday(holidayId, holiday) // Assuming you have an updateHoliday method in your service
        .then(() => {
          toast.success("Feriado atualizado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().conventions.getHolidays();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isUpdatingHoliday = false;
            })
          );
        });
    },
    createHoliday: (holiday: Holiday) => {
      set(
        produce((state) => {
          state.conventions.isCreatingHoliday = true;
        })
      );

      conventionService
        .createHoliday(holiday)
        .then(() => {
          toast.success("Feriado cadastrado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().conventions.getHolidays();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isCreatingHoliday = false;
            })
          );
        });
    },

    exportHolidayTable: (format: "csv" | "pdf") => {
      set(
        produce((state) => {
          state.conventions.isExportingHoliday[format] = true;
        })
      );

      const columnsVisibility =
        get().conventions.holidaysList.columnsVisibility;
      const columnsToExport: HolidaysVisibleColumnsKey[] = Object.keys(
        columnsVisibility
      )
        .filter((key) => columnsVisibility[key as HolidaysVisibleColumnsKey])
        .map((key) => key as HolidaysVisibleColumnsKey);

      conventionService
        .exportHoliday(format, columnsToExport) // Assuming you have an exportHoliday method in your service
        .then((data) => {
          downloadURI(data.fileUrl, data.fileName);
          toast.success("Documento exportado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isExportingHoliday[format] = false;
            })
          );
        });
    },
    resetHolidaysListState: () => {
      set(
        produce((state) => {
          state.conventions.holidaysList = {
            ...state.conventions.holidaysList,
            ...initialHolidaysListData,
          };
        })
      );
    },
    resetSelectedSyndicate: () => {
      set(
        produce((state) => {
          state.conventions.selectedSyndicate = null;
        })
      );
    },
    getSyndicate: (syndicateId: string) => {
      set(
        produce((state) => {
          state.conventions.isLoadingSyndicate = true;
        })
      );

      conventionService
        .getSyndicate(syndicateId)
        .then((data) => {
          set(
            produce((state) => {
              state.conventions.selectedSyndicate = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isLoadingSyndicate = false;
            })
          );
        });
    },
    getSyndicates: () => {
      set(
        produce((state) => {
          state.conventions.isLoadingSyndicatesList = true;
        })
      );

      const filters = get().conventions.syndicatesList.filters;
      const limit = get().conventions.syndicatesList.limitPerPage;
      const offset = get().conventions.syndicatesList.offset;

      conventionService
        .getSyndicates({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.conventions.syndicatesList.data = data;
              state.conventions.syndicatesList.totalItems = totalItems;
              state.conventions.syndicatesList.totalPages = Math.ceil(
                totalItems / state.conventions.syndicatesList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isLoadingSyndicatesList = false;
            })
          );
        });
    },
    deleteSyndicate: (syndicateId: string) => {
      set(
        produce((state) => {
          state.conventions.isDeletingSyndicate = true;
        })
      );

      conventionService
        .deleteSyndicate(syndicateId) // Assuming you have a deleteSyndicate method in your service
        .then(() => {
          toast.success("Sindicato excluído com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().conventions.getSyndicates();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isDeletingSyndicate = false;
            })
          );
        });
    },
    updateSyndicate: (syndicateId: string, syndicate: Syndicate) => {
      set(
        produce((state) => {
          state.conventions.isUpdatingSyndicate = true;
        })
      );

      conventionService
        .updateSyndicate(syndicateId, syndicate) // Assuming you have an updateSyndicate method in your service
        .then(() => {
          toast.success("Sindicato atualizado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().conventions.getSyndicates();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isUpdatingSyndicate = false;
            })
          );
        });
    },
    createSyndicate: (syndicate: Syndicate) => {
      set(
        produce((state) => {
          state.conventions.isCreatingSyndicate = true;
        })
      );

      conventionService
        .createSyndicate(syndicate)
        .then(() => {
          toast.success("Sindicato cadastrado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().conventions.getSyndicates();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isCreatingSyndicate = false;
            })
          );
        });
    },
    exportSyndicateTable: (format: "csv" | "pdf") => {
      set(
        produce((state) => {
          state.conventions.isExportingSyndicate[format] = true;
        })
      );

      const columnsVisibility =
        get().conventions.syndicatesList.columnsVisibility;
      const columnsToExport: SyndicatesVisibleColumnsKey[] = Object.keys(
        columnsVisibility
      )
        .filter((key) => columnsVisibility[key as SyndicatesVisibleColumnsKey])
        .map((key) => key as SyndicatesVisibleColumnsKey);

      conventionService
        .exportSyndicate(format, columnsToExport) // Assuming you have an exportSyndicate method in your service
        .then((data) => {
          downloadURI(data.fileUrl, data.fileName);
          toast.success("Documento exportado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isExportingSyndicate[format] = false;
            })
          );
        });
    },
    resetSyndicatesListState: () => {
      set(
        produce((state) => {
          state.conventions.syndicatesList = {
            ...state.conventions.syndicatesList,
            ...initialSyndicatesListData,
          };
        })
      );
    },

    resetSelectedSection: () => {
      set(
        produce((state) => {
          state.conventions.selectedSection = null;
        })
      );
    },
    getSection: (sectionId: string) => {
      set(
        produce((state) => {
          state.conventions.isLoadingSection = true;
        })
      );

      conventionService
        .getSection(sectionId)
        .then((data) => {
          set(
            produce((state) => {
              state.conventions.selectedSection = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isLoadingSection = false;
            })
          );
        });
    },
    getSections: () => {
      set(
        produce((state) => {
          state.conventions.isLoadingSectionsList = true;
        })
      );

      const filters = get().conventions.sectionsList.filters;
      const limit = get().conventions.sectionsList.limitPerPage;
      const offset = get().conventions.sectionsList.offset;

      conventionService
        .getSections({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.conventions.sectionsList.data = data;
              state.conventions.sectionsList.totalItems = totalItems;
              state.conventions.sectionsList.totalPages = Math.ceil(
                totalItems / state.conventions.sectionsList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isLoadingSectionsList = false;
            })
          );
        });
    },
    deleteSection: (sectionId: string) => {
      set(
        produce((state) => {
          state.conventions.isDeletingSection = true;
        })
      );

      conventionService
        .deleteSection(sectionId) // Assuming you have a deleteSection method in your service
        .then(() => {
          toast.success("Seção excluída com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().conventions.getSections();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isDeletingSection = false;
            })
          );
        });
    },
    updateSection: (sectionId: string, section: CreateSectionData) => {
      set(
        produce((state) => {
          state.conventions.isUpdatingSection = true;
        })
      );

      conventionService
        .updateSection(sectionId, section) // Assuming you have an updateSection method in your service
        .then(() => {
          toast.success("Seção atualizada com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().conventions.getSections();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isUpdatingSection = false;
            })
          );
        });
    },
    createSection: (section: CreateSectionData) => {
      set(
        produce((state) => {
          state.conventions.isCreatingSection = true;
        })
      );

      conventionService
        .createSection(section)
        .then(() => {
          toast.success("Seção cadastrada com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().conventions.getSections();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isCreatingSection = false;
            })
          );
        });
    },
    exportSectionTable: (format: "csv" | "pdf") => {
      set(
        produce((state) => {
          state.conventions.isExportingSection[format] = true;
        })
      );

      const columnsVisibility =
        get().conventions.sectionsList.columnsVisibility;
      const columnsToExport: SectionsVisibleColumnsKey[] = Object.keys(
        columnsVisibility
      )
        .filter((key) => columnsVisibility[key as SectionsVisibleColumnsKey])
        .map((key) => key as SectionsVisibleColumnsKey);

      conventionService
        .exportSection(format, columnsToExport) // Assuming you have an exportSection method in your service
        .then((data) => {
          downloadURI(data.fileUrl, data.fileName);
          toast.success("Documento exportado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isExportingSection[format] = false;
            })
          );
        });
    },
    resetSectionsListState: () => {
      set(
        produce((state) => {
          state.conventions.sectionsList = {
            ...state.conventions.sectionsList,
            ...initialSectionsListData,
          };
        })
      );
    },

    getJourneyEvents: () => {
      set(
        produce((state) => {
          state.conventions.isLoadingJourneyEventsList = true;
        })
      );

      macroService
        .getJourneyEvents({ 
          filters: {
            sort_key: "name",
            sort_value: "asc",
            all: true
          }, 
          limit: 9999, 
          offset: 0 
        })
        .then(({ data }: any) => {
          set(
            produce((state) => {
              state.conventions.journeyEventsList = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.conventions.isLoadingJourneyEventsList = false;
            })
          );
        });
    },
    selectJourneyEvent: (journeyEventType: string | undefined) => {
      if (journeyEventType === undefined) return;
      set(
        produce((state) => {
          state.conventions.journeyEventsSelected.push(journeyEventType);
        })
      );
    },
    unselectJourneyEvent: (journeyEventType: string | undefined) => {
      if (journeyEventType === undefined) return;
      set(
        produce((state) => {
          state.conventions.journeyEventsSelected =
            state.conventions.journeyEventsSelected.filter((type: string) => type !== journeyEventType);
        })
      );
    },
  },
});
