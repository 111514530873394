import { userAPI } from "../api/api";
import { handleServiceError } from "./handleServiceError";
import {
  Company,
  CompanyFilter,
  CompaniesVisibleColumns,
} from "../store/types/company";
import { removeEmptyProperties } from "../utils/functions/functions";

const companyService = {
  getCompany: async (id: string) => {
    try {
      const response = await userAPI.get(`/v1/company/${id}`);
      const data: Company = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getCompanies: async ({
    filters,
    limit,
    offset,
  }: {
    filters: CompanyFilter | {};
    limit: number;
    offset: number;
  }) => {
    try {
      const response = await userAPI.get(`/v1/company`, {
        params: { ...removeEmptyProperties(filters), limit, offset },
      });
      const data: Company[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  createCompany: async (company: Company) => {
    try {
      const response = await userAPI.post(`/v1/company/`, company);
      const data: Company = response.data.data;
      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  updateCompany: async (id: string, company: Partial<Company>) => {
    try {
      const response = await userAPI.put(`/v1/company/${id}`, company);
      const data: Company = response.data.data;
      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  deleteCompany: async (id: string) => {
    try {
      await userAPI.delete(`/v1/company/${id}`);
    } catch (error) {
      handleServiceError(error);
    }
  },
  exportCompany: async (
    format: "csv" | "pdf",
    columns: (keyof CompaniesVisibleColumns)[]
  ) => {
    try {
      const response = await userAPI.post("/v1/company/report", {
        format,
        columns,
      });
      return response.data.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  changeCompany: async (id: string) => {
    try {
      const response = await userAPI.patch(`/v1/company/${id}/switch`);
      const data = response.data.data;

      return data.token;
    } catch (error) {
      handleServiceError(error);
    }
  },
  uploadCompanyLogo: async (id: string, formData: FormData) => {
    try {
      const response = await userAPI.patch(`/v1/company/${id}/logo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      const data = response.data.data;
      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
};

export default companyService;
