import DataTable from "../../../components/DataTable/DataTable";
import useModal from "../../../hooks/useModal";
import { useStore } from "../../../store/useStore";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import { formatDateTime } from "../../../utils/functions/formatters";
import {
  Macro,
  MacrosLinkVisibleColumns,
} from "../../../store/types/macroState";
import DetailsMacroModal from "./DetailsMacroModal";
import { useEffect } from "react";
import MacroOrigin from "../../../components/MacroOrigin/MacroOrigin";



const MacrosLinkSelectTable: React.FC<{}> = () => {
  const { openModal } = useModal();
  const getMacro = useStore((state) => state.macro.getMacro);
  const resetSelectedMacroLink = useStore(
    (state) => state.macro.resetMacrosLinkListState
  );
  const macros_link = useStore((state) => state.macro.macrosLinkList.data);
  const setFilters = useStore((state) => state.macro.macrosLinkList.setFilters);
  const filters = useStore((state) => state.macro.macrosLinkList.filters);
  const macrosSelected = useStore((state) => state.macro.macrosLinkList.macrosSelected);
  const resetSelectedMacros = useStore((state) => state.macro.macrosLinkList.resetSelectedMacros);
  const selectMacro = useStore((state) => state.macro.macrosLinkList.selectMacro);
  const unselectMacro = useStore((state) => state.macro.macrosLinkList.unselectMacro);
  const columnsVisibility = useStore(
    (state) => state.macro.macrosLinkList.columnsVisibility
  );
  const isLoadingMacrosLinkList = useStore(
    (state) => state.macro.isLoadingMacrosLinkList
  );
  const hasPermissions = useStore((state) => state.admin.hasPermissions);

  useEffect(() => {
    resetSelectedMacros();
  }, [isLoadingMacrosLinkList])

  const openDetailsMacroModal = (macroId: string) => {
    getMacro(macroId);
    openModal(<DetailsMacroModal onClose={resetSelectedMacroLink} />);
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    {
      key: "macro_origin",
      label: "Origem",
      render: (item: Macro) =>
        item?.macro_origin ? <MacroOrigin origin={item?.macro_origin} /> : "-",
    },
    {
      key: "vehicle",
      label: "Veículo",
      render: (item: Macro) => item?.vehicle ?? "-",
    },
    {
      key: "integration_description",
      label: "Descrição",
      render: (item: Macro) => item?.integration?.description ?? "-",
    },
    {
      key: "integration_journey_event_id",
      label: "ID do Evento",
      render: (item: Macro) => item?.integration?.journey_event_id ?? "-",
    },
    {
      key: "integration_macro_id",
      label: "ID da Macro",
      render: (item: Macro) => item?.integration?.macro_id ?? "-",
    },
    {
      key: "integration_user_id",
      label: "ID do Motorista",
      render: (item: Macro) => item?.integration?.user_id ?? "-",
    },
    {
      key: "started_at",
      label: "Inicio",
      render: (item: Macro) =>
        item?.started_at ? formatDateTime(item.started_at) : "-",
      sortKey: "started_at",
    }
  ].filter((column) => {
    return columnsVisibility[column.key as keyof MacrosLinkVisibleColumns];
  });

  const isAllChecked = () => {
    return macros_link.every((macroLink) => macrosSelected.includes(macroLink?._id ?? ""))
  }

  return (
    <DataTable
      data={macros_link}
      columns={columns}
      onDetails={
        hasPermissions([PERMISSIONS.READ_MACRO]) ? openDetailsMacroModal : null
      }
      onEdit={null}
      isAllChecked={isAllChecked}
      onSelectAll={() => {
        resetSelectedMacros();
        if(!isAllChecked()) {
          for(const item of macros_link) {
            selectMacro(item._id);
          }
        }
      }}
      isChecked={(item) => !!macrosSelected.includes(item._id)}
      onSelect={(item) => {
        if(macrosSelected.includes(item._id)) {
          unselectMacro(item._id)
        } else {
          selectMacro(item._id)
        }
      }}
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingMacrosLinkList}
    />
  );
};

export default MacrosLinkSelectTable;
