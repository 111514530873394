import { fleetAPI, payRollAPI } from "../api/api";
import { handleServiceError } from "./handleServiceError";
import { removeEmptyProperties } from "../utils/functions/functions";
import { JourneyRule, JourneyRuleFilter, Parameter, ParameterFilter, PayRoll, PayRollFilter } from "../store/types/journeyRule";

const journeyRuleService = {
  getJourneyRules: async ({
    filters,
    limit,
    offset,
  }: {
    filters: JourneyRuleFilter | {};
    limit: number;
    offset: number;
  }) => {
    try {
      const response = await fleetAPI.get(`/v1/journeyrule`, {
        params: { ...removeEmptyProperties(filters), limit, offset },
      });
      const data: JourneyRule[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getParameters: async ({
    filters,
    limit,
    offset,
  }: {
    filters: ParameterFilter | {};
    limit: number;
    offset: number;
  }) => {
    try {
      const response = await fleetAPI.get(`/v1/parameter`, {
        params: { ...removeEmptyProperties(filters), limit, offset },
      });
      const data: Parameter[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getPayRolls: async ({
    filters,
    limit,
    offset,
  }: {
    filters: PayRollFilter | {};
    limit: number;
    offset: number;
  }) => {
    try {
      const response = await payRollAPI.get(`/v1/payrollevent`, {
        params: { ...removeEmptyProperties(filters), limit, offset },
      });
      const data: PayRoll[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getPayRoll: async (id: string) => {
    try {
      const response = await payRollAPI.get(`/v1/payrollevent/${id}`);
      const data: PayRoll = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getParameter: async (id: string) => {
    try {
      const response = await fleetAPI.get(`/v1/parameter/${id}`);
      const data: Parameter = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getJourneyRule: async (id: string) => {
    try {
      const response = await fleetAPI.get(`/v1/journeyrule/${id}`);
      const data: JourneyRule = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  createJourneyRule: async (data: any) => {
    try {
      await fleetAPI.post(`/v1/journeyrule/`, data);
    } catch (error) {
      handleServiceError(error);
    }
  },
  updateJourneyRule: async (id: string, data: any) => {
    try {
      await fleetAPI.put(`/v1/journeyrule/${id}`, data);
    } catch (error) {
      handleServiceError(error);
    }
  },
};

export default journeyRuleService;
