'use client';

import Home from "./sections/home/home";
import Clients from "./sections/clients/clients";
import Features from "./sections/features/features";
import Testimonials from "./sections/testimonials/testimonials";
import Conquests from "./sections/conquests/conquests";
import Integrations from "./sections/integrations/integrations";
import Management from "./sections/management/management";
import Footer from "./sections/footer/footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import './intitutional-globals-style.css';
import { LogoWpp } from "../../assets/imgs";

export default function LandingPage() {
  const { height, width } = useWindowDimensions();
  const proportion = width! / height!;
  const whatsappLink = "https://wa.me/5535992256401";
  let contentClass = "content";

  if(height! > width!) {
    contentClass += "-mobile";
  } else if(proportion! <= 1.333) { // 4.3
    contentClass += "-4-3";
  } else if(proportion! <= 1.777) { // 16.9
    contentClass += "-16-9";
  } else { // 21.9
    contentClass += "-21-9";
  }

  return (
    <html lang="pt_br">
      <body className="institutional-body antialiased" >
        <FloatingWhatsApp 
            phoneNumber="+5535992256401"
            accountName="Jornada Sat"
            chatMessage="Olá! Digite sua mensagem aqui e você será redirecionado para um de nossos consultores"
            statusMessage="Soluções inteligentes"
            placeholder="Digite sua mensagem..."
            avatar={LogoWpp}
            allowEsc
            notification />
        <Home  contentClass={contentClass} whatsappLink={whatsappLink}/>
        <Clients  contentClass={contentClass} />
        <Features  contentClass={contentClass} />
        <Testimonials  contentClass={contentClass} />
        <Conquests  contentClass={contentClass} />
        <Integrations  contentClass={contentClass} />
        <Management  contentClass={contentClass} whatsappLink={whatsappLink} />
        <Footer  contentClass={contentClass} />
      </body>
    </html>
  );
}
