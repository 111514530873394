import { PlusIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import { useStore } from "../../../store/useStore";
import { Company } from "../../../store/types/company";
import OutlinedMaskedInput from "../../../components/FormComponents/OutlinedMaskedInput/OutlinedMaskedInput";
import { removeNumberMask } from "../../../utils/functions/formatters";
import LogoInput from "../../../components/FormComponents/LogoInput/LogoInput";
import { SUPPORTED_FORMATS } from "../../../utils/constants/constants";

const initialValues = {
  name: "",
  cnpj: "",
  logo: "",
  address: {
    street: "",
    number: "",
    neighbourhood: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
  },
};

const validationSchema = {
  name: Yup.string().required("Campo obrigatório"),
  cnpj: Yup.string()
    .length(18, "O CNPJ deve conter exatamente 14 números")
    .required("Campo obrigatório"),
  logo: Yup.mixed()
    .test(
      "size",
      "A logotipo deve ter no máximo 2MB",
      (value: any) => !value || (value && value.size <= 2 * 1024 * 1024)
    )
    .test(
      "format",
      "Tipo de imagem não suportado",
      (value: any) =>
        !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  address: Yup.object().shape({
    street: Yup.string().required("Campo obrigatório"),
    number: Yup.string().required("Campo obrigatório"),
    neighbourhood: Yup.string().required("Campo obrigatório"),
    city: Yup.string().required("Campo obrigatório"),
    state: Yup.string().required("Campo obrigatório"),
    country: Yup.string().required("Campo obrigatório"),
    zipcode: Yup.string().length(9, "O CEP deve conter exatamente 8 números").required("Campo obrigatório"),
  }),
};

const CreateCompanyModal: React.FC<{}> = () => {
  const createCompany = useStore((state) => state.company.createCompany);
  const isCreatingCompany = useStore(
    (state) => state.company.isCreatingCompany
  );

  return (
    <Modal
      title="Cadastro de nova transportadora"
      icon={<PlusIcon width={30} height={30} />}
      className="w-[447px]"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          values = {
            ...values,
            cnpj: removeNumberMask(values.cnpj),
            address: {
              ...values.address,
              zipcode: removeNumberMask(values.address.zipcode),
            },
          };
          createCompany(values as Company);
        }}
      >
        <Form>
          <div className="flex flex-col gap-8">
            <div className="flex justify-center">
              <Field name="logo" component={LogoInput} />
            </div>
            <div className="flex gap-8">
              <div className="flex-1 flex flex-col gap-8">
                <Field
                  placeholder="Nome"
                  name="name"
                  component={OutlinedInput}
                />
                <Field
                  placeholder="CNPJ"
                  name="cnpj"
                  mask="99.999.999/9999-99"
                  component={OutlinedMaskedInput}
                />
                <Field
                  placeholder="País"
                  name="address.country"
                  component={OutlinedInput}
                />
                <Field
                  placeholder="Estado"
                  name="address.state"
                  component={OutlinedInput}
                />
                <Field
                  placeholder="Cidade"
                  name="address.city"
                  component={OutlinedInput}
                />
              </div>
              <div className="flex-1 flex flex-col gap-8">
                <Field
                  placeholder="Rua"
                  name="address.street"
                  component={OutlinedInput}
                />
                <Field
                  placeholder="Número"
                  name="address.number"
                  component={OutlinedMaskedInput}
                  mask="999999999999"
                />
                <Field
                  placeholder="Complemento"
                  name="address.neighbourhood"
                  component={OutlinedInput}
                />
                <Field
                  placeholder="CEP"
                  name="address.zipcode"
                  component={OutlinedMaskedInput}
                  mask="99999-999"
                />
              </div>
            </div>
            <div className="flex gap-5">
              <div className="flex-1"></div>
              <div className="flex-1">
                <FilledButton
                  loading={isCreatingCompany}
                  className="w-full"
                  type="submit"
                >
                  Cadastrar
                </FilledButton>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default CreateCompanyModal;
