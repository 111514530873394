import DataTable from "../../DataTable/DataTable";
import { useStore } from "../../../store/useStore";
import {
  formatAddress,
  formatDateTime,
} from "../../../utils/functions/formatters";
import StatusText from "../../StatusText/StatusText";
import { Macro } from "../../../store/types/macroState";
import { MacroStatusColor } from "../../../utils/constants/constants";

const LastMacrosTable: React.FC<{}> = () => {
  const macros = useStore((state) => state.dashboard.statusPanel.selectedMacro.lastMacrosList.data);
  const setFilters = useStore((state) => state.dashboard.statusPanel.selectedMacro.lastMacrosList.setFilters);
  const filters = useStore((state) => state.dashboard.statusPanel.selectedMacro.lastMacrosList.filters);
  const isLoadingLastMacros = useStore(
    (state) => state.dashboard.isLoadingLastMacros
  );

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "vehicle", label: "Veículo", sortKey: "vehicle", },
    { key: "user", label: "Motorista", sortKey: "user", },
    {
      key: "journey_event",
      label: "Evento de Jornada",
      sortKey: "journey_event",
    },
    {
      key: "location",
      label: "Endereço",
      render: (item: Macro) => formatAddress(item.location),
    },
    {
      key: "status",
      label: "Status",
      sortKey: "status",
      render: (item: Macro) => (
        <div className="flex justify-center">
        <StatusText
          status={item.status?.type ? MacroStatusColor[item.status?.type] : "danger"}
          text={item.status?.name ?? '-'}
        />
        </div>
      ),
    },
    {
      key: "started_at",
      label: "Inicio",
      render: (item: Macro) => item?.started_at ? formatDateTime(item.started_at) : '-',
      sortKey: "started_at",
    },
    {
      key: "ended_at",
      label: "Fim",
      render: (item: Macro) => item?.ended_at ? formatDateTime(item.ended_at) : '-',
      sortKey: "ended_at",
    },
  ]

  return (
    <DataTable
      data={macros}
      columns={columns}
      onDetails={
        null
      }
      onEdit={
        null
      }
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingLastMacros}
    />
  );
};

export default LastMacrosTable;
