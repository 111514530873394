import { trackingAPI } from "../api/api";
import { handleServiceError } from "./handleServiceError";
import { CollaboratorIntegration } from "../store/types/collaboratorIntegrations";

const collaboratorIntegrationService = {
  getIntegrations: async () => {
    try {
      const response = await trackingAPI.get(`/v1/integration`);
      const data: CollaboratorIntegration[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getCollaboratorIntegrations: async (collaboratorId: string) => {
    try {
      const response = await trackingAPI.get(`/v1/integration/user/${collaboratorId}`);
      const data: CollaboratorIntegration[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  createCollaboratorIntegration: async (
    collaboratorId: string,
    integrationId: string,
    integrationUser: string,
  ) => {
    try {
      await trackingAPI.post(`/v1/integration/user`, [{
        user: collaboratorId,
        integration: integrationId,
        integration_user: integrationUser
      }]);
    } catch (error) {
      handleServiceError(error);
    }
  },
  deleteCollaboratorIntegration: async (
    collaboratorId: string,
    integrationId: string,
    integrationUser: string,
  ) => {
    try {
      await trackingAPI.delete(`/v1/integration/user`, {
        data: [{
          user: collaboratorId,
          integration: integrationId,
          integration_user: integrationUser
        }]
      });
    } catch (error) {
      handleServiceError(error);
    }
  }
};

export default collaboratorIntegrationService;
