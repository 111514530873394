import { NavLink } from "react-router-dom";
import { JSatLogoPreto, HomeTruckOpacity } from "../../../../assets/imgs";
import NavbarLink from "../../../../components/Navbar/components/NavbarLink";
import { ROUTES } from "../../../../routes/routes";

import Button from '../../components/button';
import '../home/home-style.css';
import { Building4, PlayCircle } from 'iconsax-react';
import { Fade } from 'react-awesome-reveal';

export default function Home(props: { contentClass: string; whatsappLink: string;}) {
  if(props.contentClass == "content-mobile") {
    return(
      <section id="home">
        <div  style={{
          backgroundImage: `url(${HomeTruckOpacity})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '20%'
        }}>
          <div className={props.contentClass} style={{paddingBottom: 32, paddingTop: 32}}>
            <Fade cascade damping={0.3}>
              <img
                src={JSatLogoPreto}
                alt="Logotipo Jornada Sat"
              />
              <p className="home-subtitle-mobile">Aumente a segurança da sua operação de transporte com nosso know-how em gestão e tecnologia avançada de <strong>rastreamento e controle de jornadas</strong>.</p>
              <div className="home-contact-us-mobile">
                <Button 
                  className="btn-primary-mobile"
                  label='Fale conosco'
                  link={props.whatsappLink} />
                <a href="https://www.youtube.com" target='_blank'>
                  <div className='btn-terciary'>
                    <PlayCircle size='16' color='#ED3A3A' style={{marginRight: 8}} />
                    Fique por dentro
                  </div>
                </a>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    );
  }
  return (
    <section id="home">
      <div  style={{
        backgroundImage: `url(${HomeTruckOpacity})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '20%'
      }}>
        <div className={props.contentClass} style={{paddingBottom: 64}}>
          <nav className="navbar">
            <ul className="nav_list">
                <li className="nav-item">
                  <a href="#home">Home</a> 
                </li>
                <li className="nav-item">
                    <a href="#features">Funcionalidades</a>
                </li>
                <li className="nav-item">
                    <a href="#testimonials">Depoimentos</a>
                </li>
                <li className="nav-item">
                    <a href="#management">Gerencie</a>
                </li>
            </ul>

            <NavLink
                  to={ROUTES.LOGIN.PATH}
                  className="btn-seccondary login-btn"
                >
                {ROUTES.LOGIN.NAME}
            </NavLink>
          </nav>
          <div className='home-content'>
            <div className='home-content-left'>
              <Fade cascade damping={0.3}>
                <img
                  src={JSatLogoPreto}
                  alt="Logotipo Jornada Sat"
                />
                <p className="home-subtitle">Aumente a segurança da sua operação de transporte com nosso know-how em gestão e tecnologia avançada de <strong>rastreamento e controle de jornadas</strong>.</p>
                <div className="home-contact-us">
                  <Button 
                    className="btn-primary"
                    label='Fale conosco'
                    link={props.whatsappLink} />
                  <a href="https://www.youtube.com" target='_blank'>
                    <div className='btn-terciary'>
                      <PlayCircle size='16' color='#ED3A3A' style={{marginRight: 8}} />
                      Fique por dentro
                    </div>
                  </a>
                </div>
              </Fade>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
}