import { CSVIcon, PDFIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import Checkbox from "../../../components/FormComponents/Checkbox/Checkbox";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import { useStore } from "../../../store/useStore";
import { CollaboratorsVisibleColumnsKey } from "../../../store/types/collaborator";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import { Setting2 } from "iconsax-react";
import { Colors } from "../../../utils/constants/style";

const labels = {
  name: "Nome",
  email: "E-mail",
  phone: "Telefone",
  registration: "Matrícula",
  position: "Cargo",
  cpf: "CPF",
  rg: "RG",
  section: "Seção",
  cnh: "CNH",
  created_at: "Criado em",
  integration_user: "Integrações"
};

const fixedColumns = {
  name: true,
  email: false,
  phone: false,
  registration: false,
  position: false,
  section: false,
  cpf: false,
  rg: false,
  cnh: false,
  created_at: true,
  integration_user: false
};

const OptionsCollaboratorModal: React.FC<{}> = () => {
  const toggleColumnVisibility = useStore(
    (state) => state.collaborator.collaboratorsList.toggleColumnVisibility
  );
  const columnsVisibility = useStore(
    (state) => state.collaborator.collaboratorsList.columnsVisibility
  );
  const isExporting = useStore((state) => state.collaborator.isExporting);

  const exportCollaboratorTable = useStore(
    (state) => state.collaborator.exportCollaboratorTable
  );
  const hasPermissions = useStore((state) => state.admin.hasPermissions);
  const userPropertiesProjection = useStore(
    (state) => state.admin.data.projection.user
  );

  const columnsVisibilityFiltered = (
    Object.keys(columnsVisibility) as CollaboratorsVisibleColumnsKey[]
  ).filter(key => userPropertiesProjection.includes(key));

  return (
    <Modal
      title="Opções"
      icon={<Setting2 size="32" variant="Bold" color={Colors.white}/>}
      className="min-w-[223px]"
    >
      <>
        <div className="pb-2 text-dark-blue">Exibir Colunas</div>
        <div className="flex flex-col gap-2 pl-3">
          {columnsVisibilityFiltered.map((key) => (
            <Checkbox
              key={key}
              text={labels[key]}
              checked={columnsVisibility[key]}
              onChange={(e) => {
                if(!fixedColumns[key])
                  toggleColumnVisibility(key, columnsVisibility[key])
              }
              }
            />
          ))}
        </div>
        {hasPermissions([PERMISSIONS.CREATE_USERS_REPORT]) ? (
          <>
            <div className="pt-4 pb-2 text-dark-blue">Exportar</div>
            <div className="flex gap-3">
              <div className="flex-1">
                <RoundedFilledButton
                  className="w-full"
                  onClick={() => exportCollaboratorTable("csv")}
                  loading={isExporting["csv"]}
                  icon={<CSVIcon />}
                  text="CSV"
                />
              </div>
              <div className="flex-1">
                <RoundedFilledButton
                  className="w-full"
                  onClick={() => exportCollaboratorTable("pdf")}
                  loading={isExporting["pdf"]}
                  icon={<PDFIcon />}
                  text="PDF"
                />
              </div>
            </div>
          </>
        ) : null}
      </>
    </Modal>
  );
};

export default OptionsCollaboratorModal;
