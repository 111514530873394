import { Form, Formik } from "formik";
import {
  CSVIcon,
  PDFIcon,
} from "../../../assets/imgs";
import DataTable from "../../../components/DataTable/DataTable";
import InfoField from "../../../components/InfoField/InfoField";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import Modal from "../../../components/Modal/Modal";
import { PayrollCollaborator } from "../../../store/types/collaborator";
import { useStore } from "../../../store/useStore";
import { Colors } from "../../../utils/constants/style";
import { formatCPF, formatDateTime } from "../../../utils/functions/formatters";
import FilledButton from "../../../components/FilledButton/FilledButton";
import Checkbox from "../../../components/FormComponents/Checkbox/Checkbox";
import { 
  PayrollGroupStatusColor, 
  REPORT_ORIENTATIONS, 
  REPORT_TYPES 
} from "../../../utils/constants/constants";
import { 
  CloseCircle, 
  MinusCirlce, 
  SearchNormal1, 
  TickCircle } from "iconsax-react";
import SimpleSelect from "../../../components/SimpleSelect/SimpleSelect";
import { PayRollGroupProcessStatus, PayRollGroupSatus } from "../../../store/types/timeManager";
import StatusText from "../../../components/StatusText/StatusText";

interface DetailsPayRollModalProps {
  onClose: () => void;
}

const initialValues = {
  payrollGroupId: "" as string,	
  collaborators: {} as Record<string, any>,
  unified: {} as Record<string, any>,
  format: "" as string,
  orientation: REPORT_ORIENTATIONS[0].value as string,
  consolidated: false as boolean,
};

const statusMap = {
  [PayRollGroupProcessStatus.OK]: <TickCircle size="20" color={Colors.green} />,
  [PayRollGroupProcessStatus.ERROR]: <CloseCircle size="20" color={Colors.red} />
}

const DetailsPayRollModal: React.FC<DetailsPayRollModalProps> = ({
  onClose,
}) => {
  const selectedPayRoll = useStore(
    (state) => state.timeManager.selectedPayRoll
  );
  const isLoadingPayRoll = useStore(
    (state) => state.timeManager.isLoadingPayRoll
  );
  const isLoadingCollaboratorsList = useStore(
    (state) => state.collaborator.isLoadingCollaboratorsList
  );
  const downloadPayroll = useStore(
    (state) => state.report.downloadPayroll
  );
  const isDownloadingReport = useStore(
    (state) => state.report.isDownloadingReport
  );
  const setCollaboratorsFilters = useStore(
    (state) => state.collaborator.collaboratorsList.setFilters
  );
  const collaborators = useStore((state) => state.timeManager.selectedPayRoll?.users ?? []);

  const collaboratorsSelected = useStore((state) => state.timeManager.collaborators.collaboratorsSelected);
  const isAllChecked = () => {
    return collaborators?.every((collaborators) => collaboratorsSelected.includes(collaborators?._id ?? "")) ?? false;
  }
  const collaboratorsList = useStore((state) => state.collaborator.collaboratorsList.data);

  const selectCollaborator = useStore((state) => state.timeManager.selectCollaborator);
  const unselectCollaborator = useStore((state) => state.timeManager.unselectCollaborator);
  const refreshPayRoll = useStore((state) => state.timeManager.refreshPayRoll);
  const handleSearchUser = (name: string) => {
    setCollaboratorsFilters({ 
      name,
      payroll_group: selectedPayRoll?._id ?? '',
      sort_key: "name",
      sort_value: "asc",
      registration: "",
      phone: "",
      email: "",
      position: "",
      cpf: "",
      rg: "",
      cnh: ""
    });
  };

  const columns = [
    { key: "name", label: "Nome" },
    {
      key: "cpf",
      label: "CPF",
      render: (item: PayrollCollaborator) => formatCPF(item.cpf),
    },
    {
      key: "last_process",
      label: "Último Processamento",
      render: (item: PayrollCollaborator) => {
        const lastProcess = selectedPayRoll?.users?.find((user) => user._id === item._id)?.last_process;
        return (
          <div className="flex px-6 items-center gap-2">
            { lastProcess?.status ? statusMap[lastProcess.status as PayRollGroupProcessStatus] : <MinusCirlce size="20" color={Colors.orange} /> }
            { lastProcess?.date ? formatDateTime(lastProcess?.date) : 'Sem informações' }
          </div>
        )
      },
    }
  ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        const collaborators = Object.keys(values.collaborators).filter(
          (k: string) => values.collaborators[k]
        );
        const unified = Object.keys(values.unified).filter(
          (k: string) => values.unified[k]
        );
        if(!collaborators.length) return;
        if(!selectedPayRoll?._id) return;

        downloadPayroll(collaborators, selectedPayRoll?._id as string, unified ?? [], values.format, values.orientation, values.consolidated);
        for(const item of collaborators) unselectCollaborator(item);
        resetForm();
      }}
    >
    {(formik) => (
      <Modal
        title="Detalhes de folha de pagamento"
        icon={<SearchNormal1 size="30" color={Colors.white} variant="Linear" />}
        className="w-[900px]"
        onClose={() => {
          onClose()
          for(const item of collaborators) {
            unselectCollaborator(item._id);
            formik.setFieldValue(
              `collaborators.${item._id}`,
              false
            )
          }
        }}
      >
        <div className="flex flex-col gap-5">
          <InfoFieldBox>
            <InfoField
              loading={isLoadingPayRoll}
              label="Nome"
              content={selectedPayRoll?.name}
            />
            <InfoField
              loading={isLoadingPayRoll}
              label="Lote"
              content={selectedPayRoll?.batch}
            />
          </InfoFieldBox>
          <InfoFieldBox>
            <InfoField
              loading={isLoadingPayRoll}
              label="Início Competência"
              content={formatDateTime(selectedPayRoll?.competence_start)}
            />
            <InfoField
              loading={isLoadingPayRoll}
              label="Fim Competência"
              content={formatDateTime(selectedPayRoll?.competence_end)}
            />
          </InfoFieldBox>
          <InfoFieldBox>
            <InfoField
            loading={isLoadingPayRoll}
            label="Quantidade de Folhas"
            content={selectedPayRoll?.users?.length}
            />
            <InfoField
            loading={isLoadingPayRoll}
            label="Status"
            content={
              (selectedPayRoll?.status?.type && selectedPayRoll?.status?.name)  ? 
                <div className="flex">
                  <StatusText
                    status={PayrollGroupStatusColor[selectedPayRoll.status.type as PayRollGroupSatus]}
                    text={selectedPayRoll.status.name ?? '-'}
                  />
                </div>
              : '-'
              }
            />
          </InfoFieldBox>
          <Form>
            { 
              selectedPayRoll?.status?.type !== PayRollGroupSatus.CLOSED &&
              selectedPayRoll?.status?.type !== PayRollGroupSatus.CLOSING ?
                (
                  isLoadingPayRoll ? null :
                    <div className="flex gap-5">
                      <div className="w-[320px]">
                        <div className="text-dark-blue font-bold my-4">
                          Orientação da Folha
                        </div>
                        <div>
                          <SimpleSelect
                            options={REPORT_ORIENTATIONS}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'orientation',
                                e.target.value
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="text-dark-blue font-bold my-4">
                          Unificar Relatórios em PDF
                        </div>
                        <div className="flex flex-wrap items-start">
                          {
                            REPORT_TYPES.map((report_type: any) => {
                              return (
                                <div className="bg-gray_0 p-1 rounded-[7px] mr-2 mb-2">
                                  <Checkbox
                                    key={report_type.value}
                                    onChange={() => {
                                      formik.setFieldValue(
                                        `unified.${report_type.value}`,
                                        !formik.values['unified'][report_type.value]
                                      )
                                    }}
                                    className="w-[14px] h-[14px]"
                                    labelClassName="font-light font-base"
                                    checked={formik.values?.unified?.[report_type.value] ?? false}
                                    text={report_type.label}
                                  />
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                )
              : null
            }
            <div className="text-dark-blue font-bold my-4">
              Colaboradores
            </div>
            {/* <div className="flex m-5 my-8">
              <SearchInput
                placeholder="Buscar registro"
                onChange={handleSearchUser}
              />
            </div> */}
            <div className="px-5">
              <DataTable
                data={(collaboratorsList.length ? collaboratorsList : selectedPayRoll?.users) || []}
                columns={columns}
                onDetails={null}
                onEdit={null}
                onReprocess={(item) => {
                  refreshPayRoll({ payRollGroupId: selectedPayRoll?._id ?? '', collaboratorId: item })}
                }
                showReprocessByItem={() => (selectedPayRoll?.status?.type !== PayRollGroupSatus.CLOSED && selectedPayRoll?.status?.type !== PayRollGroupSatus.CLOSING)}
                loading={isLoadingCollaboratorsList || isLoadingPayRoll}
                isAllChecked={isAllChecked}
                onSelectAll={() => {
                  if(!isAllChecked()) {
                    for(const item of collaborators) {
                      selectCollaborator(item._id);
                      formik.setFieldValue(
                        `collaborators.${item._id}`,
                        true
                      )
                    }
                  } else {
                    for(const item of collaborators) {
                      unselectCollaborator(item._id);
                      formik.setFieldValue(
                        `collaborators.${item._id}`,
                        false
                      )
                    }
                  }
                }}
                isChecked={(item) => !!collaboratorsSelected.includes(item._id)}
                onSelect={(item) => {
                  if(collaboratorsSelected.includes(item._id)) {
                    unselectCollaborator(item._id)
                  } else {
                    selectCollaborator(item._id)
                  }
                  formik.setFieldValue(
                    `collaborators.${item._id}`,
                    !formik.values['collaborators'][item._id]
                  )
                }}
              />
            </div>
            {
              isLoadingPayRoll ? null :
              <div className="flex gap-5 z-30 py-4 sticky -bottom-6 bg-white">
                {
                  selectedPayRoll?.status?.type !== PayRollGroupSatus.CLOSED &&
                  selectedPayRoll?.status?.type !== PayRollGroupSatus.CLOSING ?
                  <div className="flex-1">
                    <FilledButton
                      loading={isDownloadingReport}
                      className="w-full flex gap-4 justify-center"
                      onClick={() => {
                        formik.setFieldValue('format', 'csv');
                        formik.setFieldValue('consolidated', true);
                        formik.submitForm();
                      }}
                      type='button'
                      icon={<CSVIcon />}
                    >
                      CSV Consolidado
                    </FilledButton>
                  </div>
                  : null
                }
                <div className="flex-1">
                  <FilledButton
                    loading={isDownloadingReport}
                    onClick={() => {
                      formik.setFieldValue('format', 'csv');
                      formik.submitForm();
                    }}
                    type='button'
                    className="w-full flex gap-4 justify-center"
                    icon={<CSVIcon />}
                  >
                    CSV Individual
                  </FilledButton>
                </div>
                <div className="flex-1">
                  <FilledButton
                    loading={isDownloadingReport}
                    className="w-full flex gap-4 justify-center"
                    onClick={() => {
                      formik.setFieldValue('format', 'pdf');
                      formik.submitForm();
                    }}
                    type='button'
                    icon={<PDFIcon />}
                  >
                    PDF
                  </FilledButton>
                </div>
              </div>
            }
          </Form>
        </div>
      </Modal>
    )}
  </Formik>
  );
};

export default DetailsPayRollModal;