interface TabsProps {
  children: JSX.Element[] | JSX.Element;
  setTab: (tabIndex: number) => void;
  tab: number;
}

const Tabs: React.FC<TabsProps> = ({ children, tab, setTab }) => {
  if(!Array.isArray(children)) children = [children];
  return (
    <div className="bg-gray_0 flex w-fit rounded-[8px] p-1">
      { 
      children.map((tabElement: JSX.Element, tabIndex: number) => {
        return (
          <div
            onClick={() => setTab(tabIndex)}
            className={`${
              tab === tabIndex ? "bg-white shadow-base rounded-[8px]" : ""
            } px-3 py-2 cursor-pointer`}
          >
            {tabElement}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;