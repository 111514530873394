import { PlusIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import { useStore } from "../../../store/useStore";
import { useEffect } from "react";
import CollaboratorsSelectTable from "./CollaboratorsSelectTable";
import { Collaborator } from "../../../store/types/collaborator";
import { formatCPF } from "../../../utils/functions/formatters";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import { PayRoll } from "../../../store/types/journeyRule";

const initialValues = {
  payroll: "",
  users: {} as Record<string, any>
};

const CreateJFWCustomReportModal: React.FC<{}> = () => {
  const downloadReport = useStore((state) => state.report.downloadReport);
  const isDownloadingReport = useStore(
    (state) => state.report.isDownloadingReport
  );
  const getCollaborators = useStore(
    (state) => state.collaborator.getCollaborators
  );
  const getPayrolls = useStore(
    (state) => state.timeManager.getPayRollsWithLimit
  );
  const collaboratorsSelected = useStore((state) => state.collaborator.collaboratorsList.collaboratorsSelected);
  const resetCollaboratorListState = useStore(
    (state) => state.collaborator.resetCollaboratorListState
  );
  const payrolls = useStore(
    (state) => state.timeManager.payRollsList.data
  );
  const setCollaboratorsLimitPerPage = useStore(
    (state) => state.collaborator.collaboratorsList.setLimitPerPage
  );

  useEffect(() => {
    setCollaboratorsLimitPerPage(999);
    getPayrolls(9999);
    return () => {
      resetCollaboratorListState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user_columns = [
    { key: "name", label: "Nome" },
    {
      key: "cpf",
      label: "CPF",
      render: (item: Collaborator) => formatCPF(item.cpf),
    },
  ];

  return (
    <Modal
      title="JFWCustom"
      icon={<PlusIcon width={30} height={30} />}
      className="w-[600px]"
      childrenClassName="px-5 overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          payroll: Yup.string().required("Campo obrigatório"),
        })}
        onSubmit={(values) => {
          const users = Object.keys(values.users).filter(
            (k: string) => values.users[k]
          );
          if(!users.length) return;

          const data = {
            users,
            payrollGroupId: values.payroll,
          };

          downloadReport(data);
        }}
      >
        {(formik) => (
          <Form>
            <div className="flex flex-col gap-8 pt-5">
              <div className="flex-1">
                <Field
                  name="payroll"
                  component={OutlinedSelect}
                  placeholder="Selecione a folha de pagamento..."
                  options={payrolls.map((payroll: Partial<PayRoll>) => {
                    return { label: payroll.name, value: payroll._id}
                  })}
                />
              </div>
              <div className="flex gap-5">
                <div className="flex-1">
                  <div className="text-red text-sm">
                    {(formik.errors.users as any) && (formik.touched.users as any)
                      ? (formik.errors.users as any)
                      : null}
                  </div>
                  <CollaboratorsSelectTable
                    columns={user_columns}
                    form={formik}
                    fieldName="users"
                  />
                </div>
              </div>
              <div className="flex gap-5 z-30 py-4 sticky bottom-0 bg-white">
                <div className="flex-1"></div>
                <div className="flex-1">
                  <FilledButton
                    loading={isDownloadingReport}
                    className="w-full"
                    type="submit"
                  >
                    Baixar relatório
                  </FilledButton>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateJFWCustomReportModal;
