import { useEffect, useState } from "react";
import DataPanel from "../../../components/DataPanel/DataPanel";
import Pagination from "../../../components/Pagination/Pagination";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import useModal from "../../../hooks/useModal";
import MacrosTable from "./MacrosTable";
import { useStore } from "../../../store/useStore";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import Skeleton from "react-loading-skeleton";
import FilterMacroModal from "./FilterMacroModal";
import OptionsMacroModal from "./OptionsMacroModal";
import CreateMacroModal from "./CreateMacroModal";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import SimpleSelect from "../../../components/SimpleSelect/SimpleSelect";
import { AddCircle, Filter, Refresh2, Setting2 } from "iconsax-react";

const MacroGeneral: React.FC<{}> = () => {
  const { openModal } = useModal();
  const getMacros = useStore((state) => state.macro.getMacros);
  const macrosList = useStore(
    (state) => state.macro.macrosList
  );
  const isLoadingMacrosList = useStore(
    (state) => state.macro.isLoadingMacrosList
  );
  const hasPermissions = useStore(
    (state) => state.admin.hasPermissions
  );

  useEffect(() => {
    getMacros();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateMacroModal = () =>
    openModal(<CreateMacroModal />);
  const openFilterMacroModal = () =>
    openModal(<FilterMacroModal />);
  const openOptionsCollaboratorModal = () =>
    openModal(<OptionsMacroModal />);
  
  const [macrosRequestInterval, setStatusAndMacrosRequestInterval] =
    useState<NodeJS.Timer>();

  const createMacrosRequestInterval = (intervalInMinutes: number) => {
    clearInterval(macrosRequestInterval);
    const newRequestInverval = setInterval(
      getMacros,
      intervalInMinutes * 60 * 1000 // convert to ms
    );
    setStatusAndMacrosRequestInterval(newRequestInverval);
  };

  const getDashboardStatusAndMacrosAndCreateInterval = (
    intervalInMinutes: number
  ) => {
    getMacros();
    createMacrosRequestInterval(intervalInMinutes);
  };

  return (
    <>
      <div className="mt-9">
        <DataPanel className="px-3 pt-8 flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="pl-1">
              {/* <SearchInput placeholder="Buscar registro" onChange={() => {}} /> */}
            </div>
            <div className="pr-1 flex gap-4">
              <div className="flex gap-2 items-center">
                <button
                  className="rounded-[11px] bg-gray_0 w-fit p-2 hover:bg-gray_05"
                  onClick={() => getMacros()}
                >
                  <Refresh2 size={24}/>
                </button>
                <SimpleSelect
                  className="bg-white border-b border-b-gray_2 text-gray_2"
                  options={[
                    { label: "5 minutos", value: 5 },
                    { label: "15 minutos", value: 15 },
                    { label: "30 minutos", value: 30 },
                  ]}
                  onChange={(e) => {
                    getDashboardStatusAndMacrosAndCreateInterval(
                      parseInt(e.target.value)
                    );
                  }}
                />
              </div>
              <RoundedFilledButton
                onClick={openCreateMacroModal}
                icon={<AddCircle />}
                text="Novo"
                show={hasPermissions([PERMISSIONS.CREATE_MANUAL_MACRO])}
              />
              <RoundedFilledButton
                onClick={openFilterMacroModal}
                icon={<Filter size={24} />}
                text="Filtros"
              />
              <RoundedFilledButton
                onClick={openOptionsCollaboratorModal}
                icon={<Setting2 />}
                text="Opções"
              />
            </div>
          </div>
          <MacrosTable />
          <div className="flex justify-between items-center">
            <div className="text-gray_1">
              Mostrando{" "}
              {!isLoadingMacrosList ? (macrosList.data.length + macrosList.offset * macrosList.limitPerPage) : <Skeleton inline width={20} />}{" "}
              de {macrosList.totalItems} registros
            </div>
            <div className="flex gap-5 items-center">
              <LimitPerPage
                onLimitChange={(e: any) =>
                  macrosList.setLimitPerPage(e.target.value)
                }
              />
              <Pagination
                currentPage={macrosList.currentPage}
                totalPages={macrosList.totalPages}
                onPageChange={macrosList.setCurrentPage}
              />
            </div>
          </div>
        </DataPanel>
      </div>
    </>
  );
};

export default MacroGeneral;
