import { StateCreator } from "zustand";
import { produce } from "immer";
import {
  CompanyState,
  CompanyFilter,
  Company,
  CompaniesVisibleColumnsKey,
} from "../types/company";
import companyService from "../../services/companyService";
import { toast } from "react-toastify";
import { useStore } from "../useStore";
import { downloadURI } from "../../utils/functions/download";

const initialCompanyListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  columnsVisibility: {
    name: true,
    cnpj: true,
    address: true,
    created_at: true,
  },
  filters: {
    name: "",
    cnpj: "",
    sort_key: "name",
    sort_value: "asc",
  },
};

const readFileAsArrayBuffer = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const createCompanySlice: StateCreator<CompanyState> = (set, get) => ({
  company: {
    companiesList: {
      ...initialCompanyListData,
      toggleColumnVisibility: (key: string, value: boolean) => {
        set(
          produce((state) => {
            state.company.companiesList.columnsVisibility[key] = !value;
          })
        );
      },
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.company.companiesList.limitPerPage = limitPerPage;
            state.company.companiesList.offset = 0;
            state.company.companiesList.currentPage = 1;
          })
        );
        get().company.getCompanies();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.company.companiesList.currentPage = page;
            state.company.companiesList.offset = page - 1;
          })
        );
        get().company.getCompanies();
      },
      setFilters: (filters: CompanyFilter) => {
        set(
          produce((state) => {
            state.company.companiesList.filters = filters;
            state.company.companiesList.offset = 0;
            state.company.companiesList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().company.getCompanies();
      },
    },
    selectedCompany: null,
    isLoadingCompaniesList: false,
    isLoadingCompany: false,
    isCreatingCompany: false,
    isDeletingCompany: false,
    isUpdatingCompany: false,
    isExporting: {
      csv: false,
      pdf: false,
    },
    resetSelectedCompany: () => {
      set(
        produce((state) => {
          state.company.selectedCompany = null;
        })
      );
    },
    getCompanies: () => {
      set(
        produce((state) => {
          state.company.isLoadingCompaniesList = true;
        })
      );

      const filters = get().company.companiesList.filters;
      const limit = get().company.companiesList.limitPerPage;
      const offset = get().company.companiesList.offset;

      companyService
        .getCompanies({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.company.companiesList.data = data;
              state.company.companiesList.totalItems = totalItems;
              state.company.companiesList.totalPages = Math.ceil(
                totalItems / state.company.companiesList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.company.isLoadingCompaniesList = false;
            })
          );
        });
    },
    getCompany: (companyId: string) => {
      set(
        produce((state) => {
          state.company.isLoadingCompany = true;
        })
      );

      companyService
        .getCompany(companyId)
        .then((data) => {
          set(
            produce((state) => {
              state.company.selectedCompany = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.company.isLoadingCompany = false;
            })
          );
        });
    },
    createCompany: (company: Company) => {
      set(
        produce((state) => {
          state.company.isCreatingCompany = true;
        })
      );

      const logoFile: any = company.logo;
      delete company.logo;

      companyService
        .createCompany(company)
        .then(async (data) => {
          toast.success("Empresa cadastrada com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().company.getCompanies();

          if (!data?._id || !logoFile) return;

          try {
            const logoBinary = await readFileAsArrayBuffer(logoFile);
            const formData = new FormData();
            formData.append(
              "file",
              new Blob([logoBinary as any], { type: logoFile.type }),
              logoFile.name
            );

            companyService
              .uploadCompanyLogo(data._id, formData)
              .catch((error) => {
                toast.error(error.message);
              });
          } catch (err) {
            toast.error("Erro ao enviar logotipo");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.company.isCreatingCompany = false;
            })
          );
        });
    },

    updateCompany: (companyId: string, company: Company) => {
      set(
        produce((state) => {
          state.company.isUpdatingCompany = true;
        })
      );

      const logoFile: any = company.logo;
      delete company.logo;

      companyService
        .updateCompany(companyId, company)
        .then(() => {
          toast.success("Empresa atualizada com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().company.getCompanies();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.company.isUpdatingCompany = false;
            })
          );
        });

      if (!logoFile) return;

      readFileAsArrayBuffer(logoFile)
        .then((logoBinary) => {
          const formData = new FormData();
          formData.append(
            "file",
            new Blob([logoBinary as any], { type: logoFile.type }),
            logoFile.name
          );

          companyService
            .uploadCompanyLogo(companyId, formData)
            .catch((error) => {
              toast.error(error.message);
            });
        })
        .catch(() => {
          toast.error("Erro ao enviar logotipo");
        });
    },

    deleteCompany: (companyId: string) => {
      set(
        produce((state) => {
          state.company.isDeletingCompany = true;
        })
      );

      companyService
        .deleteCompany(companyId)
        .then(() => {
          toast.success("Empresa excluída com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().company.getCompanies();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.company.isDeletingCompany = false;
            })
          );
        });
    },
    exportCompanyTable: (format: "csv" | "pdf") => {
      set(
        produce((state) => {
          state.company.isExporting[format] = true;
        })
      );

      const columnsVisibility = get().company.companiesList.columnsVisibility;

      const columnsToExport = Object.keys(columnsVisibility)
        .filter((key) => columnsVisibility[key as CompaniesVisibleColumnsKey])
        .map((key) => key as CompaniesVisibleColumnsKey);

      companyService
        .exportCompany(format, columnsToExport)
        .then((data) => {
          downloadURI(data.fileUrl, data.fileName);
          toast.success("Documento exportado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.company.isExporting[format] = false;
            })
          );
        });
    },
    resetCompanyListState: () => {
      set(
        produce((state) => {
          state.company.companiesList = {
            ...state.company.companiesList,
            ...initialCompanyListData,
          };
        })
      );
    },
  },
});
