import { useStore } from '../store/useStore';

export default function useModal() {
  const isModalOpen = useStore(state => state.modal.isModalOpen)
  const setIsModalOpen = useStore(state => state.modal.setIsModalOpen)
  const modalContent = useStore(state => state.modal.modalContent)
  const setModalContent = useStore(state => state.modal.setModalContent)

  function openModal(content: JSX.Element | null) {
    setModalContent(content);
    setIsModalOpen(true);
  }

  function closeModal() {
    setModalContent(null);
    setIsModalOpen(false);
  }

  return {
    isModalOpen,
    openModal,
    closeModal,
    modalContent,
  };
}