const CSVIcon: React.FC<{}> = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 14.875H6.125V13.125H12.25V14.875ZM14.875 11.375H6.125V9.625H14.875V11.375ZM14.875 7.875H6.125V6.125H14.875V7.875ZM16.625 2.625H4.375C3.40375 2.625 2.625 3.40375 2.625 4.375V16.625C2.625 17.0891 2.80937 17.5342 3.13756 17.8624C3.46575 18.1906 3.91087 18.375 4.375 18.375H16.625C17.0891 18.375 17.5342 18.1906 17.8624 17.8624C18.1906 17.5342 18.375 17.0891 18.375 16.625V4.375C18.375 3.40375 17.5875 2.625 16.625 2.625Z"
        fill="white"
      />
    </svg>
  );
};
export default CSVIcon;
