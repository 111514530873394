import { NavLink } from "react-router-dom";

interface NavbarLinkProps {
  to: string;
  icon: JSX.Element;
  text: string;
  show?: boolean;
}

const NavbarLink: React.FC<NavbarLinkProps> = ({ to, icon, text, show=true }) => {
  return show ? (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center gap-3 px-4 py-4 rounded-[4px] text-white text-sm ${
          isActive ? "bg-red" : "hover:bg-gray_3 transition duration-300 ease-in-out"
        }`
      }
    >
      {icon}
      {text}
    </NavLink>
  ) : null;
};

export default NavbarLink;
