interface RoundedCardProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: JSX.Element;
  title: string;
  text: string;
}

const RoundedCard: React.FC<RoundedCardProps> = ({ icon, title, text, onClick }) => {

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) { onClick(event) }
  };
  
  return (
    <button
      onClick={handleClick}
    >
      <div className="flex-1 w-[350px] text-gray_2">
        <div className="bg-white rounded-[50px] shadow-base relative">
          <div className="flex items-start p-3">
            <div className="rounded-full w-[64px] h-[64px] bg-red items-center justify-center p-5">
              {icon}
            </div>
            <div>
              <div className="text-dark-blue text-lg font-medium">{title}</div>
              <div className="p-1 text-sm">{text}</div>
            </div>
          </div>
        </div>
      </div>
    </button>
  );
};

export default RoundedCard;
