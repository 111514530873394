import { StateCreator } from "zustand";
import { produce } from "immer";
import macroService, {
  CheckMacroBody,
  CreateMacrosDataBody,
  MacrosLinkBody,
} from "../../services/macroService";
import { toast } from "react-toastify";
import { useStore } from "../useStore";
import { MacroState, MacrosVisibleColumnsKey } from "../types/macroState";
import { downloadURI } from "../../utils/functions/download";
import moment from "moment";

const initialJourneyEventsListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  filters: {
    name: "",
    type: "",
    sort_key: "type",
    sort_value: "asc",
  },
};

const initialMacroListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  filters: {
    started_at_begin: "",
    started_at_end: "",
    ended_at_begin: "",
    ended_at_end: "",
    vehicle: "",
    user: "",
    status_type: "",
    journey_event: "",
    street: "",
    number: "",
    neighbourhood: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    sort_key: "started_at",
    sort_value: "desc",
  },
  columnsVisibility: {
    macro_origin: true,
    vehicle: true,
    user: true,
    journey_event: true,
    description: true,
    status: true,
    location: true,
    started_at: true,
    duration: true
  },
  macrosSelected: []
};

const initialMacroRestoreListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  filters: {
    started_at_begin: "",
    started_at_end: "",
    ended_at_begin: "",
    ended_at_end: "",
    vehicle: "",
    user: "",
    status_type: "",
    journey_event: "",
    street: "",
    number: "",
    neighbourhood: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    sort_key: "started_at",
    sort_value: "desc",
    deleted: true
  },
  columnsVisibility: {
    macro_origin: true,
    vehicle: true,
    user: true,
    journey_event: true,
    description: true,
    status: true,
    location: true,
    started_at: true
  },
  macrosSelected: []
};

const initialMacroLinkListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  filters: {
    started_at_begin: "",
    started_at_end: "",
    ended_at_begin: "",
    ended_at_end: "",
    vehicle: "",
    user: "",
    status_type: "UNLINKED",
    journey_event: "",
    street: "",
    number: "",
    neighbourhood: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    sort_key: "started_at",
    sort_value: "desc",
  },
  columnsVisibility: {
    macro_origin: true,
    vehicle: true,
    integration_description: true,
    integration_journey_event_id: true,
    integration_macro_id: true,
    integration_user_id: true,
    started_at: true,
    ended_at: true,
  },
  macrosSelected: []
};

export const createMacroSlice: StateCreator<MacroState> = (set, get) => ({
  macro: {
    journeyEventsList: {
      ...initialJourneyEventsListData,
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.macro.journeyEventsList.limitPerPage = limitPerPage;
            state.macro.journeyEventsList.offset = 0;
            state.macro.journeyEventsList.currentPage = 1;
          })
        );
        get().macro.getJourneyEvents();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.macro.journeyEventsList.currentPage = page;
            state.macro.journeyEventsList.offset = page - 1;
          })
        );
        get().macro.getJourneyEvents();
      },
      setFilters: (filters) => {
        set(
          produce((state) => {
            state.macro.journeyEventsList.filters = filters;
            state.macro.journeyEventsList.offset = 0;
            state.macro.journeyEventsList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().macro.getJourneyEvents();
      },
    },
    macrosList: {
      ...initialMacroListData,
      toggleColumnVisibility: (key: string, value: boolean) => {
        set(
          produce((state) => {
            state.macro.macrosList.columnsVisibility[key] = !value;
          })
        );
      },
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.macro.macrosList.limitPerPage = limitPerPage;
            state.macro.macrosList.offset = 0;
            state.macro.macrosList.currentPage = 1;
          })
        );
        get().macro.getMacros();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.macro.macrosList.currentPage = page;
            state.macro.macrosList.offset = page - 1;
          })
        );
        get().macro.getMacros();
      },
      setFilters: (filters) => {
        set(
          produce((state) => {
            state.macro.macrosList.filters = filters;
            state.macro.macrosList.offset = 0;
            state.macro.macrosList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().macro.getMacros();
      },
    },
    macrosRestoreList: {
      ...initialMacroRestoreListData,
      toggleColumnVisibility: (key: string, value: boolean) => {
        set(
          produce((state) => {
            state.macro.macrosList.columnsVisibility[key] = !value;
          })
        );
      },
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.macro.macrosRestoreList.limitPerPage = limitPerPage;
            state.macro.macrosRestoreList.offset = 0;
            state.macro.macrosRestoreList.currentPage = 1;
          })
        );
        get().macro.getMacrosRestore();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.macro.macrosRestoreList.currentPage = page;
            state.macro.macrosRestoreList.offset = page - 1;
          })
        );
        get().macro.getMacrosRestore();
      },
      setFilters: (filters) => {
        set(
          produce((state) => {
            state.macro.macrosRestoreList.filters = {
              ...filters,
              deleted: true
            };
            state.macro.macrosRestoreList.offset = 0;
            state.macro.macrosRestoreList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().macro.getMacrosRestore();
      },
    },
    macrosLinkList: {
      ...initialMacroLinkListData,
      selectMacro: (macroId: string | undefined) => {
        if (macroId === undefined) return;
        set(
          produce((state) => {
            state.macro.macrosLinkList.macrosSelected.push(macroId);
          })
        );
      },
      unselectMacro: (macroId: string | undefined) => {
        if (macroId === undefined) return;
        set(
          produce((state) => {
            state.macro.macrosLinkList.macrosSelected =
              state.macro.macrosLinkList.macrosSelected.filter(
                (id: string) => id !== macroId
              );
          })
        );
      },
      resetSelectedMacros: () => {
        set(
          produce((state) => {
            state.macro.macrosLinkList.macrosSelected = [];
          })
        );
      },
      toggleColumnVisibility: (key: string, value: boolean) => {
        set(
          produce((state) => {
            state.macro.macrosLinkList.columnsVisibility[key] = !value;
          })
        );
      },
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.macro.macrosLinkList.limitPerPage = limitPerPage;
            state.macro.macrosLinkList.offset = 0;
            state.macro.macrosLinkList.currentPage = 1;
          })
        );
        get().macro.getMacrosLink();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.macro.macrosLinkList.currentPage = page;
            state.macro.macrosLinkList.offset = page - 1;
          })
        );
        get().macro.getMacrosLink();
      },
      setFilters: (filters) => {
        set(
          produce((state) => {
            state.macro.macrosLinkList.filters = {
              ...filters,
              status_type: "UNLINKED",
            };
            state.macro.macrosLinkList.offset = 0;
            state.macro.macrosLinkList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().macro.getMacrosLink();
      },
    },
    isLoadingJourneyEventsList: false,
    isLoadingJourneyEvent: false,
    selectedJourneyEvent: null,
    selectedMacro: null,
    macroDataToUpdate: null,
    macrosDataToLink: null,
    isLoadingMacrosList: false,
    isLoadingMacro: false,
    isCheckingMacro: false,
    isUpdatingMacro: false,
    isLinkingMacro: false,
    isCreatingMacro: false,
    selectedMacroLink: null,
    isLoadingMacrosLinkList: false,
    isLoadingMacrosRestoreList: false,
    isLoadingMacroLink: false,
    isDeletingMacro: false,
    isRestoringMacro: false,
    isExporting: {
      csv: false,
      pdf: false,
    },
    deleteMacros: (macrosIds: string[], reason: string, description: string) => {
      set(
        produce((state) => {
          state.macro.isDeletingMacro = true;
        })
      );

      macroService
        .deleteMacros({ macros: macrosIds, reason, description })
        .then(() => {
          toast.success("Registros excluídos com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().macro.getMacros();
          set(
            produce((state) => {
              state.macro.macrosList.macrosSelected = [];
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.macro.isDeletingMacro = false;
            })
          );
        });
    },
    restoreMacros: (macrosIds: string[]) => {
      set(
        produce((state) => {
          state.macro.isRestoringMacro = true;
        })
      );

      macroService
        .restoreMacros({ macros: macrosIds })
        .then(() => {
          toast.success("Registros restaurados com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().macro.getMacrosRestore();
          set(
            produce((state) => {
              state.macro.macrosRestoreList.macrosSelected = [];
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.macro.isRestoringMacro = false;
            })
          );
        });
    },
    resetSelectedMacro: () => {
      set(
        produce((state) => {
          state.macro.selectedMacro = null;
        })
      );
    },
    selectMacro: (macroId: string | undefined) => {
      if (macroId === undefined) return;
      set(
        produce((state) => {
          state.macro.macrosList.macrosSelected.push(macroId);
        })
      );
    },
    unselectMacro: (macroId: string | undefined) => {
      if (macroId === undefined) return;
      set(
        produce((state) => {
          state.macro.macrosList.macrosSelected =
            state.macro.macrosList.macrosSelected.filter(
              (id: string) => id !== macroId
            );
        })
      );
    },
    selectRestoreMacro: (macroId: string | undefined) => {
      if (macroId === undefined) return;
      set(
        produce((state) => {
          state.macro.macrosRestoreList.macrosSelected.push(macroId);
        })
      );
    },
    unselectRestoreMacro: (macroId: string | undefined) => {
      if (macroId === undefined) return;
      set(
        produce((state) => {
          state.macro.macrosRestoreList.macrosSelected =
            state.macro.macrosRestoreList.macrosSelected.filter(
              (id: string) => id !== macroId
            );
        })
      );
    },
    getJourneyEvent: (journeyEventId) => {
      set(
        produce((state) => {
          state.macro.isLoadingJourneyEvent = true;
        })
      );

      macroService
        .getJourneyEvent(journeyEventId)
        .then((data) => {
          set(
            produce((state) => {
              state.macro.selectedJourneyEvent = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.macro.isLoadingJourneyEvent = false;
            })
          );
        });
    },
    resetSelectedJourneyEvent: () => {
      set(
        produce((state) => {
          state.macro.selectedJourneyEvent = null;
        })
      );
    },
    getJourneyEvents: () => {
      set(
        produce((state) => {
          state.macro.isLoadingJourneyEventsList = true;
        })
      );

      const filters = get().macro.journeyEventsList.filters;
      const limit = get().macro.journeyEventsList.limitPerPage;
      const offset = get().macro.journeyEventsList.offset;

      macroService
        .getJourneyEvents({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.macro.journeyEventsList.data = data;
              state.macro.journeyEventsList.totalItems = totalItems;
              state.macro.journeyEventsList.totalPages = Math.ceil(
                totalItems / state.macro.journeyEventsList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.macro.isLoadingJourneyEventsList = false;
            })
          );
        });
    },
    getJourneyEventOptions: async (name: string) => {
      try {
        const { data }: any = await macroService.getJourneyEvents({
          filters: { name },
          limit: 20,
          offset: 0,
        });
        return data;
      } catch (error: any) {
        toast.error(error.message);
      }
    },
    resetJourneyEventsListState: () => {
      set(
        produce((state) => {
          state.macro.journeyEventsList = {
            ...state.macro.journeyEventsList,
            ...initialJourneyEventsListData,
          };
        })
      );
    },
    setMacroDataToUpdate: (macroDataToUpdate) => {
      set(
        produce((state) => {
          state.macro.macroDataToUpdate = macroDataToUpdate;
        })
      );
    },
    getMacros: () => {
      let section;
      set(
        produce((state) => {
          state.macro.isLoadingMacrosList = true;
          section = state.admin.data.section?._id;
        })
      );

      const filters = { ...get().macro.macrosList.filters };
      if (filters.started_at_begin) {
        filters.started_at_begin = moment(filters.started_at_begin).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
      }
      if (filters.started_at_end) {
        filters.started_at_end = moment(filters.started_at_end).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
      }
      if (filters.ended_at_begin) {
        filters.ended_at_begin = moment(filters.ended_at_begin).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
      }
      if (filters.ended_at_end) {
        filters.ended_at_end = moment(filters.ended_at_end).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
      }

      const limit = get().macro.macrosList.limitPerPage;
      const offset = get().macro.macrosList.offset;

      macroService
        .getMacros({ filters, limit, offset }, section)
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.macro.macrosList.data = data;
              state.macro.macrosList.totalItems = totalItems;
              state.macro.macrosList.totalPages = Math.ceil(
                totalItems / state.macro.macrosList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.macro.isLoadingMacrosList = false;
            })
          );
        });
    },
    getMacrosLink: () => {
      set(
        produce((state) => {
          state.macro.isLoadingMacrosLinkList = true;
        })
      );

      const filters = { ...get().macro.macrosLinkList.filters };
      if (filters.started_at_begin) {
        filters.started_at_begin = moment(filters.started_at_begin).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
      }
      if (filters.started_at_end) {
        filters.started_at_end = moment(filters.started_at_end).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
      }
      if (filters.ended_at_begin) {
        filters.ended_at_begin = moment(filters.ended_at_begin).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
      }
      if (filters.ended_at_end) {
        filters.ended_at_end = moment(filters.ended_at_end).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
      }

      const limit = get().macro.macrosLinkList.limitPerPage;
      const offset = get().macro.macrosLinkList.offset;

      macroService
        .getMacros({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.macro.macrosLinkList.data = data;
              state.macro.macrosLinkList.totalItems = totalItems;
              state.macro.macrosLinkList.totalPages = Math.ceil(
                totalItems / state.macro.macrosLinkList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.macro.isLoadingMacrosLinkList = false;
            })
          );
        });
    },
    getMacrosRestore: () => {
      let section;
      set(
        produce((state) => {
          state.macro.isLoadingMacrosRestoreList = true;
          section = state.admin.data.section?._id;
        })
      );

      const filters = { ...get().macro.macrosRestoreList.filters };
      if (filters.started_at_begin) {
        filters.started_at_begin = moment(filters.started_at_begin).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
      }
      if (filters.started_at_end) {
        filters.started_at_end = moment(filters.started_at_end).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
      }
      if (filters.ended_at_begin) {
        filters.ended_at_begin = moment(filters.ended_at_begin).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
      }
      if (filters.ended_at_end) {
        filters.ended_at_end = moment(filters.ended_at_end).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
      }

      const limit = get().macro.macrosRestoreList.limitPerPage;
      const offset = get().macro.macrosRestoreList.offset;

      macroService
        .getMacros({ filters, limit, offset }, section)
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.macro.macrosRestoreList.data = data;
              state.macro.macrosRestoreList.totalItems = totalItems;
              state.macro.macrosRestoreList.totalPages = Math.ceil(
                totalItems / state.macro.macrosRestoreList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.macro.isLoadingMacrosRestoreList = false;
            })
          );
        });
    },
    getMacro: (macroId) => {
      set(
        produce((state) => {
          state.macro.isLoadingMacro = true;
        })
      );

      macroService
        .getMacro(macroId)
        .then((data) => {
          set(
            produce((state) => {
              state.macro.selectedMacro = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.macro.isLoadingMacro = false;
            })
          );
        });
    },
    updateMacro: () => {
      const macroId = get().macro.selectedMacro?._id;
      const macroDataToUpdate = get().macro.macroDataToUpdate;

      if (!macroId || !macroDataToUpdate) return null;

      const macroBody: CheckMacroBody = {
        user: macroDataToUpdate.user.value,
        vehicle: macroDataToUpdate.vehicle.value,
        journey_event: macroDataToUpdate.journey_event.value,
        started_at:
          moment(macroDataToUpdate.started_at).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          ) ?? null,
      };

      set(
        produce((state) => {
          state.macro.isUpdatingMacro = true;
        })
      );

      macroService
        .updateMacro(macroId, macroBody)
        .then(() => {
          toast.success("Alteração requisitada com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().macro.getMacros();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.macro.isUpdatingMacro = false;
              state.macro.selectedMacro = null;  
            })
          );
        });
    },
    checkMacro: async () => {
      try {
        const macroId = get().macro.selectedMacro?._id;
        const macroDataToUpdate = get().macro.macroDataToUpdate;

        if (!macroId || !macroDataToUpdate) return null;

        const macroBody: CheckMacroBody = {
          user: macroDataToUpdate.user.value,
          vehicle: macroDataToUpdate.vehicle.value,
          journey_event: macroDataToUpdate.journey_event.value,
          started_at:
            moment(macroDataToUpdate.started_at).format(
              "YYYY-MM-DDTHH:mm:ss.SSSZ"
            ) ?? null
        };

        set(
          produce((state) => {
            state.macro.isCheckingMacro = true;
          })
        );

        const data: any = await macroService.checkMacro(macroId, macroBody);

        if (!data?.is_valid) {
          throw Error("Macro inválida");
        }

        set(
          produce((state) => {
            state.macro.macroDataToUpdate.old_flow = [...data.old_flow];
            state.macro.macroDataToUpdate.new_flow = [...data.new_flow];
          })
        );

        return data;
      } finally {
        set(
          produce((state) => {
            state.macro.isCheckingMacro = false;
          })
        );
      }
    },
    exportMacroTable: (format: "csv" | "pdf") => {
      set(
        produce((state) => {
          state.macro.isExporting[format] = true;
        })
      );

      const columnsVisibility = get().macro.macrosList.columnsVisibility;

      const columnsToExport = Object.keys(columnsVisibility)
        .filter((key) => columnsVisibility[key as MacrosVisibleColumnsKey])
        .map((key) => key as MacrosVisibleColumnsKey);

      macroService
        .exportMacro(format, columnsToExport)
        .then((data) => {
          downloadURI(data.fileUrl, data.fileName);
          toast.success("Documento exportado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.macro.isExporting[format] = false;
            })
          );
        });
    },
    resetMacrosListState: () => {
      set(
        produce((state) => {
          state.macro.macrosList = {
            ...state.macro.macrosList,
            ...initialMacroListData,
          };
        })
      );
    },
    resetMacrosLinkListState: () => {
      set(
        produce((state) => {
          state.macro.macrosListLink = {
            ...state.macro.macrosLinkList,
            ...initialMacroLinkListData,
          };
        })
      );
    },
    resetMacrosRestoreListState: () => {
      set(
        produce((state) => {
          state.macro.macrosListRestore = {
            ...state.macro.macrosRestoreList,
            ...initialMacroRestoreListData,
          };
        })
      );
    },
    linkMacros: (macrosDataToLink: {
      user: { value: string; label: string };
      macro_ids: string[];
    }) => {
      if (
        !macrosDataToLink ||
        macrosDataToLink.user.value === "" ||
        macrosDataToLink.macro_ids.length === 0
      )
        return null;

      const linkMacrosBody: MacrosLinkBody = {
        user_id: macrosDataToLink.user.value,
        macro_ids: macrosDataToLink.macro_ids
      };

      set(
        produce((state) => {
          state.macro.isLinkingMacro = true;
        })
      );

      macroService
        .linkMacros(linkMacrosBody)
        .then(() => {
          toast.success("Macros vinculadas com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().macro.getMacrosLink();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.macro.isLinkingMacro = false;
            })
          );
        });
    },
    createMacros: (createMacrosData: CreateMacrosDataBody[]) => {
  
      set(
        produce((state) => {
          state.macro.isCreatingMacro = true;
        })
      );

      macroService
        .createMacros(createMacrosData)
        .then(() => {
          toast.success("Macro criada com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().macro.getMacros();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.macro.isCreatingMacro = false;
            })
          );
        });
    },
  },
});

