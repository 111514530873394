import { useStore } from "../../../store/useStore";
import DataTable from "../../../components/DataTable/DataTable";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import Pagination from "../../../components/Pagination/Pagination";
import Skeleton from "react-loading-skeleton";

const CollaboratorsSelectTable: React.FC<{ form: any; fieldName: string, columns: any }> = ({
  fieldName,
  form,
  columns
}) => {
  const collaboratorsList = useStore(
    (state) => state.collaborator.collaboratorsList
  );

  const setFilters = useStore(
    (state) => state.collaborator.collaboratorsList.setFilters
  );
  const filters = useStore(
    (state) => state.collaborator.collaboratorsList.filters
  );
  const isLoadingCollaboratorsList = useStore(
    (state) => state.collaborator.isLoadingCollaboratorsList
  );

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const collaborators = useStore((state) => state.collaborator.collaboratorsList.data);
  const collaboratorsSelected = useStore((state) => state.collaborator.collaboratorsList.collaboratorsSelected);
  const isAllChecked = () => {
    return collaborators.every((collaborators) => collaboratorsSelected.includes(collaborators?._id ?? ""))
  }
  const selectCollaborator = useStore((state) => state.collaborator.selectCollaborator);
  const unselectCollaborator = useStore((state) => state.collaborator.unselectCollaborator);

  return (
    <>
      <DataTable
        data={collaborators}
        columns={columns}
        onDetails={null}
        onEdit={null}
        isAllChecked={isAllChecked}
        onSelectAll={() => {
          if(!isAllChecked()) {
            for(const item of collaborators) {
              selectCollaborator(item._id);
              form.setFieldValue(
                `${fieldName}.${item._id}`,
                true
              )
            }
          } else {
            for(const item of collaborators) {
              unselectCollaborator(item._id);
              form.setFieldValue(
                `${fieldName}.${item._id}`,
                false
              )
            }
          }
        }}
        isChecked={(item) => !!collaboratorsSelected.includes(item._id)}
        onSelect={(item) => {
          if(collaboratorsSelected.includes(item._id)) {
            unselectCollaborator(item._id)
          } else {
            selectCollaborator(item._id)
          }
          form.setFieldValue(
            `${fieldName}.${item._id}`,
            !form.values[fieldName][item._id]
          )
        }}
        onOrder={handleTableOrder}
        sortKey={filters.sort_key}
        sortValue={filters.sort_value}
        loading={isLoadingCollaboratorsList}
      />
      <div className="flex justify-between items-center">
        <LimitPerPage
          onLimitChange={(e: any) =>
            collaboratorsList.setLimitPerPage(e.target.value)
          }
        />
        <Pagination
          currentPage={collaboratorsList.currentPage}
          totalPages={collaboratorsList.totalPages}
          onPageChange={collaboratorsList.setCurrentPage}
        />
      </div>
      <div className="text-gray_1 text-center mt-2">
        Mostrando{" "}
        {!isLoadingCollaboratorsList ? (
          collaboratorsList.data.length +
          collaboratorsList.offset * collaboratorsList.limitPerPage
        ) : (
          <Skeleton inline width={20} />
        )}{" "}
        de {collaboratorsList.totalItems} registros
      </div>
    </>
  );
};

export default CollaboratorsSelectTable;
