import DataTable from "../../DataTable/DataTable";
import useModal from "../../../hooks/useModal";
import { useStore } from "../../../store/useStore";
import StatusText from "../../StatusText/StatusText";
import { getJourneyTimeStatus } from "../../../utils/functions/functions";
import TimeCounter from "../../TimeCounter/TimeCounter";
import DetailsStatusModal from "./DetailsStatusModal";
import { DocumentText, TruckFast } from "iconsax-react";
import { Colors } from "../../../utils/constants/style";

const DashboardMacrosTable = () => {
  const { openModal } = useModal();

  const macros = useStore(
    (state) => state.dashboard.statusPanel.macrosList.data
  );
  const setFilters = useStore((state) => state.dashboard.statusPanel.macrosList.setFilters);
  const filters = useStore((state) => state.dashboard.statusPanel.macrosList.filters);
  const isLoadingDashboardMacro = useStore(
    (state) => state.dashboard.isLoadingDashboardMacro
  );
  const resetSelectedMacro = useStore(
    (state) => state.dashboard.resetSelectedMacro
  );
  const setSelectedMacro = useStore(
    (state) => state.dashboard.setSelectedMacro
  );

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const openDetailsModal = (macroParam: any) => {
    const macroSelected = macros.find((macro: any) => macro.user_id === macroParam.user_id);
    setSelectedMacro(macroSelected);
    openModal(<DetailsStatusModal onClose={() => resetSelectedMacro()} />);
  };

  const columns = [
    { key: "user", label: "Motorista", sortKey: "user" },
    { key: "vehicle", label: "Placa", sortKey: "vehicle" },
    {
      key: "journey_event",
      label: "Atividade",
      render: (item: any) => {
        return item.journey_event?.started_at &&
          item.journey_event?.name ? (
          <div className="flex flex-col justify-center items-center">
            <StatusText
              text={item.journey_event?.name ?? "-"}
              status={getJourneyTimeStatus(item?.journey_event)}
            />
            <TimeCounter startDate={item.journey_event?.started_at} />
          </div>
        ) : (
          "-"
        );
      },
    },
  ];

  return (
    <DataTable
      data={macros}
      columns={columns}
      onDetails={openDetailsModal}
      fullObjOnParameters
      onDetailsIcon={
        <TruckFast size="24" color={Colors.gray_3} variant="Bulk" />
      }
      onEdit={null}
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingDashboardMacro}
    />
  );
};

export default DashboardMacrosTable;
