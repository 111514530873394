import { useEffect, useState } from "react";
import TabPanel from "../../components/Tabs/Components/TabPanel";
import CollaboratorsGeneral from "./Components/CollaboratorsGeneral";
import { useStore } from "../../store/useStore";

const Collaborators: React.FC<{}> = () => {
  const [tab, setTab] = useState<number>(0);
  const resetCollaboratorListState = useStore(state => state.collaborator.resetCollaboratorListState);

  useEffect(() => {
    return () => {
      resetCollaboratorListState();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {/*<Tabs tab={tab} setTab={setTab}>
        <Tab label="Visão Geral" icon={<Home variant="Bulk" />} />
         <Tab label="Gerenciadora de Risco" icon={<GlobeIcon />} />
        <Tab label="Analytics" icon={<AnalyticsIcon />} />
      </Tabs>*/}
      <TabPanel tab={tab} index={0}>
        <CollaboratorsGeneral />
      </TabPanel>
    </div>
  );
};

export default Collaborators;
