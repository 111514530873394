import { Colors } from '../../utils/constants/style';
import { ChevronIcon } from '../../assets/imgs';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

function Pagination({ currentPage, totalPages, onPageChange }: PaginationProps) {
  const pageNumbers: (number | string)[] = [];
  const maxPagesToShow = 5;

  if (totalPages <= maxPagesToShow) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    const halfMaxPages = Math.floor(maxPagesToShow / 2);

    if (currentPage <= halfMaxPages) {
      for (let i = 1; i <= maxPagesToShow - 2; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
    } else if (currentPage >= totalPages - halfMaxPages) {
      pageNumbers.push(1);
      pageNumbers.push('...');
      for (let i = totalPages - maxPagesToShow + 3; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      pageNumbers.push('...');
      for (let i = currentPage - halfMaxPages + 2; i <= currentPage + halfMaxPages - 1; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
    }
  }

  return (
    <div className="pagination select-none flex items-center gap-1">
      <span
        className={`cursor-pointer bg-white flex items-center justify-center text-gray_2 border border-gray_2 rounded-full w-[34px] h-[34px]`}
        onClick={() => {
          if (currentPage > 1) {
            onPageChange(currentPage - 1);
          }
        }}
      >
        <ChevronIcon color={Colors.gray_2} direction="left" />
      </span>

      {pageNumbers.map((page, index) => (
        <span
          key={index}
          className={`page-number ${
            page === currentPage
              ? 'active bg-red flex cursor-pointer items-center justify-center text-white rounded-full w-[34px] h-[34px]'
              : page === '...'
              ? 'text-gray_2 px-1'
              : 'bg-white flex items-center cursor-pointer justify-center text-gray_2 border border-gray_1 rounded-full w-[34px] h-[34px]'
          }`}
          onClick={() => {
            if (typeof page === 'number') {
              onPageChange(page);
            }
          }}
        >
          {page}
        </span>
      ))}

      <span
        className={`cursor-pointer bg-white flex items-center justify-center text-gray_2 border border-gray_2 rounded-full w-[34px] h-[34px]`}
        onClick={() => {
          if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
          }
        }}
      >
        <ChevronIcon color={Colors.gray_2} direction="right" />
      </span>
    </div>
  );
}

export default Pagination;
