import { PlusIcon, UserIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import { useStore } from "../../../store/useStore";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import { useEffect, useState } from "react";
import CollaboratorsSelectTable from "../../Conventions/Components/CollaboratorsSelectTable";
import CustomDatePicker from "../../../components/FormComponents/CustomDatePicker/CustomDatePicker";
import { Collaborator } from "../../../store/types/collaborator";
import { formatCPF } from "../../../utils/functions/formatters";
import Tabs from "../../../components/Tabs/Tabs";
import Tab from "../../../components/Tabs/Components/Tab";
import TabPanel from "../../../components/Tabs/Components/TabPanel";
import PayrollLayoutSelectTable from "../../Conventions/Components/PayrollLayoutSelectTable";
import { LAYOUT_COLUMNS_OPTIONS, LAYOUT_TOTALIZERS_OPTIONS } from "../../../utils/constants/constants";
import LayoutIcon from "../../../assets/imgs/LayoutIcon";

const initialValues = {
  name: "",
  batch: "",
  startCompetence: "",
  endCompetence: "",
  users: {} as Record<string, any>,
  columns: {} as Record<string, any>,
  totalizers: {} as Record<string, any>
};

const CreatePayRollModal: React.FC<{}> = () => {
  const createPayRoll = useStore((state) => state.timeManager.createPayRoll);
  const isCreatingSection = useStore(
    (state) => state.timeManager.isCreatingPayRoll
  );
  const getCollaborators = useStore(
    (state) => state.collaborator.getCollaborators
  );
  const resetCollaboratorListState = useStore(
    (state) => state.collaborator.resetCollaboratorListState
  );
  const [tab, setTab] = useState<number>(0);

  LAYOUT_TOTALIZERS_OPTIONS.forEach((item: any) => {
    initialValues.totalizers[item.type] = true;
  });
  LAYOUT_COLUMNS_OPTIONS.forEach((item: any) => {
    initialValues.columns[item.type] = true;
  });
  const setCollaboratorsLimitPerPage = useStore(
    (state) => state.collaborator.collaboratorsList.setLimitPerPage
  );

  useEffect(() => {
    setCollaboratorsLimitPerPage(999);
    return () => {
      resetCollaboratorListState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user_columns = [
    { key: "name", label: "Nome" },
    {
      key: "cpf",
      label: "CPF",
      render: (item: Collaborator) => formatCPF(item.cpf),
    },
  ];

  const layout_columns = [
    { key: "name", label: "" }
  ];

  return (
    <Modal
      title="Cadastro de nova folha de pagamento"
      icon={<PlusIcon width={30} height={30} />}
      className="w-[800px]"
      childrenClassName="px-5 overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Campo obrigatório"),
          batch: Yup.string().required("Campo obrigatório"),
          startCompetence: Yup.string().required("Campo obrigatório"),
          endCompetence: Yup.string().required("Campo obrigatório"),
          users: Yup.object().test(
            "at-least-one-true",
            "Selecione pelo menos 1 colaborador",
            (obj) => {
              return Object.values(obj || {}).some((value) => value === true);
            }
          ),
        })}
        onSubmit={(values) => {
          const users = Object.keys(values.users).filter(
            (k: string) => values.users[k]
          );
          const columns = Object.keys(values.columns).filter(
            (k: string) => values.columns[k]
          );
          const totalizers = Object.keys(values.totalizers).filter(
            (k: string) => values.totalizers[k]
          );

          const data = {
            name: values.name,
            batch: values.batch,
            competence_start: values.startCompetence,
            competence_end: values.endCompetence,
            users,
            layout: {
              columns,
              totalizers
            }
          };

          createPayRoll(data);
        }}
      >
        {(formik) => (
          <Form>
            <div className="flex flex-col gap-8 pt-5">
              <div className="flex gap-5">
                <div className="flex-1">
                  <Field
                    placeholder="Nome"
                    name="name"
                    component={OutlinedInput}
                    labelClassName="w-full"
                  />
                </div>
                <div className="flex-1">
                  <Field
                    placeholder="Lote"
                    name="batch"
                    component={OutlinedInput}
                    labelClassName="w-full"
                  />
                </div>
              </div>
              <div className="flex gap-5">
                <div className="flex-1">
                  <Field
                    placeholder="Início Competência"
                    name="startCompetence"
                    component={CustomDatePicker}
                  />
                </div>
                <div className="flex-1">
                  <Field
                    placeholder="Fim Competência"
                    name="endCompetence"
                    component={CustomDatePicker}
                  />
                </div>
              </div>
              <Tabs tab={tab} setTab={setTab}>
                <Tab
                  label="Colaboradores"
                  icon={<UserIcon color="#344767" width={15} height={15} />}
                />
                <Tab label="Layout" icon={<LayoutIcon />} />
              </Tabs>
              <div>
                <TabPanel tab={tab} index={0}>
                  <div>
                    <div className="text-red text-sm">
                      {(formik.errors.users as any) && (formik.touched.users as any)
                        ? (formik.errors.users as any)
                        : null}
                    </div>
                    <CollaboratorsSelectTable
                      columns={user_columns}
                      form={formik}
                      fieldName="users"
                    />
                  </div>
                </TabPanel>
                <TabPanel tab={tab} index={1}>
                  <div className="flex gap-5">
                    <div className="flex-1">
                      <div className="text-dark-blue font-bold pb-5 -mb-5">
                        Colunas
                      </div>
                        <PayrollLayoutSelectTable
                          columns={layout_columns}
                          form={formik}
                          fieldName="columns"
                        />
                    </div>
                    <div className="flex-1">
                      <div className="text-dark-blue font-bold pb-5 -mb-5">
                        Totalizadores
                      </div>
                      <PayrollLayoutSelectTable
                        columns={layout_columns}
                        form={formik}
                        fieldName="totalizers"
                      />
                    </div>
                  </div>
                </TabPanel>
              </div>
              <div className="flex gap-5 z-30 py-4 sticky bottom-0 bg-white">
                <div className="flex-1"></div>
                <div className="flex-1">
                  <FilledButton
                    loading={isCreatingSection}
                    className="w-full"
                    type="submit"
                  >
                    Cadastrar
                  </FilledButton>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreatePayRollModal;
