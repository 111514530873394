import DataTable from "../../../components/DataTable/DataTable";
import useModal from "../../../hooks/useModal";
import { useStore } from "../../../store/useStore";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import {
  formatAddress,
  formatDateTime,
} from "../../../utils/functions/formatters";
import StatusText from "../../../components/StatusText/StatusText";
import { Macro, MacrosVisibleColumns } from "../../../store/types/macroState";
import DetailsMacroModal from "./DetailsMacroModal";
import { MacroStatusColor } from "../../../utils/constants/constants";
import MacroOrigin from "../../../components/MacroOrigin/MacroOrigin";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import RestoreMacroModal from "./RestoreMacrosModal";

const initialValues = {
  macros: {} as Record<string, any>
};

const MacrosRestoreTable = () => {
  const { openModal } = useModal();

  const getMacro = useStore((state) => state.macro.getMacro);
  const resetSelectedMacro = useStore(
    (state) => state.macro.resetSelectedMacro
  );
  const macros = useStore((state) => state.macro.macrosRestoreList.data);
  const setFilters = useStore((state) => state.macro.macrosRestoreList.setFilters);
  const filters = useStore((state) => state.macro.macrosRestoreList.filters);
  const columnsVisibility = useStore(
    (state) => state.macro.macrosRestoreList.columnsVisibility
  );
  const isLoadingMacrosRestoreList = useStore(
    (state) => state.macro.isLoadingMacrosRestoreList
  );
  const hasPermissions = useStore((state) => state.admin.hasPermissions);

  const openRestoreMacroModal = () => {
    openModal(<RestoreMacroModal onClose={resetSelectedMacro} />);
  };

  const openDetailsMacroModal = (macroId: string) => {
    getMacro(macroId);
    openModal(<DetailsMacroModal onClose={resetSelectedMacro} />);
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const macrosSelected = useStore((state) => state.macro.macrosRestoreList.macrosSelected);
  const isAllChecked = () => {
    return macros.every((macros) => macrosSelected.includes(macros?._id ?? ""))
  }
  const selectMacro = useStore((state) => state.macro.selectRestoreMacro);
  const unselectMacro = useStore((state) => state.macro.unselectRestoreMacro);

  const columns = [
    {
      key: "macro_origin",
      label: "Origem",
      render: (item: Macro) => item?.macro_origin ? <MacroOrigin origin={item?.macro_origin} /> : '-', 
    },
    { 
      key: "vehicle", 
      label: "Veículo", 
      render: (item: Macro) => item?.vehicle ?? '-', 
    },
    { 
      key: "user", 
      label: "Motorista", 
      sortKey: "user",
      render: (item: Macro) => item?.user ?? '-',
    },
    {
      key: "journey_event",
      label: "Evento de Jornada",
      sortKey: "journey_event",
      render: (item: Macro) => item?.journey_event ?? '-',
    },
    {
      key: "location",
      label: "Endereço",
      render: (item: Macro) => formatAddress(item.location),
    },
    {
      key: "status",
      label: "Status",
      sortKey: "status",
      render: (item: Macro) => (
        <div className="flex justify-center">
        <StatusText
          status={item.status?.type ? MacroStatusColor[item.status?.type] : "danger"}
          text={item.status?.name ?? '-'}
        />
        </div>
      ),
    },
    {
      key: "started_at",
      label: "Inicio",
      render: (item: Macro) => item?.started_at ? formatDateTime(item.started_at) : '-',
      sortKey: "started_at",
    }
  ].filter((column) => {
    return columnsVisibility[column.key as keyof MacrosVisibleColumns];
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        periodStart: Yup.string().required("Campo obrigatório"),
        periodEnd: Yup.string().required("Campo obrigatório"),
      })}
      onSubmit={() => {}}
    >
      {(formik) => (
        <Form>
          <DataTable
            data={macros}
            columns={columns}
            onDetails={
              hasPermissions([PERMISSIONS.READ_MACRO]) ? openDetailsMacroModal : null
            }
            showRestoreByItem={() => hasPermissions([PERMISSIONS.RESTORE_MACRO])}
            onRestore={(item_id: string) => {
              if(hasPermissions([PERMISSIONS.RESTORE_MACRO])) {
                if(!macrosSelected.includes(item_id)) selectMacro(item_id)
                formik.setFieldValue(
                  `macros.${item_id}`,
                  !formik.values['macros'][item_id]
                )
                openRestoreMacroModal();
              }
            }}
            isAllChecked={isAllChecked}
            onSelectAll={() => {
              if(!isAllChecked()) {
                for(const item of macros) {
                  selectMacro(item._id);
                  formik.setFieldValue(
                    `macros.${item._id}`,
                    true
                  )
                }
              } else {
                for(const item of macros) {
                  unselectMacro(item._id);
                  formik.setFieldValue(
                    `macros.${item._id}`,
                    false
                  )
                }
              }
            }}
            isChecked={(item) => !!macrosSelected.includes(item._id)}
            onSelect={(item) => {
              if(macrosSelected.includes(item._id)) {
                unselectMacro(item._id)
              } else {
                selectMacro(item._id)
              }
              formik.setFieldValue(
                `macros.${item._id}`,
                !formik.values['macros'][item._id]
              )
            }}
            onOrder={handleTableOrder}
            sortKey={filters.sort_key}
            sortValue={filters.sort_value}
            loading={isLoadingMacrosRestoreList}
          />
        </Form>
      )}
    </Formik>
  );
};

export default MacrosRestoreTable;
