import React from "react";

interface CheckboxProps {
  text?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  labelClassName?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ text, checked, onChange, className='w-[18px] h-[18px]', labelClassName='font-light text-sm' }) => {
  return (
    <div className={`flex items-center ${text ? 'gap-2' : ''} `}>
      <input
        className={`accent-red rounded-[4px] ${className}`}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <label className={labelClassName}>{text}</label>
    </div>
  );
};

export default Checkbox;
