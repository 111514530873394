import DataTable from "../../../components/DataTable/DataTable";
import useModal from "../../../hooks/useModal";
import { HOLIDAY_TYPE, HOLIDAY_TYPE_TRANSLATION, Holiday, HolidaysVisibleColumns } from "../../../store/types/convention";
import { useStore } from "../../../store/useStore";
import {
  formatDateTime,
} from "../../../utils/functions/formatters";
import DetailsHolidayModal from "./DetailsHolidayModal";
import EditHolidayModal from "./EditHolidayModal";

const HolidaysTable = () => {
  const holidays = useStore((state) => state.conventions.holidaysList.data);
  const setFilters = useStore(
    (state) => state.conventions.holidaysList.setFilters
  );
  const filters = useStore((state) => state.conventions.holidaysList.filters);
  const isLoadingHolidaysList = useStore(
    (state) => state.conventions.isLoadingHolidaysList
  );
  const resetSelectedHoliday = useStore((state) => state.conventions.resetSelectedHoliday);
  const getHoliday = useStore((state) => state.conventions.getHoliday);
  const { openModal } = useModal();
  const columnsVisibility = useStore(
    (state) => state.conventions.holidaysList.columnsVisibility
  );

  const openDetailsHolidayModal = (holidayId: string) => {
    getHoliday(holidayId);
    openModal(<DetailsHolidayModal onClose={resetSelectedHoliday} />);
  };

  const openEditHolidayModal = (holidayId: string) => {
    getHoliday(holidayId);
    openModal(<EditHolidayModal onClose={resetSelectedHoliday} />);
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "name", label: "Nome", sortKey: "name" },
    { key: "type", label: "Tipo", render: (item: Holiday) => HOLIDAY_TYPE_TRANSLATION[item.type as HOLIDAY_TYPE] },
    { key: "date", label: "Data", sortKey: "date" },
    {
      key: "created_at",
      label: "Criado em",
      render: (item: Holiday) => formatDateTime(item.created_at),
    },
  ].filter((column) => {
    return columnsVisibility[column.key as keyof HolidaysVisibleColumns];
  });

  return (
    <DataTable
      data={holidays}
      columns={columns}
      onDetails={openDetailsHolidayModal}
      onEdit={openEditHolidayModal}
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingHolidaysList}
    />
  );
};

export default HolidaysTable;
