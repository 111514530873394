import { PlusIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Colors } from "../../../utils/constants/style";
import { useStore } from "../../../store/useStore";
import { Field, Formik } from "formik";
import AsyncOutlinedSelect from "../../../components/AsyncOutlinedSelect/AsyncOutlinedSelect";
import { Collaborator } from "../../../store/types/collaborator";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";

const LinkMacroModal: React.FC<{}> = () => {
  const isLinkingMacro = useStore((state) => state.macro.isLinkingMacro);
  const linkMacros = useStore((state) => state.macro.linkMacros);
  const macrosSelected = useStore((state) => state.macro.macrosLinkList.macrosSelected);

  const getCollaboratorOptionsCallback = async (inputValue: string) => {
    const options = (await getCollaboratorOptions(inputValue)).map(
      (c: Collaborator) => {
        return { label: c.name, value: c._id };
      }
    );
    return options;
  };
  const getCollaboratorOptions = useStore(
    (state) => state.collaborator.getCollaboratorOptions
  );

  return (
    <Modal
      title="Selecione o Motorista para Vincular"
      icon={<PlusIcon width={30} height={30} color={Colors.white} />}
      className="w-[547px]"
      onClose={() => {}}
    >
      <>
        <Formik
          initialValues={{
            user: {
              value: "",
              label: "",
            },
            macro_ids: macrosSelected,
          }}
          validationSchema={Yup.object().shape({
            user: Yup.object().shape({
              value: Yup.string().required("Campo obrigatório"),
              label: Yup.string().required("Campo obrigatório"),
            }),
          })}
          onSubmit={(values) => {
            linkMacros(values);
          }}
        >
          {(formik) => {
            return (
              <div className="flex flex-col gap-8 pt-8">
                <div className="flex-1">
                  <Field
                    loading={false}
                    name="user"
                    component={AsyncOutlinedSelect}
                    callback={getCollaboratorOptionsCallback}
                    value={{
                      value: formik.values.user.value,
                      label: formik.values.user.label,
                    }}
                    onChange={(option: any) => {
                      formik.setFieldValue("user", option);
                    }}
                    menuPlacement="top"
                    placeholder="Motorista"
                  />
                </div>
                <div className="flex gap-5">
                  <div className="flex-1"></div>
                  <div className="flex-1">
                    <FilledButton
                      loading={isLinkingMacro}
                      className="w-full"
                      onClick={() => formik.submitForm()}
                    >
                      <span>Vincular {macrosSelected.length} Macro{macrosSelected.length > 1 ? "s" : ""}</span>
                    </FilledButton>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </>
    </Modal>
  );
};

export default LinkMacroModal;
