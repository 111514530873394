import { useState } from "react";

interface TooltipProps {
  text: string;
  isVisible: boolean;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, isVisible, children }) => {
  const [isHovered, setIsHovered] = useState(false);

  const show = isVisible && isHovered;

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {show && (
        <div className="absolute -top-10 left-1/2 transform -translate-x-1/2 bg-gray_3 text-white p-2 rounded-md z-10 w-[300px]">
          <div className="z-10">{text}</div>
          <div className="absolute bottom-full left-1/2 border-t-2 border-transparent border-black border-solid transform translate-x-[-50%] mt-1"></div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
