const EditProfileIcon = () => {
  return (
    <svg
      width="31"
      height="29"
      viewBox="0 0 31 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.1666 16.25L28.5 17.9167L25.0833 14.5L26.75 12.8334C27.1 12.4834 27.6833 12.4834 28.0333 12.8334L30.1666 14.9667C30.5166 15.3167 30.5166 15.9 30.1666 16.25ZM14 25.5667L24.1 15.4667L27.5166 18.8834L17.4333 29H14V25.5667ZM14 17.3334C6.63329 17.3334 0.666626 20.3167 0.666626 24V27.3334H10.6666V24.1834L17.3333 17.5167C16.2333 17.3834 15.1166 17.3334 14 17.3334ZM14 0.666687C12.2319 0.666687 10.5362 1.36907 9.28591 2.61931C8.03567 3.86955 7.33329 5.56524 7.33329 7.33335C7.33329 9.10146 8.03567 10.7972 9.28591 12.0474C10.5362 13.2976 12.2319 14 14 14C15.7681 14 17.4638 13.2976 18.714 12.0474C19.9643 10.7972 20.6666 9.10146 20.6666 7.33335C20.6666 5.56524 19.9643 3.86955 18.714 2.61931C17.4638 1.36907 15.7681 0.666687 14 0.666687Z"
        fill="white"
      />
    </svg>
  );
};

export default EditProfileIcon;
