import { SearchNormal1 } from "iconsax-react";
import InfoField from "../../../components/InfoField/InfoField";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import Modal from "../../../components/Modal/Modal";
import { useStore } from "../../../store/useStore";
import { Colors } from "../../../utils/constants/style";
import { formatDateTime } from "../../../utils/functions/formatters";

interface DetailsPayRollModalProps {
  onClose: () => void;
}

const DetailsPayRollModal: React.FC<DetailsPayRollModalProps> = ({
  onClose,
}) => {
  const selectedPayRoll = useStore(
    (state) => state.journeyRules.selectedPayRoll
  );
  const isLoadingPayRoll = useStore(
    (state) => state.journeyRules.isLoadingPayRoll
  );

  return (
    <Modal
      title="Detalhes de evento de folha"
      icon={<SearchNormal1 size="30" color={Colors.white} variant="Linear" />}
      className="min-w-[479px]"
      onClose={onClose}
    >
      <div className="flex flex-col gap-5">
        <InfoFieldBox>
          <InfoField
            loading={isLoadingPayRoll}
            label="Nome"
            content={selectedPayRoll?.name}
          />
          <InfoField
            loading={isLoadingPayRoll}
            label="Descrição"
            content={selectedPayRoll?.description}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingPayRoll}
            label="Criado em"
            content={formatDateTime(selectedPayRoll?.created_at ?? '')}
          />
        </InfoFieldBox>
      </div>
    </Modal>
  );
};

export default DetailsPayRollModal;
