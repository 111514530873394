import Modal from "../../../components/Modal/Modal";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { Colors } from "../../../utils/constants/style";
import { useStore } from "../../../store/useStore";
import { Danger, Trash } from "iconsax-react";

const DeleteSyndicateModal: React.FC<{}> = () => {
  const selectedSyndicate = useStore(
    (state) => state.conventions.selectedSyndicate
  );
  const deleteSyndicate = useStore(
    (state) => state.conventions.deleteSyndicate
  );
  const isDeletingSyndicate = useStore(
    (state) => state.conventions.isDeletingSyndicate
  );

  const handleDeleteVehicle = () => {
    if(selectedSyndicate?._id)
      deleteSyndicate(selectedSyndicate._id);
  };

  return (
    <Modal
      title="Exclusão de sindicato"
      icon={<Trash size={30} variant="Bold" color={Colors.white} />}
      className="w-[361px]"
    >
      <div className="text-center flex flex-col items-center gap-5">
        <Danger size="72" variant="Bulk" color={Colors.red}/>
        <div className="px-5">
          Tem certeza que deseja remover o sindicato de nome {selectedSyndicate?.name ?? '-'}?
        </div>
        <FilledButton loading={isDeletingSyndicate} onClick={handleDeleteVehicle} className="px-14">
          Excluir
        </FilledButton>
      </div>
    </Modal>
  );
};

export default DeleteSyndicateModal;
