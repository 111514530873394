interface PlusIconProps {
  width?: string | number;
  height?: string | number;
  color?: string;
}

const PlusIcon: React.FC<PlusIconProps> = ({ width=15, height=15, color="white" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 8.57143H8.57143V15H6.42857V8.57143H0V6.42857H6.42857V0H8.57143V6.42857H15V8.57143Z"
        fill={color}
      />
    </svg>
  );
};

export default PlusIcon;
