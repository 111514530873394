import Modal from "../../../components/Modal/Modal";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { Colors } from "../../../utils/constants/style";
import { useStore } from "../../../store/useStore";
import { Danger, Trash } from "iconsax-react";

interface DeletePayRollModalProps {
    onClose: () => void;
  }

const DeletePayRollModal: React.FC<DeletePayRollModalProps> = () => {
  const selectedPayRoll = useStore(
    (state) => state.timeManager.selectedPayRoll
  );
  const deletePayRoll = useStore(
    (state) => state.timeManager.deletePayRoll
  );
  const isDeletingPayRoll = useStore(
    (state) => state.timeManager.isDeletingPayRoll
  );

  const handleDeleteRegister = () => {
    if(selectedPayRoll?._id)
      deletePayRoll(selectedPayRoll._id);
  };

  return (
    <Modal
      title="Exclusão de lote de folha de pagamento"
      icon={<Trash size={30} variant="Bold" color={Colors.white} />}
      className="w-[400px]"
    >
      <div className="text-center flex flex-col items-center gap-5">
        <Danger size="72" variant="Bulk" color={Colors.red}/>
        <div className="px-5">Tem certeza que deseja remover este lote de folha de pagamento?</div>
          {selectedPayRoll?.name ? (<div className="text-dark-blue font-bold">{selectedPayRoll?.name}</div>) : ''}
          <FilledButton loading={isDeletingPayRoll} onClick={handleDeleteRegister} className="px-14">
            Excluir
          </FilledButton>
      </div>
    </Modal>
  );
};

export default DeletePayRollModal;
