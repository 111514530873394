import produce from "immer";
import { StateCreator } from "zustand";
import { NavbarState } from "../types/navbar";
import companyService from "../../services/companyService";
import { toast } from "react-toastify";

export const createNavbarSlice: StateCreator<NavbarState> = (set, get) => ({
  navbar: {
    isNavbarOpen: false,
    company: null,
    isLoadingCompany: false,
    toggleNavbar: () => {
      set(
        produce((state: NavbarState) => {
          state.navbar.isNavbarOpen = !state.navbar.isNavbarOpen;
        })
      );
    },
    getCompany: (companyId) => {
      set(
        produce((state) => {
          state.navbar.isLoadingCompany = true;
        })
      );

      companyService
        .getCompany(companyId)
        .then((data) => {
          set(
            produce((state) => {
              state.navbar.company = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.navbar.isLoadingCompany = false;
            })
          );
        });
    },
  },
});
