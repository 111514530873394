import {
  formatAddress,
  formatCoordinates,
  formatDateTime,
  formatDuration,
  formatSecondsToHour,
} from "../../../utils/functions/formatters";
import StatusText from "../../../components/StatusText/StatusText";
import { MacroStatusColor } from "../../../utils/constants/constants";
import InfoField from "../../../components/InfoField/InfoField";
import { Macro, MacroStatus } from "../../../store/types/macroState";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import SmallOutlinedButton from "../../../components/SmallOutlinedButton/SmallOutlinedButton";
import Divider from "../../../components/Divider/Divider";
import MacroOrigin from "../../../components/MacroOrigin/MacroOrigin";
// import MacroTimeline from "./MacroTimeline";

interface MacroDetailsProps {
  macro: Macro | null;
  loading?: boolean;
  title?: string;
  macroStatus?: { type: MacroStatus; name: string };
}

const openCoordinatesOnMaps = (coordinates: number[]) => {
  const link = `https://www.google.com/maps?q=${coordinates[0]},${coordinates[1]}`;
  window.open(link, "_blank");
};

const MacroDetails: React.FC<MacroDetailsProps> = ({
  macro,
  loading,
  title,
  macroStatus
}) => {
  return (
    <div>
      {title ? (
        <div className="text-dark-blue font-bold pb-5">{title}</div>
      ) : null}
      {macroStatus ? (
        <div className="pb-5 pl-4">
          <StatusText
            status={MacroStatusColor[macroStatus.type]}
            text={macroStatus.name}
          />
        </div>
      ) : null}
      <div className="flex flex-col gap-5">
        <InfoFieldBox>
          <InfoField
            loading={loading}
            label="Veículo"
            content={macro?.vehicle}
          />
          <InfoField
            loading={loading}
            label="Motorista"
            content={macro?.user}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={loading}
            label="Evento de Jornada"
            content={macro?.journey_event}
          />
          <InfoField
            loading={loading}
            label="Coordenadas"
            content={
              <>
                <div>
                  {macro?.coordinates?.length
                    ? formatCoordinates(macro?.coordinates)
                    : "-"}
                </div>
                {macro?.coordinates?.length ? (
                  <SmallOutlinedButton
                    onClick={() => openCoordinatesOnMaps(macro.coordinates)}
                  >
                    Ver no mapa
                  </SmallOutlinedButton>
                ) : null}
              </>
            }
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={loading}
            label="Endereço"
            content={formatAddress(macro?.location)}
          />
          <InfoField
            loading={loading}
            label="Duração"
            content={macro?.duration ? formatSecondsToHour(macro?.duration) : "-"}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={loading}
            label="Inicio"
            content={formatDateTime(macro?.started_at)}
          />
          <InfoField
            loading={loading}
            label="Fim"
            content={macro?.ended_at ? formatDateTime(macro?.ended_at) : "-"}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={loading}
            label="Origem"
            content={<MacroOrigin origin={macro?.macro_origin ?? "-"} />}
          />
          <InfoField
            loading={loading}
            label="Status"
            content={
              <StatusText
                status={
                  macro?.status?.type
                    ? MacroStatusColor[macro.status?.type]
                    : "danger"
                }
                text={macro?.status?.name ?? "-"}
              />
            }
          />
        </InfoFieldBox>
        {macro?.status?.type === MacroStatus.ALTERATION_PENDING || macro?.status?.type === MacroStatus.ALTERATION_APPROVED ? 
        <InfoFieldBox>
          <InfoField
            loading={loading}
            label="Atualizada por"
            content={macro?.alteration_flow?.history?.find((h) => h.type === MacroStatus.ALTERATION_PENDING)?.actor?.name}
          />
          <InfoField
            loading={loading}
            label="Atualizada em"
            content={formatDateTime(macro?.updated_at)}
          />
        </InfoFieldBox> : null}
        {macro?.manual ? <InfoFieldBox>
          <InfoField
            loading={loading}
            label="Motivo"
            content={macro?.manual?.reason?.name}
          />
          <InfoField
            loading={loading}
            label="Observação"
            content={macro?.manual?.description}
          />
        </InfoFieldBox> : null}
      </div>
      {macro?.macro_origin !== "JORNADASAT" ? (
        <>
          <br />
          <div className="text-dark-blue font-bold pb-5">
            Dados da Integração
          </div>
          <div className="flex flex-col gap-5">
            <InfoFieldBox>
              <InfoField
                loading={loading}
                label="ID da Macro"
                content={macro?.integration?.macro_id}
              />
              <InfoField
                loading={loading}
                label="ID do Motorista"
                content={macro?.integration?.user_id}
              />
            </InfoFieldBox>
            <InfoFieldBox>
              <InfoField
                loading={loading}
                label="ID do Evento"
                content={macro?.integration?.journey_event_id}
              />
            </InfoFieldBox>
            <InfoFieldBox>
              <InfoField
                loading={loading}
                label="Descrição"
                content={macro?.integration?.description}
              />
            </InfoFieldBox>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default MacroDetails;
