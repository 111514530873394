import DataTable from "../../../components/DataTable/DataTable";
import useModal from "../../../hooks/useModal";
import { Syndicate, SyndicatesVisibleColumns } from "../../../store/types/convention";
import { useStore } from "../../../store/useStore";
import {
  formatDateTime,
} from "../../../utils/functions/formatters";
import DetailsSyndicateModal from "./DetailsSyndicateModal";
import EditSyndicateModal from "./EditSyndicateModal";

const SyndicatesTable = () => {
  const syndicates = useStore((state) => state.conventions.syndicatesList.data);
  const setFilters = useStore(
    (state) => state.conventions.syndicatesList.setFilters
  );
  const filters = useStore((state) => state.conventions.syndicatesList.filters);
  const isLoadingSyndicatesList = useStore(
    (state) => state.conventions.isLoadingSyndicatesList
  );
  const resetSelectedSyndicate = useStore((state) => state.conventions.resetSelectedSyndicate);
  const getSyndicate = useStore((state) => state.conventions.getSyndicate);
  const { openModal } = useModal();
  const columnsVisibility = useStore(
    (state) => state.conventions.syndicatesList.columnsVisibility
  );

  const openDetailsSyndicateModal = (syndicateId: string) => {
    getSyndicate(syndicateId);
    openModal(<DetailsSyndicateModal onClose={resetSelectedSyndicate} />);
  };

  const openEditSyndicateModal = (syndicateId: string) => {
    getSyndicate(syndicateId);
    openModal(<EditSyndicateModal onClose={resetSelectedSyndicate} />);
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "name", label: "Nome", sortKey: "name" },
    { key: "operation_area", label: "Área de operação", sortKey: "operation_area" },
    {
      key: "created_at",
      label: "Criado em",
      render: (item: Syndicate) => formatDateTime(item.created_at),
    },
  ].filter((column) => {
    return columnsVisibility[column.key as keyof SyndicatesVisibleColumns];
  });

  return (
    <DataTable
      data={syndicates}
      columns={columns}
      onDetails={openDetailsSyndicateModal}
      onEdit={openEditSyndicateModal}
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingSyndicatesList}
    />
  );
};

export default SyndicatesTable;
