import { userAPI } from "../api/api";
import { handleServiceError } from "./handleServiceError";
import { removeEmptyProperties } from "../utils/functions/functions";
import { CreateSectionData, Holiday, HolidayFilter, HolidaysVisibleColumns, Section, SectionFilter, SectionsVisibleColumns } from "../store/types/convention";
import { Syndicate, SyndicateFilter, SyndicatesVisibleColumns } from "../store/types/convention";

const conventionService = {
  getHolidays: async ({
    filters,
    limit,
    offset,
  }: {
    filters: HolidayFilter | {};
    limit: number;
    offset: number;
  }) => {
    try {
      const response = await userAPI.get(`/v1/holiday`, {
        params: { ...removeEmptyProperties(filters), limit, offset },
      });
      const data: Holiday[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getHoliday: async (id: string) => {
    try {
      const response = await userAPI.get(`/v1/holiday/${id}`);
      const data: Holiday = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  createHoliday: async (holiday: Holiday) => {
    try {
      await userAPI.post(`/v1/holiday/`, holiday);
    } catch (error) {
      handleServiceError(error);
    }
  },
  updateHoliday: async (id: string, holiday: Holiday) => {
    try {
      await userAPI.put(`/v1/holiday/${id}`, holiday);
    } catch (error) {
      handleServiceError(error);
    }
  },
  deleteHoliday: async (id: string) => {
    try {
      await userAPI.delete(`/v1/holiday/${id}`);
    } catch (error) {
      handleServiceError(error);
    }
  },
  exportHoliday: async (format: "csv" | "pdf", columns: (keyof HolidaysVisibleColumns)[]) => {
    try {
      const response = await userAPI.post('/v1/holiday/report', { format, columns });
      return response.data.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getSyndicates: async ({
    filters,
    limit,
    offset,
  }: {
    filters: SyndicateFilter | {};
    limit: number;
    offset: number;
  }) => {
    try {
      const response = await userAPI.get(`/v1/syndicate`, {
        params: { ...removeEmptyProperties(filters), limit, offset },
      });
      const data: Syndicate[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getSyndicate: async (id: string) => {
    try {
      const response = await userAPI.get(`/v1/syndicate/${id}`);
      const data: Syndicate = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  createSyndicate: async (syndicate: Syndicate) => {
    try {
      await userAPI.post(`/v1/syndicate/`, syndicate);
    } catch (error) {
      handleServiceError(error);
    }
  },
  updateSyndicate: async (id: string, syndicate: Syndicate) => {
    try {
      await userAPI.put(`/v1/syndicate/${id}`, syndicate);
    } catch (error) {
      handleServiceError(error);
    }
  },
  deleteSyndicate: async (id: string) => {
    try {
      await userAPI.delete(`/v1/syndicate/${id}`);
    } catch (error) {
      handleServiceError(error);
    }
  },
  exportSyndicate: async (format: "csv" | "pdf", columns: (keyof SyndicatesVisibleColumns)[]) => {
    try {
      const response = await userAPI.post('/v1/syndicate/report', { format, columns });
      return response.data.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getSections: async ({
    filters,
    limit,
    offset,
  }: {
    filters: SectionFilter | {};
    limit: number;
    offset: number;
  }) => {
    try {
      const response = await userAPI.get(`/v1/section`, {
        params: { ...removeEmptyProperties(filters), limit, offset },
      });
      const data: Section[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getSection: async (id: string) => {
    try {
      const response = await userAPI.get(`/v1/section/${id}`);
      const data: Section = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  createSection: async (section: CreateSectionData) => {
    try {
      await userAPI.post(`/v1/section/`, section);
    } catch (error) {
      handleServiceError(error);
    }
  },
  updateSection: async (id: string, section: CreateSectionData) => {
    try {
      await userAPI.put(`/v1/section/${id}`, section);
    } catch (error) {
      handleServiceError(error);
    }
  },
  deleteSection: async (id: string) => {
    try {
      await userAPI.delete(`/v1/section/${id}`);
    } catch (error) {
      handleServiceError(error);
    }
  },
  exportSection: async (format: "csv" | "pdf", columns: (keyof SectionsVisibleColumns)[]) => {
    try {
      const response = await userAPI.post('/v1/section/report', { format, columns });
      return response.data.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
};

export default conventionService;
