import DataTable from "../../../components/DataTable/DataTable";
import useModal from "../../../hooks/useModal";
import { PayRoll } from "../../../store/types/journeyRule";
import { useStore } from "../../../store/useStore";
import { formatDateTime } from "../../../utils/functions/formatters";
import DetailsPayRollModal from "./DetailsPayRollModal";

const PayRollsTable = () => {
  const payRolls = useStore((state) => state.journeyRules.payRollsList.data);
  const setFilters = useStore(
    (state) => state.journeyRules.payRollsList.setFilters
  );
  const filters = useStore((state) => state.journeyRules.payRollsList.filters);
  const isLoadingPaymentsRollsList = useStore(
    (state) => state.journeyRules.isLoadingPaymentsRollsList
  );
  const resetSelectedPayRoll = useStore((state) => state.journeyRules.resetSelectedPayRoll);
  const getPayRoll = useStore((state) => state.journeyRules.getPayRoll);
  const { openModal } = useModal();


  const openDetailsPayRollModal = (payRollId: string) => {
    getPayRoll(payRollId);
    openModal(<DetailsPayRollModal onClose={resetSelectedPayRoll} />);
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "name", label: "Nome", sortKey: "name" },
    { key: "description", label: "Descrição" },
    {
      key: "created_at",
      label: "Criado em",
      render: (item: PayRoll) => formatDateTime(item.created_at),
    },
  ];

  return (
    <DataTable
      data={payRolls}
      columns={columns}
      onDetails={openDetailsPayRollModal}
      onEdit={null}
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingPaymentsRollsList}
    />
  );
};

export default PayRollsTable;
