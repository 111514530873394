import { fleetAPI } from "../api/api";
import { Fleet, FleetFilter, FleetsVisibleColumns } from "../store/types/fleet"; // Update import paths and types
import { handleServiceError } from "./handleServiceError";
import { removeEmptyProperties } from "../utils/functions/functions";

const fleetService = {
  getFleets: async ({ filters, limit, offset }: { filters: FleetFilter | {}, limit: number, offset: number }) => {
    try {
      const response = await fleetAPI.get("/v1/vehicle", { params: { ...removeEmptyProperties(filters), limit, offset } });
      const data: Fleet[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getFleet: async (id: string) => {
    try {

      const response = await fleetAPI.get(`/v1/vehicle/${id}`);
      const data: Fleet = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  createFleet: async (fleet: Fleet) => {
    try {
      await fleetAPI.post(`/v1/vehicle/`, fleet);
    } catch (error) {
      handleServiceError(error);
    }
  },
  updateFleet: async (id: string, fleet: Fleet) => {
    try {
      await fleetAPI.put(`/v1/vehicle/${id}`, fleet);
    } catch (error) {
      handleServiceError(error);
    }
  },
  deleteFleet: async (id: string) => {
    try {
      await fleetAPI.delete(`/v1/vehicle/${id}`);
    } catch (error) {
      handleServiceError(error);
    }
  },
  unlinkFleet: async (id: string) => {
    try {
      await fleetAPI.patch(`/v1/vehicle/${id}/user`);
    } catch (error) {
      handleServiceError(error);
    }
  },
  exportFleet: async (format: "csv" | "pdf", columns: (keyof FleetsVisibleColumns)[]) => {
    try {
      const response = await fleetAPI.post('/v1/vehicle/report', { format, columns });
      return response.data.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
};

export default fleetService;
