import { CreateMacrosDataBody } from "../../services/macroService";
import { MACRO_REASON_TYPE } from "../../utils/constants/constants";
import { Address } from "./company";

export interface JourneyEvent {
  _id?: string;
  name: string;
  type: string;
  default_payroll_event: { name: string; type: string };
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

export type Coordinates = [number, number];

export type MacroHistoryItem = {
  _id: string;
  type: MacroStatus;
  vehicle: string;
  user: string;
  actor: {
    name: string
  }
  reason: {
    type: string,
    name: string,
  }
  description?: string;
  journey_event: string;
  started_at: string | null;
  ended_at: string | null;
  created_at: string | null;
};

export interface Macro {
  _id: string;
  vehicle: string;
  vehicle_id: string;
  user: string;
  user_id: string;
  journey_event: string;
  journey_event_type: string;
  coordinates: Coordinates;
  location: Address;
  started_at?: string | null;
  ended_at: string | null;
  duration: any;
  integration: {
    macro_id: string;
    description: string;
    user_id: string;
    journey_event_id: string;
  };
  macro_origin: string;
  manual?: {
    reason: {
      type: MACRO_REASON_TYPE,
      name: string
    },
    actor: {
      _id: string,
      name: string
    },
    description: string;
  },
  status?: {
    type: MacroStatus;
    name: string;
  };
  alteration_flow?: {
    history: MacroHistoryItem[];
    suggestion: MacroHistoryItem;
  };
  created_at?: string | null;
  updated_at?: string | null;
  deleted_at?: string | null;
}

export enum MacroStatus {
  OK = "OK",
  ALTERATION_REQUESTED = "ALTERATION_REQUESTED",
  ALTERATION_PENDING = "ALTERATION_PENDING",
  ALTERATION_APPROVED = "ALTERATION_APPROVED",
  ALTERATION_REJECTED = "ALTERATION_REJECTED",
  UNLINKED = "UNLINKED",
  LINKED = "LINKED",
  MANUAL = "MANUAL",
  DELETED = "DELETED"
}

export interface MacroState {
  macro: {
    journeyEventsList: {
      data: JourneyEvent[];
      currentPage: number;
      totalItems: number;
      totalPages: number;
      limitPerPage: number;
      offset: number;
      filters: JourneyEventFilter;
      setLimitPerPage: (limitPerPage: number) => void;
      setCurrentPage: (page: number) => void;
      setFilters: (filters: JourneyEventFilter) => void;
    };
    macrosList: {
      data: Macro[];
      macrosSelected: string[];
      currentPage: number;
      totalItems: number;
      totalPages: number;
      limitPerPage: number;
      offset: number;
      filters: MacroFilter;
      columnsVisibility: MacrosVisibleColumns;
      toggleColumnVisibility: (key: string, value: boolean) => void;
      setLimitPerPage: (limitPerPage: number) => void;
      setCurrentPage: (page: number) => void;
      setFilters: (filters: MacroFilter) => void;
    };
    macrosRestoreList: {
      data: Macro[];
      macrosSelected: string[];
      currentPage: number;
      totalItems: number;
      totalPages: number;
      limitPerPage: number;
      offset: number;
      filters: MacroFilter;
      columnsVisibility: MacrosVisibleColumns;
      toggleColumnVisibility: (key: string, value: boolean) => void;
      setLimitPerPage: (limitPerPage: number) => void;
      setCurrentPage: (page: number) => void;
      setFilters: (filters: MacroFilter) => void;
    };
    macrosLinkList: {
      data: Macro[];
      currentPage: number;
      totalItems: number;
      totalPages: number;
      limitPerPage: number;
      offset: number;
      filters: MacroLinkFilter;
      columnsVisibility: MacrosLinkVisibleColumns;
      toggleColumnVisibility: (key: string, value: boolean) => void;
      setLimitPerPage: (limitPerPage: number) => void;
      setCurrentPage: (page: number) => void;
      setFilters: (filters: MacroLinkFilter) => void;
      selectMacro: (macroId: string | undefined) => void;
      unselectMacro: (macroId: string | undefined) => void;
      resetSelectedMacros: () => void;
      macrosSelected: string[];
    };
    isLoadingJourneyEventsList: boolean;
    isLoadingMacrosList: boolean;
    isLoadingMacro: boolean;
    isLoadingJourneyEvent: boolean;
    isExporting: { csv: boolean; pdf: boolean };
    isCheckingMacro: boolean;
    isUpdatingMacro: boolean;
    isLinkingMacro: boolean;
    isCreatingMacro: boolean;
    selectedJourneyEvent: JourneyEvent | null;
    selectedMacro: Macro | null;
    macroDataToUpdate: MacroDataToUpdate | null;
    macrosDataToLink: MacrosDataToLink | null;
    isLoadingMacrosLinkList: boolean;
    isLoadingMacrosRestoreList: boolean;
    isLoadingMacroLink: boolean;
    selectedMacroLink: Macro | null;
    isDeletingMacro: boolean;
    isRestoringMacro: boolean;
    deleteMacros: (macrosIds: string[], reason: string, description: string) => void;
    restoreMacros: (macrosIds: string[]) => void;
    resetSelectedJourneyEvent: () => void;
    getJourneyEvent: (id: string) => void;
    getJourneyEvents: () => void;
    getJourneyEventOptions: (name: string) => Promise<JourneyEvent[]>;
    resetJourneyEventsListState: () => void;
    setMacroDataToUpdate: (selectedMacro: MacroDataToUpdate) => void;
    getMacros: () => void;
    getMacrosLink: () => void;
    getMacrosRestore: () => void;
    linkMacros: (macrosDataToLink: {
      user: { value: string; label: string };
      macro_ids: string[]
    }) => void;
    getMacro: (id: string) => void;
    updateMacro: () => void;
    checkMacro: () => Promise<any>;
    exportMacroTable: (format: "csv" | "pdf") => void;
    resetMacrosListState: () => void;
    resetMacrosLinkListState: () => void;
    resetMacrosRestoreListState: () => void;
    resetSelectedMacro: () => void;
    selectMacro: (macroId: string | undefined) => void;
    unselectMacro: (macroId: string | undefined) => void;
    selectRestoreMacro: (macroId: string | undefined) => void;
    unselectRestoreMacro: (macroId: string | undefined) => void;
    createMacros: (createMacrosData: CreateMacrosDataBody[]) => void;
  };
}

export interface MacroTimelineItem {
  _id: string;
  journey_event: string;
  started_at: string;
  ended_at: string;
}

export interface MacrosDataToLink {
  user: {
    value: string;
    label: string;
  };
  macro_ids: {
    [key: string]: boolean;
  };
}

export interface MacroDataToUpdate {
  journey_event: {
    value: string;
    label: string;
  };
  user: {
    value: string;
    label: string;
  };
  vehicle: {
    value: string;
    label: string;
  };
  started_at: string | null | undefined;
  ended_at?: string | null | undefined;
  old_flow?: MacroTimelineItem[];
  new_flow?: MacroTimelineItem[];
}

export interface MacrosVisibleColumns {
  macro_origin: boolean;
  vehicle: boolean;
  user: boolean;
  journey_event: boolean;
  status: boolean;
  location: boolean;
  description: boolean;
  started_at: boolean;
  duration?: boolean;
}

export interface MacrosLinkVisibleColumns {
  macro_origin: boolean;
  vehicle: boolean;
  integration_description: boolean;
  integration_journey_event_id: boolean;
  integration_macro_id: boolean;
  integration_user_id: boolean;
  started_at: boolean;
}

export type MacrosVisibleColumnsKey = keyof MacrosVisibleColumns;

export type MacrosLinkVisibleColumnsKey = keyof MacrosLinkVisibleColumns;

export interface JourneyEventFilter {
  name: string;
  type: string;
  sort_key: string;
  sort_value: string;
}

export interface MacroFilter {
  started_at_begin: string;
  started_at_end: string;
  ended_at_begin: string;
  ended_at_end: string;
  vehicle: string;
  user: string;
  status_type: string;
  journey_event: string;
  street: string;
  number: string;
  neighbourhood: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  sort_key: string;
  sort_value: string;
}

export interface MacroLinkFilter {
  started_at_begin: string;
  started_at_end: string;
  ended_at_begin: string;
  ended_at_end: string;
  vehicle: string;
  user: string;
  status_type: string;
  journey_event: string;
  street: string;
  number: string;
  neighbourhood: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  sort_key: string;
  sort_value: string;
}
