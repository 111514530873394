import * as Imgs from "../../assets/imgs";
import { LoginForm } from "./components/LoginForm";

const Login: React.FC<{}> = () => {
  return (
    <main className="min-h-screen bg-[url(./assets/imgs/login-background.svg)] bg-cover bg-center bg-no-repeat flex flex-col gap-10 justify-center items-center">
      <img
        src={Imgs.Logo}
        className="lg:w-[300px] w-[250px]"
        alt="jornadasat logo"
      />
      <div className="bg-white px-6 py-8 rounded-[12px] text-gray_3 md:w-[408px] text-center">
        <h1 className="text-2xl font-bold mb-5">Entre com suas credenciais</h1>
        <LoginForm />
      </div>
    </main>
  );
};

export default Login;
