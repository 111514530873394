
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik, FormikProps } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import { useStore } from "../../../store/useStore";
import { useEffect } from "react";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import CustomDatePicker from "../../../components/FormComponents/CustomDatePicker/CustomDatePicker";
import moment from "moment";
import { Colors } from "../../../utils/constants/style";
import { Filter } from "iconsax-react";

const initialValues = {
  name: "",
  batch: "",
  competence_start: "",
  competence_end: "",
  created_at: "",
};

const FilterPayRollModal: React.FC<{}> = () => {
  const setFilters = useStore(
    (state) => state.timeManager.payRollsList.setFilters
  );
  const filters = useStore((state) => state.timeManager.payRollsList.filters);
  const clearFilter = (formik: FormikProps<typeof initialValues>) => {
    formik.resetForm();
  };

  return (
    <Modal
      title="Filtros de folha de pagamento"
      icon={<Filter size="32" variant="Bold" color={Colors.white}/>}
      className="w-[447px]"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string(),
          batch: Yup.string(),
          competence_start: Yup.string(),
          competence_end: Yup.string(),
          created_at: Yup.string(),
        })}
        onSubmit={(values) => {
          setFilters({
            ...filters,
            ...values,
            competence_start: values.competence_start ? moment(values.competence_start).format(
              "YYYY-MM-DD"
            ) : "",
            competence_end: values.competence_end ? moment(values.competence_end).format("YYYY-MM-DD") : "",
            created_at: values.created_at ? moment(values.created_at).format("YYYY-MM-DD") : "",
          });
        }}
      >
        {(formik) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (filters) formik.setValues(filters);
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [filters]);

          return (
            <Form>
              <div className="flex flex-col gap-8 pt-5">
                <div className="flex gap-8">
                  <div className="flex-1 flex flex-col gap-8">
                    <Field
                      placeholder="Nome"
                      name="name"
                      component={OutlinedInput}
                    />
                    <Field
                      placeholder="Lote"
                      name="batch"
                      component={OutlinedInput}
                    />
                  </div>
                  <div className="flex-1 flex flex-col gap-8">
                    <Field
                      placeholder="Início Competência"
                      name="competence_start"
                      component={CustomDatePicker}
                    />
                    <Field
                      placeholder="Fim Competência"
                      name="competence_end"
                      component={CustomDatePicker}
                    />
                    <Field
                      placeholder="Criado em"
                      name="created_at"
                      component={CustomDatePicker}
                    />
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="flex-1">
                    <OutlinedButton
                      onClick={() => clearFilter(formik)}
                      className="w-full"
                      type="button"
                    >
                      Limpar Filtros
                    </OutlinedButton>
                  </div>
                  <div className="flex-1">
                    <FilledButton className="w-full" type="submit">
                      Filtrar
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default FilterPayRollModal;
