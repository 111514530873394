export interface JourneyRule {
  _id?: string;
  name: string;
  description: string;
  regime: REGIME;
  parameters: any;
  journey_events_code: any;
  payroll_events: any;
  company: string;
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

export enum PARAMETER_MEASURE {
  MULTI_SELECT = 'MULTI_SELECT',
  CHECK = 'CHECK',
  TIME = 'TIME',
  PERCENT = 'PERCENT',
  DAY_TIME_INTERVAL = 'DAY_TIME_INTERVAL',
  TIME_BY_WEEK_DAY = 'TIME_BY_WEEK_DAY',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
}

export enum REGIME {
  WEEKLY="WEEKLY",
  MONTHLY="MONTHLY",
  QUARTERLY="QUARTERLY",
  SEMESTER="SEMESTER",
}

export enum REGIME_TRANSLATION {
  WEEKLY="Semanal",
  MONTHLY="Mensal",
  QUARTERLY="Trimestral",
  SEMESTER="Semestral",
}

export interface Parameter {
  _id?: string;
  name: string;
  code: string;
  description: string;
  measure: PARAMETER_MEASURE;
  type: string;
  default: any;
  custom_value: any;
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

export interface PayRoll {
  _id?: string;
  name: string;
  description: string;
  type: string;
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

export interface JourneyRuleState {
  journeyRules: {
    journeyRulesList: {
      data: JourneyRule[];
      currentPage: number;
      totalItems: number;
      totalPages: number;
      limitPerPage: number;
      offset: number;
      filters: JourneyRuleFilter;
      setLimitPerPage: (limitPerPage: number) => void;
      setCurrentPage: (page: number) => void;
      setFilters: (filters: JourneyRuleFilter) => void;
    };
    parametersList: {
      data: Parameter[];
      currentPage: number;
      totalItems: number;
      totalPages: number;
      limitPerPage: number;
      offset: number;
      filters: ParameterFilter;
      setLimitPerPage: (limitPerPage: number) => void;
      setCurrentPage: (page: number) => void;
      setFilters: (filters: ParameterFilter) => void;
    };
    payRollsList: {
      data: PayRoll[];
      currentPage: number;
      totalItems: number;
      totalPages: number;
      limitPerPage: number;
      offset: number;
      filters: PayRollFilter;
      setLimitPerPage: (limitPerPage: number) => void;
      setCurrentPage: (page: number) => void;
      setFilters: (filters: ParameterFilter) => void;
    };
    selectedPayRoll: PayRoll | null;
    selectedParameter: Parameter | null;
    selectedJourneyRule: JourneyRule | null;
    journeyRuleToBeCreated: any,
    isLoadingJourneyRule: boolean;
    isLoadingJourneyRulesList: boolean;
    isLoadingParametersList: boolean;
    isLoadingPaymentsRollsList: boolean;
    isLoadingParameter: boolean;
    isLoadingPayRoll: boolean;
    isCreatingJourneyRule: boolean;
    isUpdatingJourneyRule: boolean;
    getJourneyRules: () => void;
    getJourneyRule: (id: string) => void;
    getParameters: () => void;
    getPayRolls: () => void;
    getPayRoll: (id: string) => void;
    getParameter: (id: string) => void;
    createJourneyRule: (data: any) => void;
    updateJourneyRule: (journeyRuleId: string, data: any) => void;
    resetJourneyRulesListState: () => void;
    resetParametersListState: () => void;
    resetPayRollsListState: () => void;
    resetSelectedPayRoll: () => void;
    resetSelectedParameter: () => void;
    resetSelectedJourneyRule: () => void;
  };
}

export interface JourneyRuleFilter {
  name: string;
  description: string;
  sort_key: string;
  sort_value: string;
}

export interface ParameterFilter {
  name: string;
  description: string;
  sort_key: string;
  sort_value: string;
}

export interface PayRollFilter {
  name: string;
  description: string;
  sort_key: string;
  sort_value: string;
}
