interface LayoutIconProps {
  width?: string | number;
  height?: string | number;
  color?: string;
}

const LayoutIcon: React.FC<LayoutIconProps> = ({width=17, height=17, color="#344767"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.583328 0.583344H2.16666V14.8333H16.4167V16.4167H0.583328V0.583344ZM4.54166 6.91668H12.4583V9.29168H4.54166V6.91668ZM7.70833 10.875H15.625V13.25H7.70833V10.875ZM3.74999 2.16668H16.4167V5.33334H14.8333V3.75001H5.33333V5.33334H3.74999V2.16668Z"
        fill={color}
      />
    </svg>
  );
};

export default LayoutIcon;
