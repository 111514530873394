interface RefreshIconProps {
  width?: string | number;
  height?: string | number;
  color?: string;
}
const RefreshIcon: React.FC<RefreshIconProps> = ({
  width = "18",
  height = "18",
  color = "#fff",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00004 7.37504H10.0834V10.625H9.00004V7.37504ZM3.58337 8.45837H4.66671V7.37504H3.58337V8.45837ZM17.6667 2.50004V15.5C17.6667 16.0747 17.4384 16.6258 17.0321 17.0321C16.6258 17.4384 16.0747 17.6667 15.5 17.6667H2.50004C1.9254 17.6667 1.3743 17.4384 0.967976 17.0321C0.561647 16.6258 0.333374 16.0747 0.333374 15.5V2.50004C0.333374 1.9254 0.561647 1.3743 0.967976 0.967976C1.3743 0.561647 1.9254 0.333374 2.50004 0.333374H15.5C16.0747 0.333374 16.6258 0.561647 17.0321 0.967976C17.4384 1.3743 17.6667 1.9254 17.6667 2.50004ZM6.29171 7.37504C6.29171 6.94406 6.1205 6.53074 5.81576 6.22599C5.51101 5.92125 5.09768 5.75004 4.66671 5.75004H1.95837V12.25H3.58337V10.0834H4.66671C5.09768 10.0834 5.51101 9.91217 5.81576 9.60742C6.1205 9.30268 6.29171 8.88935 6.29171 8.45837V7.37504ZM11.7084 7.37504C11.7084 6.94406 11.5372 6.53074 11.2324 6.22599C10.9277 5.92125 10.5143 5.75004 10.0834 5.75004H7.37504V12.25H10.0834C10.5143 12.25 10.9277 12.0788 11.2324 11.7741C11.5372 11.4693 11.7084 11.056 11.7084 10.625V7.37504ZM16.0417 5.75004H12.7917V12.25H14.4167V10.0834H16.0417V8.45837H14.4167V7.37504H16.0417V5.75004Z"
        fill={color}
      />
    </svg>
  );
};
export default RefreshIcon;
