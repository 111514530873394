import Modal from "../../../components/Modal/Modal";
import LimitPerPage from "../../LimitPerPage/LimitPerPage";
import Pagination from "../../Pagination/Pagination";
import SearchInput from "../../SearchInput/SeachInput";
import GenericCompanyImage from "../../GenericCompanyImage/GenericCompanyImage";
import { useStore } from "../../../store/useStore";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCNPJ } from "../../../utils/functions/formatters";
import { Company } from "../../../store/types/company";
import SelectCompanyDataTable from "./SelectCompanyDataTable";
import { Building4, Eye, Home, Setting2, TableDocument } from "iconsax-react";
import { Colors } from "../../../utils/constants/style";
import { Field } from "formik";
import SelectCompanyViewport from "./SelectCompanyViewport";
import Tabs from "../../Tabs/Tabs";
import Tab from "../../Tabs/Components/Tab";
import TabPanel from "../../Tabs/Components/TabPanel";

const ChangeCompanyModal: React.FC<{ notClosable?: boolean }> = ({
  notClosable,
}) => {
  const [tab, setTab] = useState<number>(0);
  const companyList = useStore((state) => state.changeCompanyModal.companyList);
  const navigate = useNavigate();
  const location = useLocation();
  const getCompanies = useStore(
    (state) => state.changeCompanyModal.getCompanies
  );
  const isLoadingCompanyList = useStore(
    (state) => state.changeCompanyModal.isLoadingCompanyList
  );
  const isChangingCurrentCompany = useStore(
    (state) => state.changeCompanyModal.isChangingCurrentCompany
  );
  const setFilters = useStore(
    (state) => state.changeCompanyModal.companyList.setFilters
  );
  const adminCompanyId = useStore((state) => state.admin.data.company);
  const changeCurrentCompany = useStore(
    (state) => state.changeCompanyModal.changeCurrentCompany
  );
  const [nextCurrentCompanyId, setNextCurrentCompanyId] = useState<
    string | undefined
  >();

  useEffect(() => {
    getCompanies();
    setNextCurrentCompanyId(adminCompanyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectNextCompany = (item: any) => {
    setNextCurrentCompanyId(item._id);
    handleChangeCurrentCompany(item._id);
  };

  const handleChangeCurrentCompany = (companyId?: string) => {
    const reloadNavigate = () => navigate(location.pathname, { replace: true });
  
    if (companyId || nextCurrentCompanyId) {
      changeCurrentCompany(companyId || nextCurrentCompanyId!, reloadNavigate);
    }
  };

  const columns = [
    {
      key: "logo",
      label: "",
      render: (item: Company) => {
        return (
          <div className="flex justify-center">
            <GenericCompanyImage logo={item.logo} />
          </div>
        );
      },
    },
    { key: "name", label: "Nome" },
    { key: "cnpj", label: "CNPJ", render: (item: Company) => formatCNPJ(item.cnpj) },
  ];

  const handleSearchCompany = (nameOrCNPJ: string) => {
    setFilters({ name_cnpj: nameOrCNPJ });
  };

  return (
    <Modal
      title="Selecione a transportadora"
      icon={<Building4 size="32" variant="Bold" color={Colors.white}/>}
      className="w-[1200px] min-h-[90%] max-h-[90%] overflow-y-auto"
      notClosable={notClosable}
    >
      <div>
        <div className="flex justify-between">
          <SearchInput
            placeholder="Buscar registro"
            onChange={handleSearchCompany}
          />

          <Tabs tab={tab} setTab={setTab}>
            <Tab label="Vitrine" icon={<Eye variant="TwoTone" />} />
            <Tab label="Lista" icon={<TableDocument variant="TwoTone" />} />
          </Tabs>
        </div>
        <div className="py-3">
          <TabPanel tab={tab} index={0}>
            <SelectCompanyViewport
              currentCompanyId={nextCurrentCompanyId}
              data={companyList.data}
              loading={isLoadingCompanyList}
              onSelectCompany={onSelectNextCompany}
            />
          </TabPanel>
          <TabPanel tab={tab} index={1}>
            <SelectCompanyDataTable
              currentCompanyId={nextCurrentCompanyId}
              columns={columns}
              data={companyList.data}
              loading={isLoadingCompanyList}
              onSelectCompany={onSelectNextCompany}
            />
          </TabPanel>
        </div>
        {/* <div className="flex gap-5 items-center justify-between">
          <LimitPerPage
            onLimitChange={(e: any) =>
              companyList.setLimitPerPage(e.target.value)
            }
            total={companyList.totalItems}
          />
          <Pagination
            currentPage={companyList.currentPage}
            totalPages={companyList.totalPages}
            onPageChange={companyList.setCurrentPage}
          />
        </div> */}
      </div>
    </Modal>
  );
};

export default ChangeCompanyModal;
