import { CSVIcon, PDFIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import Checkbox from "../../../components/FormComponents/Checkbox/Checkbox";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import { useStore } from "../../../store/useStore";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import { HolidaysVisibleColumnsKey } from "../../../store/types/convention";
import { Setting2 } from "iconsax-react";
import { Colors } from "../../../utils/constants/style";

const labels = {
  name: "Nome",
  type: "Tipo",
  date: "Data",
  created_at: "Criado em",
};

const fixedColumns = {
  name: true,
  type: false,
  date: false,
  created_at: true,
};

const OptionsHolidayModal: React.FC<{}> = () => {
  const toggleColumnVisibility = useStore(
    (state) => state.conventions.holidaysList.toggleColumnVisibility
  );
  const columnsVisibility = useStore(
    (state) => state.conventions.holidaysList.columnsVisibility
  );
  const isExportingHoliday = useStore((state) => state.conventions.isExportingHoliday);

  const exportHolidayTable = useStore(
    (state) => state.conventions.exportHolidayTable
  );
  const hasPermissions = useStore((state) => state.admin.hasPermissions);

  return (
    <Modal
      title="Opções"
      icon={<Setting2 size="32" variant="Bold" color={Colors.white}/>}
      className="min-w-[223px]"
    >
      <>
        <div className="pb-2 text-dark-blue">Exibir Colunas</div>
        <div className="flex flex-col gap-2 pl-3">
          {(Object.keys(columnsVisibility) as HolidaysVisibleColumnsKey[]).map(
            (key) => (
              <Checkbox
                key={key}
                text={labels[key]}
                checked={columnsVisibility[key]}
                onChange={(e) => {
                  if (!fixedColumns[key])
                    toggleColumnVisibility(key, columnsVisibility[key]);
                }}
              />
            )
          )}
        </div>
        {hasPermissions([PERMISSIONS.CREATE_VEHICLES_REPORT]) ? (
          <>
            <div className="pt-4 pb-2 text-dark-blue">Exportar</div>
            <div className="flex gap-3">
              <div className="flex-1">
                <RoundedFilledButton
                  className="w-full"
                  onClick={() => exportHolidayTable("csv")}
                  loading={isExportingHoliday["csv"]}
                  icon={<CSVIcon />}
                  text="CSV"
                />
              </div>
              <div className="flex-1">
                <RoundedFilledButton
                  className="w-full"
                  onClick={() => exportHolidayTable("pdf")}
                  loading={isExportingHoliday["pdf"]}
                  icon={<PDFIcon />}
                  text="PDF"
                />
              </div>
            </div>
          </>
        ) : null}
      </>
    </Modal>
  );
};

export default OptionsHolidayModal;
