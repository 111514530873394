import { useEffect } from "react";
import DataPanel from "../../../components/DataPanel/DataPanel";
import Pagination from "../../../components/Pagination/Pagination";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import useModal from "../../../hooks/useModal";
import { useStore } from "../../../store/useStore";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import Skeleton from "react-loading-skeleton";
import PayRollsTable from "./PayRollsTable";
import CreatePayRollModal from "./CreatePayRollModal";
import FilterPayRollModal from "./FilterPayRollModal";
import { AddCircle, Filter } from "iconsax-react";

const PayRollsGeneral: React.FC<{}> = () => {
  const { openModal } = useModal();
  const getPayRolls = useStore((state) => state.timeManager.getPayRolls);
  const payRollsList = useStore((state) => state.timeManager.payRollsList);
  const isLoadingPayRollsList = useStore(
    (state) => state.timeManager.isLoadingPayRollsList
  );

  useEffect(() => {
    getPayRolls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreatePayRollModal = () => openModal(<CreatePayRollModal />);
  const openFilterPayRollModal = () => openModal(<FilterPayRollModal />);

  return (
    <>
      <div className="mt-9">
        <DataPanel className="px-3 pt-8 flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="pl-1">
              {/* <SearchInput placeholder="Buscar registro" onChange={() => {}} /> */}
            </div>
            <div className="pr-1 flex gap-4">
              <RoundedFilledButton
                onClick={openCreatePayRollModal}
                icon={<AddCircle />}
                text="Novo"
              />
              <RoundedFilledButton
                onClick={openFilterPayRollModal}
                icon={<Filter size={24} />}
                text="Filtros"
              />
            </div>
          </div>
          <PayRollsTable />
          <div className="flex justify-between items-center">
            <div className="text-gray_1">
              Mostrando{" "}
              {!isLoadingPayRollsList ? (
                payRollsList.data.length +
                payRollsList.offset * payRollsList.limitPerPage
              ) : (
                <Skeleton inline width={20} />
              )}{" "}
              de {payRollsList.totalItems} registros
            </div>
            <div className="flex gap-5 items-center">
              <LimitPerPage
                onLimitChange={(e: any) =>
                  payRollsList.setLimitPerPage(e.target.value)
                }
                total={payRollsList.totalItems}
              />
              <Pagination
                currentPage={payRollsList.currentPage}
                totalPages={payRollsList.totalPages}
                onPageChange={payRollsList.setCurrentPage}
              />
            </div>
          </div>
        </DataPanel>
      </div>
    </>
  );
};

export default PayRollsGeneral;
