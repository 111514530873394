import DataTable from "../../../components/DataTable/DataTable";
import useModal from "../../../hooks/useModal";
import {
  Fleet,
  FleetsVisibleColumns,
} from "../../../store/types/fleet";
import { useStore } from "../../../store/useStore";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import { formatDateTime } from "../../../utils/functions/formatters";
import DetailsFleetModal from "./DetailsFleetModal";
import EditFleetModal from "./EditFleetModal";
import StatusText from "../../../components/StatusText/StatusText";
import { getJourneyTimeStatus } from "../../../utils/functions/functions";
import TimeCounter from "../../../components/TimeCounter/TimeCounter";
import UnlinkFleetModal from "./UnlinkFleetModal";

const FleetTable = () => {
  const { openModal } = useModal();

  const getFleet = useStore((state) => state.fleet.getFleet);
  const resetSelectedFleet = useStore(
    (state) => state.fleet.resetSelectedFleet
  );
  const fleets = useStore((state) => state.fleet.fleetsList.data);
  const setFilters = useStore((state) => state.fleet.fleetsList.setFilters);
  const filters = useStore((state) => state.fleet.fleetsList.filters);
  const columnsVisibility = useStore(
    (state) => state.fleet.fleetsList.columnsVisibility
  );
  const isLoadingFleetsList = useStore(
    (state) => state.fleet.isLoadingFleetsList
  );
  const hasPermissions = useStore((state) => state.admin.hasPermissions);

  const openDetailsFleetModal = (fleetId: string) => {
    getFleet(fleetId);
    openModal(<DetailsFleetModal onClose={resetSelectedFleet} />);
  };

  const openEditFleetModal = (fleetId: string) => {
    getFleet(fleetId);
    openModal(<EditFleetModal onClose={resetSelectedFleet} />);
  };

  const openUnlinkFleetModal = (fleetId: string) => {
    getFleet(fleetId);
    openModal(<UnlinkFleetModal onClose={resetSelectedFleet} />);
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "tag", label: "Placa", sortKey: "tag" },
    { key: "fleet", label: "Frota", sortKey: "fleet" },
    {
      key: "last_journey_event.name",
      label: "Atividade",
      sortKey: "started_at",
      render: (item: Fleet) => {
        return item.last_journey_event?.started_at && item.last_journey_event?.name ? (
          <div className="flex flex-col justify-center items-center">
            <StatusText
              text={item.last_journey_event?.name ?? "-"}
              status={getJourneyTimeStatus(item?.last_journey_event)}
            />
            <TimeCounter startDate={item.last_journey_event?.started_at} />
          </div>
        ) : '-';
      },
    },
    {
      key: "user",
      label: "Motorista",
      render: (item: Fleet) => item?.user?.name ?? "-"
    },
    {
      key: "created_at",
      label: "Criado em",
      render: (item: Fleet) => formatDateTime(item?.created_at),
      sortKey: "created_at",
    },
  ].filter((column) => {
    if (column.key === "last_journey_event.name")
      return columnsVisibility.last_journey_event;
    return columnsVisibility[column.key as keyof FleetsVisibleColumns];
  });

  return (
    <DataTable
      data={fleets}
      columns={columns}
      onDetails={
        hasPermissions([PERMISSIONS.READ_VEHICLE])
          ? openDetailsFleetModal
          : null
      }
      onEdit={
        hasPermissions([PERMISSIONS.UPDATE_VEHICLE]) ? openEditFleetModal : null
      }
      showUnlinkByItem={(item: Fleet) =>
        (item.user && hasPermissions([PERMISSIONS.UPDATE_VEHICLE_USER])) ? true : false
      }
      onUnlink={
        hasPermissions([PERMISSIONS.UPDATE_VEHICLE_USER]) ? openUnlinkFleetModal : null
      }
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingFleetsList}
    />
  );
};

export default FleetTable;
