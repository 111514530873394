import React from "react";
import * as Icons from "../../assets/imgs";

interface SmallOutlinedButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: string | JSX.Element;
  loading?: boolean;
  className?: string;
}

const SmallOutlinedButton: React.FC<SmallOutlinedButtonProps> = ({
  children,
  onClick,
  loading,
  className,
  ...props
}) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!loading && onClick) {
      onClick(event);
    }
  };

  return (
    <button
      onClick={handleClick}
      {...props}
      className={`border border-red text-red rounded-[8px] font-semibold px-2 py-0 text-sm ${className}`}
    >
      {loading ? <Icons.LoadingIcon /> : children}
    </button>
  );
};

export default SmallOutlinedButton;
