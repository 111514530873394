import { useLocation, useNavigate } from "react-router-dom";
import {
  BurgerIcon
} from "../../assets/imgs";
import { ROUTES } from "../../routes/routes";
import { useStore } from "../../store/useStore";
import { Colors } from "../../utils/constants/style";
import useModal from "../../hooks/useModal";
import ChangeSectionModal from "../Navbar/components/ChangeSectionModal";
import './header-style.css'
import { Location, User } from "iconsax-react";

const Header: React.FC<{}> = () => {
  const { openModal } = useModal();
  const toggleNavbar = useStore((state) => state.navbar.toggleNavbar);
  const isNavbarOpen = useStore((state) => state.navbar.isNavbarOpen);
  const adminData = useStore((state) => state.admin.data);

  const location = useLocation();
  const navigate = useNavigate();

  const navigateToProfile = () => navigate(ROUTES.PROFILE.PATH);

  const routeName = Object.values(ROUTES).find(route => route.PATH === location.pathname)?.NAME ?? ''

  const openSectionModal = () => {
    openModal(<ChangeSectionModal />);
  };

  return (
    <header className={`bg-white flex justify-between py-2 px-5 shadow-base fixed z-10 ${
        isNavbarOpen
          ? "w-full "
          : "w-[calc(100%-225px)]"
      }`}
    >
      <div className="flex gap-3 items-center">
        <div className="cursor-pointer" onClick={toggleNavbar}><BurgerIcon /></div>
        <span className="text-lg font-bold text-dark-blue">{routeName}</span>
      </div>
      <div className="flex items-center gap-5">
        <div onClick={openSectionModal} className="cursor-pointer flex items-center gap-3 border-r border-[#EEE] pr-3">
          <div className="text-right">
            <div className="text-[10px]">{adminData.section?.name ? 'Seção selecionada' : 'Clique para selecionar uma seção'}</div>
            <div className="text-sm font-bold text-red">{adminData.section?.name ?? 'Nenhuma seção selecionada'}</div>
          </div>
          <div className="flex h-8 w-8 shrink-0 grow-0 items-center justify-center rounded-full bg-dark-blue">
            <Location size="18" color={Colors.white}/>
          </div>
        </div>
        <div onClick={navigateToProfile} className="cursor-pointer flex items-center gap-3 pr-3">
          <div className="text-right">
            <div className="text-[10px]">{adminData.name}</div>
            <div className="text-sm font-bold text-red">{adminData.position.name}</div>
          </div>
          <div className="flex h-8 w-8 shrink-0 grow-0 items-center justify-center rounded-full bg-dark-blue">
            <User size="18" color={Colors.white}/>
          </div>
        </div>
        {/*<div className="flex items-center gap-3 px-3 py-1 rounded-[16px] bg-gray_0">
          <ChevronIcon direction="down" color={Colors.gray_3} />
          <img src={BrazilFlagIcon} alt="language country flag" />
        </div>}
        <div className="relative px-5">
          <NotificationIcon />
          <div className="absolute top-[-5px] right-[0px] rounded-[19px] bg-red text-[10px] px-2 text-white">
            11
          </div>
        </div> */}
      </div>
    </header>
  );
};

export default Header;
