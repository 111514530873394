import DataTable from "../../../components/DataTable/DataTable";
import { LAYOUT_COLUMNS_OPTIONS, LAYOUT_TOTALIZERS_OPTIONS } from "../../../utils/constants/constants";
const PayrollLayoutSelectTable: React.FC<{ form: any; fieldName: string, columns: any }> = ({
  form,
  fieldName,
  columns
}) => {
  let data: any[] = [];
  if(fieldName === "columns") data = LAYOUT_COLUMNS_OPTIONS;
  else if(fieldName === "totalizers") data = LAYOUT_TOTALIZERS_OPTIONS;
  return (
    <>
      <DataTable
        data={data}
        columns={columns}
        onDetails={null}
        onEdit={null}
        isChecked={(item) => !!form.values[fieldName][item.type]}
        onSelect={(item) =>
          form.setFieldValue(
            `${fieldName}.${item.type}`,
            !form.values[fieldName][item.type]
          )
        }
      />
    </>
  );
};

export default PayrollLayoutSelectTable;
