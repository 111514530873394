
import axios, { AxiosError } from "axios";
import { 
  initUserServiceMock, 
  initUserServiceMockPublic, 
  initVehicleServiceMock, 
  initAnalyticsServiceMock, 
  initTrackingServiceMock, 
  initPaymentsServiceMock,
  initReportServiceMock
} from "./mocks";

const axiosInstanceParams = (baseURL: string | undefined) => {
  if (!baseURL) return;
  return {
    baseURL: baseURL,
    headers: {
      Authorization: localStorage.getItem("token")
        ? `Bearer ${localStorage.getItem("token")}`
        : "",
      "Content-Type": "application/json",
      "accept-language": "pt-br",
    },
  };
};

export let userAPI = axios.create(
  axiosInstanceParams(process.env?.REACT_APP_USER_API_URL)
);
export let fleetAPI = axios.create(
  axiosInstanceParams(process.env?.REACT_APP_JOURNEY_API_URL)
);
export let payRollAPI = axios.create(
  axiosInstanceParams(process.env?.REACT_APP_PAYROLL_API_URL)
);
export let chartsAPI = axios.create(
  axiosInstanceParams(process.env?.REACT_APP_CHARTS_API_URL)
);
export let trackingAPI = axios.create(
  axiosInstanceParams(process.env?.REACT_APP_TRACKING_API_URL)
);
export let reportAPI = axios.create(
  axiosInstanceParams(process.env?.REACT_APP_REPORT_API_URL)
);

export const apiPublic = axios.create({
  baseURL: process.env.REACT_APP_USER_API_URL,
  headers: {
    "Content-Type": "application/json",
    "accept-language": "pt-br",
  },
});

const errorInterceptor = (error: AxiosError) => {
  // Token expired
  if (error?.response && error?.response?.status === 401) {
    localStorage.clear();
    window.location.href = "/";
    return;
  }

  return Promise.reject(error);
};

userAPI.interceptors.response.use((response) => response, errorInterceptor);
if (process.env.REACT_APP_API_ENV === "mock") {
  initUserServiceMockPublic(apiPublic);
  initUserServiceMock(userAPI);
  initVehicleServiceMock(fleetAPI);
  initAnalyticsServiceMock(chartsAPI);
  initTrackingServiceMock(trackingAPI);
  initPaymentsServiceMock(payRollAPI);
  initReportServiceMock(reportAPI);
}
