import { PlusIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import { useStore } from "../../../store/useStore";
import GenericCompanyImage from "../../../components/GenericCompanyImage/GenericCompanyImage";
import { ReportType } from "../../../store/types/report";

const initialValues = {
  periodStart: "",
  periodEnd: "",
  users: {} as Record<string, any>
};

const CreateAFDAEJReportModal: React.FC<{}> = () => {
  const downloadReport = useStore((state) => state.report.downloadReport);
  const reportType = useStore((state) => state.report.reportType);
  const isDownloadingReport = useStore(
    (state) => state.report.isDownloadingReport
  );
  const company = useStore((state) => state.navbar.company);

  return (
    <Modal
      title="Novo Relatório"
      icon={<PlusIcon width={30} height={30} />}
      className="w-[400px]"
      childrenClassName="px-5 overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({})}
        onSubmit={() => downloadReport()}
      >
        {() => (
          <Form>
            <div className="flex flex-col gap-8 pt-5">
              <div className="flex flex-col items-center">
                <span className="text-dark-blue text-base mb-5">
                  Baixar { reportType === ReportType.AEJ ? 'AEJ' : 'AFD' } para a empresa
                </span>
                <div className="flex gap-5">
                  <GenericCompanyImage
                    logo={company?.logo}
                    width={"40px"}
                    height={"40px"}
                    className="min-w-[40px] shadow-lg"
                  />
                  <span className="text-lg">
                    {company?.name ?? "-"}
                  </span>
                </div>
              </div>
              <div className="flex gap-5 z-30 py-2 sticky bottom-0 bg-white">
                <div className="flex-1"></div>
                <div className="flex-1">
                  <FilledButton
                    loading={isDownloadingReport}
                    className="w-full"
                    type="submit"
                  >
                    Baixar relatório
                  </FilledButton>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateAFDAEJReportModal;
