import { Route, Routes } from "react-router-dom";
import { ROUTES } from "./routes";
import Dashboard from "../pages/Dashboard/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Login from "../pages/Login/Login";
import Collaborators from "../pages/Collaborators/Collaborators";
import Companies from "../pages/Companies/Companies";
import Fleet from "../pages/Fleet/Fleet";
import TimeManager from "../pages/TimeManager/TimeManager";
import Reports from "../pages/Reports/Reports";
import Macros from "../pages/Macros/Macros";
import JourneyRules from "../pages/JourneyRules/JourneyRules";
import Sessions from "../pages/Sessions/Sessions";
import NotFound from "../pages/NotFound/NotFound";
import Profile from "../pages/Profile/Profile";
import Conventions from "../pages/Conventions/Conventions";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import LandingPage from "../pages/Institutional/intitutional-page";

export const RoutesComponent: React.FC = () => {

  return (
    <Routes>
      <Route path={ROUTES.INSTITUTIONAL.PATH} element={<LandingPage />} />
      <Route path={ROUTES.LOGIN.PATH} element={<Login />} />
      <Route path="/status" element={<div>OK</div>} />
      <Route path={ROUTES.PRIVACY_POLICY.PATH} element={<PrivacyPolicy />} />
      <Route element={<ProtectedRoute />}>
        <>
          <Route path={ROUTES.DASHBOARD.PATH} element={<Dashboard />} />
          <Route
            path={ROUTES.SHIPPING_COMPANIES.PATH}
            element={<Companies />}
          />
          <Route path={ROUTES.COLLABORATORS.PATH} element={<Collaborators />} />
          <Route path={ROUTES.FLEET.PATH} element={<Fleet />} />
          {/* TODO: HANDLE PERMISSIONS */}
          <Route path={ROUTES.TIME_MANAGER.PATH} element={<TimeManager />} />
          {/* TODO: HANDLE PERMISSIONS */}
          <Route path={ROUTES.REPORTS.PATH} element={<Reports />} />
          <Route path={ROUTES.MACROS.PATH} element={<Macros />} />
          <Route path={ROUTES.JOURNEY_RULES.PATH} element={<JourneyRules />} />
          <Route path={ROUTES.SESSIONS.PATH} element={<Sessions />} />
          <Route path={ROUTES.CONVENTIONS.PATH} element={<Conventions />} />
          <Route path={ROUTES.PROFILE.PATH} element={<Profile />} />
          <Route path="/nao-encontrada" element={<NotFound />} />
        </>
      </Route>
    </Routes>
  );
};
