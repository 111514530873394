import { useEffect } from "react";
import DataPanel from "../../../components/DataPanel/DataPanel";
import Pagination from "../../../components/Pagination/Pagination";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import SearchInput from "../../../components/SearchInput/SeachInput";
import useModal from "../../../hooks/useModal";
import { useStore } from "../../../store/useStore";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import Skeleton from "react-loading-skeleton";
import { FilterIcon, OptionsIcon, PlusIcon } from "../../../assets/imgs";
import SectionsTable from "./SectionsTable";
import FilterSectionModal from "./FilterSectionModal";
import OptionsSectionModal from "./OptionsSectionModal";
import CreateSectionModal from "./CreateSectionModal";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import { AddCircle, Filter, Setting2 } from "iconsax-react";

const SectionGeneral: React.FC<{}> = () => {
  const { openModal } = useModal();
  const getSections = useStore((state) => state.conventions.getSections);
  const sectionsList = useStore((state) => state.conventions.sectionsList);
  const isLoadingSectionsList = useStore(
    (state) => state.conventions.isLoadingSectionsList
  );
  const hasPermissions = useStore((state) => state.admin.hasPermissions);

  useEffect(() => {
    getSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateSectionModal = () => openModal(<CreateSectionModal />);
  const openFilterSectionModal = () => openModal(<FilterSectionModal />);
  const openOptionsSectionModal = () => openModal(<OptionsSectionModal />);

  return (
    <>
      <div className="mt-9">
        <DataPanel className="px-3 pt-8 flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="pl-1">
              {/* <SearchInput placeholder="Buscar registro" onChange={() => {}} /> */}
            </div>
            <div className="pr-1 flex gap-4">
              <RoundedFilledButton
                onClick={openCreateSectionModal}
                icon={<AddCircle />}
                text="Novo"
                show={hasPermissions([PERMISSIONS.CREATE_SECTION])}
              />
              <RoundedFilledButton
                onClick={openFilterSectionModal}
                icon={<Filter size={24} />}
                text="Filtros"
              />
              <RoundedFilledButton
                onClick={openOptionsSectionModal}
                icon={<Setting2 />}
                text="Opções"
              />
            </div>
          </div>
          <SectionsTable />
          <div className="flex justify-between items-center">
            <div className="text-gray_1">
              Mostrando{" "}
              {!isLoadingSectionsList ? (
                sectionsList.data.length +
                sectionsList.offset * sectionsList.limitPerPage
              ) : (
                <Skeleton inline width={20} />
              )}{" "}
              de {sectionsList.totalItems} registros
            </div>
            <div className="flex gap-5 items-center">
              <LimitPerPage
                onLimitChange={(e: any) =>
                  sectionsList.setLimitPerPage(e.target.value)
                }
              />
              <Pagination
                currentPage={sectionsList.currentPage}
                totalPages={sectionsList.totalPages}
                onPageChange={sectionsList.setCurrentPage}
              />
            </div>
          </div>
        </DataPanel>
      </div>
    </>
  );
};

export default SectionGeneral;
