import { 
  payRollAPI,
  reportAPI
} from "../api/api";
import { handleServiceError } from "./handleServiceError";
import { removeEmptyProperties } from "../utils/functions/functions";
import {
  CompensatoryTime,
  CompensatoryTimeFilter,
  PayRoll,
  PayRollFilter,
} from "../store/types/timeManager";

const timeManagerService = {
  getPayRolls: async ({
    filters,
    limit,
    offset,
  }: {
    filters: PayRollFilter | {};
    limit: number;
    offset: number;
  }) => {
    try {
      const response = await payRollAPI.get(`/v1/payrollgroup`, {
        params: { ...removeEmptyProperties(filters), limit, offset },
      });
      const data: PayRoll[] = response.data.data;

      data.forEach(element => {
        element.payroll_total = element.users?.length.toString();
      });

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getPayRollsWithLimit: async ({
    filters,
    limit,
    offset,
  }: {
    filters: PayRollFilter | {};
    limit: number;
    offset: number;
  }) => {
    try {
      const response = await payRollAPI.get(`/v1/payrollgroup`, {
        params: { ...removeEmptyProperties(filters), limit, offset },
      });
      const data: PayRoll[] = response.data.data;

      data.forEach(element => {
        element.payroll_total = element.users?.length.toString();
      });

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getPayRoll: async (id: string) => {
    try {
      const response = await payRollAPI.get(`/v1/payrollgroup/${id}`);
      const data: PayRoll = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getCompensatoryTimes: async ({
    filters,
    limit,
    offset,
  }: {
    filters: CompensatoryTimeFilter | {};
    limit: number;
    offset: number;
  }) => {
    try {
      const response = await payRollAPI.get(`/v1/hourbank`, {
        params: { ...removeEmptyProperties(filters), limit, offset },
      });
      const data: CompensatoryTime[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getCompensatoryTime: async (id: string) => {
    try {
      const response = await payRollAPI.get(`/v1/hourbank/${id}`);
      const data: CompensatoryTime = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  createPayRoll: async (payRoll: PayRoll) => {
    try {
      await payRollAPI.post(`/v1/payrollgroup`, payRoll);
    } catch (error) {
      handleServiceError(error);
    }
  },
  updatePayRoll: async (id: string, payRoll: PayRoll) => {
    try {
      await payRollAPI.put(`/v1/payrollgroup/${id}`, payRoll);
    } catch (error) {
      handleServiceError(error);
    }
  },
  deletePayRoll: async (id: string) => {
    try {
      await payRollAPI.delete(`/v1/payrollgroup/${id}`);
    } catch (error) {
      handleServiceError(error);
    }
  },
  closePayRoll: async (
    id: string,
    unified: string[],
    orientation: string
  ) => {
    try {
      await payRollAPI.patch(`/v1/payrollgroup/${id}/close`, {
        unified,
        orientation
      });
    } catch (error) {
      handleServiceError(error);
    }
  },
  downloadPayRoll: async (params: {
    collaboratorId: string;
    payRollGroupId: string;
  }) => {
    try {
      const response = await reportAPI.get(`/v1/payroll/export`, {
        params: {
          user_id: params.collaboratorId,
          payroll_group_id: params.payRollGroupId,
        },
      });
      return response.data.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  refreshPayRoll: async (data: {
    collaboratorId: string;
    payRollGroupId: string;
  }) => {
    try {
      const response = await payRollAPI.post(`/v1/payroll/reprocess`, {
        user_id: data.collaboratorId,
        payroll_group_id: data.payRollGroupId,
      });
      return response.data.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  deleteCompensatoryTime: async (id: string) => {
    try {
      await payRollAPI.delete(`/v1/hourbank/${id}`);
    } catch (error) {
      handleServiceError(error);
    }
  },
  createCompensatoryTimeRegister: async (compensatoryTimeRegister: any) => {
    try {
      await payRollAPI.post(`/v1/hourbank`, compensatoryTimeRegister);
    } catch (error) {
      handleServiceError(error);
    }
  },
};

export default timeManagerService;
