import { Bank, MonitorMobbile, ReceiptEdit, Setting2, Timer1, Wind } from 'iconsax-react';

export default function Feature(
  props: { 
    icon: string; 
    label: string; 
    description: string;
    mobile: boolean
  }) {
  let icon;
  const iconSize = props.mobile ? 36 : 48;
  const color = "#ED3A3A";

  switch(props.icon) {
    case "first" :
      icon = <MonitorMobbile size={iconSize} color={color} variant="Bulk" />;
      break;

    case "seccond" :
      icon = <Timer1 size={iconSize} color={color} variant="Bulk" />;
      break;

    case "third" :
      icon = <Setting2 size={iconSize} color={color} variant="Bulk" />;
      break;
        
    case "fourth" :
      icon = <Wind size={iconSize} color={color} variant="Bulk" />;
      break;
      
    case "fifth" :
      icon = <Bank size={iconSize} color={color} variant="Bulk" />;
      break;
      
    case "sixth" :
      icon = <ReceiptEdit size={iconSize} color={color} variant="Bulk" />;
      break;

    default :
      icon = <MonitorMobbile size={iconSize} color={color} variant="Bulk" />;
      break;
  }

  return (
    <div style={{
      width: '30%',
      maxWidth: '30%'
    }}>
      <center className='icon-style'>{icon}</center>
      <p className={props.mobile ? "feature-header-mobile" : 'feature-header'}>{props.label}</p>
      <p className={props.mobile ? "feature-description-mobile" : 'description'}>{props.description}</p>
    </div>
  );
}
