import Modal from "../../../components/Modal/Modal";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { Colors } from "../../../utils/constants/style";
import { DangerIcon, NoSignalIcon } from "../../../assets/imgs";
import { useStore } from "../../../store/useStore";
import { Danger, TruckRemove } from "iconsax-react";

interface UnlinkFleetModalProps {
  onClose: () => void;
}

const UnlinkFleetModal: React.FC<UnlinkFleetModalProps> = ({ onClose }) => {
  const selectedFleet = useStore(
    (state) => state.fleet.selectedFleet
  );
  const unlinkFleet = useStore(
    (state) => state.fleet.unlinkFleet
  );
  const isUpdatingFleet = useStore(
    (state) => state.fleet.isUpdatingFleet
  );

  const handleUnlinkVehicle = () => {
    if(selectedFleet?._id)
      unlinkFleet(selectedFleet._id);
  };

  return (
    <Modal
      title="Desassociação de veículo"
      icon={<TruckRemove size={32} variant="Linear" color={Colors.white} />}
      className="w-[361px]"
      onClose={onClose}
    >
      <div className="text-center flex flex-col items-center gap-5">
        <Danger size="72" variant="Bulk" color={Colors.red}/>
        <div className="px-5">
          Tem certeza que deseja desassociar o motorista {selectedFleet?.user?.name} da placa {selectedFleet?.tag ?? '-'}?
        </div>
        <FilledButton loading={isUpdatingFleet} onClick={handleUnlinkVehicle} className="px-14">
          Desassociar
        </FilledButton>
      </div>
    </Modal>
  );
};

export default UnlinkFleetModal;
