
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik, FormikProps } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import { useStore } from "../../../store/useStore";
import { useEffect } from "react";
import OutlinedMaskedInput from "../../../components/FormComponents/OutlinedMaskedInput/OutlinedMaskedInput";
import { tagMask } from "../../../utils/constants/masks";
import CustomDatePicker from "../../../components/FormComponents/CustomDateTimePicker/CustomDateTimePicker";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import { removeNumberMask } from "../../../utils/functions/formatters";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import { MACRO_STATUS_OPTIONS } from "../../../utils/constants/constants";
import { Filter } from "iconsax-react";
import { Colors } from "../../../utils/constants/style";

const initialValues = {
  started_at_begin: "",
  started_at_end: "",
  ended_at_begin: "",
  ended_at_end: "",
  vehicle: "",
  user: "",
  status_type: "",
  journey_event: "",
  street: "",
  number: "",
  neighbourhood: "",
  city: "",
  state: "",
  country: "",
  zipcode: "",
};

const FilterMacroModal: React.FC<{}> = () => {
  const setFilters = useStore((state) => state.macro.macrosList.setFilters);
  const filters = useStore((state) => state.macro.macrosList.filters);
  const clearFilter = (formik: FormikProps<typeof initialValues>) => {
    formik.resetForm();
  };

  return (
    <Modal
      title="Filtros de macro"
      icon={<Filter size="32" variant="Bold" color={Colors.white}/>}
      className="w-[447px]"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          started_at_begin: Yup.string(),
          started_at_end: Yup.string(),
          ended_at_begin: Yup.string(),
          ended_at_end: Yup.string(),
          vehicle: Yup.string(),
          user: Yup.string(),
          status_type: Yup.string(),
          journey_event: Yup.string(),
          street: Yup.string(),
          number: Yup.string(),
          neighbourhood: Yup.string(),
          city: Yup.string(),
          state: Yup.string(),
          country: Yup.string(),
          zipcode: Yup.string(),
        })}
        onSubmit={(values) => {
          setFilters({
            ...filters,
            ...values,
            zipcode: removeNumberMask(values.zipcode),
          });
        }}
      >
        {(formik) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (filters) formik.setValues(filters);
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [filters]);

          return (
            <Form>
              <div>
                <div className="text-dark-blue font-semibold text-sm">
                  Dados de macro
                </div>
                <div className="flex gap-8 pb-5">
                  <div className="flex flex-col gap-8 pt-5">
                    <Field
                      placeholder="Macro iniciada a partir de"
                      name="started_at_begin"
                      component={CustomDatePicker}
                    />
                    <Field
                      placeholder="Macro finalizada a partir de"
                      name="ended_at_begin"
                      component={CustomDatePicker}
                    />
                    <Field
                      placeholder="Veículo"
                      name="vehicle"
                      mask={tagMask}
                      component={OutlinedMaskedInput}
                    />
                    <Field
                      placeholder="Status"
                      name="status_type"
                      component={OutlinedSelect}
                      options={MACRO_STATUS_OPTIONS}
                    />
                  </div>
                  <div className="flex flex-col gap-8 pt-5">
                    <Field
                      placeholder="Macro iniciada até"
                      name="started_at_end"
                      component={CustomDatePicker}
                    />
                    <Field
                      placeholder="Macro finalizada até"
                      name="ended_at_end"
                      component={CustomDatePicker}
                    />
                    <Field
                      placeholder="Motorista"
                      name="user"
                      component={OutlinedInput}
                    />
                    <Field
                      placeholder="Evento de Jornada"
                      name="journey_event"
                      component={OutlinedInput}
                    />
                  </div>
                </div>
                <div className="text-dark-blue font-semibold text-sm">
                  Endereço de macro
                </div>
                <div className="flex gap-8 pb-5">
                  <div className="flex flex-col gap-8 pt-5">
                    <Field
                      placeholder="Rua"
                      name="street"
                      component={OutlinedInput}
                    />
                    <Field
                      placeholder="Número"
                      name="number"
                      component={OutlinedMaskedInput}
                      mask="999999999999"
                    />
                    <Field
                      placeholder="Complemento"
                      name="neighbourhood"
                      component={OutlinedInput}
                    />
                    <Field
                      placeholder="CEP"
                      name="zipcode"
                      component={OutlinedMaskedInput}
                      mask="99999-999"
                    />
                  </div>
                  <div className="flex flex-col gap-8 pt-5">
                    <Field
                      placeholder="Cidade"
                      name="city"
                      component={OutlinedInput}
                    />
                    <Field
                      placeholder="Estado"
                      name="state"
                      component={OutlinedInput}
                    />
                    <Field
                      placeholder="País"
                      name="country"
                      component={OutlinedInput}
                    />
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="flex-1">
                    <OutlinedButton
                      onClick={() => {
                        clearFilter(formik);
                      }}
                      className="w-full"
                      type="button"
                    >
                      Limpar Filtros
                    </OutlinedButton>
                  </div>
                  <div className="flex-1">
                    <FilledButton className="w-full" type="submit">
                      Filtrar
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default FilterMacroModal;
