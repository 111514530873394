import Modal from "../../../components/Modal/Modal";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { Colors } from "../../../utils/constants/style";
import { useStore } from "../../../store/useStore";
import { Danger, Trash } from "iconsax-react";

const DeleteSectionModal: React.FC<{}> = () => {
  const selectedSection = useStore(
    (state) => state.conventions.selectedSection
  );
  const deleteSection = useStore(
    (state) => state.conventions.deleteSection
  );
  const isDeletingSection = useStore(
    (state) => state.conventions.isDeletingSection
  );

  const handleDeleteSection = () => {
    if(selectedSection?._id)
    deleteSection(selectedSection._id);
  };

  return (
    <Modal
      title="Exclusão de seção"
      icon={<Trash size={30} variant="Bold" color={Colors.white} />}
      className="w-[361px]"
    >
      <div className="text-center flex flex-col items-center gap-5">
        <Danger size="72" variant="Bulk" color={Colors.red}/>
        <div className="px-5">
          Tem certeza que deseja remover a seção de nome {selectedSection?.name ?? '-'}?
        </div>
        <FilledButton loading={isDeletingSection} onClick={handleDeleteSection} className="px-14">
          Excluir
        </FilledButton>
      </div>
    </Modal>
  );
};

export default DeleteSectionModal;
