import { SearchNormal1 } from "iconsax-react";
import InfoField from "../../../components/InfoField/InfoField";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import Modal from "../../../components/Modal/Modal";
import { useStore } from "../../../store/useStore";
import { Colors } from "../../../utils/constants/style";
import { formatDateTime } from "../../../utils/functions/formatters";

interface DetailsSyndicateModalProps {
  onClose: () => void;
}

const DetailsSyndicateModal: React.FC<DetailsSyndicateModalProps> = ({
  onClose,
}) => {
  const selectedSyndicate = useStore((state) => state.conventions.selectedSyndicate);
  const isLoadingSyndicate = useStore((state) => state.conventions.isLoadingSyndicate);

  return (
    <Modal
      title="Detalhes de sindicato"
      icon={<SearchNormal1 size="30" color={Colors.white} variant="Linear" />}
      className="min-w-[479px] max-w-[579px]"
      onClose={onClose}
    >
      <div className="flex flex-col gap-5">
        <InfoFieldBox>
          <InfoField
            loading={isLoadingSyndicate}
            label="Nome"
            content={selectedSyndicate?.name}
          />
          <InfoField
            loading={isLoadingSyndicate}
            label="Área de operação"
            content={selectedSyndicate?.operation_area}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingSyndicate}
            label="Criado em"
            content={formatDateTime(selectedSyndicate?.created_at ?? "")}
          />
        </InfoFieldBox>
      </div>
    </Modal>
  );
};

export default DetailsSyndicateModal;
