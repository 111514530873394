import { JornadaSatLogo, OmnilinkLogo, OnixSatLogo, SascarLogo } from "../../assets/imgs";

const MacroOrigin: React.FC<{ origin: string }> = ({ origin }) => {
  if (origin === "ONIXSAT") {
    return (
      <div className="flex gap-1 items-center bg-gray_0 rounded-[20px] min-h-[30px] w-fit px-3 flex-wrap justify-center">
        <img src={OnixSatLogo} alt="onixsat logo" width={35} />{" "}
        <span className="font-medium text-sm">Onix Sat</span>
      </div>
    );
  } else if (origin === "JORNADASAT") {
    return (
      <div className="flex gap-1 items-center bg-gray_0 rounded-[20px] min-h-[30px] w-fit px-3 flex-wrap justify-center">
        <img width={35} src={JornadaSatLogo} alt="jornadasat logo" />{" "}
        <span className="font-medium text-sm">Jornada Sat</span>
      </div>
    );
  } else if (origin === "SASCAR") {
    return (
      <div className="flex gap-1 items-center bg-gray_0 rounded-[20px] min-h-[30px] w-fit px-3 flex-wrap justify-center">
        <img width={35} src={SascarLogo} alt="jornadasat logo" />{" "}
        <span className="font-medium text-sm">Sascar</span>
      </div>
    );
  }  else if (origin === "OMNILINK") {
    return (
      <div className="flex gap-1 items-center bg-gray_0 rounded-[20px] min-h-[30px] w-fit px-3 flex-wrap justify-center">
        <img width={35} src={OmnilinkLogo} alt="jornadasat logo" />{" "}
        <span className="font-medium text-sm">Omnilink</span>
      </div>
    );
  }

  return (
    <span className="font-medium text-sm bg-gray_0 rounded-[20px] w-fit p-3">
      {origin}
    </span>
  );
};

export default MacroOrigin;
