import useModal from "../../../hooks/useModal";
import { useStore } from "../../../store/useStore";
import CreateReportModal from "./CreateReportModal";
import CreateCompetenceReportModal from "./CreateCompetenceReportModal";
import RoundedCard from "../../../components/RoundedCard/RoundedCard";
import { ReportType } from "../../../store/types/report";
import CalendarSearchIcon from "../../../assets/imgs/CalendarSearch";
import CalendarEditIcon from "../../../assets/imgs/CalendarEdit";
import { ClockIcon, DrivingIcon, MoonIcon } from "../../../assets/imgs";
import { Colors } from "../../../utils/constants/style";
import CreateConsolidatedPaymentHoursModal from "./CreateConsolidatedPaymentHoursModal";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import CreateAFDAEJReportModal from "./CreateAFDAEJReportModal";
import CreateJFWCustomReportModal from "./CreateJFWCustomReportModal";
import CreateDailiesReportModal from "./CreateDailiesReportModal";
import { Folder2, FolderOpen, Routing, Ticket2 } from "iconsax-react";

const ReportGeneral: React.FC<{}> = () => {
  const { openModal } = useModal();
  const openCreateReportModal = () => openModal(<CreateReportModal />);
  const openCreateAFDAEJReportModal = () => openModal(<CreateAFDAEJReportModal />);
  const openCompetenceReportModal = () => openModal(<CreateCompetenceReportModal />);
  const openConsolidatedPaymentHoursModal = () => openModal(<CreateConsolidatedPaymentHoursModal />);
  const openDailiesModal = () => openModal(<CreateDailiesReportModal />);
  const openJFWCustomHoursReportModal = () => openModal(<CreateJFWCustomReportModal />);
  const setReportType = useStore((state) => state.report.setReportType);
  const hasPermissions = useStore((state) => state.admin.hasPermissions);
  const adminData = useStore((state) => state.admin.data);

  return (
    <>
      <div className="text-dark-blue text-xl font-medium mt-7">Relatórios individuais:</div>
      <div className="mt-7 flex flex-wrap gap-10">
        <div>
          <RoundedCard
            onClick={() => { 
              setReportType(ReportType.MACRO_ALTERED)
              openCreateReportModal()
            }}
            icon={<CalendarEditIcon width={32} height={32} />}
            title="Macros com Alteração"
            text="Baixe aqui o relatório de macros que sofreram alteração no período (portaria 671)"
          />
        </div>
        <div>
          <RoundedCard
            onClick={() => { 
              setReportType(ReportType.MACRO_MANUAL)
              openCreateReportModal()
            }}
            icon={<CalendarSearchIcon width={32} height={32} />}
            title="Macros Manuais"
            text="Baixe aqui o relatório de macros manuais no período (portaria 671)"
          />
        </div>
        <div>
          <RoundedCard
            onClick={() => { 
              setReportType(ReportType.MACRO_INTERJOURNEY)
              openCreateReportModal()
            }}
            icon={<MoonIcon width={32} height={32} />}
            title="Descanso Interjornada"
            text="Baixe aqui o relatório de descanso interjornada realizado no período"
          />
        </div>
        <div>
          <RoundedCard
            onClick={() => { 
              setReportType(ReportType.LONGEST_WORKING_TIME)
              openCreateReportModal()
            }}
            icon={<DrivingIcon width={24} height={24} color={Colors.white}/>}
            title="Trabalho Ininterrupto"
            text="Baixe aqui o relatório de maior tempo de trabalho ininterrupto realizado no período"
          />
        </div>
        <div>
          <RoundedCard
            onClick={() => { 
              setReportType(ReportType.MACROS_LIST)
              openCreateReportModal()
            }}
            icon={<Routing width={32} height={32} color={'white'}/>}
            title="Lista de Macros"
            text="Baixe aqui o relatório com a lista de macros do período"
          />
        </div>
        { ['66ec11b94a66048d08c1dec0', '67603528a2255ac7867f141f'].includes(adminData.company) ?
          <div>
            <RoundedCard
              onClick={() => { 
                setReportType(ReportType.DAILIES)
                openDailiesModal()
              }}
              icon={<Ticket2 width={24} height={24} color={Colors.white}/>}
              title="Diárias"
              text="Baixe aqui o relatório de diárias realizadas na folha de pagamento"
            />
          </div>
          : null
        }
        { adminData.company === '66bb92ee2a8bc6dd7ddd9c27' ?
          <div>
            <RoundedCard
              onClick={() => { 
                setReportType(ReportType.JFW_CUSTOM_HOURS)
                openJFWCustomHoursReportModal()
              }}
              icon={<Folder2 width={24} height={24} color={Colors.white}/>}
              title="Importação JFW"
              text="Baixe aqui o arquivo de importação das horas (Customizado JFW)"
            />
          </div>
          : null
        }
      </div>

      <div className="text-dark-blue text-xl font-medium mt-12">Relatórios consolidados:</div>
      <div className="mt-7 flex flex-wrap gap-10">
        <div>
          <RoundedCard
            onClick={() => { 
              setReportType(ReportType.CREATE_CONSOLIDATED_REST_TIME_REPORT)
              openCompetenceReportModal()
            }}
            icon={<MoonIcon width={32} height={32} color={Colors.white}/>}
            title="Folgas"
            text="Baixe aqui o relatório com a lista de folgas por período"
          />
        </div>
        <div>
          <RoundedCard
            onClick={() => { 
              setReportType(ReportType.CREATE_CONSOLIDATED_PAYMENT_HOURS_REPORT)
              openConsolidatedPaymentHoursModal()
            }}
            icon={<ClockIcon width={24} height={24} color={Colors.white}/>}
            title="Horas Consolidadas"
            text="Baixe aqui o relatório com os pagamentos de horas consolidadas"
          />
        </div>
      </div>

      { hasPermissions([PERMISSIONS.CREATE_AFD_REPORT, PERMISSIONS.CREATE_AEJ_REPORT]) ?
        <div>
          <div className="text-dark-blue text-xl font-medium mt-7">Relatórios legais:</div>
          <div className="mt-7 flex flex-wrap gap-10">
            <div>
              <RoundedCard
                onClick={() => { 
                  setReportType(ReportType.AFD)
                  openCreateAFDAEJReportModal()
                }}
                icon={<FolderOpen size={24} color={Colors.white}/>}
                title="Registros AFD"
                text="Baixe aqui os registros AFD da empresa para um determinado período"
              />
            </div>
            <div>
              <RoundedCard
                onClick={() => { 
                  setReportType(ReportType.AEJ)
                  openCreateAFDAEJReportModal()
                }}
                icon={<FolderOpen size={24} color={Colors.white}/>}
                title="Registros AEJ"
                text="Baixe aqui os registros AFD da empresa para um determinado período"
              />
            </div>
          </div>
        </div>
      : null }
    </>
  );
};

export default ReportGeneral;
