
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik, FormikProps } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import { useStore } from "../../../store/useStore";
import { useEffect } from "react";
import { POSITION_OPTIONS } from "../../../utils/constants/permissions";
import OutlinedMaskedInput from "../../../components/FormComponents/OutlinedMaskedInput/OutlinedMaskedInput";
import { removeNumberMask } from "../../../utils/functions/formatters";
import { Filter } from "iconsax-react";
import { Colors } from "../../../utils/constants/style";

const initialValues = {
  name: "",
  email: "",
  phone: "",
  registration: "",
  position: "",
  cpf: "",
  rg: "",
  cnh: "",
};

const FilterCollaboratorModal: React.FC<{}> = () => {
  const setFilters = useStore(
    (state) => state.collaborator.collaboratorsList.setFilters
  );
  const filters = useStore(
    (state) => state.collaborator.collaboratorsList.filters
  );
  const clearFilter = (formik: FormikProps<typeof initialValues>) => {
    formik.resetForm();
  };
  const userPropertiesProjection = useStore(
    (state) => state.admin.data.projection.user
  );
  const adminPositionType = useStore((state) => state.admin.data.position.type);

  return (
    <Modal
      title="Filtros de colaborador"
      icon={<Filter size="32" variant="Bold" color={Colors.white}/>}
      className="w-[447px]"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string(),
          email: Yup.string(),
          phone: Yup.string(),
          registration: Yup.string(),
          position: Yup.string(),
          cpf: Yup.string(),
          rg: Yup.string(),
          cnh: Yup.string(),
        })}
        onSubmit={(values) => {
          values = {
            ...values,
            cpf: removeNumberMask(values.cpf),
            phone: removeNumberMask(values.phone),
          };
          setFilters({ ...filters, ...values });
        }}
      >
        {(formik) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (filters) formik.setValues(filters);
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [filters]);

          return (
            <Form>
              <div className="flex flex-col gap-8 pt-5">
                <div className="flex gap-8">
                  <div className="flex-1 flex flex-col gap-8">
                    {" "}
                    <Field
                      show={userPropertiesProjection.includes("name")}
                      placeholder="Nome"
                      name="name"
                      component={OutlinedInput}
                    />
                    <Field
                      show={userPropertiesProjection.includes("email")}
                      placeholder="E-mail"
                      name="email"
                      component={OutlinedInput}
                    />
                    <Field
                      show={userPropertiesProjection.includes("phone")}
                      placeholder="Telefone"
                      mask={"9999999999999"}
                      name="phone"
                      alwaysShowMask={false}
                      component={OutlinedMaskedInput}
                    />
                    <Field
                      show={userPropertiesProjection.includes("registration")}
                      placeholder="Matrícula"
                      name="registration"
                      component={OutlinedInput}
                    />
                  </div>
                  <div className="flex-1 flex flex-col gap-8">
                    <Field
                      show={userPropertiesProjection.includes("position")}
                      name="position"
                      component={OutlinedSelect}
                      placeholder="Cargo"
                      options={POSITION_OPTIONS[adminPositionType]}
                    />
                    <Field
                      show={userPropertiesProjection.includes("cpf")}
                      placeholder="CPF"
                      name="cpf"
                      component={OutlinedMaskedInput}
                      mask="99999999999"
                    />
                    <Field
                      show={userPropertiesProjection.includes("rg")}
                      placeholder="RG"
                      name="rg"
                      component={OutlinedMaskedInput}
                      mask="999999999"
                    />
                    <Field
                      show={userPropertiesProjection.includes("cnh")}
                      placeholder="CNH"
                      name="cnh"
                      component={OutlinedMaskedInput}
                      mask="999999999999"
                    />
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="flex-1">
                    <OutlinedButton
                      onClick={() => clearFilter(formik)}
                      className="w-full"
                      type="button"
                    >
                      Limpar Filtros
                    </OutlinedButton>
                  </div>
                  <div className="flex-1">
                    <FilledButton className="w-full" type="submit">
                      Filtrar
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default FilterCollaboratorModal;
