import { useEffect } from "react";
import DataPanel from "../../../components/DataPanel/DataPanel";
import Pagination from "../../../components/Pagination/Pagination";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import useModal from "../../../hooks/useModal";
import { useStore } from "../../../store/useStore";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import Skeleton from "react-loading-skeleton";
import OptionsMacroModal from "./OptionsMacroModal";
import MacrosRestoreTable from "./MacrosRestoreTable";
import FilterMacroRestoreModal from "./FilterRestoreMacroModal";
import { Filter, Setting2 } from "iconsax-react";

const MacroRestore: React.FC<{}> = () => {
  const { openModal } = useModal();
  const getMacrosRestore = useStore((state) => state.macro.getMacrosRestore);
  const macrosList = useStore(
    (state) => state.macro.macrosRestoreList
  );
  const isLoadingMacrosRestoreList = useStore(
    (state) => state.macro.isLoadingMacrosRestoreList
  );
  useEffect(() => {
    getMacrosRestore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openFilterMacroModal = () =>
    openModal(<FilterMacroRestoreModal />);
  const openOptionsCollaboratorModal = () =>
    openModal(<OptionsMacroModal />);

  return (
    <>
      <div className="mt-9">
        <DataPanel className="px-3 pt-8 flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="pl-1">
              {/* <SearchInput placeholder="Buscar registro" onChange={() => {}} /> */}
            </div>
            <div className="pr-1 flex gap-4">
              <RoundedFilledButton
                onClick={openFilterMacroModal}
                icon={<Filter size={24} />}
                text="Filtros"
              />
              {/* <RoundedFilledButton
                onClick={openOptionsCollaboratorModal}
                icon={<Setting2 />}
                text="Opções"
              /> */}
            </div>
          </div>
          <MacrosRestoreTable />
          <div className="flex justify-between items-center">
            <div className="text-gray_1">
              Mostrando{" "}
              {!isLoadingMacrosRestoreList ? (macrosList.data.length + macrosList.offset * macrosList.limitPerPage) : <Skeleton inline width={20} />}{" "}
              de {macrosList.totalItems} registros
            </div>
            <div className="flex gap-5 items-center">
              <LimitPerPage
                onLimitChange={(e: any) =>
                  macrosList.setLimitPerPage(e.target.value)
                }
              />
              <Pagination
                currentPage={macrosList.currentPage}
                totalPages={macrosList.totalPages}
                onPageChange={macrosList.setCurrentPage}
              />
            </div>
          </div>
        </DataPanel>
      </div>
    </>
  );
};

export default MacroRestore;
