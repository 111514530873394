import { SearchNormal1 } from "iconsax-react";
import InfoField from "../../../components/InfoField/InfoField";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import Modal from "../../../components/Modal/Modal";
import { useStore } from "../../../store/useStore";
import { Colors } from "../../../utils/constants/style";
import { formatDateTime } from "../../../utils/functions/formatters";
import ParameterValueComponent from "./ParameterValueComponent";

interface DetailsParameterModalProps {
  onClose: () => void;
}

const DetailsParameterModal: React.FC<DetailsParameterModalProps> = ({
  onClose,
}) => {
  const selectedParameter = useStore(
    (state) => state.journeyRules.selectedParameter
  );
  const isLoadingParameter = useStore(
    (state) => state.journeyRules.isLoadingParameter
  );

  return (
    <Modal
      title="Detalhes de parâmetro"
      icon={<SearchNormal1 size="30" color={Colors.white} variant="Linear" />}
      className="min-w-[479px] max-w-[579px]"
      onClose={onClose}
    >
      <div className="flex flex-col gap-5">
        <InfoFieldBox>
          <InfoField
            loading={isLoadingParameter}
            label="Código"
            content={selectedParameter?.code}
          />
          <InfoField
            loading={isLoadingParameter}
            label="Nome"
            content={selectedParameter?.name}
          />
        </InfoFieldBox>
        <InfoFieldBox>
        <InfoField
            loading={isLoadingParameter}
            label="Descrição"
            content={selectedParameter?.description}
          />
          <InfoField
            loading={isLoadingParameter}
            label="Valor padrão"
            content={<div className="flex"><ParameterValueComponent parameter={selectedParameter} /></div>}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingParameter}
            label="Criado em"
            content={formatDateTime(selectedParameter?.created_at ?? "")}
          />
        </InfoFieldBox>
      </div>
    </Modal>
  );
};

export default DetailsParameterModal;
