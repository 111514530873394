import { useMemo, useState } from "react";
import { useStore } from "../../../store/useStore";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import {
  JourneyChartDataType,
  STATUS_COLOR,
} from "../../../store/types/dashboard";

const LegendItem: React.FC<{ title: string; color: string; show: boolean }> = ({
  title,
  color,
  show,
}) => {
  return show ? (
    <div className="flex gap-1 items-center pt-5">
      <div
        style={{ backgroundColor: color }}
        className="rounded-full w-2 h-2"
      ></div>
      <div className="text-sm">{title}</div>
    </div>
  ) : null;
};

const JourneyDetailsChart: React.FC<{}> = () => {
  const journeyChartData = useStore(
    (state) => state.dashboard.statusPanel.selectedMacro.journeyChartData
  );
  const isLoadingUserJourney = useStore(
    (state) => state.dashboard.isLoadingUserJourney
  );
  const [journeyIndexToHighlight, setJourneyIndexToHighlight] = useState(-1);

  const totalHours = useMemo(
    () =>
      journeyChartData?.reduce((acc: any, item: any) => acc + item.hours, 0),
    [journeyChartData]
  );

  const allTypesInData =
    journeyChartData?.map((item: JourneyChartDataType) => item.type) ?? [];

    const minWidthPercent = 3.8; // Minimum width percentage for any segment
  
    // Calculate initial widths
    let segments = journeyChartData?.map(item => {
      let widthPercent = (item.hours / totalHours) * 100;
      return { ...item, widthPercent };
    }) ?? [];
  
    // Adjust segments to ensure minimum width
    segments.forEach(segment => {
      if (segment.widthPercent < minWidthPercent) {
        const differencePercent = minWidthPercent - segment.widthPercent;
        segment.widthPercent = minWidthPercent;
  
        // Find the segment with the largest widthPercent to reduce
        let largestSegment = segments.reduce((prev, current) => (prev.widthPercent > current.widthPercent) ? prev : current);
        largestSegment.widthPercent -= differencePercent;
      }
    });

  return (
    <div>
      {isLoadingUserJourney ? (
        <Skeleton width="100%" height={20} />
      ) : (
        segments?.map((item, i: number) => {
          return (
            <div
              key={i}
              style={{
                width: `${item.widthPercent}%`,
                backgroundColor: STATUS_COLOR[item.type],
              }}
              onMouseOver={() => setJourneyIndexToHighlight(i)}
              onMouseOut={() => setJourneyIndexToHighlight(-1)}
              className={`relative inline-block h-[45px] ml-[-1px] ${
                journeyIndexToHighlight === i
                  ? "z-10 border border-black"
                  : "border-l border-gray_2"
              }`}
            >
              <span
                className={`absolute top-[45px] text-[10px] bg-white cursor-default ml-[-1px] ${
                  journeyIndexToHighlight === i
                    ? "z-10 px-1 rounded border-black border"
                    : "border-l border-gray_2"
                }`}
              >
                <div>{moment(item.start_time).format("HH:mm")}</div>
                <div className="text-[10px]">
                  {moment(item.start_time).format("DD/MM")}
                </div>
              </span>
            </div>
          );
        })
      )}
      <div className="flex gap-2 mt-3">
        <LegendItem
          title="Horas trabalhadas"
          color={STATUS_COLOR.WORKED_HOURS}
          show={allTypesInData.includes("WORKED_HOURS")}
        />
        <LegendItem
          title="Esperando"
          color={STATUS_COLOR.WAITING_HOURS}
          show={allTypesInData.includes("WAITING_HOURS")}
        />
        <LegendItem
          title="Descanso Intrajornada"
          color={STATUS_COLOR.INTRADAY_REST}
          show={allTypesInData.includes("INTRADAY_REST")}
        />
        <LegendItem
          title="Descanso Interjornada"
          color={STATUS_COLOR.INTERDAY_REST}
          show={allTypesInData.includes("INTERDAY_REST")}
        />
         <LegendItem
          title="Compensação Interjornada"
          color={STATUS_COLOR.INTERDAY_COMPENSATION}
          show={allTypesInData.includes("INTERDAY_COMPENSATION")}
        />
        <LegendItem
          title="Refeição"
          color={STATUS_COLOR.MEAL}
          show={allTypesInData.includes("MEAL")}
        />
        <LegendItem
          title="Disponível"
          color={STATUS_COLOR.AVAILABLE}
          show={allTypesInData.includes("AVAILABLE")}
        />
        <LegendItem
          title="Hora Extra"
          color={STATUS_COLOR.EXTRA_HOUR}
          show={allTypesInData.includes("EXTRA_HOUR")}
        />
      </div>
    </div>
  );
};

export default JourneyDetailsChart;
