import { StateCreator } from "zustand";
import {
  Collaborator,
  CollaboratorFilter,
  CollaboratorState,
  CollaboratorsVisibleColumnsKey,
} from "../types/collaborator";
import collaboratorService from "../../services/collaboratorService";
import { toast } from "react-toastify";
import produce from "immer";
import { useStore } from "../useStore";
import { downloadURI } from "../../utils/functions/download";

const initialCollaboratorListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  columnsVisibility: {
    name: true,
    email: false,
    phone: true,
    registration: true,
    position: true,
    cpf: true,
    rg: false,
    cnh: false,
    section: true,
    created_at: true,
    integration_user: true
  },
  filters: {
    name: "",
    registration: "",
    phone: "",
    email: "",
    position: "",
    cpf: "",
    rg: "",
    cnh: "",
    payroll_group: "",
    sort_key: "name",
    sort_value: "asc",
  },
  collaboratorsSelected: []
};

export const createCollaboratorSlice: StateCreator<CollaboratorState> = (
  set,
  get
) => ({
  collaborator: {
    collaboratorsList: {
      ...initialCollaboratorListData,
      toggleColumnVisibility: (key: string, value: boolean) => {
        set(
          produce((state) => {
            state.collaborator.collaboratorsList.columnsVisibility[key] =
              !value;
          })
        );
      },
      setCollaboratorsList: (collaborators: Collaborator[]) => {
        set(
          produce((state) => {
            state.collaborator.collaboratorsList.collaboratorsList.data = collaborators;
          })
        );
        get().collaborator.getCollaborators();
      },
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.collaborator.collaboratorsList.limitPerPage = limitPerPage;
            state.collaborator.collaboratorsList.offset = 0;
            state.collaborator.collaboratorsList.currentPage = 1;
          })
        );
        get().collaborator.getCollaborators();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.collaborator.collaboratorsList.currentPage = page;
            state.collaborator.collaboratorsList.offset = page - 1;
          })
        );
        get().collaborator.getCollaborators();
      },
      setFilters: (filters: CollaboratorFilter) => {
        set(
          produce((state) => {
            state.collaborator.collaboratorsList.filters = filters;
            state.collaborator.collaboratorsList.offset = 0;
            state.collaborator.collaboratorsList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().collaborator.getCollaborators();
      },
    },
    selectedCollaborator: null,
    isLoadingCollaboratorsList: false,
    isLoadingCollaborator: false,
    isCreatingCollaborator: false,
    isDeletingCollaborator: false,
    isUpdatingCollaborator: false,
    isExporting: { csv: false, pdf: false },
    resetSelectedCollaborator: () => {
      set(
        produce((state) => {
          state.collaborator.collaboratorsList.collaboratorsSelected = [];
        })
      );
    },
    selectCollaborator: (collaboratorId: string | undefined) => {
      if (collaboratorId === undefined) return;
      set(
        produce((state) => {
          state.collaborator.collaboratorsList.collaboratorsSelected.push(collaboratorId);
        })
      );
    },
    unselectCollaborator: (collaboratorId: string | undefined) => {
      if (collaboratorId === undefined) return;
      set(
        produce((state) => {
          state.collaborator.collaboratorsList.collaboratorsSelected =
            state.collaborator.collaboratorsList.collaboratorsSelected.filter(
              (id: string) => id !== collaboratorId
            );
        })
      );
    },
    getCollaborators: () => {
      set(
        produce((state) => {
          state.collaborator.isLoadingCollaboratorsList = true;
        })
      );

      const filters = get().collaborator.collaboratorsList.filters;
      const limit = get().collaborator.collaboratorsList.limitPerPage;
      const offset = get().collaborator.collaboratorsList.offset;

      collaboratorService
        .getCollaborators({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.collaborator.collaboratorsList.data = data;
              state.collaborator.collaboratorsList.totalItems = totalItems;
              state.collaborator.collaboratorsList.totalPages = Math.ceil(
                totalItems / state.collaborator.collaboratorsList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.collaborator.isLoadingCollaboratorsList = false;
            })
          );
        });
    },
    getCollaboratorOptions: async (name: string) => {
      try {
        const { data }: any = await collaboratorService
          .getCollaborators({ filters: { name }, limit: 20, offset: 0 })
        return data;
      } catch (error: any) {
        toast.error(error.message);
      }
    },
    getCollaborator: (collaboratorId: string) => {
      set(
        produce((state) => {
          state.collaborator.isLoadingCollaborator = true;
        })
      );

      collaboratorService
        .getCollaborator(collaboratorId)
        .then((data) => {
          set(
            produce((state) => {
              state.collaborator.selectedCollaborator = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.collaborator.isLoadingCollaborator = false;
            })
          );
        });
    },
    deleteCollaborator: (collaboratorId: string) => {
      set(
        produce((state) => {
          state.collaborator.isDeletingCollaborator = true;
        })
      );

      collaboratorService
        .deleteCollaborator(collaboratorId)
        .then(() => {
          toast.success("Usuário excluído com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().collaborator.getCollaborators();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.collaborator.isDeletingCollaborator = false;
            })
          );
        });
    },
    updateCollaborator: (
      collaboratorId: string,
      collaborator: Collaborator
    ) => {
      set(
        produce((state) => {
          state.collaborator.isUpdatingCollaborator = true;
        })
      );

      collaboratorService
        .updateCollaborator(collaboratorId, collaborator)
        .then(() => {
          toast.success("Usuário atualizado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().collaborator.getCollaborators();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.collaborator.isUpdatingCollaborator = false;
            })
          );
        });
    },
    updateCollaboratorPassword: (
      collaboratorId: string,
      password: string
    ) => {
      set(
        produce((state) => {
          state.collaborator.isUpdatingCollaborator = true;
        })
      );

      collaboratorService
        .updateCollaboratorPassword(collaboratorId, password)
        .then(() => {
          toast.success("Usuário atualizado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().collaborator.getCollaborators();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.collaborator.isUpdatingCollaborator = false;
            })
          );
        });
    },
    createCollaborator: (collaborator: Collaborator) => {
      set(
        produce((state) => {
          state.collaborator.isCreatingCollaborator = true;
        })
      );

      collaboratorService
        .createCollaborator(collaborator)
        .then(() => {
          toast.success("Usuário cadastrado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().collaborator.getCollaborators();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.collaborator.isCreatingCollaborator = false;
            })
          );
        });
    },
    exportCollaboratorTable: (format: "csv" | "pdf") => {
      set(
        produce((state) => {
          state.collaborator.isExporting[format] = true;
        })
      );

      const columnsVisibility =
        get().collaborator.collaboratorsList.columnsVisibility;

      const columnsToExport: CollaboratorsVisibleColumnsKey[] = Object.keys(
        columnsVisibility
      )
        .filter(
          (key) => columnsVisibility[key as CollaboratorsVisibleColumnsKey]
        )
        .map((key) => key as CollaboratorsVisibleColumnsKey);

      const userPropertiesProjection =
        useStore.getState().admin.data.projection.user;

      const columnsToExportFiltered = columnsToExport.filter((key) =>
        userPropertiesProjection.includes(key)
      );

      collaboratorService
        .exportCollaborator(format, columnsToExportFiltered)
        .then((data) => {
          downloadURI(data.file_url, data.fileName);
          toast.success("Documento exportado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.collaborator.isExporting[format] = false;
            })
          );
        });
    },
    resetCollaboratorListState: () => {
      set(
        produce((state) => {
          state.collaborator.collaboratorsList = {
            ...state.collaborator.collaboratorsList,
            ...initialCollaboratorListData,
          };
        })
      );
    },
  },
});
