import Skeleton from "react-loading-skeleton";

interface InfoFieldProps {
  label: string;
  content: JSX.Element | string | number | undefined;
  loading?: boolean;
  show?: boolean;
  hasChanged?: boolean;
}

const InfoField: React.FC<InfoFieldProps> = ({ label, content, loading, show=true, hasChanged=false }) => {
  return show ? (
    <div className="flex-1">
      <div className="text-sm text-gray_2">{label}</div>
      {!loading ? (
        <div className={`${hasChanged ? 'text-red' : 'text-black'}`}>{content ?? "-"}</div>
      ) : (
        <Skeleton height={14} width={130} />
      )}
    </div>
  ) : null;
};

export default InfoField;
