import { useState } from "react";
import { Colors } from "../../utils/constants/style";
import { SearchNormal1 } from "iconsax-react";

interface SearchInputProps {
  className?: string;
  labelClassName?: string;
  placeholder: string;
  onChange: (e: any) => void;
  showIcon?: boolean;
}

const SearchInput: React.FC<SearchInputProps> = ({ className, labelClassName, placeholder, onChange, showIcon=true }) => {
  const [debouceTimeout, setDebouceTimeout] = useState<NodeJS.Timeout | undefined>(undefined);

  const handleChange = (e: any) => {
    clearTimeout(debouceTimeout);
    const timeout = setTimeout(() => onChange(e.target.value), 1000);
    setDebouceTimeout(timeout);
  };
  
  return (
    <div className="flex gap-2 items-center">
      {showIcon ? <SearchNormal1 size="24" color={Colors.gray_2} variant="Linear" /> : null}
      <label className={`float-label-input ${labelClassName}`}>
        <input
          placeholder=" "
          onChange={handleChange}
          className={`border-b outline-none border-gray_1 w-full ${className}`}
        />
        <span className="text-gray_1">{placeholder}</span>
      </label>
    </div>
  );
};

export default SearchInput;
