import { Field, FieldArray, Form, Formik } from "formik";
import { PlusIcon, TrashOutlinedIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { useStore } from "../../../store/useStore";
import * as Yup from "yup";
import FilledButton from "../../../components/FilledButton/FilledButton";
import AsyncOutlinedSelect from "../../../components/AsyncOutlinedSelect/AsyncOutlinedSelect";
import { Collaborator } from "../../../store/types/collaborator";
import { Fleet } from "../../../store/types/fleet";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import { JourneyEvent } from "../../../store/types/macroState";
import CustomDateTimePicker from "../../../components/FormComponents/CustomDateTimePicker/CustomDateTimePicker";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import useModal from "../../../hooks/useModal";
import { CreateMacrosDataBody } from "../../../services/macroService";
import { MACRO_ORIGIN_OPTIONS, MACRO_REASON_OPTIONS } from "../../../utils/constants/constants";
import Tippy from "@tippyjs/react";
import { Trash } from "iconsax-react";
import { Colors } from "../../../utils/constants/style";

const initialEvent = {
  journeyEvent: {
    value: "",
    label: "",
  },
  startedAt: "",
  city: "",
  state: "",
};

const CreateMacroModal: React.FC<{}> = () => {
  const createMacros = useStore((state) => state.macro.createMacros);
  const isCreatingMacro = useStore((state) => state.macro.isCreatingMacro);
  const getCollaboratorOptions = useStore(
    (state) => state.collaborator.getCollaboratorOptions
  );
  const getFleetOptions = useStore((state) => state.fleet.getFleetOptions);
  const getJourneyEventOptions = useStore(
    (state) => state.macro.getJourneyEventOptions
  );
  const { closeModal } = useModal();

  const getCollaboratorOptionsCallback = async (inputValue: string) => {
    const options = (await getCollaboratorOptions(inputValue)).map(
      (c: Collaborator) => {
        return { label: c.name, value: c._id };
      }
    );
    return options;
  };
  const getFleetOptionsCallback = async (inputValue: string) => {
    const options = (await getFleetOptions(inputValue)).map((c: Fleet) => {
      return { label: c.tag, value: c._id };
    });
    return options;
  };

  const getJourneyEventOptionsCallback = async (inputValue: string) => {
    const options = (await getJourneyEventOptions(inputValue)).map(
      (c: JourneyEvent) => {
        return { label: c.name, value: c.type };
      }
    );
    return options;
  };

  return (
    <Modal
      title="Nova macro"
      icon={<PlusIcon width={30} height={30} />}
      className="w-[800px]"
    >
      <Formik
        initialValues={{
          user: {
            value: "",
            label: "",
          },
          vehicle: {
            value: "",
            label: "",
          },
          macroOrigin: "",
          reason: "",
          description: "",
          events: [initialEvent],
        }}
        validationSchema={Yup.object().shape({
          user: Yup.object().shape({
            value: Yup.string().required("Campo obrigatório"),
            label: Yup.string().required("Campo obrigatório"),
          }),
          vehicle: Yup.object().shape({
            value: Yup.string().required("Campo obrigatório"),
            label: Yup.string().required("Campo obrigatório"),
          }),
          macroOrigin: Yup.string().required("Campo obrigatório"),
          reason: Yup.string().required("Campo obrigatório"),
          description: Yup.string().required("Campo obrigatório"),
          events: Yup.array(
            Yup.object({
              journeyEvent: Yup.object().shape({
                value: Yup.string().required("Campo obrigatório"),
                label: Yup.string().required("Campo obrigatório"),
              }),
              startedAt: Yup.string().required("Campo obrigatório"),
            })
          ),
        })}
        onSubmit={(values) => {
          let createMacrosData: CreateMacrosDataBody[] = [];
          let primaryData = {
            user: values.user.value,
            vehicle: values.vehicle.value,
            macro_origin: values.macroOrigin,
            reason: {
              type: values.reason,
              description: values.description,
            },
          };
          for (let event of values.events) {
            createMacrosData.push({
              ...primaryData,
              journey_event: event.journeyEvent.value,
              started_at: event.startedAt,
              location: {
                city: event.city,
                state: event.state,
              },
            });
          }
          createMacros(createMacrosData);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className="flex flex-col gap-8 pt-5">
                <div className="flex gap-5">
                  <div className="flex-1">
                    <Field
                      name="user"
                      component={AsyncOutlinedSelect}
                      callback={getCollaboratorOptionsCallback}
                      value={{
                        value: formik.values.user.value,
                        label: formik.values.user.label,
                      }}
                      onChange={(option: any) =>
                        formik.setFieldValue("user", option)
                      }
                      menuPlacement="top"
                      placeholder="Motorista"
                    />
                  </div>
                  <div className="flex-1">
                    <Field
                      name="vehicle"
                      component={AsyncOutlinedSelect}
                      callback={getFleetOptionsCallback}
                      value={{
                        value: formik.values.vehicle.value,
                        label: formik.values.vehicle.label,
                      }}
                      onChange={(option: any) =>
                        formik.setFieldValue("vehicle", option)
                      }
                      menuPlacement="top"
                      placeholder="Veículo"
                    />
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="flex-1">
                    <Field
                      name="macroOrigin"
                      component={OutlinedSelect}
                      placeholder="Origem"
                      options={MACRO_ORIGIN_OPTIONS}
                    />
                  </div>
                  <div className="flex-1">
                    <Field
                      name="reason"
                      component={OutlinedSelect}
                      placeholder="Motivo"
                      options={MACRO_REASON_OPTIONS}
                    />
                  </div>
                </div>
                <Field
                  placeholder="Observações"
                  name="description"
                  component={OutlinedInput}
                />

                <div className="text-dark-blue font-semibold">
                  Eventos e Horários
                </div>
                <div className="bg-light-orange text-orange rounded-[8px] px-2 py-1 text-center text-sm">
                  As macros inseridas passam por um sistema de validação de
                  consistência e podem levar algum tempo para aparecer no
                  sistema.
                </div>

                <FieldArray name="events">
                  {({ push, remove }: any) => (
                    <div>
                      {formik.values.events.map((_, index) => (
                        <div className="flex items-center gap-5">
                          <div className="bg-gray_0 py-5 px-6 mb-5 flex-1 rounded-[12px]">
                            <div className="flex gap-5 pb-8">
                              <div className="flex-1">
                                <Field
                                  name={`events[${index}].journeyEvent`}
                                  component={AsyncOutlinedSelect}
                                  callback={getJourneyEventOptionsCallback}
                                  value={{
                                    value:
                                      formik.values.events[index].journeyEvent
                                        .value,
                                    label:
                                      formik.values.events[index].journeyEvent
                                        .label,
                                  }}
                                  onChange={(option: any) =>
                                    formik.setFieldValue(
                                      `events[${index}].journeyEvent`,
                                      option
                                    )
                                  }
                                  menuPlacement="top"
                                  placeholder="Evento"
                                />
                              </div>
                              <div className="flex-1">
                                <Field
                                  placeholder="Data/Hora"
                                  name={`events[${index}].startedAt`}
                                  className="bg-gray_0"
                                  component={CustomDateTimePicker}
                                />
                              </div>
                            </div>
                            <div className="flex gap-5">
                              <div className="flex-1">
                                <Field
                                  placeholder="Cidade"
                                  name={`events[${index}].city`}
                                  className="bg-gray_0"
                                  labelClassName="w-full"
                                  component={OutlinedInput}
                                />
                              </div>
                              <div className="flex-1">
                                <Field
                                  placeholder="Estado"
                                  name={`events[${index}].state`}
                                  className="bg-gray_0"
                                  labelClassName="w-full"
                                  component={OutlinedInput}
                                />
                              </div>
                            </div>
                          </div>
                          <Tippy content="Remover" className="px-2 py-1 bg-gray_3 text-sm rounded-md text-white font-medium">
                            <button
                              type="button"
                              style={{
                                display:
                                  formik.values.events.length > 1
                                    ? "block"
                                    : "none",
                              }}
                              className="border-gray_08 bg-gray_08 border rounded-full p-2"
                              onClick={() => remove(index)}
                            >
                                <Trash size="24" color={Colors.gray_3} variant="Bulk" />
                            </button>
                          </Tippy>
                        </div>
                      ))}
                      <div className="flex justify-center">
                        <button
                          className="underline underline-offset-4 text-red "
                          onClick={() => push(initialEvent)}
                        >
                          Novo registro
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <div className="flex gap-5 justify-end">
                  <div>
                    <OutlinedButton
                      className="w-[200px]"
                      onClick={() => closeModal()}
                    >
                      Cancelar
                    </OutlinedButton>
                  </div>
                  <div>
                    <FilledButton
                      loading={isCreatingMacro}
                      className="w-[200px]"
                      type="submit"
                    >
                      <span>
                        Inserir {String(formik.values.events.length)} macro
                        {formik.values.events.length > 1 ? "s" : ""}
                      </span>
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateMacroModal;
