import { EditIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import { Colors } from "../../../utils/constants/style";
import useModal from "../../../hooks/useModal";
import DeleteFleetModal from "./DeleteFleetModal";
import { useEffect } from "react";
import { useStore } from "../../../store/useStore";
import { tagMask } from "../../../utils/constants/masks";
import OutlinedMaskedInput from "../../../components/FormComponents/OutlinedMaskedInput/OutlinedMaskedInput";
import { PERMISSIONS } from "../../../utils/constants/permissions";

interface EditFleetModalProps {
  onClose: () => void;
}

const EditFleetModal: React.FC<EditFleetModalProps> = ({ onClose }) => {
  const { openModal } = useModal();
  const selectedFleet = useStore((state) => state.fleet.selectedFleet);
  const updateFleet = useStore((state) => state.fleet.updateFleet);
  const isUpdatingFleet = useStore((state) => state.fleet.isUpdatingFleet);
  const isLoadingFleet = useStore((state) => state.fleet.isLoadingFleet);
  const hasPermissions = useStore((state) => state.admin.hasPermissions);

  const openDeleteFleetModal = () => openModal(<DeleteFleetModal />);

  return (
    <Modal
      title="Editar veículo"
      icon={<EditIcon width={30} height={30} color={Colors.white} />}
      className="w-[447px]"
      onClose={onClose}
    >
      <Formik
        initialValues={{
          tag: "",
          fleet: "",
        }}
        validationSchema={Yup.object().shape({
          tag: Yup.string()
            .length(7, "A placa deve conter exatamente 7 caracteres")
            .required("Campo obrigatório"),
          fleet: Yup.string().required("Campo obrigatório"),
        })}
        onSubmit={(values) => {
          if (selectedFleet?._id) updateFleet(selectedFleet._id, values);
        }}
      >
        {(formik) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (selectedFleet) {
              const selectedCollaboratorCopy = {
                ...selectedFleet,
                fleet: String(selectedFleet.fleet),
              };
              delete selectedCollaboratorCopy._id;
              delete selectedCollaboratorCopy.deleted_at;
              delete selectedCollaboratorCopy.updated_at;
              delete selectedCollaboratorCopy.created_at;
              delete selectedCollaboratorCopy.company;

              formik.setValues(selectedCollaboratorCopy);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [selectedFleet]);

          return (
            <Form>
              <div className="flex flex-col gap-8 pt-5">
                <Field
                  loading={isLoadingFleet}
                  placeholder="Placa"
                  name="tag"
                  mask={tagMask}
                  component={OutlinedMaskedInput}
                />
                <Field
                  loading={isLoadingFleet}
                  placeholder="Frota"
                  name="fleet"
                  mask="9999999999"
                  component={OutlinedMaskedInput}
                />
                <div className="flex gap-5">
                  {hasPermissions([PERMISSIONS.DELETE_USER]) ? (
                    <div className="flex-1">
                      <OutlinedButton
                        onClick={openDeleteFleetModal}
                        className="w-full"
                      >
                        Excluir
                      </OutlinedButton>
                    </div>
                  ) : null}
                  <div className="flex-1">
                    <FilledButton
                      loading={isUpdatingFleet}
                      className="w-full"
                      type="submit"
                    >
                      Editar
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditFleetModal;
