import { StateCreator } from "zustand";
import { produce } from "immer";
import { 
  ChangeCompanyModalFilter, 
  ChangeCompanyModalState
} from "../types/company";
import companyService from "../../services/companyService";
import { toast } from "react-toastify";

const initialCompanyListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 999,
  offset: 0,
  filters: {
    name_cnpj: "",
  }
};

export const createChangeCompanyModalSlice: StateCreator<
  ChangeCompanyModalState
> = (set, get) => ({
  changeCompanyModal: {
    companyList: {
      ...initialCompanyListData,
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.changeCompanyModal.companyList.limitPerPage = limitPerPage;
            state.changeCompanyModal.companyList.offset = 0;
            state.changeCompanyModal.companyList.currentPage = 1;
          })
        );
        get().changeCompanyModal.getCompanies();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.changeCompanyModal.companyList.currentPage = page;
            state.changeCompanyModal.companyList.offset = page - 1;
          })
        );
        get().changeCompanyModal.getCompanies();
      },
      setFilters: (filters: ChangeCompanyModalFilter) => {
        set(
          produce((state) => {
            state.changeCompanyModal.companyList.filters = filters;
            state.changeCompanyModal.companyList.offset = 0;
            state.changeCompanyModal.companyList.currentPage = 1;
          })
        );
        get().changeCompanyModal.getCompanies();
      },
    },
    currentCompany: null,
    currentSection: null,
    isLoadingCompanyList: false,
    isChangingCurrentCompany: false,
    isChangingCurrentSection: false,
    getCompanies: () => {
      set(
        produce((state) => {
          state.changeCompanyModal.isLoadingCompanyList = true;
        })
      );

      const filters = get().changeCompanyModal.companyList.filters;
      const limit = get().changeCompanyModal.companyList.limitPerPage;
      const offset = get().changeCompanyModal.companyList.offset;

      companyService
        .getCompanies({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.changeCompanyModal.companyList.data = data;
              state.changeCompanyModal.companyList.totalItems = totalItems;
              state.changeCompanyModal.companyList.totalPages = Math.ceil(
                totalItems / state.changeCompanyModal.companyList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.changeCompanyModal.isLoadingCompanyList = false;
            })
          );
        });
    },
    changeCurrentCompany: (companyId, reloadNavigate) => {
      set(
        produce((state) => {
          state.changeCompanyModal.isChangingCurrentCompany = true;
        })
      );

      companyService
        .changeCompany(companyId)
        .then((token) => {
          if (localStorage.getItem("token")) {
            localStorage.setItem("token", token);
          } else if (sessionStorage.getItem("token")) {
            sessionStorage.setItem("token", token);
          } else {
            throw Error("Erro");
          }
          sessionStorage.removeItem("section_id");  
          sessionStorage.removeItem("section_name");  
          reloadNavigate();
          window.location.reload();
        })
        .catch((error) => {
          toast.error(error.message);
          set(
            produce((state) => {
              state.changeCompanyModal.isChangingCurrentCompany = false;
            })
          );
        })
    },
    changeCurrentSection: (section, reloadNavigate) => {
      if(section) {
        set(
          produce((state) => {
            state.changeCompanyModal.isChangingCurrentSection = true;
          })
        );
        sessionStorage.setItem("section_id", section?._id ?? "");
        sessionStorage.setItem("section_name", section?.name ?? "");
        reloadNavigate();
        window.location.reload();
      } else {
        sessionStorage.removeItem("section_id");
        sessionStorage.removeItem("section_name");
        reloadNavigate();
        window.location.reload();
      }
    },
  },
});
