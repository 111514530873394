import { RoutesComponent } from "./routes/RoutesComponent";
import { ToastContainer } from "react-toastify";
import { SkeletonTheme } from "react-loading-skeleton";
import useModal from "./hooks/useModal";
import { Colors } from "./utils/constants/style";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import { registerLocale } from "react-datepicker";
import { APIProvider } from "@vis.gl/react-google-maps";
registerLocale("pt-BR", ptBR);

const App: React.FC<{}> = () => {
  const { isModalOpen, modalContent } = useModal();

  return (
    <APIProvider
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API as string}
      libraries={["marker"]}
    >
      <div className="App text-black text-base">
        <SkeletonTheme baseColor={Colors.gray_1} highlightColor="#E9EAED">
          <RoutesComponent />
          <ToastContainer />
          {isModalOpen && modalContent}
        </SkeletonTheme>
      </div>
    </APIProvider>
  );
};

export default App;
