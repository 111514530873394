import Checkbox from "../../../components/FormComponents/Checkbox/Checkbox";
import { PARAMETER_MEASURE, Parameter } from "../../../store/types/journeyRule";

enum WEEK_DAY_SHORT_NAME {
  MONDAY = "Seg.",
  TUESDAY = "Ter.",
  WEDNESDAY = "Qua.",
  THURSDAY = "Qui.",
  FRIDAY = "Sex.",
  SATURDAY = "Sáb.",
  SUNDAY = "Dom.",
}

const ParameterValueComponent: React.FC<{ parameter: Parameter | null }> = ({
  parameter,
}) => {
  if (!parameter) return null;

  let content: JSX.Element | JSX.Element[] | null = null;

  if (parameter.measure === PARAMETER_MEASURE.MULTI_SELECT) {
    content = Object.keys(parameter.default)
      .filter((key: any) => parameter.default[key].value)
      .map((key: string | WEEK_DAY_SHORT_NAME) => (
        <span
          key={key}
          className="bg-gray_0 p-1 rounded-[7px] mr-2 inline-block my-1"
        >
          {WEEK_DAY_SHORT_NAME[key as keyof typeof WEEK_DAY_SHORT_NAME]
            ? WEEK_DAY_SHORT_NAME[key as keyof typeof WEEK_DAY_SHORT_NAME]
            : parameter.default[key]?.name
            ? parameter.default[key]?.name
            : key}
        </span>
      ));
  } else if (parameter.measure === PARAMETER_MEASURE.CHECK) {
    content = (
      <div>
        <Checkbox text="" checked={parameter.default.value} />
      </div>
    );
  } else if (parameter.measure === PARAMETER_MEASURE.TIME)
    content = (
      <div className="flex justify-center items-center">
        <div className="bg-gray_0 p-1 rounded-[7px] mr-2 w-fit ">
          {parameter.default.value}
        </div>
      </div>
    );
  else if (parameter.measure === PARAMETER_MEASURE.PERCENT)
    content = (
      <div className="flex justify-center items-center">
        <div className="bg-gray_0 py-1 px-2 rounded-[7px] mr-2 w-fit ">
          {parameter.default.value}
        </div>
        %
      </div>
    );
  else if (parameter.measure === PARAMETER_MEASURE.DAY_TIME_INTERVAL)
    content = (
      <div className="mx-auto w-fit">
        <div className="mb-2">
          <span className="text-gray_3 font-light text-left">Início:</span>{" "}
          <span className="bg-gray_0 py-1 px-2 rounded-[7px] mr-2 text-right">
            {parameter.default.value[0]}
          </span>
        </div>
        <div>
          <span className="text-gray_3 font-light text-left">Final:</span>{" "}
          <span className="bg-gray_0 py-1 px-2 rounded-[7px] mr-2 text-right">
            {parameter.default.value[1]}
          </span>
        </div>
      </div>
    );
  else if (parameter.measure === PARAMETER_MEASURE.TIME_BY_WEEK_DAY)
    content = (
      <div className="flex justify-center">
        <div className="px-2 py-1 text-gray_3 font-light text-left">
          {Object.keys(parameter.default).map((key: any) => (
            <div>{WEEK_DAY_SHORT_NAME[key as keyof typeof WEEK_DAY_SHORT_NAME]}</div>
          ))}
        </div>
        <div className="px-2 py-1 bg-gray_0 rounded-[7px]">
          {Object.values(parameter.default).map((p: any) => (
            <div>{p.value}</div>
          ))}
        </div>
      </div>
    );
  else if (parameter.measure === PARAMETER_MEASURE.SELECT)
    content = (
      <div className="mx-auto w-fit">
        {Object.keys(parameter.default)
          .filter((key: any) => parameter.default[key].value)
          .map((key: any) => (
            <div
              key={key}
              className="flex gap-2 items-start bg-gray_0 rounded-[7px] px-2 py-1"
            >
              <Checkbox
                text=""
                checked={parameter.default[key].value}
                className="w-[14px] h-[14px] mt-[3px]"
              />
              <div className="text-left">{parameter.default[key].name}</div>
            </div>
          ))}
      </div>
    );
  else if (parameter.measure === PARAMETER_MEASURE.TEXT)
    content = (
      <div className="flex justify-center items-center">
        <div className="bg-gray_0 p-1 rounded-[7px] mr-2 w-fit ">
          {parameter.default.value}
        </div>
      </div>
    );
  return <div>{content}</div>;
};

export default ParameterValueComponent;
