import { PlusIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import { useStore } from "../../../store/useStore";

const initialValues = {
  integration: "",
  integrationUser: "",
};

const validationSchema = {
  integration: Yup.string().required("Campo obrigatório"),
  integrationUser: Yup.string().required("Campo obrigatório"),
};

const CreateCollaboratorIntegrationModal: React.FC<{}> = () => {
  const createCollaboratorIntegration = useStore(
    (state) => state.collaboratorIntegration.createCollaboratorIntegration
  );
  const isCreatingCollaboratorIntegration = useStore(
    (state) => state.collaboratorIntegration.isCreatingCollaboratorIntegration
  );
  const isLoadingIntegrationsList = useStore(
    (state) => state.collaboratorIntegration.isLoadingIntegrationsList
  );
  const integrations = useStore(
    (state) => state.collaboratorIntegration.integrationsList.data
  );
  const selectedCollaborator = useStore(
    (state) => state.collaborator.selectedCollaborator
  );

  return (
    <Modal
      title="Cadastro de integração"
      icon={<PlusIcon width={30} height={30} />}
      className="w-[447px]"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          if(!selectedCollaborator?._id) return;
          createCollaboratorIntegration(
            selectedCollaborator._id,
            values.integration,
            values.integrationUser,
          );
        }}
      >
        <Form>
          <div className="flex flex-col gap-8 pt-5">
            <div className="flex gap-5">
              <div className="flex-1">
                <Field
                  name="integration"
                  component={OutlinedSelect}
                  placeholder="Integração"
                  options={integrations}
                  loading={isLoadingIntegrationsList}
                />
              </div>
              <div className="flex-1">
                <Field
                  name="integrationUser"
                  component={OutlinedInput}
                  placeholder="ID da Integração"
                />
              </div>
            </div>
            <div className="flex gap-5">
              <div className="flex-1"></div>
              <div className="flex-1">
                <FilledButton
                  loading={isCreatingCollaboratorIntegration}
                  className="w-full"
                  type="submit"
                >
                  Cadastrar
                </FilledButton>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default CreateCollaboratorIntegrationModal;
