import { SearchNormal1 } from "iconsax-react";
import InfoField from "../../../components/InfoField/InfoField";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import Modal from "../../../components/Modal/Modal";
import { useStore } from "../../../store/useStore";
import { Colors } from "../../../utils/constants/style";
import { formatDateTime } from "../../../utils/functions/formatters";

interface DetailsSectionModalProps {
  onClose: () => void;
}

const DetailsSectionModal: React.FC<DetailsSectionModalProps> = ({
  onClose,
}) => {
  const selectedSection = useStore((state) => state.conventions.selectedSection);
  const isLoadingSection = useStore((state) => state.conventions.isLoadingSection);

  return (
    <Modal
      title="Detalhes de seção"
      icon={<SearchNormal1 size="30" color={Colors.white} variant="Linear" />}
      className="min-w-[479px] max-w-[579px]"
      onClose={onClose}
    >
      <div className="flex flex-col gap-5">
        <InfoFieldBox>
          <InfoField
            loading={isLoadingSection}
            label="Nome"
            content={selectedSection?.name}
          />
          <InfoField
            loading={isLoadingSection}
            label="Descrição"
            content={selectedSection?.description}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingSection}
            label="Criado em"
            content={formatDateTime(selectedSection?.created_at ?? "")}
          />
        </InfoFieldBox>
      </div>
    </Modal>
  );
};

export default DetailsSectionModal;
