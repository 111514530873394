import Skeleton from "react-loading-skeleton";
import { NoLogoIcon } from "../../assets/imgs";

interface GenericCompanyImageProps {
  width?: number | string;
  height?: number | string;
  className?: string;
  logo?: string;
  loading?: boolean;
}

const GenericCompanyImage: React.FC<GenericCompanyImageProps> = ({
  width = "30px",
  height = "30px",
  className = "",
  logo = "",
  loading = false,
}) => {
  return loading ? (
    <div className="rounded-full">
      <Skeleton
        width={width}
        height={height}
        borderRadius={"100%"}
        containerClassName="mt-[-3px]"
      />
    </div>
  ) : (
    <div
      style={{ width, height }}
      className={`rounded-full overflow-hidden bg-white flex justify-center items-center ${className}`}
    >
      {logo ? (
        <img className="object-cover" src={logo}  alt="Logo da empresa" />
      ) : (
        <img
          src={NoLogoIcon}
          width={"90%"}
          height={"90%"}
          className="mt-[10%]"
          alt="Empresa sem logo"
        />
      )}
    </div>
  );
};

export default GenericCompanyImage;
