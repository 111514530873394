import StatusText from "../../../components/StatusText/StatusText";
import FilledButton from "../../../components/FilledButton/FilledButton";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import InfoField from "../../../components/InfoField/InfoField";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import {
  formatAddress,
  formatDateTime,
  formatDuration,
} from "../../../utils/functions/formatters";
import StepsProgress from "../../../components/Steps/StepsProgress";
import { useStore } from "../../../store/useStore";
import { toast } from "react-toastify";

export interface Step2Props {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  totalSteps: number;
  show: boolean;
}

const Step2: React.FC<Step2Props> = ({
  currentStep,
  setCurrentStep,
  totalSteps,
  show,
}) => {
  const macroDataToUpdate = useStore((state) => state.macro.macroDataToUpdate);
  const selectedMacro = useStore((state) => state.macro.selectedMacro);
  const checkMacro = useStore((state) => state.macro.checkMacro);
  const isCheckingMacro = useStore((state) => state.macro.isCheckingMacro);

  const nextStep = () => {
    if (!selectedMacro?._id) return;

    checkMacro().then(() => {
      setCurrentStep(3);
    }).catch((error) => {
      toast.error(error.message)
    });
  };

  return (
    <div className={`${!show ? "hidden" : ""}`}>
      <div className="flex justify-center pb-5">
        <StepsProgress currentStep={currentStep} totalSteps={totalSteps} />
      </div>
      <div className="pb-5 flex justify-center">
        <StatusText
          status="warning"
          text="A alteração só terá efeito após a aprovação do motorista"
        />
      </div>
      <h1 className="font-bold text-dark-blue pb-5">Alteração Proposta</h1>
      <div className="flex flex-col gap-5">
        <InfoFieldBox>
          <InfoField
            content={macroDataToUpdate?.vehicle.label}
            hasChanged={
              macroDataToUpdate?.vehicle.value !== selectedMacro?.vehicle_id
            }
            label="Placa"
          />
          <InfoField
            content={formatAddress(selectedMacro?.location)}
            label="Local"
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            content={macroDataToUpdate?.journey_event.label}
            hasChanged={
              macroDataToUpdate?.journey_event.value !==
              selectedMacro?.journey_event_type
            }
            label="Evento"
          />
          <InfoField
            content={macroDataToUpdate?.user.label}
            hasChanged={
              macroDataToUpdate?.user.value !== selectedMacro?.user_id
            }
            label="Motorista"
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            content={formatDateTime(macroDataToUpdate?.started_at)}
            hasChanged={
              macroDataToUpdate?.started_at !== selectedMacro?.started_at
            }
            label="Inicio"
          />
        </InfoFieldBox>

        <div className="flex gap-5">
          <div className="flex-1">
            <OutlinedButton
              onClick={() => setCurrentStep(1)}
              className="w-full"
            >
              Voltar
            </OutlinedButton>
          </div>
          <div className="flex-1">
            <FilledButton
              loading={isCheckingMacro}
              onClick={nextStep}
              className="w-full"
            >
              Próximo passo
            </FilledButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
