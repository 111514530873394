import DataTable from "../../../components/DataTable/DataTable";
import StatusText from "../../../components/StatusText/StatusText";
import useModal from "../../../hooks/useModal";
import { PayRoll, PayRollGroupSatus } from "../../../store/types/timeManager";
import { useStore } from "../../../store/useStore";
import { PayrollGroupStatusColor } from "../../../utils/constants/constants";
import { formatDateTime } from "../../../utils/functions/formatters";
import DeletePayRollModal from "./DeletePayRollModal";
import DetailsPayRollModal from "./DetailsPayRollModal";
import EditPayRollModal from "./EditPayRollModal";

const PayRollsTable = () => {
  const payRolls = useStore((state) => state.timeManager.payRollsList.data);
  const setFilters = useStore(
    (state) => state.timeManager.payRollsList.setFilters
  );
  const filters = useStore((state) => state.timeManager.payRollsList.filters);
  const isLoadingPayRollsList = useStore(
    (state) => state.timeManager.isLoadingPayRollsList
  );
  const resetSelectedPayRoll = useStore(
    (state) => state.timeManager.resetSelectedPayRoll
  );
  const setCollaboratorsFilters = useStore(
    (state) => state.collaborator.collaboratorsList.setFilters
  );
  const setCollaboratorsLimitPerPage = useStore(
    (state) => state.collaborator.collaboratorsList.setLimitPerPage
  );
  const selectedPayRoll = useStore(
    (state) => state.timeManager.selectedPayRoll
  );
  const getPayRoll = useStore((state) => state.timeManager.getPayRoll);
  const { openModal } = useModal();

  const openDetailsPayRollModal = (payRollId: string) => {
    getPayRoll(payRollId);
    setCollaboratorsFilters({ 
      payroll_group: payRollId,
      sort_key: "name",
      sort_value: "asc",
      name: "",
      registration: "",
      phone: "",
      email: "",
      position: "",
      cpf: "",
      rg: "",
      cnh: ""
    });
    setCollaboratorsLimitPerPage(999);
    openModal(<DetailsPayRollModal onClose={resetSelectedPayRoll} />);
  };

  const openEditPayRollModal = (payRollId: string) => {
    getPayRoll(payRollId);
    openModal(<EditPayRollModal onClose={resetSelectedPayRoll} />);
  };

  const openDeletePayRollModal = (payRollId: string) => {
    getPayRoll(payRollId);
    openModal(<DeletePayRollModal onClose={resetSelectedPayRoll} />);
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "name", label: "Nome", sortKey: "name" },
    { key: "batch", label: "Lote" },
    {
      key: "competence_start",
      label: "Início Competência",
      render: (item: PayRoll) => formatDateTime(item.competence_start),
    },
    {
      key: "competence_end",
      label: "Fim Competência",
      render: (item: PayRoll) => formatDateTime(item.competence_end),
    },
    {
      key: "created_at",
      label: "Criado em",
      render: (item: PayRoll) => formatDateTime(item.created_at),
    },
    {
      key: "payroll_total",
      label: "Quantidade de Folhas",
      render: (item: PayRoll) => item.payroll_total
    },
    {
      key: "status",
      label: "Status",
      render: (item: PayRoll) => (
        (item.status?.type && item.status?.name)  ? 
          <div className="flex justify-center">
            <StatusText
              status={PayrollGroupStatusColor[item.status.type as PayRollGroupSatus]}
              text={item.status.name ?? '-'}
            />
          </div>
        : '-'
      ),
    }
  ];

  return (
    <DataTable
      data={payRolls}
      columns={columns}
      onDetails={openDetailsPayRollModal}
      onEdit={openEditPayRollModal}
      showEditByItem={(item: PayRoll) => item.status?.type === PayRollGroupSatus.OPEN || item.status?.type === PayRollGroupSatus.ERROR }
      onOrder={handleTableOrder}
      onDelete={openDeletePayRollModal}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingPayRollsList}
    />
  );
};

export default PayRollsTable;
