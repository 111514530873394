import { userAPI } from "../api/api";
import {
  Collaborator,
  CollaboratorFilter,
  CollaboratorsVisibleColumns,
} from "../store/types/collaborator";
import { handleServiceError } from "./handleServiceError";
import { removeEmptyProperties } from "../utils/functions/functions";

const collaboratorService = {
  getCollaborators: async ({
    filters,
    limit,
    offset,
  }: {
    filters: CollaboratorFilter | {name: string};
    limit: number;
    offset: number;
  }) => {
    try {
      const response = await userAPI.get("/v1/user", {
        params: { ...removeEmptyProperties(filters), limit, offset },
      });
      const data: Collaborator[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getCollaborator: async (id: string) => {
    try {
      const response = await userAPI.get(`/v1/user/${id}`);
      const data: Collaborator = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  createCollaborator: async (collaborator: Collaborator) => {
    try {
      await userAPI.post("/v1/user", collaborator);
    } catch (error) {
      handleServiceError(error);
    }
  },
  updateCollaborator: async (
    id: string,
    collaborator: Partial<Collaborator>
  ) => {
    try {
      const response = await userAPI.put(`/v1/user/${id}`, collaborator);
      const data: Collaborator = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  updateCollaboratorPassword: async (
    id: string,
    password: string
  ) => {
    try {
      const response = await userAPI.patch(`/v1/user/${id}/password`, { password });
      const data: Collaborator = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  deleteCollaborator: async (id: string) => {
    try {
      await userAPI.delete(`/v1/user/${id}`);
    } catch (error) {
      handleServiceError(error);
    }
  },
  exportCollaborator: async (
    format: "csv" | "pdf",
    columns: (keyof CollaboratorsVisibleColumns)[]
  ) => {
    try {
      const response = await userAPI.post("/v1/user/report", {
        format,
        columns,
      });
      return response.data.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  updatePassword: async (
    id: string,
    passwords: { currentPassword: string; newPassword: string }
  ) => {
    try {
      await userAPI.patch("/v1/user/password", {
        current_password: passwords.currentPassword,
        new_password: passwords.newPassword,
      });
    } catch (error) {
      handleServiceError(error);
    }
  },
};

export default collaboratorService;
