import { EditIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import { Colors } from "../../../utils/constants/style";
import useModal from "../../../hooks/useModal";
import DeleteCollaboratorModal from "./DeleteCollaboratorModal";
import { useEffect, useState } from "react";
import { useStore } from "../../../store/useStore";
import {
  PERMISSIONS,
  POSITION_OPTIONS,
} from "../../../utils/constants/permissions";
import OutlinedMaskedInput from "../../../components/FormComponents/OutlinedMaskedInput/OutlinedMaskedInput";
import {
  formatCPF,
  formatPhoneNumber,
  removeNumberMask,
} from "../../../utils/functions/formatters";
import { Collaborator } from "../../../store/types/collaborator";
import { filterObject } from "../../../utils/functions/functions";
import Tabs from "../../../components/Tabs/Tabs";
import Tab from "../../../components/Tabs/Components/Tab";
import TabPanel from "../../../components/Tabs/Components/TabPanel";
import { CollaboratorIntegration } from "../../../store/types/collaboratorIntegrations";
import MacroOrigin from "../../../components/MacroOrigin/MacroOrigin";
import DataTable from "../../../components/DataTable/DataTable";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import CreateCollaboratorIntegrationModal from "./CreateCollaboratorIntegrationModal";
import DeleteCollaboratorIntegrationModal from "./DeleteCollaboratorIntegrationModal";
import CustomDatePicker from "../../../components/FormComponents/CustomDatePicker/CustomDatePicker";
import { AddCircle, Global, Lock, User } from "iconsax-react";

interface EditCollaboratorModalProps {
  onClose?: () => void;
}

const initialValuesTab1 = {
  name: "",
  email: "",
  phone: "",
  registration: "",
  position: {
    type: "",
  },
  cpf: "",
  rg: "",
  cnh: "",
};

const initialValuesTab2 = {
  password: "",
  passwordConfirmation: "",
};

const validationSchemaTab1 = {
  name: Yup.string().required("Campo obrigatório"),
  email: Yup.string().email("Formato de e-mail inválido"),
  phone: Yup.string()
    .length(19, "O telefone deve conter exatamente 13 números"),
  registration: Yup.string(),
  position: Yup.object().shape({
    type: Yup.string().required("Campo obrigatório"),
  }),
  cpf: Yup.string()
    .matches(
      /\d{2}\.\d{3}\.\d{3}-\d{2}$/,
      "O CPF deve conter exatamente 11 números"
    )
    .required("Campo obrigatório"),
  rg: Yup.string().max(13, "O RG deve conter exatamente no máximo números"),
  cnh: Yup.string().max(12, "A CNH deve conter no máximo 12 números"),
};

const validationSchemaTab2 = {
  password: Yup.string().required('Campo obrigatório'),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password')], 'As senhas devem combinar').required("Campo obrigatório")
};

const columns = [
  {
    key: "origin",
    label: "Origem",
    render: (item: CollaboratorIntegration) => item?.integration?.type ? <MacroOrigin origin={item?.integration?.type} /> : '-', 
  },
  { key: "integration_user", label: "Identificador" },
];


const EditCollaboratorModal: React.FC<EditCollaboratorModalProps> = ({
  onClose,
}) => {
  const { openModal } = useModal();
  const selectedCollaborator = useStore(
    (state) => state.collaborator.selectedCollaborator
  );
  const setSelectedCollaboratorIntegration = useStore(
    (state) => state.collaboratorIntegration.setSelectedCollaboratorIntegration
  );
  const updateCollaborator = useStore(
    (state) => state.collaborator.updateCollaborator
  );
  const updateCollaboratorPassword = useStore(
    (state) => state.collaborator.updateCollaboratorPassword
  );
  const isUpdatingCollaborator = useStore(
    (state) => state.collaborator.isUpdatingCollaborator
  );
  const isLoadingCollaborator = useStore(
    (state) => state.collaborator.isLoadingCollaborator
  );
  const userPropertiesProjection = useStore(
    (state) => state.admin.data.projection.user
  );
  const collaboratorIntegrations = useStore(
    (state) => state.collaboratorIntegration.collaboratorIntegrationsList.data
  );
  const isLoadingCollaboratorIntegrationsList = useStore(
    (state) => state.collaboratorIntegration.isLoadingCollaboratorIntegrationsList
  );
  const getIntegrations = useStore(
    (state) => state.collaboratorIntegration.getIntegrations
  );
  const hasPermissions = useStore((state) => state.admin.hasPermissions);
  const adminPositionType = useStore((state) => state.admin.data.position.type);
  const initialValuesFiltered = filterObject(
    initialValuesTab1,
    userPropertiesProjection
  );
  const validationSchemaFiltered = filterObject(
    validationSchemaTab1,
    userPropertiesProjection
  );

  const [tab, setTab] = useState<number>(0);

  const openDeleteCollaboratorModal = () =>
    openModal(<DeleteCollaboratorModal />);

  return (
    <Modal
      title={"Editar colaborador"}
      icon={<EditIcon width={30} height={30} color={Colors.white} />}
      className="w-[650px] min-h-[450px]"
      onClose={onClose}
    >
      <Tabs tab={tab} setTab={setTab}>
        <Tab label="Dados Gerais" icon={<User color="#344767" width={15} height={15} />}/>
        { hasPermissions([PERMISSIONS.UPDATE_USER_PASSWORD]) ? (<Tab label="Credenciais" icon={<Lock />}/>) : <></> }
        { hasPermissions([PERMISSIONS.LIST_INTEGRATIONS]) ? (<Tab label="Integrações" icon={<Global />}/>) : <></> }
      </Tabs>
      <div className="mt-4">
        <TabPanel tab={tab} index={0}>
          <Formik
              initialValues={initialValuesFiltered}
              validationSchema={Yup.object().shape(validationSchemaFiltered)}
              onSubmit={(values) => {
                if (selectedCollaborator?._id) {
                  const data = {
                    ...values,
                    ...(values.cpf ? { cpf: removeNumberMask(values.cpf) } : {}),
                    ...(values.phone
                      ? { phone: removeNumberMask(values.phone) }
                      : {}),
                  } as Collaborator;
      
                  updateCollaborator(selectedCollaborator._id, data);
                }
              }}
          >
            {(formik) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                if (selectedCollaborator) {
                  const selectedCollaboratorCopy = {
                    ...selectedCollaborator,
                    ...(selectedCollaborator.position
                      ? { position: { ...selectedCollaborator.position } }
                      : {}),
                    ...(selectedCollaborator.phone
                      ? { phone: formatPhoneNumber(selectedCollaborator.phone) }
                      : {}),
                    ...(selectedCollaborator.cpf
                      ? { cpf: formatCPF(selectedCollaborator.cpf) }
                      : {}),
                  };
                  delete selectedCollaboratorCopy._id;
                  delete selectedCollaboratorCopy.company;
                  delete selectedCollaboratorCopy.deleted_at;
                  delete selectedCollaboratorCopy.updated_at;
                  delete selectedCollaboratorCopy.created_at;
                  delete selectedCollaboratorCopy.position.name;
                  formik.setValues(selectedCollaboratorCopy);
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, [selectedCollaborator]);

              return (
                <Form>
                  <div className="flex flex-col gap-8 pt-5">
                    <div className="flex gap-8">
                      <div className="flex-1 flex flex-col gap-8">
                        <Field
                          show={userPropertiesProjection.includes("name")}
                          loading={isLoadingCollaborator}
                          placeholder="Nome"
                          name="name"
                          component={OutlinedInput}
                        />
                        <Field
                          show={userPropertiesProjection.includes("email")}
                          loading={isLoadingCollaborator}
                          placeholder="E-mail"
                          name="email"
                          component={OutlinedInput}
                        />
                        <Field
                          show={userPropertiesProjection.includes("phone")}
                          loading={isLoadingCollaborator}
                          placeholder="Telefone"
                          name="phone"
                          mask={"+99 (99) 99999-9999"}
                          alwaysShowMask={false}
                          component={OutlinedMaskedInput}
                        />
                        <Field
                          show={userPropertiesProjection.includes("registration")}
                          loading={isLoadingCollaborator}
                          placeholder="Matrícula"
                          name="registration"
                          component={OutlinedInput}
                        />
                        <Field
	                        show={userPropertiesProjection.includes("pis")}
                          loading={isLoadingCollaborator}
                          placeholder="PIS"
                          name="pis"
                          mask={"99999999999"}
                          alwaysShowMask={true}
                          component={OutlinedMaskedInput}
                        />
                      </div>
                      <div className="flex-1 flex flex-col gap-8">
                        <Field
                          show={userPropertiesProjection.includes("position")}
                          name="position.type"
                          component={OutlinedSelect}
                          loading={isLoadingCollaborator}
                          placeholder="Cargo"
                          options={POSITION_OPTIONS[adminPositionType]}
                        />
                        <Field
                          show={userPropertiesProjection.includes("cpf")}
                          loading={isLoadingCollaborator}
                          placeholder="CPF"
                          name="cpf"
                          component={OutlinedMaskedInput}
                          mask="999.999.999-99"
                        />
                        <Field
                          show={userPropertiesProjection.includes("rg")}
                          loading={isLoadingCollaborator}
                          placeholder="RG"
                          name="rg"
                          component={OutlinedMaskedInput}
                          mask="999999999"
                        />
                        <Field
                          show={userPropertiesProjection.includes("cnh")}
                          loading={isLoadingCollaborator}
                          placeholder="CNH"
                          name="cnh"
                          component={OutlinedMaskedInput}
                          mask="999999999999"
                        />
                        <Field
                          show={userPropertiesProjection.includes("hired_at")}
                          loading={isLoadingCollaborator}
                          component={CustomDatePicker}
                          placeholder="Data de admissão"
                          name="hired_at"
                          />
                      </div>
                    </div>
                    <div className="flex gap-5">
                      {hasPermissions([PERMISSIONS.DELETE_USER]) ? (
                        <div className="flex-1">
                          <OutlinedButton
                            onClick={openDeleteCollaboratorModal}
                            className="w-full"
                          >
                            Excluir
                          </OutlinedButton>
                        </div>
                      ) : null}

                      <div className="flex-1">
                        <FilledButton
                          loading={isUpdatingCollaborator}
                          type="submit"
                          className="w-full"
                        >
                          Editar
                        </FilledButton>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </TabPanel>
        {hasPermissions([PERMISSIONS.UPDATE_USER_PASSWORD]) ? (
          <TabPanel tab={tab} index={1}>
            <Formik
              initialValues={initialValuesTab2}
              validationSchema={Yup.object().shape(validationSchemaTab2)}
              onSubmit={(values) => {
                updateCollaboratorPassword(selectedCollaborator?._id ?? '-', values.password);
            }}
            >
              {(formik) => {
                return (
                  <Form>
                    <div className="flex flex-col gap-8 pt-5">
                      <div className="flex gap-8">
                        <div className="flex-1 flex flex-col gap-8">
                          <Field
                              placeholder="Senha"
                              name="password"
                              type="password"
                              component={OutlinedInput}
                            />
                        </div>
                        <div className="flex-1 flex flex-col gap-8">
                          <Field
                            placeholder="Confirmação de Senha"
                            name="passwordConfirmation"
                            type="password"
                            component={OutlinedInput}
                          />
                        </div>
                      </div>
                      <div className="flex gap-5">
                        <div className="flex-1"></div>
                        <div className="flex-1">
                          <FilledButton
                            loading={isUpdatingCollaborator}
                            type="submit"
                            className="w-full"
                          >
                            Atualizar Senha
                          </FilledButton>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </TabPanel>
        ) : null}
        {hasPermissions([PERMISSIONS.LIST_INTEGRATIONS]) ? (
          <TabPanel tab={tab} index={2}>
            <div>
              <div className="flex justify-end">
                <div className="my-2 pr-1 gap-4">
                  {hasPermissions([PERMISSIONS.CREATE_INTEGRATION_USER]) ?
                    <RoundedFilledButton
                      onClick={() => {
                        getIntegrations();
                        openModal(<CreateCollaboratorIntegrationModal/>);
                      }}
                      icon={<AddCircle />}
                      text="Novo"
                      show={hasPermissions([PERMISSIONS.CREATE_INTEGRATION_USER])}
                    />
                  : null}
                </div>
              </div>
              <DataTable
                data={collaboratorIntegrations}
                columns={columns}
                loading={isLoadingCollaboratorIntegrationsList}
                showDeleteByItem={() => hasPermissions([PERMISSIONS.DELETE_INTEGRATION_USER])}
                onDelete={(item: CollaboratorIntegration) => {
                  setSelectedCollaboratorIntegration(item);
                  openModal(<DeleteCollaboratorIntegrationModal/>)
                }}
                fullObjOnParameters={true}
              />
            </div>
          </TabPanel>
        ) : null}
      </div>
    </Modal>
  );
};

export default EditCollaboratorModal;
