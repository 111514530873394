import { SearchNormal1 } from "iconsax-react";
import InfoField from "../../../components/InfoField/InfoField";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import Modal from "../../../components/Modal/Modal";
import { useStore } from "../../../store/useStore";
import { Colors } from "../../../utils/constants/style";
import { formatDateTime } from "../../../utils/functions/formatters";

interface DetailsJourneyEventModalProps {
  onClose: () => void;
}

const DetailsJourneyEventModal: React.FC<DetailsJourneyEventModalProps> = ({
  onClose,
}) => {
  const selectedJourneyEvent = useStore(
    (state) => state.macro.selectedJourneyEvent
  );
  const isLoadingJourneyEvent = useStore(
    (state) => state.macro.isLoadingJourneyEvent
  );

  return (
    <Modal
      title="Detalhes de evento de jornada"
      icon={<SearchNormal1 size="30" color={Colors.white} variant="Linear" />}
      className="min-w-[479px] max-w-[579px]"
      onClose={onClose}
    >
      <div className="flex flex-col gap-5">
        <InfoFieldBox>
          <InfoField
            loading={isLoadingJourneyEvent}
            label="Nome"
            content={selectedJourneyEvent?.name}
          />
          <InfoField
            loading={isLoadingJourneyEvent}
            label="Tipo"
            content={selectedJourneyEvent?.type}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingJourneyEvent}
            label="Criado em"
            content={formatDateTime(selectedJourneyEvent?.created_at ?? "")}
          />
        </InfoFieldBox>
      </div>
    </Modal>
  );
};

export default DetailsJourneyEventModal;
