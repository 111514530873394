import { PlusIcon, RouteIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import { useStore } from "../../../store/useStore";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import { useEffect, useState } from "react";
import conventionService from "../../../services/conventionService";
import { Syndicate } from "../../../store/types/convention";
import journeyRuleService from "../../../services/journeyRuleService";
import { JourneyRule } from "../../../store/types/journeyRule";
import Tabs from "../../../components/Tabs/Tabs";
import Tab from "../../../components/Tabs/Components/Tab";
import TabPanel from "../../../components/Tabs/Components/TabPanel";

import { toast } from "react-toastify";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import useModal from "../../../hooks/useModal";
import DeleteSectionModal from "./DeleteSectionModal";
import CollaboratorsSelectTable from "./CollaboratorsSelectTable";
import HolidaysSelectTable from "./HolidaysSelectTable";
import { Collaborator } from "../../../store/types/collaborator";
import { formatCPF, removeNumberCurrencyMask } from "../../../utils/functions/formatters";
import JourneyEventsSelectTable from "./JourneyEventsSelectTable";
import OutlinedMaskedInput from "../../../components/FormComponents/OutlinedMaskedInput/OutlinedMaskedInput";
import { Calendar, User } from "iconsax-react";

const initialValues = {
  name: "",
  description: "",
  syndicate: "",
  journeyRule: "",
  users: {} as Record<string, boolean>,
  holidays: {} as Record<string, boolean>,
  journeyEvents: {} as Record<string, boolean>,
  hourValue: "",
  mealValue: "",
  overnightValue: "",
};

const EditSectionModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { openModal } = useModal();
  const updateSection = useStore((state) => state.conventions.updateSection);
  const isUpdatingSection = useStore(
    (state) => state.conventions.isUpdatingSection
  );
  const selectedSection = useStore(
    (state) => state.conventions.selectedSection
  );
  const [syndicateOptions, setSyndicateOptions] = useState<any[]>([]);
  const [journeyRuleOptions, setJourneyRuleOptions] = useState<any[]>([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const getHolidays = useStore((state) => state.conventions.getHolidays);
  const getCollaborators = useStore(
    (state) => state.collaborator.getCollaborators
  );
  const getJourneyEvents = useStore((state) => state.conventions.getJourneyEvents);
  const hasPermissions = useStore((state) => state.admin.hasPermissions);
  const resetHolidaysListState = useStore(
    (state) => state.conventions.resetHolidaysListState
  );
  const resetCollaboratorListState = useStore(
    (state) => state.collaborator.resetCollaboratorListState
  );
  const setCollaboratorsLimitPerPage = useStore(
    (state) => state.collaborator.collaboratorsList.setLimitPerPage
  );

  const columns = [
    { key: "name", label: "Nome" },
    {
      key: "cpf",
      label: "CPF",
      render: (item: Collaborator) => formatCPF(item.cpf),
    },
    { key: "section.name", label: "Seção" },
  ];

  const openDeleteSectionModal = () => openModal(<DeleteSectionModal />);

  useEffect(() => {
    setCollaboratorsLimitPerPage(999);
    getHolidays();
    getJourneyEvents();

    return () => {
      resetHolidaysListState();
      resetCollaboratorListState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title="Editar seção"
      icon={<PlusIcon width={30} height={30} />}
      className="w-[600px]"
      childrenClassName="px-5 overflow-y-auto"
      onClose={onClose}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Campo obrigatório"),
          description: Yup.string().required("Campo obrigatório"),
          syndicate: Yup.string().required("Campo obrigatório"),
          journeyRule: Yup.string().required("Campo obrigatório"),
        })}
        onSubmit={(values) => {
          if (!selectedSection?._id) return;
          values = {
            ...values,
            ...(values.hourValue ? { hourValue: removeNumberCurrencyMask(values.hourValue) } : {}),
            ...(values.mealValue ? { mealValue: removeNumberCurrencyMask(values.mealValue) } : {}),
            ...(values.overnightValue ? { overnightValue: removeNumberCurrencyMask(values.overnightValue) } : {}),
          }

          const users = Object.keys(values.users).filter(
            (k: string) => values.users[k]
          );
          const holidays = Object.keys(values.holidays).filter(
            (k: string) => values.holidays[k]
          );
          const journey_events = Object.keys(values.journeyEvents).filter(
            (k: string) => values.journeyEvents[k]
          );
          
          const data = {
            users,
            holidays,
            journey_rule: values.journeyRule,
            syndicate: values.syndicate,
            name: values.name,
            description: values.description,
            journey_events,
            config: {
              hour_value: parseFloat(values.hourValue),
              meal_value: parseFloat(values.mealValue),
              overnight_value: parseFloat(values.overnightValue),
            },
          };
          updateSection(selectedSection?._id, data);
        }}
      >
        {(formik) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setIsLoadingOptions(true);
            if (!selectedSection) return;
            const params = {
              filters: { sort_key: "name", sort_value: "asc" },
              limit: 9999,
              offset: 0,
            };

            Promise.all([
              conventionService.getSyndicates(params),
              journeyRuleService.getJourneyRules(params),
            ])
              .then((values: any) => {
                if (!values[0] || !values[1])
                  throw Error("Erro ao buscar sindicatos e regra de jornada");

                setSyndicateOptions(
                  values[0].data.map((d: Syndicate) => {
                    return { label: d.name, value: d._id };
                  })
                );

                setJourneyRuleOptions(
                  values[1].data.map((d: JourneyRule) => {
                    return { label: d.name, value: d._id };
                  })
                );
              })
              .catch((e) => {
                toast.error(e);
              })
              .finally(() => {
                setIsLoadingOptions(false);
              });

            formik.setFieldValue("syndicate", selectedSection.syndicate._id);
            formik.setFieldValue(
              "journeyRule",
              selectedSection.journey_rule?._id
            );
            formik.setFieldValue(
              "hourValue",
              selectedSection.config?.hour_value?.toFixed(2).toString().padStart(6, '0')
            );
            formik.setFieldValue(
              "mealValue",
              selectedSection.config?.meal_value?.toFixed(2).toString().padStart(6, '0')
            );
            formik.setFieldValue(
              "overnightValue",
              selectedSection.config?.overnight_value?.toFixed(2).toString().padStart(6, '0')
            );
            formik.setFieldValue("name", selectedSection.name);
            formik.setFieldValue("description", selectedSection.description);
            formik.setFieldValue(
              "users",
              selectedSection.users.reduce((acc, key) => {
                return {
                  ...acc,
                  [key]: true,
                };
              }, {} as Record<string, boolean>)
            );
            formik.setFieldValue(
              "holidays",
              selectedSection.holidays.reduce((acc, value) => {
                return {
                  ...acc,
                  [value._id as string]: true,
                };
              }, {} as Record<string, boolean>)
            );
            formik.setFieldValue(
              "journeyEvents",
              selectedSection.journey_events?.reduce((acc, value) => {
                return {
                  ...acc,
                  [value.toString()]: true,
                };
              }, {} as Record<string, boolean>)
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [selectedSection]);

          return (
            <Form>
              <div className="flex flex-col gap-8 pt-5">
                <div className="flex gap-5">
                  <div className="flex-1">
                    <Field
                      placeholder="Nome da seção"
                      name="name"
                      component={OutlinedInput}
                      labelClassName="w-full"
                    />
                  </div>
                  <div className="flex-1">
                    <Field
                      placeholder="Descrição"
                      name="description"
                      component={OutlinedInput}
                      labelClassName="w-full"
                    />
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="flex-1">
                    <Field
                      name="syndicate"
                      component={OutlinedSelect}
                      loading={isLoadingOptions}
                      placeholder="Sindicato"
                      options={syndicateOptions}
                    />
                  </div>
                  <div className="flex-1">
                    <Field
                      name="journeyRule"
                      component={OutlinedSelect}
                      loading={isLoadingOptions}
                      placeholder="Regra de Jornada"
                      options={journeyRuleOptions}
                    />
                  </div>
                </div>
                <div className="text-dark-blue font-bold pb-2">
                Configurações de Diária
                </div>
                <div className="flex gap-5">
                  <div className="flex-1">
                    <Field
                      name="mealValue"
                      component={OutlinedMaskedInput}
                      mask="R$999,99"
                      placeholder="Valor da Refeição"
                    />
                  </div>
                  <div className="flex-1">
                    <Field
                      name="overnightValue"
                      component={OutlinedMaskedInput}
                      mask="R$999,99"
                      placeholder="Valor do Pernoite"
                    />
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="flex-1">
                    <Field
                      name="hourValue"
                      component={OutlinedMaskedInput}
                      mask="R$999,99"
                      placeholder="Valor da Diária"
                    />
                  </div>
                  <div className="flex-1"></div>
                </div>
                <Tabs tab={tab} setTab={setTab}>
                  <Tab
                    label="Colaboradores"
                    icon={<User color="#344767" size={15} />}
                  />
                  <Tab label="Feriados" icon={<Calendar variant="Bulk" />} />
                  <Tab label="Eventos de Jornada" icon={<RouteIcon />} />
                </Tabs>
                <div className="-mt-4">
                  <TabPanel tab={tab} index={0}>
                    <div>
                      <div className="text-red text-sm">
                        {(formik.errors.users as any) &&
                        (formik.touched.users as any)
                          ? (formik.errors.users as any)
                          : null}
                      </div>
                      <CollaboratorsSelectTable
                        columns={columns}
                        form={formik}
                        fieldName="users"
                      />
                    </div>
                  </TabPanel>
                  <TabPanel tab={tab} index={1}>
                    <div>
                      <div className="text-red text-sm">
                        {(formik.errors.holidays as any) &&
                        (formik.touched.holidays as any)
                          ? (formik.errors.holidays as any)
                          : null}
                      </div>
                      <HolidaysSelectTable form={formik} fieldName="holidays" />
                    </div>
                  </TabPanel>
                  <TabPanel tab={tab} index={2}>
                    <div>
                      <div className="text-red text-sm">
                        {(formik.errors.journeyEvents as any) &&
                        (formik.touched.journeyEvents as any)
                          ? (formik.errors.journeyEvents as any)
                          : null}
                      </div>
                      <JourneyEventsSelectTable form={formik} fieldName="journeyEvents" />
                    </div>
                  </TabPanel>
                </div>
                <div className="flex gap-5 z-30 py-4 sticky bottom-0 bg-white">
                  {hasPermissions([PERMISSIONS.DELETE_SECTION]) ? (
                    <div className="flex-1">
                      <OutlinedButton
                        onClick={openDeleteSectionModal}
                        className="w-full"
                      >
                        Excluir
                      </OutlinedButton>
                    </div>
                  ) : null}
                  <div className="flex-1">
                    <FilledButton
                      loading={isUpdatingSection}
                      className="w-full"
                      type="submit"
                    >
                      Editar
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditSectionModal;
