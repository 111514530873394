import { useEffect, useState } from "react";
import Tabs from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Components/Tab";
import TabPanel from "../../components/Tabs/Components/TabPanel";
import { useStore } from "../../store/useStore";
import MacroGeneral from "./Components/MacroGeneral";
import MacroLink from "./Components/MacroLink";
import MacroRestore from "./Components/MacroRestore";
import { Home, Link1, Trash } from "iconsax-react";

const Macros: React.FC<{}> = () => {
  const [tab, setTab] = useState<number>(0);
  const resetJourneyEventsListState = useStore(state => state.macro.resetJourneyEventsListState);
  const resetMacrosListState = useStore(state => state.macro.resetMacrosListState);

  useEffect(() => {
    return () => {
      resetJourneyEventsListState();
      resetMacrosListState();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Tabs tab={tab} setTab={setTab}>
        <Tab label="Visão Geral" icon={<Home variant="Bulk" />} />
        <Tab label="Desvinculadas" icon={<Link1 />} />
        <Tab label="Removidas" icon={<Trash variant="Bulk" />} />
      </Tabs>
      <TabPanel tab={tab} index={0}>
        <MacroGeneral />
      </TabPanel>
      <TabPanel tab={tab} index={1}>
        <MacroLink />
      </TabPanel>
      <TabPanel tab={tab} index={2}>
        <MacroRestore />
      </TabPanel>
    </div>
  );
};

export default Macros;
