import { useEffect, useState } from "react";
import TabPanel from "../../components/Tabs/Components/TabPanel";
import FleetGeneral from "./Components/FleetGeneral";
import { useStore } from "../../store/useStore";

const Fleet: React.FC<{}> = () => {
  const [tab, setTab] = useState<number>(0);
  const resetFleetListState = useStore(state => state.fleet.resetFleetListState);

  useEffect(() => {
    return () => {
      resetFleetListState();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {/* <Tabs tab={tab} setTab={setTab}>
        <Tab label="Visão Geral" icon={<Home variant="Bulk" />} />
         <Tab label="Gerenciadora de Risco" icon={<GlobeIcon />} />
        <Tab label="Analytics" icon={<AnalyticsIcon />} /> 
      </Tabs> */}
      <TabPanel tab={tab} index={0}>
        <FleetGeneral />
      </TabPanel>
    </div>
  );
};

export default Fleet;
