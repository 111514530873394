import DataTable from "../../../components/DataTable/DataTable";
import useModal from "../../../hooks/useModal";
import { JourneyEvent } from "../../../store/types/macroState";
import { useStore } from "../../../store/useStore";
import {
  formatDateTime,
} from "../../../utils/functions/formatters";
import DetailsJourneyEventModal from "./DetailsJourneyEventModal";

const JourneyEventsTable = () => {
  const journeyEvents = useStore((state) => state.macro.journeyEventsList.data);
  const setFilters = useStore(
    (state) => state.macro.journeyEventsList.setFilters
  );
  const filters = useStore((state) => state.macro.journeyEventsList.filters);
  const isLoadingJourneyEventsList = useStore(
    (state) => state.macro.isLoadingJourneyEventsList
  );
  const resetSelectedJourneyEvent = useStore((state) => state.macro.resetSelectedJourneyEvent);
  const getJourneyEvent = useStore((state) => state.macro.getJourneyEvent);
  const { openModal } = useModal();


  const openDetailsJourneyEventModal = (journeyEventId: string) => {
    getJourneyEvent(journeyEventId);
    openModal(<DetailsJourneyEventModal onClose={resetSelectedJourneyEvent} />);
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "name", label: "Nome", sortKey: "name" },
    { key: "type", label: "Tipo", sortKey: "type" },
    {
      key: "created_at",
      label: "Criado em",
      render: (item: JourneyEvent) => formatDateTime(item.created_at),
      sortKey: "created_at",
    },
  ];

  return (
    <DataTable
      data={journeyEvents}
      columns={columns}
      onDetails={openDetailsJourneyEventModal}
      onEdit={null}
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingJourneyEventsList}
    />
  );
};

export default JourneyEventsTable;
