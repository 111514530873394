import Skeleton from "react-loading-skeleton";
import Modal from "../../Modal/Modal";
import FilledButton from "../../FilledButton/FilledButton";
import LimitPerPage from "../../LimitPerPage/LimitPerPage";
import Pagination from "../../Pagination/Pagination";
import { useStore } from "../../../store/useStore";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SelectSectionDataTable from "./SelectSectionDataTable";
import { Section } from "../../../store/types/convention";
import OutlinedButton from "../../OutlinedButton/OutlinedButton";
import { Building4, Location } from "iconsax-react";
import { Colors } from "../../../utils/constants/style";

const ChangeSectionModal: React.FC<{ notClosable?: boolean }> = ({
  notClosable,
}) => {
  const sectionList = useStore((state) => state.conventions.sectionsList);
  const navigate = useNavigate();
  const location = useLocation();
  const getSections = useStore(
    (state) => state.conventions.getSections
  );
  const isLoadingSectionsList = useStore(
    (state) => state.conventions.isLoadingSectionsList
  );
  const isChangingCurrentSection = useStore(
    (state) => state.changeCompanyModal.isChangingCurrentSection
  );
  const adminSection = useStore((state) => state.admin.data.section);

  const changeCurrentSection = useStore(
    (state) => state.changeCompanyModal.changeCurrentSection
  );
  const [nextCurrentSection, setNextCurrentSectionId] = useState<
    Partial<Section>| undefined
  >();

  useEffect(() => {
    getSections();
    if(adminSection) setNextCurrentSectionId(adminSection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSelectNextSection = (item: any) => {
    setNextCurrentSectionId(item);
  };

  const handleChangeCurrentSection = () => {
    const reloadNavigate = () => navigate(location.pathname, { replace: true });
    if (nextCurrentSection) changeCurrentSection(nextCurrentSection, reloadNavigate);
  };

  const handleCleanSection = () => {
    const reloadNavigate = () => navigate(location.pathname, { replace: true });
    changeCurrentSection(null, reloadNavigate);
  };

  const columns = [
    { key: "name", label: "Nome" },
    { key: "description", label: "Descrição" }
  ];

  return (
    <Modal
      title="Selecione a seção"
      icon={<Location size="32" variant="Bold" color={Colors.white}/>}
      className="w-[600px] max-h-[1000px]"
      notClosable={notClosable}
    >
      <div>
        <div className="py-3">
          <SelectSectionDataTable
            currentSectionId={nextCurrentSection?._id}
            columns={columns}
            data={sectionList.data}
            loading={isLoadingSectionsList}
            onSelectSection={onSelectNextSection}
          />
        </div>
        <div className="flex gap-5 items-center justify-between">
          <LimitPerPage
            onLimitChange={(e: any) =>
              sectionList.setLimitPerPage(e.target.value)
            }
          />
          <Pagination
            currentPage={sectionList.currentPage}
            totalPages={sectionList.totalPages}
            onPageChange={sectionList.setCurrentPage}
          />
        </div>
      </div>
      <div className="text-gray_1 text-center py-3">
        Mostrando{" "}
        {!isLoadingSectionsList ? (
          sectionList.data.length +
          sectionList.offset * sectionList.limitPerPage
        ) : (
          <Skeleton inline width={20} />
        )}{" "}
        de {sectionList.totalItems} registros
      </div>
      <div className="flex justify-between">
        <OutlinedButton
          className="px-12 mx-1"
          onClick={handleCleanSection}
        >
          REMOVER SELEÇÃO
        </OutlinedButton>
        <FilledButton
          className="px-12 mx-1"
          onClick={handleChangeCurrentSection}
          loading={isChangingCurrentSection}
        >
          SELECIONAR
        </FilledButton>
      </div>
    </Modal>
  );
};

export default ChangeSectionModal;
