interface EditIconProps {
  width?: string | number;
  height?: string | number;
  color?: string;
}
const EditIcon: React.FC<EditIconProps> = ({
  width = "16",
  height = "16",
  color = "#4F4F52",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2583 3.86666C15.5833 3.54166 15.5833 2.99999 15.2583 2.69166L13.3083 0.74166C13 0.41666 12.4583 0.41666 12.1333 0.74166L10.6 2.26666L13.725 5.39166L15.2583 3.86666ZM0.5 12.375V15.5H3.625L12.8417 6.27499L9.71667 3.14999L0.5 12.375Z"
        fill={color}
      />
    </svg>
  );
};

export default EditIcon;
