import { Fade, Slide } from 'react-awesome-reveal';
import './conquests-style.css';
import Conquest from '../../components/conquest';

export default function Conquests(props: { contentClass: string;}) {
  const clazz = props.contentClass + " margin-t-full margin-b-full";

  if(props.contentClass == "content-mobile") {
    return(
      <section id="conquests">
        <div className={clazz}>
          <div className='conquest-content-mobile'>
            <Slide cascade damping={0.2}>
              <h1 className='title-conquests-mobile'>Nossas conquistas</h1>
              <p className='subtitle-conquests-mobile'>Com nosso know-how e tempo de mercado chegamos a estes números...</p>
            </Slide>
            <Fade cascade damping={0.2}>
              <Conquest icon='first' title='200,000+' description='macros registradas' mobile={true}/>
              <Conquest icon='seccond' title='até 30,000' description='reais economizados logo no primeiro mês' mobile={true}/>
              <Conquest icon='third' title='1,000+' description='motoristas monitorados' mobile={true}/>
              <Conquest icon='fourth' title='30+' description='transportadoras já utilizam o Jornada Sat' mobile={true}/>
            </Fade>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section id="conquests">
      <div className={clazz}>
        <div className='conquest-content'>
          <div className='conquest-content-left'>
            <Slide cascade damping={0.2}>
              <h1 className='title-conquests'>Nossas conquistas</h1>
              <p className='subtitle-conquests'>Com nosso know-how e tempo de mercado chegamos a estes números...</p>
            </Slide>
          </div>
          <center><div className='conquest-content-right'>
            <div className='conquest-list'>
              <Fade cascade damping={0.2}>
                <Conquest icon='first' title='200,000+' description='macros registradas' mobile={false}/>
                <Conquest icon='seccond' title='até 30,000' description='reais economizados logo no primeiro mês' mobile={false}/>
              </Fade>
            </div>
            <div className='conquest-list'>
              <Fade cascade damping={0.2}>
                <Conquest icon='third' title='1,000+' description='motoristas monitorados' mobile={false}/>
                <Conquest icon='fourth' title='30+' description='transportadoras já utilizam o Jornada Sat' mobile={false}/>
              </Fade>
            </div>
          </div></center>
        </div>
      </div>
    </section>
  );
}
