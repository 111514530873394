import { useEffect } from "react";
import DataPanel from "../../../components/DataPanel/DataPanel";
import Pagination from "../../../components/Pagination/Pagination";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import useModal from "../../../hooks/useModal";
import FleetTable from "./FleetTable";
import CreateFleetModal from "./CreateFleetModal";
import FilterFleetModal from "./FilterFleetModal";
import OptionsFleetModal from "./OptionsFleetModal";
import { useStore } from "../../../store/useStore";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import { AddCircle, Filter, Setting2 } from "iconsax-react";

const FleetGeneral: React.FC<{}> = () => {
  const { openModal } = useModal();
  const getFleets = useStore((state) => state.fleet.getFleets);
  const fleetsList = useStore(
    (state) => state.fleet.fleetsList
  );
  const isLoadingFleetsList = useStore(
    (state) => state.fleet.isLoadingFleetsList
  );
  const hasPermissions = useStore(
    (state) => state.admin.hasPermissions
  );

  useEffect(() => {
    getFleets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateFleetModal = () =>
    openModal(<CreateFleetModal />);
  const openFilterFleetModal = () =>
    openModal(<FilterFleetModal />);
  const openOptionsFleetModal = () =>
    openModal(<OptionsFleetModal />);

  return (
    <>
      <div className="mt-9">
        <DataPanel className="px-3 pt-8 flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="pl-1">
              {/* <SearchInput placeholder="Buscar registro" onChange={() => {}} /> */}
            </div>
            <div className="pr-1 flex gap-4">
              <RoundedFilledButton
                onClick={openCreateFleetModal}
                icon={<AddCircle />}
                text="Novo"
                show={hasPermissions([PERMISSIONS.CREATE_VEHICLE])}
              />
              <RoundedFilledButton
                onClick={openFilterFleetModal}
                icon={<Filter size={24} />}
                text="Filtros"
              />
              <RoundedFilledButton
                onClick={openOptionsFleetModal}
                icon={<Setting2 />}
                text="Opções"
              />
            </div>
          </div>
          <FleetTable />
          <div className="flex justify-end items-center">
            <div className="flex gap-5 items-center">
              <LimitPerPage
                onLimitChange={(e: any) =>
                  fleetsList.setLimitPerPage(e.target.value)
                }
                total={fleetsList.totalItems}
              />
              <Pagination
                currentPage={fleetsList.currentPage}
                totalPages={fleetsList.totalPages}
                onPageChange={fleetsList.setCurrentPage}
              />
            </div>
          </div>
        </DataPanel>
      </div>
    </>
  );
};

export default FleetGeneral;
