import StatusText from "../../../components/StatusText/StatusText";
import FilledButton from "../../../components/FilledButton/FilledButton";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import StepsProgress from "../../../components/Steps/StepsProgress";
import React from "react";
import MacroTimeline from "./MacroTimeline";
import { useStore } from "../../../store/useStore";

export interface Step3Props {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  totalSteps: number;
}

const Step3: React.FC<Step3Props> = ({
  currentStep,
  setCurrentStep,
  totalSteps,
}) => {
  const macroDataToUpdate = useStore((state) => state.macro.macroDataToUpdate);
  const selectedMacro = useStore((state) => state.macro.selectedMacro);
  const updateMacro = useStore((state) => state.macro.updateMacro);
  const isUpdatingMacro = useStore((state) => state.macro.isUpdatingMacro);
  
  if(!selectedMacro?._id) return null;

  return (
    <div>
      <div className="flex justify-center pb-5">
        <StepsProgress currentStep={currentStep} totalSteps={totalSteps} />
      </div>
      <div className="pb-5 flex justify-center">
        <StatusText
          status="warning"
          text="A alteração só terá efeito após a aprovação do motorista"
        />
      </div>
      <h1 className="font-bold text-dark-blue pb-5">
        Impacto na macro anterior
      </h1>
      <div className="pb-10">
        <div className="pb-2 text-dark-blue">Antes da alteração:</div>
        <MacroTimeline objects={macroDataToUpdate?.old_flow ?? []} macroId={selectedMacro._id}  />
      </div>
      <div className="pb-12">
        <div className="pb-2 text-dark-blue">Após a alteração:</div>
        <MacroTimeline objects={macroDataToUpdate?.new_flow ?? []} macroId={selectedMacro._id} selectedMacro={selectedMacro} updatedMacro={macroDataToUpdate} isNew />
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex gap-5">
          <div className="flex-1">
            <OutlinedButton
              onClick={() => setCurrentStep(2)}
              className="w-full"
            >
              Voltar
            </OutlinedButton>
          </div>
          <div className="flex-1">
            <FilledButton onClick={updateMacro} loading={isUpdatingMacro} className="w-full">
              Concluir
            </FilledButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
