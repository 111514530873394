import { JornadaSatLogo, OmnilinkLogo, OnixSatLogo, SascarLogo } from "../../assets/imgs";

const IntegrationCode: React.FC<{ origin: string, code: string }> = ({ origin, code }) => {
  if (origin === "ONIXSAT") {
    return (
      <div className="shadow-base flex gap-1 items-center bg-gray_0 rounded-[20px] min-h-[38px] w-fit px-3 flex-wrap justify-start min-w-[120px]">
        <img src={OnixSatLogo} alt="onixsat logo" width={35} />{" "}
        <span className="font-light text-sm ml-2">{code}</span>
      </div>
    );
  } else if (origin === "JORNADASAT") {
    return (
      <div className="shadow-base flex gap-1 items-center bg-gray_0 rounded-[20px] min-h-[38px] w-fit px-3 flex-wrap justify-start min-w-[120px]">
        <img width={35} src={JornadaSatLogo} alt="jornadasat logo" />{" "}
        <span className="font-light text-sm ml-2">{code}</span>
      </div>
    );
  } else if (origin === "OMNILINK") {
    return (
      <div className="shadow-base flex gap-1 items-center bg-gray_0 rounded-[20px] min-h-[38px] w-fit px-3 flex-wrap justify-start min-w-[120px]">
        <img width={35} src={OmnilinkLogo} alt="jornadasat logo" />{" "}
        <span className="font-light text-sm ml-2">{code}</span>
      </div>
    );
  } else {
    return (
      <div className="shadow-base flex gap-1 items-center bg-gray_0 rounded-[20px] min-h-[38px] w-fit px-3 flex-wrap justify-start min-w-[120px] mt-1">
        <img width={35} src={SascarLogo} alt="jornadasat logo" />{" "}
        <span className="font-light text-sm ml-2">{code}</span>
      </div>
    );
  }
};

export default IntegrationCode;
