import React from "react";

interface ChevronIconProps {
  width?: string | number;
  height?: string | number;
  color?: string;
  direction?: "down" | "up" | "left" | "right";
  className?: string;
}

const ChevronIcon: React.FC<ChevronIconProps> = ({
  width = "10",
  height = "6",
  color = "white",
  direction="down",
  className=""
}) => {
  
  let transform: string = "rotate(0)";
  if(direction === "up") transform = "rotate(180)"
  else if(direction === "left") transform = "rotate(90)"
  else if(direction === "right") transform = "rotate(270)"

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={transform}
      className={className}
    >
      <path
        d="M0.612793 0.585815L4.85543 4.82846L9.09807 0.585815"
        stroke={color}
      />
    </svg>
  );
};

export default ChevronIcon;
