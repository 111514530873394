
export const ROUTES = {
  INSTITUTIONAL: { PATH: "/", NAME: "Institucional" },
  LOGIN: { PATH: "/login", NAME: "Login" },
  FORGOT_PASSWORD: { PATH: "/esqueceu-senha", NAME: "Esqueceu a Senha" },
  DASHBOARD: { PATH: "/dashboard", NAME: "Dashboard" },
  // JOURNEY_ANALYSIS: { PATH: "/analise-jornada", NAME: "Análise de Jornada" },
  COLLABORATORS: { PATH: "/colaboradores", NAME: "Colaboradores" },
  FLEET: { PATH: "/frota", NAME: "Frota" },
  SHIPPING_COMPANIES: { PATH: "/transportadoras", NAME: "Transportadoras" },
  TIME_MANAGER: { PATH: "/gerenciador-horas", NAME: "Gerenciador de Horas" },
  REPORTS: { PATH: "/relatorios", NAME: "Relatórios" },
  MACROS: { PATH: "/macros", NAME: "Macros" },
  JOURNEY_RULES: { PATH: "/regras-jornada", NAME: "Regras de Jornada" },
  SESSIONS: { PATH: "/secoes", NAME: "Seções" },
  CONVENTIONS: { PATH: "/convencoes", NAME: "Convenções" },
  SYNDICATES: { PATH: "/sindicatos", NAME: "Sindicatos" },
  PROFILE: { PATH: "/perfil", NAME: "Perfil" },
  PRIVACY_POLICY: { PATH: "/politica-privacidade", NAME: "Política de Privacidade" },
};

export type RoutesType = typeof ROUTES;
