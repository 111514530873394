import * as Icons from "../../assets/imgs";

interface RoundedFilledButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  icon: JSX.Element;
  text: string;
  show?: boolean;
}

const RoundedFilledButton: React.FC<RoundedFilledButtonProps> = ({
  onClick,
  icon,
  text,
  loading,
  show = true,
  ...props
}) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!loading && onClick) {
      onClick(event);
    }
  };

  return show ? (
    <button
      onClick={handleClick}
      {...props}
      className={`bg-red disabled:bg-gray_1 text-white rounded-[16px] font-light hover:bg-[#bf1a20] transition duration-200 ease-in-out py-2 px-3  ${props.className}`}
    >
      {loading ? (
        <Icons.LoadingIcon />
      ) : (
        <div className="flex gap-2 justify-center items-center">
          {icon}
          <span>{text}</span>
        </div>
      )}
    </button>
  ) : null;
};

export default RoundedFilledButton;
