import { CSVIcon, OptionsIcon, PDFIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import Checkbox from "../../../components/FormComponents/Checkbox/Checkbox";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import { useStore } from "../../../store/useStore";
import { CompaniesVisibleColumnsKey } from "../../../store/types/company";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import { Setting2 } from "iconsax-react";
import { Colors } from "../../../utils/constants/style";

const labels = {
  name: "Nome",
  cnpj: "CNPJ",
  address: "Endereço",
  created_at: "Criado em",
};

const fixedColumns = {
  name: true,
  cnpj: false,
  address: false,
  created_at: true,
};

const OptionsCompanyModal: React.FC<{}> = () => {
  const toggleColumnVisibility = useStore(
    (state) => state.company.companiesList.toggleColumnVisibility
  );
  const columnsVisibility = useStore(
    (state) => state.company.companiesList.columnsVisibility
  );
  const isExporting = useStore((state) => state.company.isExporting);

  const exportCompanyTable = useStore(
    (state) => state.company.exportCompanyTable
  );
    const hasPermissions = useStore((state) => state.admin.hasPermissions);

  const columnsVisibilityFiltered = (
    Object.keys(columnsVisibility) as CompaniesVisibleColumnsKey[]
  )

  return (
    <Modal
      title="Opções"
      icon={<Setting2 size="32" variant="Bold" color={Colors.white}/>}
      className="min-w-[223px]"
    >
      <>
        <div className="pb-2 text-dark-blue">Exibir Colunas</div>
        <div className="flex flex-col gap-2 pl-3">
          {columnsVisibilityFiltered.map((key) => (
            <Checkbox
              key={key}
              text={labels[key]}
              checked={columnsVisibility[key]}
              onChange={(e) => {
                if (!fixedColumns[key])
                  toggleColumnVisibility(key, columnsVisibility[key]);
              }}
            />
          ))}
        </div>
        {hasPermissions([PERMISSIONS.CREATE_COMPANIES_REPORT]) ? (

        <>
          <div className="pt-4 pb-2 text-dark-blue">Exportar</div>
          <div className="flex gap-3">
            <div className="flex-1">
              <RoundedFilledButton
                className="w-full"
                onClick={() => exportCompanyTable("csv")}
                loading={isExporting["csv"]}
                icon={<CSVIcon />}
                text="CSV"
              />
            </div>
            <div className="flex-1">
              <RoundedFilledButton
                className="w-full"
                onClick={() => exportCompanyTable("pdf")}
                loading={isExporting["pdf"]}
                icon={<PDFIcon />}
                text="PDF"
              />
            </div>
          </div>
        </>
        ) : null}
      </>
    </Modal>
  );
};

export default OptionsCompanyModal;
