import { useEffect } from "react";
import DataPanel from "../../../components/DataPanel/DataPanel";
import Pagination from "../../../components/Pagination/Pagination";
import SearchInput from "../../../components/SearchInput/SeachInput";
import JourneyEventsTable from "./JourneyEventsTable";
import { useStore } from "../../../store/useStore";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import Skeleton from "react-loading-skeleton";

const JourneyEvents: React.FC<{}> = () => {
  const getJourneyEvents = useStore((state) => state.macro.getJourneyEvents);
  const journeyEventsList = useStore(
    (state) => state.macro.journeyEventsList
  );
  const isLoadingJourneyEventsList = useStore(
    (state) => state.macro.isLoadingJourneyEventsList
  );

  useEffect(() => {
    getJourneyEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mt-9">
        <DataPanel className="px-3 pt-8 flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="pl-1">
              {/* <SearchInput placeholder="Buscar registro" onChange={() => {}} /> */}
            </div>
          </div>
          <JourneyEventsTable />
          <div className="flex justify-between items-center">
            <div className="text-gray_1">
              Mostrando{" "}
              {!isLoadingJourneyEventsList ? (journeyEventsList.data.length + journeyEventsList.offset * journeyEventsList.limitPerPage) : <Skeleton inline width={20} />}{" "}
              de {journeyEventsList.totalItems} registros
            </div>
            <div className="flex gap-5 items-center">
              <LimitPerPage
                onLimitChange={(e: any) =>
                  journeyEventsList.setLimitPerPage(e.target.value)
                }
              />
              <Pagination
                currentPage={journeyEventsList.currentPage}
                totalPages={journeyEventsList.totalPages}
                onPageChange={journeyEventsList.setCurrentPage}
              />
            </div>
          </div>
        </DataPanel>
      </div>
    </>
  );
};

export default JourneyEvents;
