import { EditIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import { Colors } from "../../../utils/constants/style";
import useModal from "../../../hooks/useModal";
import DeleteCompanyModal from "./DeleteCompanyModal";
import { useEffect } from "react";
import { useStore } from "../../../store/useStore";
import { Company } from "../../../store/types/company";
import {
  formatCNPJ,
  removeNumberMask,
} from "../../../utils/functions/formatters";
import OutlinedMaskedInput from "../../../components/FormComponents/OutlinedMaskedInput/OutlinedMaskedInput";
import { SUPPORTED_FORMATS } from "../../../utils/constants/constants";
import LogoInput from "../../../components/FormComponents/LogoInput/LogoInput";
import { PERMISSIONS } from "../../../utils/constants/permissions";

interface EditCompanyModalProps {
  onClose?: () => void;
  hideExcludeButton?: boolean;
  modalTitle?: string;
  submitButtonText?: string;
  submitButtonAction?: (companyId: string, company: Company) => void;
}

const initialValues = {
  name: "",
  cnpj: "",
  logo: "",
  address: {
    street: "",
    number: "",
    neighbourhood: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
  },
};

const validationSchema = {
  name: Yup.string().required("Campo obrigatório"),
  cnpj: Yup.string()
    .length(18, "O CNPJ deve conter exatamente 14 números")
    .required("Campo obrigatório"),
  logo: Yup.mixed()
    .test(
      "size",
      "A logotipo deve ter no máximo 2MB",
      (value: any) => !value || (value && value.size <= 2 * 1024 * 1024)
    )
    .test(
      "format",
      "Tipo de imagem não suportado",
      (value: any) =>
        !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  address: Yup.object().shape({
    street: Yup.string().required("Campo obrigatório"),
    number: Yup.string().required("Campo obrigatório"),
    neighbourhood: Yup.string().required("Campo obrigatório"),
    city: Yup.string().required("Campo obrigatório"),
    state: Yup.string().required("Campo obrigatório"),
    country: Yup.string().required("Campo obrigatório"),
    zipcode: Yup.string().length(9, "O CEP deve conter exatamente 8 números").required("Campo obrigatório"),
  }),
};

const EditCompanyModal: React.FC<EditCompanyModalProps> = ({ onClose }) => {
  const { openModal } = useModal();
  const selectedCompany = useStore((state) => state.company.selectedCompany);
  const updateCompany = useStore((state) => state.company.updateCompany);
  const isUpdatingCompany = useStore(
    (state) => state.company.isUpdatingCompany
  );
  const isLoadingCompany = useStore((state) => state.company.isLoadingCompany);
  const hasPermissions = useStore((state) => state.admin.hasPermissions);

  const openDeleteCompanyModal = () => openModal(<DeleteCompanyModal />);

  return (
    <Modal
      title={"Editar transportadora"}
      icon={<EditIcon width={30} height={30} color={Colors.white} />}
      className="w-[447px]"
      onClose={onClose}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          if (selectedCompany?._id) {
            const data = {
              ...values,
              cnpj: removeNumberMask(values.cnpj),
              address: {
                ...values.address,
                zipcode: removeNumberMask(values.address.zipcode),
              },
            };
            updateCompany(selectedCompany._id, data as Company);
          }
        }}
      >
        {(formik) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (selectedCompany) {
              const selectedCompanyCopy = {
                ...selectedCompany,
                address: { ...selectedCompany.address },
                cnpj: formatCNPJ(selectedCompany.cnpj),
                // logo is empty to not make request updating image and only makes the request
                // when updated by the user
                logo: "", 
              };
              delete selectedCompanyCopy._id;
              delete selectedCompanyCopy.deleted_at;
              delete selectedCompanyCopy.updated_at;
              delete selectedCompanyCopy.created_at;

              formik.setValues(selectedCompanyCopy);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [selectedCompany]);

          return (
            <Form>
              <div className="flex flex-col gap-8">
                <div className="flex justify-center">
                  <Field
                    name="logo"
                    component={LogoInput}
                    image={selectedCompany?.logo}
                    loading={isLoadingCompany}
                  />
                </div>
                <div className="flex gap-8">
                  <div className="flex-1 flex flex-col gap-8">
                    <Field
                      placeholder="Nome"
                      name="name"
                      component={OutlinedInput}
                      loading={isLoadingCompany}
                    />
                    <Field
                      placeholder="CNPJ"
                      name="cnpj"
                      mask="99.999.999/9999-99"
                      component={OutlinedMaskedInput}
                      loading={isLoadingCompany}
                    />
                    <Field
                      placeholder="País"
                      name="address.country"
                      component={OutlinedInput}
                      loading={isLoadingCompany}
                    />
                    <Field
                      placeholder="Estado"
                      name="address.state"
                      component={OutlinedInput}
                      loading={isLoadingCompany}
                    />
                    <Field
                      placeholder="Cidade"
                      name="address.city"
                      component={OutlinedInput}
                      loading={isLoadingCompany}
                    />
                  </div>
                  <div className="flex-1 flex flex-col gap-8">
                    <Field
                      placeholder="Rua"
                      name="address.street"
                      component={OutlinedInput}
                      loading={isLoadingCompany}
                    />
                    <Field
                      placeholder="Número"
                      name="address.number"
                      component={OutlinedMaskedInput}
                      mask="999999999999"
                      loading={isLoadingCompany}
                    />
                    <Field
                      placeholder="Complemento"
                      name="address.neighbourhood"
                      component={OutlinedInput}
                      loading={isLoadingCompany}
                    />
                    <Field
                      placeholder="CEP"
                      name="address.zipcode"
                      component={OutlinedMaskedInput}
                      mask="99999-999"
                      loading={isLoadingCompany}
                    />
                  </div>
                </div>

                <div className="flex gap-5">
                  {hasPermissions([PERMISSIONS.DELETE_COMPANY]) ? (
                    <div className="flex-1">
                      <OutlinedButton
                        onClick={openDeleteCompanyModal}
                        className="w-full"
                      >
                        Excluir
                      </OutlinedButton>
                    </div>
                  ) : null}

                  <div className="flex-1">
                    <FilledButton
                      loading={isUpdatingCompany}
                      type="submit"
                      className="w-full"
                    >
                      Editar
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditCompanyModal;
