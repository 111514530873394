import { PlusIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import { useStore } from "../../../store/useStore";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";

const CreateSyndicateModal: React.FC<{}> = () => {
  const createSyndicate = useStore((state) => state.conventions.createSyndicate);
  const isCreatingSyndicate = useStore(
    (state) => state.conventions.isCreatingSyndicate
  );

  return (
    <Modal
      title="Cadastro de novo sindicato"
      icon={<PlusIcon width={30} height={30} />}
      className="w-[447px]"
    >
      <Formik
        initialValues={{
          name: "",
          operation_area: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Campo obrigatório"),
          operation_area: Yup.string().required("Campo obrigatório"),
        })}
        onSubmit={(values) => {
          createSyndicate(values);
        }}
      >
        <Form>
          <div className="flex flex-col gap-8 pt-5">
            <Field placeholder="Nome" name="name" component={OutlinedInput} />
            <Field
              placeholder="Área de operação"
              name="operation_area"
              component={OutlinedInput}
            />
            <div className="flex gap-5">
              <div className="flex-1"></div>
              <div className="flex-1">
                <FilledButton
                  loading={isCreatingSyndicate}
                  className="w-full"
                  type="submit"
                >
                  Cadastrar
                </FilledButton>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default CreateSyndicateModal;
