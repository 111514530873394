import DataTable from "../../../components/DataTable/DataTable";
import { HOLIDAY_TYPE, HOLIDAY_TYPE_TRANSLATION, Holiday, HolidaysVisibleColumns } from "../../../store/types/convention";
import Skeleton from "react-loading-skeleton";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import Pagination from "../../../components/Pagination/Pagination";
import { useStore } from "../../../store/useStore";

const HolidaysSelectTable: React.FC<{ form: any; fieldName: string }> = ({
  form,
  fieldName,
}) => {
  const holidaysList = useStore((state) => state.conventions.holidaysList);
  const setFilters = useStore(
    (state) => state.conventions.holidaysList.setFilters
  );
  const filters = useStore((state) => state.conventions.holidaysList.filters);
  const columnsVisibility = useStore(
    (state) => state.conventions.holidaysList.columnsVisibility
  );
  const isLoadingHolidaysList = useStore(
    (state) => state.conventions.isLoadingHolidaysList
  );

  const holidays = useStore((state) => state.conventions.holidaysList.data);
  const holidaysSelected = useStore((state) => state.conventions.holidaysList.holidaysSelected);
  const isAllChecked = () => {
    return holidays.every((holidays) => holidaysSelected.includes(holidays?._id ?? ""))
  }
  const selectHoliday = useStore((state) => state.conventions.selectHoliday);
  const unselectHoliday = useStore((state) => state.conventions.unselectHoliday);

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "name", label: "Nome", sortKey: "name" },
    { key: "type", label: "Tipo", render: (item: Holiday) => HOLIDAY_TYPE_TRANSLATION[item.type as HOLIDAY_TYPE] },
    {
      key: "date",
      label: "Data",
    },
  ];

  const columnsVisible = columns.filter((column) => {
    return columnsVisibility[column.key as keyof HolidaysVisibleColumns];
  });

  return (
    <>
      <DataTable
        data={holidaysList.data}
        columns={columnsVisible}
        onDetails={null}
        onEdit={null}
        isChecked={(item) => !!form.values[fieldName][item._id]}
        isAllChecked={isAllChecked}
        onSelectAll={() => {
          if(!isAllChecked()) {
            for(const item of holidays) {
              selectHoliday(item._id);
              form.setFieldValue(
                `${fieldName}.${item._id}`,
                true
              )
            }
          } else {
            for(const item of holidays) {
              unselectHoliday(item._id);
              form.setFieldValue(
                `${fieldName}.${item._id}`,
                false
              )
            }
          }
        }}
        onSelect={(item) =>
          form.setFieldValue(
            `${fieldName}.${item._id}`,
            !form.values[fieldName][item._id]
          )
        }
        onOrder={handleTableOrder}
        sortKey={filters.sort_key}
        sortValue={filters.sort_value}
        loading={isLoadingHolidaysList}
      />
      <div className="flex justify-between items-center">
        <LimitPerPage
          onLimitChange={(e: any) =>
            holidaysList.setLimitPerPage(e.target.value)
          }
        />
        <Pagination
          currentPage={holidaysList.currentPage}
          totalPages={holidaysList.totalPages}
          onPageChange={holidaysList.setCurrentPage}
        />
      </div>
      <div className="text-gray_1 text-center mt-2">
        Mostrando{" "}
        {!isLoadingHolidaysList ? (
          holidaysList.data.length +
          holidaysList.offset * holidaysList.limitPerPage
        ) : (
          <Skeleton inline width={20} />
        )}{" "}
        de {holidaysList.totalItems} registros
      </div>
    </>
  );
};

export default HolidaysSelectTable;
