import InfoField from "../../InfoField/InfoField";
import InfoFieldBox from "../../InfoFieldBox/InfoFieldBox";
import Modal from "../../Modal/Modal";
import { useStore } from "../../../store/useStore";
import { Colors } from "../../../utils/constants/style";
import StatusText from "../../StatusText/StatusText";
import TimeCounter from "../../TimeCounter/TimeCounter";
import JourneyDetailsChart from "./JourneyDetailsChart";
import { AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import { Polyline } from "../../../utils/map/polyline";
import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
import LastMacrosTable from "./LastMacrosTable";
import ShadowBox from "../../ShadowBox/ShadowBox";
import Pagination from "../../Pagination/Pagination";
import LimitPerPage from "../../LimitPerPage/LimitPerPage";
import { getJourneyTimeStatus, isValidPosition } from "../../../utils/functions/functions";
import { TruckFast } from "iconsax-react";

interface DetailsStatusModalProps {
  onClose: () => void;
}

const DetailsStatusModal: React.FC<DetailsStatusModalProps> = ({ onClose }) => {
  const selectedMacro = useStore(
    (state) => state.dashboard.statusPanel.selectedMacro
  );
  const isLoadingVehiclePositions = useStore(
    (state) => state.dashboard.isLoadingVehiclePositions
  );
  const getLastMacrosList = useStore(
    (state) => state.dashboard.getLastMacrosList
  );
  const getUserJourney = useStore((state) => state.dashboard.getUserJourney);
  const getVehiclePositions = useStore(
    (state) => state.dashboard.getVehiclePositions
  );
  const lastMacrosList = useStore(
    (state) => state.dashboard.statusPanel.selectedMacro.lastMacrosList
  );
  const isLoadingLastMacros = useStore(
    (state) => state.dashboard.isLoadingLastMacros
  );

  const positionLat = selectedMacro?.position?.[0] ?? null;
  const positionLng = selectedMacro?.position?.[1] ?? null;

  useEffect(() => {
    getUserJourney(selectedMacro.user_id);
    getVehiclePositions(selectedMacro.vehicle_id);
    getLastMacrosList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title={
        <InfoFieldBox>
          <InfoField
            loading={false}
            label="Motorista"
            content={selectedMacro?.user}
          />
          <InfoField
            loading={false}
            label="Placa"
            content={selectedMacro?.vehicle}
          />
          <InfoField
            loading={false}
            label="Atividade"
            content={
              <div className="flex flex-col w-fit">
                <StatusText
                  text={selectedMacro?.journey_event?.name ?? "-"}
                  status={getJourneyTimeStatus(selectedMacro.journey_event)}
                />
                <div className="flex justify-center">
                  <TimeCounter
                    startDate={selectedMacro?.journey_event?.started_at}
                  />
                </div>
              </div>
            }
          />
        </InfoFieldBox>
      }
      titleClassName=""
      icon={<TruckFast size="32" color={Colors.white} variant="TwoTone" />}
      className="min-w-[1000px]"
      onClose={onClose}
    >
      <div className="flex flex-col gap-5 pt-1">
        <ShadowBox>
          <div>
            <div className="text-dark-blue font-bold text-2xl mb-3">
              Detalhes da Jornada
            </div>
            <JourneyDetailsChart />
          </div>
        </ShadowBox>
        <ShadowBox>
          <div>
            <div className="text-dark-blue font-bold text-2xl mb-3">
              Últimos Pontos de GPS
            </div>
            {isLoadingVehiclePositions ? (
              <Skeleton height={400} width="100%" />
            ) : !isValidPosition({ lat: positionLat, lng: positionLng }) ? <div className="text-center">Posição Inválida</div> : (
              <Map
                style={{ width: "100%", height: "400px" }}
                defaultCenter={
                  positionLat && positionLng
                    ? { lat: positionLat, lng: positionLng }
                    : { lat: -17.46, lng: -55.46 }
                }
                defaultZoom={13}
                mapId={"status_details_map"}
                reuseMaps
              >
                <Polyline
                  path={selectedMacro?.geolocations?.map((geolocation: any) => {
                    return {
                      lat: geolocation.coordinates[0],
                      lng: geolocation.coordinates[1],
                    };
                  })}
                  strokeColor={"#DA1E27"}
                />
                {selectedMacro?.geolocations?.map((geolocation: any) => {
                  return (
                    <AdvancedMarker
                      position={{
                        lat: geolocation.coordinates[0],
                        lng: geolocation.coordinates[1],
                      }}
                    >
                      <svg
                        height="28"
                        width="28"
                        style={{ transform: "translateY(13px)" }}
                      >
                        <circle
                          r="8"
                          cx="14"
                          cy="14"
                          stroke="#DA1E27"
                          stroke-width="3"
                          fill="#FFF"
                        />
                        Sorry, your browser does not support inline SVG.
                      </svg>
                    </AdvancedMarker>
                  );
                })}
                {positionLat && positionLng ? (
                  <AdvancedMarker
                    position={{
                      lat: positionLat,
                      lng: positionLng,
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/fleetIcon.svg"}
                      alt="posicao ultima macro"
                      width={56}
                      height={56}
                      style={{ transform: "translateY(26px)" }}
                    />
                  </AdvancedMarker>
                ) : null}
              </Map>
            )}
          </div>
        </ShadowBox>
        <ShadowBox>
          <div>
            <div className="text-dark-blue font-bold text-2xl mb-3">
              Últimas Macros
            </div>
            <LastMacrosTable />
            <div className="flex justify-between items-center">
              <div className="text-gray_1">
                Mostrando{" "}
                {!isLoadingLastMacros ? (
                  lastMacrosList.data.length +
                  lastMacrosList.offset * lastMacrosList.limitPerPage
                ) : (
                  <Skeleton inline width={20} />
                )}{" "}
                de {lastMacrosList.totalItems} registros
              </div>
              <div className="flex gap-5 items-center">
                <LimitPerPage
                  onLimitChange={(e: any) =>
                    lastMacrosList.setLimitPerPage(e.target.value)
                  }
                />
                <Pagination
                  currentPage={lastMacrosList.currentPage}
                  totalPages={lastMacrosList.totalPages}
                  onPageChange={lastMacrosList.setCurrentPage}
                />
              </div>
            </div>
          </div>
        </ShadowBox>
      </div>
    </Modal>
  );
};

export default DetailsStatusModal;
