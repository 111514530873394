import { StateCreator } from "zustand";
import { produce } from "immer";
import { toast } from "react-toastify";
import { useStore } from "../useStore";
import { CompensatoryTime, PayRoll } from "../types/timeManager";
import timeManagerService from "../../services/timeManagerService";
import { TimeManagerState } from "../types/timeManager";
import { downloadURI } from "../../utils/functions/download";
import moment from "moment";
import reportService from "../../services/reportService";

const initialPayRollsListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  filters: {
    name: "",
    batch: "",
    competence_start: "",
    competence_end: "",
    payroll_total: "",
    created_at: "",
    sort_key: "competence_start",
    sort_value: "desc",
  },
};

const initialCompensatoryTimesListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  filters: {
    user: {
      value: "",
      label: "",
    },
    type: "",
    manual: "",
    category: "",
    reason: "",
    reference_date_start: "",
    reference_date_end: "",
    deadline_start: "",
    deadline_end: "",
    created_at: "",
    sort_key: "created_at",
    sort_value: "asc",
  }
};

export const createTimeManagerSlice: StateCreator<TimeManagerState> = (
  set,
  get
) => ({
  timeManager: {
    payRollsList: {
      ...initialPayRollsListData,
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.timeManager.payRollsList.limitPerPage = limitPerPage;
            state.timeManager.payRollsList.offset = 0;
            state.timeManager.payRollsList.currentPage = 1;
          })
        );
        get().timeManager.getPayRolls();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.timeManager.payRollsList.currentPage = page;
            state.timeManager.payRollsList.offset = page - 1;
          })
        );
        get().timeManager.getPayRolls();
      },
      setFilters: (filters) => {
        set(
          produce((state) => {
            state.timeManager.payRollsList.filters = filters;
            state.timeManager.payRollsList.offset = 0;
            state.timeManager.payRollsList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().timeManager.getPayRolls();
      },
    },
    compensatoryTimeList: {
      ...initialCompensatoryTimesListData,
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.timeManager.compensatoryTimeList.limitPerPage = limitPerPage;
            state.timeManager.compensatoryTimeList.offset = 0;
            state.timeManager.compensatoryTimeList.currentPage = 1;
          })
        );
        get().timeManager.getCompensatoryTimes();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.timeManager.compensatoryTimeList.currentPage = page;
            state.timeManager.compensatoryTimeList.offset = page - 1;
          })
        );
        get().timeManager.getCompensatoryTimes();
      },
      setFilters: (filters) => {
        set(
          produce((state) => {
            state.timeManager.compensatoryTimeList.filters = filters;
            state.timeManager.compensatoryTimeList.offset = 0;
            state.timeManager.compensatoryTimeList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().timeManager.getCompensatoryTimes();
      },
    },
    collaborators: {
      collaboratorsSelected: []
    },
    selectedPayRoll: null,
    isLoadingPayRollsList: false,
    isLoadingPayRoll: false,
    isCreatingPayRoll: false,
    isDeletingPayRoll: false,
    isUpdatingPayRoll: false,
    isClosingPayRoll: false,
    isDeletingCompensatoryTime: false,
    isDownloadingPayRoll: false,
    isRefreshingPayRoll: {},
    resetSelectedCollaborator: () => {
      set(
        produce((state) => {
          state.timeManager.collaborator.collaboratorsSelected = [];
        })
      );
    },
    selectCollaborator: (collaboratorId: string | undefined) => {
      if (collaboratorId === undefined) return;
      set(
        produce((state) => {
          state.timeManager.collaborators.collaboratorsSelected.push(collaboratorId);
        })
      );
    },
    unselectCollaborator: (collaboratorId: string | undefined) => {
      if (collaboratorId === undefined) return;
      set(
        produce((state) => {
          state.timeManager.collaborators.collaboratorsSelected =
            state.timeManager.collaborators.collaboratorsSelected.filter(
              (id: string) => id !== collaboratorId
            );
        })
      );
    },
    selectedCompensatoryTime: null,
    isLoadingCompensatoryTimesList: false,
    isLoadingCompensatoryTime: false,
    isCreatingCompensatoryTimeRegister: false,
    setSelectedCompensatoryTime: (compensatoryTime: CompensatoryTime) => {
      set(
        produce((state) => {
          state.timeManager.selectedCompensatoryTime = compensatoryTime;
        })
      );
    },
    resetSelectedCompensatoryTime: () => {
      set(
        produce((state) => {
          state.timeManager.selectedCompensatoryTime = null;
        })
      );
    },
    getCompensatoryTime: (compensatoryTimeId: string) => {
      if(!compensatoryTimeId) return ;

      set(
        produce((state) => {
          state.timeManager.isLoadingCompensatoryTime = true;
        })
      );

      timeManagerService
        .getCompensatoryTime(compensatoryTimeId)
        .then((data) => {
          set(
            produce((state) => {
              state.timeManager.selectedCompensatoryTime = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.timeManager.isLoadingCompensatoryTime = false;
            })
          );
        });
    },
    getCompensatoryTimes: () => {
      set(
        produce((state) => {
          state.timeManager.isLoadingCompensatoryTimesList = true;
        })
      );

      const filters = get().timeManager.compensatoryTimeList.filters;
      const filtersFormatted = {...filters, user: "", user_id: filters.user.value};
      const limit = get().timeManager.compensatoryTimeList.limitPerPage;
      const offset = get().timeManager.compensatoryTimeList.offset;

      timeManagerService
        .getCompensatoryTimes({ filters: filtersFormatted, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.timeManager.compensatoryTimeList.data = data;
              state.timeManager.compensatoryTimeList.totalItems = totalItems;
              state.timeManager.compensatoryTimeList.totalPages = Math.ceil(
                totalItems / state.timeManager.compensatoryTimeList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.timeManager.isLoadingCompensatoryTimesList = false;
            })
          );
        });
    },
    resetSelectedPayRoll: () => {
      set(
        produce((state) => {
          state.timeManager.selectedPayRoll = null;
        })
      );
    },
    getPayRoll: (payRollId: string) => {
      set(
        produce((state) => {
          state.timeManager.isLoadingPayRoll = true;
        })
      );

      timeManagerService
        .getPayRoll(payRollId)
        .then((data) => {
          set(
            produce((state) => {
              state.timeManager.selectedPayRoll = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.timeManager.isLoadingPayRoll = false;
            })
          );
        });
    },
    deletePayRoll: (payRollId: string) => {
      set(
        produce((state) => {
          state.timeManager.isDeletingPayRoll = true;
        })
      );

      timeManagerService
        .deletePayRoll(payRollId)
        .then(() => {
          toast.success("Registro excluído com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().timeManager.getPayRolls();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.timeManager.isDeletingPayRoll = false;
            })
          );
        });
    },
    closePayRoll: (payRollId: string, unified: string[], orientation: string) => {
      set(
        produce((state) => {
          state.timeManager.isClosingPayRoll = true;
        })
      );

      timeManagerService
        .closePayRoll(payRollId, unified, orientation)
        .then(() => {
          toast.success("Folha em processo de fechamento");
          useStore.getState().modal.setIsModalOpen(false);
          get().timeManager.getPayRolls();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.timeManager.isClosingPayRoll = false;
            })
          );
        });
    },
    getPayRolls: () => {
      set(
        produce((state) => {
          state.timeManager.isLoadingPayRollsList = true;
        })
      );

      const filters = get().timeManager.payRollsList.filters;
      const limit = get().timeManager.payRollsList.limitPerPage;
      const offset = get().timeManager.payRollsList.offset;

      timeManagerService
        .getPayRolls({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.timeManager.payRollsList.data = data;
              state.timeManager.payRollsList.totalItems = totalItems;
              state.timeManager.payRollsList.totalPages = Math.ceil(
                totalItems / state.timeManager.payRollsList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.timeManager.isLoadingPayRollsList = false;
            })
          );
        });
    },
    getPayRollsWithLimit: (limitPerPage: number) => {
      set(
        produce((state) => {
          state.timeManager.isLoadingPayRollsList = true;
        })
      );

      const filters = get().timeManager.payRollsList.filters;
      const limit = limitPerPage;
      const offset = get().timeManager.payRollsList.offset;

      timeManagerService
        .getPayRollsWithLimit({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.timeManager.payRollsList.data = data;
              state.timeManager.payRollsList.totalItems = totalItems;
              state.timeManager.payRollsList.totalPages = Math.ceil(
                totalItems / state.timeManager.payRollsList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.timeManager.isLoadingPayRollsList = false;
            })
          );
        });
    },
    updatePayRoll: (payRollId: string, payRoll: PayRoll) => {
      set(
        produce((state) => {
          state.timeManager.isUpdatingPayRoll = true;
        })
      );

      timeManagerService
        .updatePayRoll(payRollId, payRoll)
        .then(() => {
          toast.success("Folha de pagamento atualizado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().timeManager.getPayRolls();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.timeManager.isUpdatingPayRoll = false;
            })
          );
        });
    },
    createPayRoll: (payRoll: PayRoll) => {
      set(
        produce((state) => {
          state.timeManager.isCreatingPayRoll = true;
        })
      );

      timeManagerService
        .createPayRoll(payRoll)
        .then(() => {
          toast.success("Folha de pagamento cadastrada com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().timeManager.getPayRolls();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.timeManager.isCreatingPayRoll = false;
            })
          );
        });
    },
    downloadPayRoll: ({collaborators, payrollGroupId}: {collaborators: string[], payrollGroupId: string}) => {
      set(
        produce((state) => {
          state.timeManager.isDownloadingPayRoll = true;
        })
      );

      reportService
        .downloadPayRoll({ users: collaborators, payrollGroupId })
        .then((data: any) => {
          downloadURI(data.fileUrl, `${moment().format("YYYY-MM-DD")}.zip`);
          toast.success("Documento baixado com sucesso");
        })
        .catch((error: any) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.timeManager.isDownloadingPayRoll = false;
            })
          );
        });
    },
    refreshPayRoll: ({collaboratorId, payRollGroupId}: {collaboratorId: string, payRollGroupId: string}) => {
      set(
        produce((state) => {
          state.timeManager.isRefreshingPayRoll[collaboratorId] = true;
        })
      );
      timeManagerService
        .refreshPayRoll({collaboratorId, payRollGroupId})
        .then(() => {
          toast.success("Folha de pagamento atualizada com sucesso");
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.timeManager.isRefreshingPayRoll[collaboratorId] = false;
            })
          );
        });
    },
    deleteCompensatoryTime: (compensatoryTimeId: string) => {
      set(
        produce((state) => {
          state.timeManager.isDeletingCompensatoryTime = true;
        })
      );

      timeManagerService
        .deleteCompensatoryTime(compensatoryTimeId)
        .then(() => {
          toast.success("Registro excluído com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().timeManager.getCompensatoryTimes();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.timeManager.isDeletingCompensatoryTime = false;
            })
          );
        });
    },
    createCompensatoryTimeRegister: (compensatoryTimeRegister: any) => {
      set(
        produce((state) => {
          state.timeManager.isCreatingCompensatoryTimeRegister = true;
        })
      );

      timeManagerService
        .createCompensatoryTimeRegister(compensatoryTimeRegister)
        .then(() => {
          toast.success("Registro de banco de horas criado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().timeManager.getCompensatoryTimes();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.timeManager.isCreatingCompensatoryTimeRegister = false;
            })
          );
        });
    },
    resetPayRollsListState: () => {
      set(
        produce((state) => {
          state.timeManager.payRollsList = {
            ...state.timeManager.payRollsList,
            ...initialPayRollsListData,
          };
        })
      );
    },
    resetCompensatoryTimesListState: () => {
      set(
        produce((state) => {
          state.timeManager.compensatoryTimeList = {
            ...state.timeManager.compensatoryTimeList,
            ...initialCompensatoryTimesListData,
          };
        })
      );
    },
  },
});
