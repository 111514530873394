import DataTable from "../../../components/DataTable/DataTable";
import StatusText from "../../../components/StatusText/StatusText";
import useModal from "../../../hooks/useModal";
import { CompensatoryTime } from "../../../store/types/timeManager";
import { useStore } from "../../../store/useStore";
import {
  COMPENSATORY_TIME_REGISTER_TYPE,
  COMPENSATORY_TIME_REGISTER_TYPE_STATUS_COLOR
} from "../../../utils/constants/constants";
import {
  formatDateTime,
  formatSecondsToHour,
} from "../../../utils/functions/formatters";
import DeleteCompensatoryTimeModal from "./DeleteCompensatoryTimeModal";
import DetailsCompensatoryTimeModal from "./DetailsCompensatoryTimeModal";

const renderReferenceDate = (item: CompensatoryTime) => {
  if (!item?.reference_date && !item?.reference_date_start) return "-";
  if (item.reference_date) {
    return formatDateTime(item.reference_date);
  }
  else if (item.reference_date_start && !item.reference_date_end) {
    return formatDateTime(item.reference_date_start);
  } 
  else {
    return (
      formatDateTime(item.reference_date_start) +
      " - " +
      formatDateTime(item.reference_date_end)
    );
  }
};

const CompensatoryTimeTable = () => {
  const compensatoryTimes = useStore(
    (state) => state.timeManager.compensatoryTimeList.data
  );
  const setFilters = useStore(
    (state) => state.timeManager.compensatoryTimeList.setFilters
  );
  const filters = useStore(
    (state) => state.timeManager.compensatoryTimeList.filters
  );
  const isLoadingCompensatoryTimesList = useStore(
    (state) => state.timeManager.isLoadingCompensatoryTimesList
  );
  const resetSelectedCompensatoryTime = useStore(
    (state) => state.timeManager.resetSelectedCompensatoryTime
  );
  const getCompensatoryTime = useStore(
    (state) => state.timeManager.getCompensatoryTime
  );
  const setSelectedCompensatoryTime = useStore(
    (state) => state.timeManager.setSelectedCompensatoryTime
  );
  const { openModal } = useModal();

  const openDetailsCompensatoryTimeModal = (compensatoryTime: any) => {
    getCompensatoryTime(compensatoryTime?._id ?? "");
    openModal(
      <DetailsCompensatoryTimeModal onClose={resetSelectedCompensatoryTime} />
    );
  };

  const openDeleteCompensatoryTimeModal = (compensatoryTime: any) => {
    setSelectedCompensatoryTime(compensatoryTime);
    openModal(
      <DeleteCompensatoryTimeModal onClose={resetSelectedCompensatoryTime} />
    );
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "user.name", label: "Colaborador" },
    {
      key: "type",
      label: "Tipo",
      render: (item: CompensatoryTime) => item.type.name,

    },
    {
      key: "reference_date",
      label: "Data de referência",
      sortKey: "reference_date",
      render: (item: CompensatoryTime) => renderReferenceDate(item),
    },
    {
      key: "amount",
      label: "Quantidade de horas",
      render: (item: CompensatoryTime) => formatSecondsToHour(item.amount),
    },
    {
      key: "manual",
      label: "Motivo",
      render: (item: CompensatoryTime) => item.manual?.reason?.name ?? '-'
    },
    {
      key: "manual",
      label: "Registro",
      render: (item: CompensatoryTime) => {
        return (
          <div className="flex flex-col justify-center items-center">
            <StatusText
              text={item?.manual ? "Manual" : "Automático"}
              status={
                COMPENSATORY_TIME_REGISTER_TYPE_STATUS_COLOR[
                  item.manual
                    ? COMPENSATORY_TIME_REGISTER_TYPE.MANUAL
                    : COMPENSATORY_TIME_REGISTER_TYPE.AUTOMATIC
                ]
              }
            />
          </div>
        );
      },
    },
  ];

  return (
    <DataTable
      data={compensatoryTimes}
      columns={columns}
      onDetails={openDetailsCompensatoryTimeModal}
      onEdit={openDeleteCompensatoryTimeModal}
      showEditByItem={(item) => !!item?.manual}
      fullObjOnParameters
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingCompensatoryTimesList}
    />
  );
};

export default CompensatoryTimeTable;
