import { reportAPI } from "../api/api";
import { handleServiceError } from "./handleServiceError";

const reportService: any = {
  downloadMacrosReport: async (body: {
    users: string[],
    periodStart: string,
    periodEnd: string
  }) => {
    try {
      const response = await reportAPI.post(`/v1/macro/export/list`, {
        users: body.users,
        period_start: body.periodStart,
        period_end: body.periodEnd,
      });
      return response.data?.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  downloadManualMacrosReport: async (body: {
    users: string[],
    periodStart: string,
    periodEnd: string
  }) => {
    try {
      const response = await reportAPI.post(`/v1/macro/export/manual`, {
        users: body.users,
        period_start: body.periodStart,
        period_end: body.periodEnd,
      });
      return response.data?.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  downloadAlteredMacrosReport: async (body: {
    users: string[],
    periodStart: string,
    periodEnd: string
  }) => {
    try {
      const response = await reportAPI.post(`/v1/macro/export/altered`, {
        users: body.users,
        period_start: body.periodStart,
        period_end: body.periodEnd,
      });
      return response.data?.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  downloadInterjourneyMacrosReport: async (body: {
    users: string[],
    periodStart: string,
    periodEnd: string
  }) => {
    try {
      const response = await reportAPI.post(`/v1/macro/export/interjourney`, {
        users: body.users,
        period_start: body.periodStart,
        period_end: body.periodEnd,
      });
      return response.data?.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  downloadLongestWorkingTimeReport: async (body: {
    users: string[],
    periodStart: string,
    periodEnd: string
  }) => {
    try {
      const response = await reportAPI.post(`/v1/payroll/export/workingtime`, {
        users: body.users,
        period_start: body.periodStart,
        period_end: body.periodEnd,
      });
      return response.data?.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  downloadCreateConsolidatedRestTimeReport: async (body: {
    users: string[],
    periodStart: string,
    periodEnd: string
  }) => {
    try {
      const response = await reportAPI.post(`/v1/payroll/export/consolidated/resttime`, {
        users: body.users,
        period_start: body.periodStart,
        period_end: body.periodEnd,
      });
      return response.data?.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  downloadCreateConsolidatedPaymentHoursReport: async (body: {
    users: string[],
    payrollGroupId: string
  }) => {
    try {
      const response = await reportAPI.post(`/v1/payroll/export/consolidated/paymenthours`, {
        users: body.users,
        payroll_group: body.payrollGroupId
      });
      return response.data?.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  downloadPayroll: async (
    collaborators: string[],
    payrollGroupId: string,
    unified: string[],
    format: string,
    orientation: string,
    consolidated: boolean
  ) => {
    try {
      const response = await reportAPI.post(`/v1/payroll/export`, {
        users: collaborators,
        payroll_group_id: payrollGroupId,
        format,
        unified,
        orientation,
        consolidated
      });
      return response.data?.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  downloadAFDReport: async () => {
    try {
      const response = await reportAPI.get(`/v1/company/export/afd`);
      return response.data?.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  downloadAEJReport: async () => {
    try {
      const response = await reportAPI.get(`/v1/company/export/aej`);
      return response.data?.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  downloadDailiesReport: async ({
    users,
    payrollGroupId,
    format
  }: {
    users: string[],
    payrollGroupId: string,
    format: string
  }) => {
    try {
      const response = await reportAPI.post(`/v1/payroll/export/dailies`, {
        users,
        payroll_group: payrollGroupId,
        format
      });
      return response.data?.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  downloadJFWCustomReport: async (body: {
    users: string[],
    payrollGroupId: string
  }) => {
    try {
      const response = await reportAPI.post(`/v1/payroll/export/custom/jfwhours`, {
        users: body.users,
        payroll_group: body.payrollGroupId
      });
      return response.data?.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  downloadEntitiesReport: async (body: {
    entity: string,
    filters: any
  }) => {
    try {
      const response = await reportAPI.post(`/v1/entity/export/${body.entity}`, 
        { data: body.filters });
      return response.data?.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
};

export default reportService;
