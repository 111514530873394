import { Field, FieldProps, getIn } from "formik";
import Checkbox from "../../../components/FormComponents/Checkbox/Checkbox";
import { PARAMETER_MEASURE, Parameter } from "../../../store/types/journeyRule";
import { useEffect, useState } from "react";
import Tooltip from "../../../components/Tooltip/Tooltip";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import Input from "react-select/dist/declarations/src/components/Input";
const InputMask = require("react-input-mask");

enum WEEK_DAY_SHORT_NAME {
  MONDAY = "Seg.",
  TUESDAY = "Ter.",
  WEDNESDAY = "Qua.",
  THURSDAY = "Qui.",
  FRIDAY = "Sex.",
  SATURDAY = "Sáb.",
  SUNDAY = "Dom.",
}

interface CustomTimeInputProps {
  onChange: (time: string) => void;
  value: string;
}

const CustomTimeInput: React.FC<CustomTimeInputProps> = ({
  onChange,
  value,
}) => {
  const [mask, setMask] = useState<any>(null);

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    if (newValue === "") {
      // If the input is empty, set it to "00:00"
      newValue = "00:00";
    } else if (newValue.length === 3) {
      // If only one digit remains, append "0" to it (e.g., "2" becomes "20")
      newValue = newValue + "0";
    }

    if (
      (value.startsWith("0") || value.startsWith("1")) &&
      newValue.startsWith("2")
    ) {
      newValue = newValue[0] + "0:" + newValue[3] + newValue[4];
    }

    onChange(newValue);
  };

  useEffect(() => {
    setMask([
      /[0-2]/,
      value.startsWith("2") ? /[0-3]/ : /[0-9]/,
      ":",
      /[0-5]/,
      /[0-9]/,
    ]);
  }, [value]);

  return (
    <InputMask
      mask={mask}
      value={value}
      maskPlaceholder={null}
      onChange={handleTimeChange}
    >
      <input
        type="text"
        className="border border-gray_1 px-1 w-[50px] rounded-[8px] outline-none"
      />
    </InputMask>
  );
};

const ParameterValueInput: React.FC<
  FieldProps & { parameter: Parameter | null; index: string }
> = ({ parameter, form, field, index }) => {
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const fieldError = getIn(form.errors, `${field.name}.value`)
    ? getIn(form.errors, `${field.name}.value`)
    : getIn(form.errors, `${field.name}`);
  const fieldTouched = getIn(form.touched, `${field.name}.value`)
    ? getIn(form.touched, `${field.name}.value`)
    : getIn(form.touched, `${field.name}`);
    
  let content: JSX.Element | JSX.Element[] | null = null;

  useEffect(() => {
    if (form.values.parameters && !form.values.parameters[index]?.activation) {
      setDisableInput(true);
      form.setFieldValue(`parameters[${index}].value`, parameter?.default);
    } else setDisableInput(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.parameters[index]?.activation]);

  if (!parameter || !field.value) return null;

  if (parameter.measure === PARAMETER_MEASURE.MULTI_SELECT) {
    content = (
      <div className="flex flex-wrap justify-center">
        {Object.keys(field.value).map((key: string | WEEK_DAY_SHORT_NAME) => (
          <div className="bg-gray_0 p-1 rounded-[7px] mr-2 mb-2">
          <Checkbox
            key={key}
            onChange={() => {
              // const numberOfChecked = Object.keys(field.value).filter(
              //   (checkboxKey) => field.value[checkboxKey].value
              // ).length;

             //if (numberOfChecked === 1 && field.value[key].value) return;

              form.setFieldValue(
                `${field.name}[${key}].value`,
                !field.value[key].value
              );
            }}
            className="w-[14px] h-[14px]"
            labelClassName="font-light font-base"
            checked={field.value[key].value}
            text={
              WEEK_DAY_SHORT_NAME[key as keyof typeof WEEK_DAY_SHORT_NAME]
                ? WEEK_DAY_SHORT_NAME[key as keyof typeof WEEK_DAY_SHORT_NAME]
                : parameter.default[key]?.name
                ? parameter.default[key]?.name
                : key
            }
          />
          </div>
        ))}
      </div>
    );
  } else if (parameter.measure === PARAMETER_MEASURE.CHECK) {
    content = (
      <div>
        <Checkbox
          text=""
          checked={field.value.value}
          onChange={() =>
            form.setFieldValue(`${field.name}.value`, !field.value.value)
          }
        />
      </div>
    );
  } else if (parameter.measure === PARAMETER_MEASURE.TIME) {
    content = (
      <div className="flex justify-center items-center">
        <CustomTimeInput
          value={field.value.value}
          onChange={(value) => form.setFieldValue(`${field.name}.value`, value)}
        />
      </div>
    );
  } else if (parameter.measure === PARAMETER_MEASURE.PERCENT) {
    content = (
      <div className="flex justify-center items-center">
        <InputMask
          mask="999"
          value={String(field.value.value)}
          maskPlaceholder={null}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue =
              e.target.value.trim() === "" ? "0" : e.target.value;
            const numericValue = Number(inputValue);

            // Ensure the value is within the range of 0 to 100
            const clampedValue = Math.min(Math.max(numericValue, 0), 100);

            form.setFieldValue(`${field.name}.value`, clampedValue);
          }}
        >
          <input
            type="text"
            className="border border-gray_1 px-1 w-[45px] rounded-[8px] outline-none"
          />
        </InputMask>
        %
      </div>
    );
  } else if (parameter.measure === PARAMETER_MEASURE.DAY_TIME_INTERVAL) {
    content = (
      <div className="mx-auto w-fit">
        <div className="mb-2">
          <span className="text-gray_3 font-light text-left">Início:</span>{" "}
          <CustomTimeInput
            value={field.value.value[0]}
            onChange={(value) =>
              form.setFieldValue(`${field.name}.value[0]`, value)
            }
          />
        </div>
        <div>
          <span className="text-gray_3 font-light text-left">Final:</span>{" "}
          <CustomTimeInput
            value={field.value.value[1]}
            onChange={(value) =>
              form.setFieldValue(`${field.name}.value[1]`, value)
            }
          />
        </div>
      </div>
    );
  } else if (parameter.measure === PARAMETER_MEASURE.TIME_BY_WEEK_DAY) {
    content = (
      <div className="flex justify-center">
        <div className="px-2 p-1 text-gray_3 font-light text-left flex flex-col gap-[2px]">
          {Object.keys(parameter.default).map((key: any) => (
            <div>
              {WEEK_DAY_SHORT_NAME[key as keyof typeof WEEK_DAY_SHORT_NAME]}
            </div>
          ))}
        </div>
        <div className="px-2 py-1 bg-gray_0 rounded-[7px] ">
          {Object.keys(parameter.default).map((key: any) => (
            <div>
              <CustomTimeInput
                value={field.value[key].value}
                onChange={(value) =>
                  form.setFieldValue(`${field.name}[${key}].value`, value)
                }
              />
            </div>
          ))}
        </div>
      </div>
    );
  } else if (parameter.measure === PARAMETER_MEASURE.SELECT) {
    content = (
      <div className="mx-auto w-fit">
        {Object.keys(parameter.default).map((key: any) => (
          <div
            key={key}
            className="flex gap-2 items-start bg-gray_0 rounded-[7px] px-2 py-1"
          >
            <Checkbox
              onChange={() => {
                Object.keys(parameter.default).map((key: any) =>
                  form.setFieldValue(`${field.name}[${key}].value`, false)
                );

                form.setFieldValue(`${field.name}[${key}].value`, true);
              }}
              className="w-[14px] h-[14px] mt-[3px]"
              checked={field.value[key].value}
            />
            <div className="text-left">{field.value[key].name}</div>
          </div>
        ))}
      </div>
    );
  } else if (parameter.measure === PARAMETER_MEASURE.TEXT) {
    content = (
      <div className="flex justify-center items-center">
        <input
          type="text"
          className="border border-gray_1 px-1 w-[150px] rounded-[8px] outline-none"
          onChange={(e) => form.setFieldValue(`${field.name}.value`, e.target.value)}
          value={field.value.value}
        />
      </div>
    );
  } 
  return (
    <Tooltip
      text="Ative o parâmetro para editar o valor"
      isVisible={disableInput}
    >
      <div style={{ pointerEvents: disableInput ? "none" : "auto" }}>
        {content}
      </div>
      {fieldTouched || fieldError ? (
        <div className="text-red text-sm text-left">{fieldError as string}</div>
      ) : null}
    </Tooltip>
  );
};

export default ParameterValueInput;
