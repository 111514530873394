import './testimonials-style.css';
import { Carousel } from 'primereact/carousel';
import { LogoSanpetro, LogoRodobruno, LogoTransmarreco, SunriseTruck, LogoMantiqueira } from "../../../../assets/imgs";

import { Fade, Slide } from 'react-awesome-reveal';

export default function Testimonials(props: { contentClass: string;}) {
  const clazz = props.contentClass + " padding-testimonials";

  const testimonials = [
    {
      imagePath: LogoMantiqueira,
      imageSize: 170,
      quote: `Sou Maria, faço parte do time Mantiqueira, sendo responsável por todo o controle de jornada dos nossos motoristas. Desde o início, tivemos o prazer de conhecer e iniciar nosso trabalho junto à Jornada Sat. Com a plataforma, conseguimos acesso a informações em tempo real, além de uma ampla variedade de relatórios e um dashboard completo que tem sido essencial para a nossa operação.\nA colaboração com a equipe da Jornada Sat tem sido fundamental para garantir agilidade, cumprimento de prazos e, principalmente, a qualidade no gerenciamento das jornadas. A evolução constante da plataforma tem nos surpreendido e, por isso, estamos ansiosos pelas novas funcionalidades que estão por vir.\n\nGostaria de expressar nosso sincero agradecimento a toda a equipe da Jornada Sat pela parceria, pelo suporte contínuo e pelo comprometimento em nos fornecer as melhores soluções. Vocês têm sido uma peça chave no nosso sucesso, e estamos muito felizes por poder contar com essa colaboração`,
      name: "Maria Clara Oliveira",
      position: "Analista de Logistica, Mantiqueira Brasil"
    },
    {
      imagePath: LogoTransmarreco,
      imageSize: 60,
      quote: `Só tenho elogios ao Jornada Sat, facilitou muito a dinâmica da empresa. O controle de jornada é um sistema claro, limpo de fácil manuseio uma ferramenta imprescindível para qualquer transportadora. Com essa ferramenta a empresa consegue controlar com precisão todos seus colaboradores e em contrapartida se resguardar. Não vejo minha empresa sem o sistema que oferece todo suporte necessário quando solicitado. Agradeço imensamente ao Augusto por me apresentar essa ferramenta maravilhosa que faz toda diferença no meu dia a dia.`,
      name: "Priscila Oliveira",
      position: "Diretora de RH, Transmarreco"
    },
    {
      imagePath: LogoRodobruno,
      imageSize: 170,
      quote: "O sistema Jornada Sat é prático e de fácil utilização, completo dentro das necessidades da nossa empresa. O utilizamos há mais de 1 ano e desde o início tivemos um ótimo atendimento da equipe técnica, que nos atendem sempre com agilidade e disponibilidade sanando nossas dúvidas e auxiliando no que é necessário.",
      name: "Bruna Maíra",
      position: "Gestora de frotas, RodoBruno"
    },
    {
      imagePath: LogoSanpetro,
      imageSize: 170,
      quote: `A SANPETRO adotou o sistema Jornada Sat como parte da nossa estratégia para aprimorar a gestão de informações e processos internos. Desde sua implementação, o programa trouxe mais confiabilidade e segurança às nossas operações, permitindo ajustes importantes nos processos internos e garantindo um compliance trabalhista mais eficaz. O JORNADASAT tem sido uma ferramenta essencial para assegurar que estamos alinhados às melhores práticas do mercado e às exigências legais. Recomendo pela eficiência e suporte oferecidos.`,
      name: "Reuel Maxmiliano Leme da Silva",
      position: "Gerente de Recursos Humanos, SanPetro"
    },
  ];

  const testimonialsTemplate = (testimonial: any) => {
    return (
        <div className="card-testimonial">
          <img className='image-testimonial' src={testimonial.imagePath} alt="Logotipo" style={{
              width: testimonial.imageSize
            }}/>
          <div className="aria-testimonial">
            <div className="aria-testimonial-left">
              <p>"</p>
            </div>
            <div className="aria-testimonial-right">
              <p className="quote-testimonial">{testimonial.quote}</p>
              <p className="name-testimonial">{testimonial.name}</p>
              <p className="position-testimonial">{testimonial.position}</p>
            </div>
          </div>
        </div>
    );
};

  return (
    <section id="testimonials">
      <div  style={{
        backgroundImage: `url(${SunriseTruck})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}>
        <div className={clazz}>
          <Slide cascade damping={0.2}>
            <p className='title-testimonials'>Depoimentos de nossos clientes</p>
            <p className='subtitle-testimonials'>Inspire-se com essas histórias...</p>
          </Slide>

          <div className="card">
            <Fade><Carousel value={testimonials} numVisible={1} numScroll={1} itemTemplate={testimonialsTemplate} autoplayInterval={7000}/></Fade>
        </div>
        </div>
      </div>
    </section>
  );
}