import { SearchNormal1 } from "iconsax-react";
import InfoField from "../../../components/InfoField/InfoField";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import Modal from "../../../components/Modal/Modal";
import StatusText from "../../../components/StatusText/StatusText";
import { CompensatoryTime } from "../../../store/types/timeManager";
import { useStore } from "../../../store/useStore";
import {
  COMPENSATORY_TIME_REGISTER_TYPE,
  COMPENSATORY_TIME_REGISTER_TYPE_STATUS_COLOR,
  REASON_NAME_BY_TYPE,
} from "../../../utils/constants/constants";
import { Colors } from "../../../utils/constants/style";
import {
  formatDateTime,
  formatSecondsToHour,
} from "../../../utils/functions/formatters";

const renderReferenceDate = (item: CompensatoryTime | null) => {
  if (!item?.reference_date && !item?.reference_date_start) return "-";

  if (item.reference_date) {
    return formatDateTime(item.reference_date);
  } else if (item.reference_date_start && !item.reference_date_end) {
    return <div>Início: {formatDateTime(item.reference_date_start)}</div>;
  } else {
    return (
      <>
        <div>Início: {formatDateTime(item.reference_date_start)}</div>
        <div>Fim: {formatDateTime(item.reference_date_end)}</div>
      </>
    );
  }
};

interface DetailsCompensatoryTimeModalProps {
  onClose: () => void;
}

const DetailsCompensatoryTimeModal: React.FC<
  DetailsCompensatoryTimeModalProps
> = ({ onClose }) => {
  const selectedCompensatoryTime = useStore(
    (state) => state.timeManager.selectedCompensatoryTime
  );
  const isLoadingCompensatoryTime = useStore(
    (state) => state.timeManager.isLoadingCompensatoryTime
  );

  return (
    <Modal
      title="Detalhes de banco de horas"
      icon={<SearchNormal1 size="30" color={Colors.white} variant="Linear" />}
      className="min-w-[479px] max-w-[579px]"
      onClose={onClose}
    >
      <div className="flex flex-col gap-5">
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCompensatoryTime}
            label="Colaborador"
            content={selectedCompensatoryTime?.user.name}
          />
          <InfoField
            loading={isLoadingCompensatoryTime}
            label="Tipo"
            content={selectedCompensatoryTime?.type.name}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCompensatoryTime}
            label="Categoria"
            content={selectedCompensatoryTime?.category.name}
          />
          <InfoField
            loading={isLoadingCompensatoryTime}
            label="Registro"
            content={
              <StatusText
                text={
                  selectedCompensatoryTime?.manual ? "Manual" : "Automático"
                }
                status={
                  COMPENSATORY_TIME_REGISTER_TYPE_STATUS_COLOR[
                    selectedCompensatoryTime?.manual
                      ? COMPENSATORY_TIME_REGISTER_TYPE.MANUAL
                      : COMPENSATORY_TIME_REGISTER_TYPE.AUTOMATIC
                  ]
                }
              />
            }
          />
        </InfoFieldBox>
        {selectedCompensatoryTime?.manual ? (
          <InfoFieldBox>
            <InfoField
              loading={isLoadingCompensatoryTime}
              label="Descrição"
              content={selectedCompensatoryTime?.manual?.description}
            />
            <InfoField
              loading={isLoadingCompensatoryTime}
              label="Motivo"
              content={
                selectedCompensatoryTime?.manual?.reason?.type
                  ? REASON_NAME_BY_TYPE[
                      selectedCompensatoryTime?.manual?.reason.type
                    ]
                  : "-"
              }
            />
          </InfoFieldBox>
        ) : null}
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCompensatoryTime}
            label="Data de referência"
            content={renderReferenceDate(selectedCompensatoryTime)}
          />
          <InfoField
            loading={isLoadingCompensatoryTime}
            label="Quantidade de horas"
            content={formatSecondsToHour(selectedCompensatoryTime?.amount)}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCompensatoryTime}
            label="Vencimento"
            content={formatDateTime(selectedCompensatoryTime?.deadline ?? "")}
          />
          <InfoField
            loading={isLoadingCompensatoryTime}
            label="Criado em"
            content={formatDateTime(selectedCompensatoryTime?.created_at ?? "")}
          />
        </InfoFieldBox>
      </div>
    </Modal>
  );
};

export default DetailsCompensatoryTimeModal;
