import { SearchNormal1 } from "iconsax-react";
import InfoField from "../../../components/InfoField/InfoField";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import Modal from "../../../components/Modal/Modal";
import { useStore } from "../../../store/useStore";
import { Colors } from "../../../utils/constants/style";
import { formatDateTime } from "../../../utils/functions/formatters";

interface DetailsFleetModalProps {
  onClose: () => void;
}

const DetailsFleetModal: React.FC<DetailsFleetModalProps> = ({
  onClose,
}) => {
  const selectedFleet = useStore(
    (state) => state.fleet.selectedFleet
  );
  const isLoadingFleet = useStore(
    (state) => state.fleet.isLoadingFleet
  );

  return (
    <Modal
      title="Detalhes do veículo"
      icon={<SearchNormal1 size="30" color={Colors.white} variant="Linear" />}
      className="min-w-[479px]"
      onClose={onClose}
    >
      <div className="flex flex-col gap-5">
        <InfoFieldBox>
          <InfoField
            loading={isLoadingFleet}
            label="Placa"
            content={selectedFleet?.tag}
          />
          <InfoField
            loading={isLoadingFleet}
            label="Frota"
            content={selectedFleet?.fleet}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingFleet}
            label="Criado em"
            content={formatDateTime(selectedFleet?.created_at ?? '')}
          />
        </InfoFieldBox>
      </div>
    </Modal>
  );
};

export default DetailsFleetModal;
