import { useEffect, useState } from "react";
import { useStore } from "../../store/useStore";
import PayRollsGeneral from "./Components/PayRollsGeneral";
import Tabs from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Components/Tab";
import TabPanel from "../../components/Tabs/Components/TabPanel";
import CompensatoryTimeGeneral from "./Components/CompensatoryTimeGeneral";
import { DocumentText, Watch } from "iconsax-react";

const TimeManager: React.FC<{}> = () => {
  const [tab, setTab] = useState<number>(0);

  const resetPayRollsListState = useStore(
    (state) => state.timeManager.resetPayRollsListState
  );

  useEffect(() => {
    return () => {
      resetPayRollsListState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Tabs tab={tab} setTab={setTab}>
        <Tab label="Folha de Pagamento" icon={<DocumentText variant="Bulk" />} />
        <Tab label="Banco de Horas" icon={<Watch variant="Bulk" />} />
      </Tabs>
      <TabPanel tab={tab} index={0}>
        <PayRollsGeneral />
      </TabPanel>
      <TabPanel tab={tab} index={1}>
        <CompensatoryTimeGeneral />
      </TabPanel>
    </div>
  );
};

export default TimeManager;
