import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { Colors } from "../../utils/constants/style";
import Skeleton from "react-loading-skeleton";
import { getIn } from "formik";

const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>
        Nenhum resultado <br />
        encontrado
      </span>
    </components.NoOptionsMessage>
  );
};

export default function AsyncOutlinedSelect(props: any) {
  const { field, placeholder, form, loading } = props;

  const [debouceTimeout, setDebounceTimeout] = useState<number>(0);
  const [hasTyped, setHasTyped] = useState<string>("");

  const filterOptions = (inputValue: any, newOptions: any) => {
    return newOptions.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue: any, callback: any) => {
    clearTimeout(debouceTimeout);
    setDebounceTimeout(
      // @ts-ignore
      setTimeout(async () => {
        const newOptions = await props.callback(inputValue);
        callback(() => filterOptions(inputValue, newOptions));
      }, 1000)
    );
  };
  const handleInputChange = (inputValue: string) => {
    setHasTyped(inputValue); // Set hasTyped to true if inputValue is not empty
  };

  return loading ? (
    <Skeleton height={24} className="mt-[-10px]" width="100%" />
  ) : (
    <div className="relative">
      <span
        className={` absolute text-gray_2 transition-all duration-200 ease-in-out ${
          hasTyped || field.value?.value ? "text-[11px] -top-2.5" : "text-base "
        }`}
      >
        {placeholder}
      </span>
      <AsyncSelect
        onInputChange={handleInputChange}
        components={{ NoOptionsMessage }}
        styles={{
          menu: (base) => ({
            ...base,
            width: "max-content",
            minWidth: "100%",
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            outline: "none",
            boxShadow: "none",
            background: "none",
            minHeight: 26,
            height: 26,
            border: "none",
            borderBottom: "1px solid #C7CCD0",
            borderColor: Colors.gray_1,
            borderRadius: 0,
            "&:hover": {
              boxShadow: "none",
            },
            "&:focus": {
              borderColor: Colors.gray_1,
            },
          }),
          dropdownIndicator: () => ({
            color: Colors.gray_1,
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: "0px",
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            margin: "0",
            color: Colors.black,
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            padding: "0px",
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: '#fff',
            color: "#333",
            "&:hover": {
              backgroundColor: "#f8f9fa",
            },
          }),
          indicatorSeparator: () => ({}),
        }}
        menuPosition="fixed"
        cacheOptions
        loadOptions={loadOptions}
        placeholder={placeholder}
        isClearable={props.isClearable}
        value={props.value}
        name={field.name}
        onChange={
          props.onChange
            ? props.onChange
            : (option: any) => form.setFieldValue(field.name, option.value)
        }
        onBlur={field.onBlur}
        loadingMessage={() => "Buscando..."}
        {...props}
      />
      {getIn(form.errors, `${field.name}.value`) || getIn(form.touched, `${field.name}.value`) ? (
        <div className="text-red text-sm text-left">{getIn(form.errors, `${field.name}.value`) as string}</div>
      ) : null}
    </div>
  );
}
