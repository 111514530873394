import { Field } from "formik";
import DataTable from "../../../components/DataTable/DataTable";
import { Parameter } from "../../../store/types/journeyRule";
import ParameterValueInput from "./ParameterValueInput";
import ToggleSwitch from "../../../components/FormComponents/ToggleSwitch";
import ParameterValueComponent from "./ParameterValueComponent";

interface ChangeParametersDataTableProps {
  data?: any[];
  loading: boolean;
}

const ChangeParametersDataTable: React.FC<ChangeParametersDataTableProps> = ({
  data,
  loading,
}) => {
  const parametersColumns = [
    {
      key: "activation",
      label: "Ativação",
      render: (item: Parameter, index?: number) => (
        <Field
          name={`parameters[${index}].activation`}
          component={ToggleSwitch}
        />
      ),
    },
    { key: "name", label: "Nome" },
    { key: "description", label: "Descrição" },
    {
      key: "value",
      label: "Valor",
      render: (item: Parameter, index?: number) => (
        <div className="flex justify-center items-center">
          <Field
            name={`parameters[${index}].value`}
            parameter={item}
            index={index}
            component={ParameterValueInput}
          />
        </div>
      ),
    },
    {
      key: "measure",
      label: "Valor padrão",
      render: (item: Parameter) => (
        <div className="flex justify-center items-center">
          <ParameterValueComponent parameter={item} />
        </div>
      ),
    },
  ];

  return (
    <DataTable
      data={data ?? []}
      columns={parametersColumns}
      onDetails={null}
      onEdit={null}
      loading={loading}
    />
  );
};

export default ChangeParametersDataTable;
