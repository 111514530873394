import DataTable from "../../../components/DataTable/DataTable";
import useModal from "../../../hooks/useModal";
import { Section, SectionsVisibleColumns } from "../../../store/types/convention";
import { useStore } from "../../../store/useStore";
import {
  formatDateTime,
} from "../../../utils/functions/formatters";
import DetailsSectionModal from "./DetailsSectionModal";
import EditSectionModal from "./EditSectionModal";
import EditSyndicateModal from "./EditSyndicateModal";

const SectionsTable = () => {
  const sections = useStore((state) => state.conventions.sectionsList.data);
  const setFilters = useStore(
    (state) => state.conventions.sectionsList.setFilters
  );
  const filters = useStore((state) => state.conventions.sectionsList.filters);
  const isLoadingSectionsList = useStore(
    (state) => state.conventions.isLoadingSectionsList
  );
  const resetSelectedSection = useStore((state) => state.conventions.resetSelectedSection);
  const getSection = useStore((state) => state.conventions.getSection);
  const { openModal } = useModal();
  const columnsVisibility = useStore(
    (state) => state.conventions.sectionsList.columnsVisibility
  );

  const openDetailsSectionModal = (sectionId: string) => {
    getSection(sectionId);
    openModal(<DetailsSectionModal onClose={resetSelectedSection} />);
  };

  const openEditSectionModal = (sectionId: string) => {
    getSection(sectionId);
    openModal(<EditSectionModal onClose={resetSelectedSection} />);
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "name", label: "Nome", sortKey: "name" },
    { key: "description", label: "Descrição" },
    {
      key: "created_at",
      label: "Criado em",
      render: (item: Section) => formatDateTime(item.created_at),
    },
  ].filter((column) => {
    return columnsVisibility[column.key as keyof SectionsVisibleColumns];
  });

  return (
    <DataTable
      data={sections}
      columns={columns}
      onDetails={openDetailsSectionModal}
      onEdit={openEditSectionModal}
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingSectionsList}
    />
  );
};

export default SectionsTable;
