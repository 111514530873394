import { useEffect, useState } from "react";
import { ClockIcon } from "../../assets/imgs";
import { formatTime } from "../../utils/functions/formatters";

const TimeCounter: React.FC<{ startDate?: string }> = ({ startDate }) => {
    const [elapsedTime, setElapsedTime] = useState<number | null>(null);
  
    useEffect(() => {
      if (startDate === undefined) {
        setElapsedTime(null);
        return;
      }
  
      const parsedStartDate = new Date(startDate);
  
      if (isNaN(parsedStartDate.getTime())) {
        setElapsedTime(null);
        return;
      }
      const currentTime = new Date();
      const initialTimeDifferenceInSeconds = Math.floor((currentTime.getTime() - parsedStartDate.getTime()) / 1000);
  
      setElapsedTime(initialTimeDifferenceInSeconds);
  
      const intervalId = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime !== null ? prevElapsedTime + 1 : null);
      }, 1000);
  
      return () => {
        clearInterval(intervalId);
      };
    }, [startDate]);
    
    return (
      <div className="flex items-center text-sm gap-1">
        <ClockIcon />
        <span>{elapsedTime !== null ? (
          <p>{formatTime(elapsedTime)}</p>
        ) : (
          <p>Data inválida</p>
        )}</span>
      </div>
    );
  };

export default TimeCounter