import { FieldProps } from "formik";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { CloseIcon } from "../../assets/imgs";
import { Colors } from "../../utils/constants/style";

interface TagsInputProps extends FieldProps {
  name: string;
}

interface CustomTagsInputProps {
  disabled?: string;
}

const TagsInput: React.FC<CustomTagsInputProps & TagsInputProps> = ({ field, form, disabled = false }) => {
  const tags = field.value || [];
  const [inputValue, setInputValue] = useState<string>("");

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    if(disabled) return;
    const value = e.target.value;
    // Allow only numeric input
    setInputValue(value.replace(/[^0-9]/g, ""));
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key !== "Enter") return;
    const value = inputValue.trim();
    if (!value) return;
    form.setFieldValue(field.name, [...tags, value]);
    setInputValue("");
  }

  function removeTag(index: number) {
    form.setFieldValue(
      field.name,
      tags.filter((el: any, i: any) => i !== index)
    );
  }

  return (
    <div className="border border-gray_1 p-2 bg-white rounded-md max-w-[600px] w-full flex flex-wrap gap-2 items-start">
      {tags.map((tag: any, index: any) => (
        <div
          key={index}
          className="bg-red px-2 rounded-full flex gap-2 items-center"
        >
          <span
            className="text-white rounded-full cursor-pointer"
            onClick={() => removeTag(index)}
          >
            <CloseIcon width={10} height={10} color={Colors.white} />
          </span>
          <span className="text-white">{tag}</span>
        </div>
      ))}
      <input
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onKeyPress={(e) => {
          e.which === 13 && e.preventDefault();
        }}
        className="border-none outline-none"
        placeholder=""
      />
    </div>
  );
};

export default TagsInput;