import DataTable from "../../../components/DataTable/DataTable";
import useModal from "../../../hooks/useModal";
import { Parameter } from "../../../store/types/journeyRule";
import { useStore } from "../../../store/useStore";
import { formatDateTime } from "../../../utils/functions/formatters";
import DetailsParameterModal from "./DetailsParameterModal";
import ParameterValueComponent from "./ParameterValueComponent";

const JourneyRulesTable = () => {
  const parameters = useStore(
    (state) => state.journeyRules.parametersList.data
  );
  const setFilters = useStore(
    (state) => state.journeyRules.parametersList.setFilters
  );
  const filters = useStore(
    (state) => state.journeyRules.parametersList.filters
  );
  const isLoadingParametersList = useStore(
    (state) => state.journeyRules.isLoadingParametersList
  );
  const resetSelectedParameter = useStore(
    (state) => state.journeyRules.resetSelectedParameter
  );
  const getParameter = useStore((state) => state.journeyRules.getParameter);
  const { openModal } = useModal();

  const openDetailsParameterModal = (parameterId: string) => {
    getParameter(parameterId);
    openModal(<DetailsParameterModal onClose={resetSelectedParameter} />);
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "code", label: "Código", sortKey: "code" },
    { key: "name", label: "Nome", sortKey: "name" },
    { key: "description", label: "Descrição", sortKey: "description" },
    {
      key: "measure",
      label: "Valor padrão",
      render: (item: Parameter) => (
        <div className="flex justify-center items-center">
          <ParameterValueComponent parameter={item} />
        </div>
      ),
    },
    {
      key: "created_at",
      label: "Criado em",
      render: (item: Parameter) => formatDateTime(item.created_at),
    },
  ];

  return (
    <DataTable
      data={parameters}
      columns={columns}
      onDetails={openDetailsParameterModal}
      onEdit={null}
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingParametersList}
    />
  );
};

export default JourneyRulesTable;
