export const TextStatuses = {
  success: "success",
  warning: "warning",
  danger: "danger",
  unlinked: "unlinked",
  linked: "linked",
  default: "default",
} as const;

export type TextStatusesList = keyof typeof TextStatuses;

const StatusText: React.FC<{ text: string; status: TextStatusesList }> = ({
  text,
  status,
}) => {
  let statusClassName = "bg-light-green text-green";
  switch (status) {
    case TextStatuses.warning:
      statusClassName = "bg-light-orange text-orange";
      break;
    case TextStatuses.success:
      statusClassName = "bg-light-green text-green";
      break;
    case TextStatuses.danger:
      statusClassName = "bg-light-red text-red";
      break;
    case TextStatuses.unlinked:
      statusClassName = "bg-light-purple text-purple";
      break;
    case TextStatuses.linked:
      statusClassName = "bg-light-blue text-blue";
      break;
    default:
      statusClassName = "bg-gray_3 text-black";
      break;
  }
  return (
    <div
      className={`w-fit rounded-[8px] text-sm font-bold px-2 py-1 ${statusClassName}`}
    >
      {text}
    </div>
  );
};

export default StatusText;
