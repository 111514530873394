import { useEffect } from "react";
import DataPanel from "../../../components/DataPanel/DataPanel";
import Pagination from "../../../components/Pagination/Pagination";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import useModal from "../../../hooks/useModal";
import CollaboratorsTable from "./CollaboratorsTable";
import CreateCollaboratorModal from "./CreateCollaboratorModal";
import FilterCollaboratorModal from "./FilterCollaboratorModal";
import OptionsCollaboratorModal from "./OptionsCollaboratorModal";
import { useStore } from "../../../store/useStore";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import Skeleton from "react-loading-skeleton";
import { AddCircle, Filter, Setting2 } from "iconsax-react";

const CollaboratorsGeneral: React.FC<{}> = () => {
  const { openModal } = useModal();
  const getCollaborators = useStore((state) => state.collaborator.getCollaborators);
  const collaboratorsList = useStore(
    (state) => state.collaborator.collaboratorsList
  );
  const isLoadingCollaboratorsList = useStore(
    (state) => state.collaborator.isLoadingCollaboratorsList
  );
  const hasPermissions = useStore(
    (state) => state.admin.hasPermissions
  );

  useEffect(() => {
    getCollaborators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateCollaboratorModal = () =>
    openModal(<CreateCollaboratorModal />);
  const openFilterCollaboratorModal = () =>
    openModal(<FilterCollaboratorModal />);
  const openOptionsCollaboratorModal = () =>
    openModal(<OptionsCollaboratorModal />);

  return (
    <>
      <div className="mt-9">
        <DataPanel className="px-3 pt-8 flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="pl-1">
              {/* <SearchInput placeholder="Buscar registro" onChange={() => {}} /> */}
            </div>
            <div className="pr-1 flex gap-4">
              <RoundedFilledButton
                onClick={openCreateCollaboratorModal}
                icon={<AddCircle />}
                text="Novo"
                show={hasPermissions([PERMISSIONS.CREATE_HOUR_BANK_RECORD])}
              />
              <RoundedFilledButton
                onClick={openFilterCollaboratorModal}
                icon={<Filter />}
                text="Filtros"
              />
              <RoundedFilledButton
                onClick={openOptionsCollaboratorModal}
                icon={<Setting2 />}
                text="Opções"
              />
            </div>
          </div>
          <CollaboratorsTable />
          <div className="flex justify-end items-center">
            <div className="flex gap-5 items-center">
              <LimitPerPage
                onLimitChange={(e: any) =>
                  collaboratorsList.setLimitPerPage(e.target.value)
                }
                total={collaboratorsList.totalItems}
              />
              <Pagination
                currentPage={collaboratorsList.currentPage}
                totalPages={collaboratorsList.totalPages}
                onPageChange={collaboratorsList.setCurrentPage}
              />
            </div>
          </div>
        </DataPanel>
      </div>
    </>
  );
};

export default CollaboratorsGeneral;
