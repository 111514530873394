import SimpleSelect from "../SimpleSelect/SimpleSelect";

interface LimitPerPageProps {
  onLimitChange: any;
  total?: number | undefined;
}

const LimitPerPage: React.FC<LimitPerPageProps> = ({ onLimitChange, total }) => {
  return (
    <div className="flex gap-2 items-center">
      <div className="text-gray_1 text-sm">Mostrando</div>
      <div>
        <SimpleSelect
          options={[
            { label: "10", value: 10 },
            { label: "20", value: 20 },
            { label: "30", value: 30 },
          ]}
          onChange={onLimitChange}
        />
      </div>
      {total != null ?
        <div className="text-gray_1 text-sm"> de {total} registros</div>
        : <div className="text-gray_1 text-sm"> registros</div>
      }
    </div>
  );
};

export default LimitPerPage;
