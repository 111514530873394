import { Field } from "formik";
import DataTable from "../../../components/DataTable/DataTable";
import { Parameter, PayRoll } from "../../../store/types/journeyRule";
import BorderedSelect from "../../../components/FormComponents/BorderedSelect/BorderedSelect";

interface ChangePayRollsDataTableProps {
  data?: any[];
  loading: boolean;
  payRolls: any[];
}

const ChangePayRollsDataTable: React.FC<ChangePayRollsDataTableProps> = ({
  data,
  loading,
  payRolls,
}) => {
  const journeyEventsColumns = [
    { key: "name", label: "Nome" },
    {
      key: "payRolls",
      label: "Evento de Folha",
      render: (item: Parameter, index?: number) => (
        <div className="flex justify-center items-center">
          <Field
            name={`payRolls[${index}].value`}
            component={BorderedSelect}
            options={
              payRolls?.map((p: PayRoll) => {
                return { label: p.name, value: p.type };
              }) ?? []
            }
          />
        </div>
      ),
    },
  ];
  
  return (
    <DataTable
      data={data ?? []}
      columns={journeyEventsColumns}
      onDetails={null}
      onEdit={null}
      loading={loading}
    />
  );
};

export default ChangePayRollsDataTable;
