import { useEffect, useState } from "react";
import TabPanel from "../../components/Tabs/Components/TabPanel";
import { useStore } from "../../store/useStore";
import ReportGeneral from "./Components/ReportGeneral";

const Reports: React.FC<{}> = () => {
  const [tab, setTab] = useState<number>(0);
  const resetJourneyEventsListState = useStore(state => state.macro.resetJourneyEventsListState);
  const resetMacrosListState = useStore(state => state.macro.resetMacrosListState);

  useEffect(() => {
    return () => {
      resetJourneyEventsListState();
      resetMacrosListState();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {/* <Tabs tab={tab} setTab={setTab}>
        <Tab label="Visão Geral" icon={<Home variant="Bulk" />} />
      </Tabs> */}
      <TabPanel tab={tab} index={0}>
        <ReportGeneral />
      </TabPanel>
    </div>
  );
}

export default Reports;