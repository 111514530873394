import Skeleton from "react-loading-skeleton";
import { Status } from "../../../store/types/dashboard";

const StatusFilter: React.FC<{
    icon: any;
    label: string;
    values: number[];
    limitTimes: [string, string, string],
    keys: [keyof Status, keyof Status, keyof Status];
    loading: boolean;
    selectedKey: keyof Status;
    onClick: (statusKey: keyof Status) => void;
  }> = ({ icon, label, values, loading, keys, limitTimes, onClick, selectedKey }) => {
    return (
      <div className="flex justify-between items-center gap-2">
        <div className="flex flex-col items-center justify-center w-[75px]">
          <div>{icon}</div>
          <div className="text-sm text-gray_3 font-medium">{label}</div>
        </div>
        <div className="flex">
          <div className={`mr-0 pr-0 p-2 ${selectedKey !== keys[0] ? 'bg-gray_0' : 'bg-gray_08'} hover:bg-gray_08 flex items-center`}>
            <div
              className="flex flex-col items-center hover:cursor-pointer"
              onClick={() => onClick(keys[0])}
            >
              <div className="text-2xl font-bold text-gray_3">
                {loading ? <Skeleton width={15} /> : values[0]}
              </div>
              <div className="text-[9px] rounded-[12px] bg-[#51A112] text-white px-[6px] pt-[1.5px]">
                {limitTimes[0]}
              </div>
            </div>
            <div className="ml-2 inline-block w-[2px] h-[40px] bg-gray_1"></div>
          </div>
          <div className={`m-0 pl-2 ${selectedKey !== keys[1] ? 'bg-gray_0' : 'bg-gray_08'} hover:bg-gray_08 flex items-center`}>
            <div
              className="flex flex-col items-center hover:cursor-pointer"
              onClick={() => onClick(keys[1])}
            >
              <div className="text-2xl font-bold text-gray_3">
                {loading ? <Skeleton width={15} /> : values[1]}
              </div>
              <div className="text-[9px] rounded-[12px] bg-[#E6AE5B] text-white px-[6px] pt-[1.5px]">
                {limitTimes[1]}
              </div>
            </div>
            <div className="ml-2 inline-block w-[2px] h-[40px] bg-gray_1"></div>
          </div>
          <div className={`m-0 px-2 ${selectedKey !== keys[2] ? 'bg-gray_0' : 'bg-gray_08'} hover:bg-gray_08 flex items-center`}>
            <div
              className="flex flex-col items-center hover:cursor-pointer"
              onClick={() => onClick(keys[2])}
            >
              <div className="text-2xl font-bold text-gray_3">
                {loading ? <Skeleton width={15} /> : values[2]}
              </div>
              <div className="text-[9px] rounded-[12px] bg-red text-white px-[6px] pt-[1.5px]">
                {limitTimes[2]}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default StatusFilter