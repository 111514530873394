import { CloseIcon } from "../../assets/imgs";
import useModal from "../../hooks/useModal";

interface ModalProps {
  title: string | JSX.Element;
  icon: JSX.Element;
  children: JSX.Element | JSX.Element[];
  className?: string;
  childrenClassName?: string;
  titleClassName?: string;
  onClose?: () => void;
  notClosable?: boolean; // Don't allow close modal, should close only when call closeModal()
}

export const Modal: React.FC<ModalProps> = ({
  title,
  icon,
  children,
  className='',
  titleClassName='text-dark-blue font-bold',
  onClose,
  childrenClassName='px-5 pb-5 overflow-y-auto',
  notClosable=false
}) => {
  const { closeModal } = useModal();

  const handleCloseModal = () => {
    if(onClose)
      onClose();

    if(!notClosable)
      closeModal();
  }

  document.onkeydown = function (evt) {
    if (evt.keyCode == 27) handleCloseModal();
};

  const handleOnBackDropClick = (e: any) => {
    if (e.target.id === "backdrop") handleCloseModal();
  };

  return (
    <div
      id="backdrop"
      className="bg-black bg-opacity-50 fixed inset-0 flex items-center justify-center z-50"
    >
      <div
        className={`bg-white shadow-base min-w-[340px] max-h-[80%] flex flex-col overflow-y-hidden md:rounded-[4px] ${className}`}
      >
        <div className="bg-red w-[64px] h-[64px] ml-5 mt-[-20px] absolute z-60 rounded-[4px] flex items-center justify-center">
          {icon}
        </div>
        <div className="relative my-5">
          <h1 className={`ml-[95px] ${titleClassName}`}>{title}</h1>
          {!notClosable && <div
            onClick={handleCloseModal}
            className="cursor-pointer absolute right-5 top-0 p-3 rounded-full transition duration-200 ease-in-out hover:bg-gray_0"
          >
            <CloseIcon />
          </div>}
        </div>
        <div className={`${childrenClassName}`}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
