import { PlusIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import { POSITION_OPTIONS } from "../../../utils/constants/permissions";
import { useStore } from "../../../store/useStore";
import OutlinedMaskedInput from "../../../components/FormComponents/OutlinedMaskedInput/OutlinedMaskedInput";
import { removeNumberMask } from "../../../utils/functions/formatters";
import { filterObject } from "../../../utils/functions/functions";
import { Collaborator } from "../../../store/types/collaborator";
import CustomDatePicker from "../../../components/FormComponents/CustomDatePicker/CustomDatePicker";

const initialValues = {
  name: "",
  email: "",
  phone: "",
  registration: "",
  position: {
    type: "",
  },
  cpf: "",
  rg: "",
  pis: "",
  cnh: "",
  password: "",
  hired_at: "",
};

const validationSchema = {
  name: Yup.string().required("Campo obrigatório"),
  email: Yup.string().email("Formato de e-mail inválido"),
  phone: Yup.string()
    .length(19, "O telefone deve conter exatamente 13 números"),
  registration: Yup.string(),
  position: Yup.object().shape({
    type: Yup.string().required("Campo obrigatório"),
  }),
  cpf: Yup.string()
    .matches(
      /\d{2}\.\d{3}\.\d{3}-\d{2}$/,
      "O CPF deve conter exatamente 11 números"
    )
    .required("Campo obrigatório"),
  rg: Yup.string().max(13, "O RG deve conter no máximo 13 números"),
  pis: Yup.string().length(11, "O PIS deve conter exatamente 11 números"),
  cnh: Yup.string().max(12, "A CNH deve conter no máximo 12 números"),
  password: Yup.string(),
  hired_at: Yup.string().required("Campo obrigatório"),
};

const CreateCollaboratorModal: React.FC<{}> = () => {
  const createCollaborator = useStore(
    (state) => state.collaborator.createCollaborator
  );
  const isCreatingCollaborator = useStore(
    (state) => state.collaborator.isCreatingCollaborator
  );
  const adminPositionType = useStore((state) => state.admin.data.position.type);
  const userPropertiesProjection = useStore(
    (state) => state.admin.data.projection.user
  );

  const initialValuesFiltered = filterObject(initialValues, [
    ...userPropertiesProjection,
    "password",
  ]);

  const validationSchemaFiltered = filterObject(validationSchema, [
    ...userPropertiesProjection,
    "password",
  ]);

  return (
    <Modal
      title="Cadastro de novo colaborador"
      icon={<PlusIcon width={30} height={30} />}
      className="w-[447px]"
    >
      <Formik
        initialValues={initialValuesFiltered}
        validationSchema={Yup.object().shape(validationSchemaFiltered)}
        onSubmit={(values) => {
          values = {
            ...values,
            ...(values.cpf ? { cpf: removeNumberMask(values.cpf) } : {}),
            ...(values.phone ? { phone: removeNumberMask(values.phone) } : {}),
          };
          delete values.company;
          delete values.deleted_at;
          delete values.updated_at;
          delete values.created_at;
          createCollaborator(values as Collaborator);
        }}
      >
        <Form>
          <div className="flex flex-col gap-8 pt-5">
            <div className="flex gap-8">
              <div className="flex-1 flex flex-col gap-8">
                <Field
                  placeholder="Nome"
                  name="name"
                  component={OutlinedInput}
                />
                <Field
                  placeholder="E-mail"
                  name="email"
                  component={OutlinedInput}
                />
                <Field
                  placeholder="Telefone"
                  name="phone"
                  component={OutlinedMaskedInput}
                  mask={"+99 (99) 99999-9999"}
                  alwaysShowMask={false}
                />
                <Field
                  placeholder="Matrícula"
                  name="registration"
                  component={OutlinedInput}
                />
                <Field
                  name="position.type"
                  component={OutlinedSelect}
                  placeholder="Cargo"
                  options={POSITION_OPTIONS[adminPositionType]}
                />
              </div>
              <div className="flex-1 flex flex-col gap-8">
                <Field
                  placeholder="CPF"
                  name="cpf"
                  component={OutlinedMaskedInput}
                  mask="999.999.999-99"
                />
                <Field
                  placeholder="RG"
                  name="rg"
                  component={OutlinedMaskedInput}
                  mask="999999999"
                />
                <Field
                  placeholder="CNH"
                  name="cnh"
                  component={OutlinedMaskedInput}
                  mask="999999999999"
                />
                <Field
                  placeholder="Senha"
                  name="password"
                  type="password"
                  component={OutlinedInput}
                />
                <Field
                  placeholder="PIS"
                  name="pis"
                  component={OutlinedMaskedInput}
                  mask="99999999999"
                />
              </div>
            </div>
            <div>
              <div className="flex">
                <Field
                  placeholder="Data de admissão"
                  name="hired_at"
                  component={CustomDatePicker}
                />
              </div>
            </div>
            <div className="flex gap-5">
              <div className="flex-1"></div>
              <div className="flex-1">
                <FilledButton
                  loading={isCreatingCollaborator}
                  className="w-full"
                  type="submit"
                >
                  Cadastrar
                </FilledButton>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default CreateCollaboratorModal;
