import DataTable from "../../../components/DataTable/DataTable";
import useModal from "../../../hooks/useModal";
import { CompaniesVisibleColumns, Company } from "../../../store/types/company";
import { useStore } from "../../../store/useStore";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import {
  formatAddress,
  formatCNPJ,
  formatDateTime,
} from "../../../utils/functions/formatters";
import DetailsCompanyModal from "./DetailsCompanyModal";
import EditCompanyModal from "./EditCompanyModal";

const CompaniesTable = () => {
  const { openModal } = useModal();

  const getCompany = useStore((state) => state.company.getCompany);
  const resetSelectedCompany = useStore(
    (state) => state.company.resetSelectedCompany
  );
  const companies = useStore((state) => state.company.companiesList.data);
  const setFilters = useStore(
    (state) => state.company.companiesList.setFilters
  );
  const filters = useStore((state) => state.company.companiesList.filters);
  const columnsVisibility = useStore(
    (state) => state.company.companiesList.columnsVisibility
  );
  const isLoadingCompaniesList = useStore(
    (state) => state.company.isLoadingCompaniesList
  );
  const hasPermissions = useStore((state) => state.admin.hasPermissions);

  const openDetailsCompanyModal = (collaboratorId: string) => {
    getCompany(collaboratorId);
    openModal(<DetailsCompanyModal onClose={resetSelectedCompany} />);
  };

  const openEditCompanyModal = (collaboratorId: string) => {
    getCompany(collaboratorId);
    openModal(<EditCompanyModal onClose={resetSelectedCompany} />);
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "name", label: "Nome", sortKey: "name" },
    {
      key: "cnpj",
      label: "CNPJ",
      sortKey: "cnpj",
      render: (item: Company) => formatCNPJ(item.cnpj),
    },
    {
      key: "address",
      label: "Endereço",
      render: (item: Company) => formatAddress(item.address),
    },
    {
      key: "created_at",
      label: "Criado em",
      render: (item: Company) => formatDateTime(item.created_at),
      sortKey: "created_at",
    },
  ];
  const columnsVisible = columns.filter((column) => {
    if (column.key === "address") return columnsVisibility["address"];
    return columnsVisibility[column.key as keyof CompaniesVisibleColumns];
  });

  return (
    <DataTable
      data={companies}
      columns={columnsVisible}
      onDetails={
        hasPermissions([PERMISSIONS.READ_COMPANY])
          ? openDetailsCompanyModal
          : null
      }
      onEdit={
        hasPermissions([PERMISSIONS.UPDATE_COMPANY])
          ? openEditCompanyModal
          : null
      }
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingCompaniesList}
    />
  );
};

export default CompaniesTable;
