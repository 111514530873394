import { useEffect } from "react";
import DataPanel from "../../../components/DataPanel/DataPanel";
import Pagination from "../../../components/Pagination/Pagination";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import SearchInput from "../../../components/SearchInput/SeachInput";
import useModal from "../../../hooks/useModal";
import { useStore } from "../../../store/useStore";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import Skeleton from "react-loading-skeleton";
import SyndicatesTable from "./SyndicatesTable";
import { FilterIcon, OptionsIcon, PlusIcon } from "../../../assets/imgs";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import CreateSyndicateModal from "./CreateSyndicateModal";
import OptionsSyndicateModal from "./OptionsSyndicateModal";
import FilterSyndicateModal from "./FilterSyndicateModal";
import { AddCircle, Filter, Setting2 } from "iconsax-react";

const SyndicatesGeneral: React.FC<{}> = () => {
  const { openModal } = useModal();
  const getSyndicates = useStore((state) => state.conventions.getSyndicates);
  const syndicatesList = useStore((state) => state.conventions.syndicatesList);
  const isLoadingSyndicatesList = useStore(
    (state) => state.conventions.isLoadingSyndicatesList
  );
  const hasPermissions = useStore((state) => state.admin.hasPermissions);

  useEffect(() => {
    getSyndicates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateSyndicateModal = () => openModal(<CreateSyndicateModal />);
  const openFilterSyndicateModal = () => openModal(<FilterSyndicateModal />);
  const openOptionsSyndicateModal = () => openModal(<OptionsSyndicateModal />);

  return (
    <>
      <div className="mt-9">
        <DataPanel className="px-3 pt-8 flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="pl-1">
              {/* <SearchInput placeholder="Buscar registro" onChange={() => {}} /> */}
            </div>
            <div className="pr-1 flex gap-4">
              <RoundedFilledButton
                onClick={openCreateSyndicateModal}
                icon={<AddCircle />}
                text="Novo"
                show={hasPermissions([PERMISSIONS.CREATE_SYNDICATE])}
              />
              <RoundedFilledButton
                onClick={openFilterSyndicateModal}
                icon={<Filter size={24} />}
                text="Filtros"
              />
              <RoundedFilledButton
                onClick={openOptionsSyndicateModal}
                icon={<Setting2 />}
                text="Opções"
              />
            </div>
          </div>
          <SyndicatesTable />
          <div className="flex justify-between items-center">
            <div className="text-gray_1">
              Mostrando{" "}
              {!isLoadingSyndicatesList ? (
                syndicatesList.data.length +
                syndicatesList.offset * syndicatesList.limitPerPage
              ) : (
                <Skeleton inline width={20} />
              )}{" "}
              de {syndicatesList.totalItems} registros
            </div>
            <div className="flex gap-5 items-center">
              <LimitPerPage
                onLimitChange={(e: any) =>
                  syndicatesList.setLimitPerPage(e.target.value)
                }
              />
              <Pagination
                currentPage={syndicatesList.currentPage}
                totalPages={syndicatesList.totalPages}
                onPageChange={syndicatesList.setCurrentPage}
              />
            </div>
          </div>
        </DataPanel>
      </div>
    </>
  );
};

export default SyndicatesGeneral;
