import moment from "moment";
import React from "react";
import { Macro, MacroDataToUpdate, MacroTimelineItem } from "../../../store/types/macroState";

interface MacroTimelineProps {
  objects: MacroTimelineItem[];
  macroId: string;
  isNew?: boolean;
  selectedMacro?: Macro;
  updatedMacro?: MacroDataToUpdate | null;
}

const fillBlockClassName = (
  object: MacroTimelineItem,
  macroId: string,
  isNew: boolean
) => {
  if (macroId === object._id) {
    if (isNew) {
      return "text-white bg-red font-bold";
    } else {
      return "text-white bg-gray_1 font-bold";
    }
  } else {
    return "border border-[#AFAFAF] text-gray_2";
  }
};

const renderTimeline = (
  objects: MacroTimelineItem[],
  macroId: string,
  isNew: boolean,
  selectedMacro?: Macro,
  updatedMacro?: MacroDataToUpdate | null
) => {
  const DEFAULT_WIDTH = 150;
  const WIDTH_VARIATION = 30;
  const changedMacro = {
    index: objects.findIndex(
      (object: MacroTimelineItem) => object._id === macroId
    ),
    width: DEFAULT_WIDTH,
  };
  let previousMacro = { index: changedMacro.index - 1, width: DEFAULT_WIDTH };
  let nextMacro = { index: changedMacro.index + 1, width: DEFAULT_WIDTH };

  if (updatedMacro && selectedMacro) {
    const increasedStartTime = moment(updatedMacro.started_at).isAfter(
      selectedMacro.started_at,
      "minutes"
    );
    const descreasedStartTime = moment(updatedMacro.started_at).isBefore(
      selectedMacro.started_at,
      "minutes"
    );
    const descreasedEndTime = moment(updatedMacro.ended_at).isBefore(
      selectedMacro.ended_at,
      "minutes"
    );
    const increasedEndTime = moment(updatedMacro.ended_at).isAfter(
      selectedMacro.ended_at,
      "minutes"
    );

    if (descreasedStartTime) {
      previousMacro.width -= WIDTH_VARIATION;
      changedMacro.width += WIDTH_VARIATION;
    } else if (increasedStartTime) {
      previousMacro.width += WIDTH_VARIATION;
      changedMacro.width -= WIDTH_VARIATION;
    }

    if (descreasedEndTime) {
      nextMacro.width += WIDTH_VARIATION;
      changedMacro.width -= WIDTH_VARIATION;
    } else if (increasedEndTime) {
      nextMacro.width -= WIDTH_VARIATION;
      changedMacro.width += WIDTH_VARIATION;
    }
  }

  return objects.map((object, index) => {
    const startTime = new Date(object.started_at);
    const endTime = new Date(object.ended_at);
    let width = DEFAULT_WIDTH;

    if (index === nextMacro.index) width = nextMacro.width;
    else if (index === previousMacro.index) width = previousMacro.width;
    else if (index === changedMacro.index) width = changedMacro.width;

    let renderStart = true;
    let renderEnd = true;

    const nextObjectExist = !!objects[index + 1];
    if (nextObjectExist) {
      renderStart = true;
      renderEnd = false;
    }

    return (
      <div
        key={index}
        className={`${fillBlockClassName(
          object,
          macroId,
          isNew
        )} text-sm text-center items-center py-1 relative mb-5`}
        style={{ width: `${width}px` }}
      >
        <div className="flex justify-center items-center h-full">
          <div>{object.journey_event ?? '-'}</div>
        </div>
        {renderStart && (
          <div className="text-xs text-black absolute -bottom-6 font-normal">
            {startTime.getHours()}h
            {String(startTime.getMinutes()).padStart(2, "0")}
          </div>
        )}
        {renderEnd && (
          <div className="text-xs text-black absolute -bottom-6 -right-0 font-normal">
            {endTime.getHours()}h{String(endTime.getMinutes()).padStart(2, "0")}
          </div>
        )}
      </div>
    );
  });
};

const MacroTimeline: React.FC<MacroTimelineProps> = ({
  objects,
  macroId,
  isNew = false,
  selectedMacro,
  updatedMacro,
}) => {
  return (
    <div className="flex relative justify-center overflow-x-auto overflow-y-hidden">
      {renderTimeline(objects ?? [], macroId, isNew, selectedMacro, updatedMacro)}
    </div>
  );
};

export default MacroTimeline;
