import { useEffect } from "react";
import DataTable from "../../../components/DataTable/DataTable";
import { useStore } from "../../../store/useStore";

const JourneyEventsSelectTable: React.FC<{ form: any; fieldName: string, operation?: string }> = ({
  form,
  fieldName,
  operation
}) => {
  const journeyEventsList = useStore((state) => state.conventions.journeyEventsList);
  const isLoadingJourneyEventsList = useStore(
    (state) => state.conventions.isLoadingJourneyEventsList
  );

  const journeyEventsSelected = useStore((state) => state.conventions.journeyEventsSelected);
  const isAllChecked = () => {
    return journeyEventsList.every((journeyEvent) => journeyEventsSelected.includes(journeyEvent?.type ?? ""))
  }
  const selectJourneyEvent = useStore((state) => state.conventions.selectJourneyEvent);
  const unselectJourneyEvent = useStore((state) => state.conventions.unselectJourneyEvent);

  const columns = [
    { key: "name", label: "Nome", sortKey: "name" },
  ];

  useEffect(() => {
    if(operation === 'create') {
      for(const item of journeyEventsList) {
        selectJourneyEvent(item.type);
        form.setFieldValue(
          `${fieldName}.${item.type}`,
          true
        )
      }
    }
  }, []);
  
  return (
    <>
      <DataTable
        data={journeyEventsList}
        columns={columns}
        onDetails={null}
        onEdit={null}
        isChecked={(item) => !!form.values[fieldName][item.type]}
        isAllChecked={isAllChecked}
        onSelectAll={() => {
          if(!isAllChecked()) {
            for(const item of journeyEventsList) {
              selectJourneyEvent(item.type);
              form.setFieldValue(
                `${fieldName}.${item.type}`,
                true
              )
            }
          } else {
            for(const item of journeyEventsList) {
              unselectJourneyEvent(item.type);
              form.setFieldValue(
                `${fieldName}.${item.type}`,
                false
              )
            }
          }
        }}
        onSelect={(item) => {
          selectJourneyEvent(item.type);
          form.setFieldValue(
            `${fieldName}.${item.type}`,
            !form.values[fieldName][item.type]
          )
        }}
        loading={isLoadingJourneyEventsList}
      />
    </>
  );
};

export default JourneyEventsSelectTable;
