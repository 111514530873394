import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useModal from "../../hooks/useModal";
import ChangeCompanyModal from "../../components/Navbar/components/ChangeCompanyModal";
// import SemiCirclePieChart from "../../components/SemiCirclePieChart/SemiCirclePieChart";
// import PartitionedBarChart from "../../components/PartitionedBarChart/PartitionedBarChart";
// import LineChart from "../../components/LineChart/LineChart";
import { useStore } from "../../store/useStore";
import ShadowBox from "../../components/ShadowBox/ShadowBox";
import DashboardStatusPanel from "../../components/DashboardStatusPanel/DashboardStatusPanel";
import LastMacroMap from "./Map/LastMacroMap";

const Dashboard: React.FC<{}> = () => {
  const location = useLocation();
  const { openModal } = useModal();
  const charts = useStore((state) => state.dashboard.charts);
  // const getFleetEvolution = useStore(
  //   (state) => state.dashboard.getFleetEvolution
  // );
  // const getTimeAggregator = useStore(
  //   (state) => state.dashboard.getTimeAggregator
  // );
  const getLastMacroPositions = useStore(
    (state) => state.dashboard.getLastMacroPositions
  );
  // const getMacroAlterations = useStore(
  //   (state) => state.dashboard.getMacroAlterations
  // );
  // const isLoadingFleetEvoluition = useStore(
  //   (state) => state.dashboard.isLoadingFleetEvoluition
  // );
  // const isLoadingTimeAggregator = useStore(
  //   (state) => state.dashboard.isLoadingTimeAggregator
  // );
  const isLoadingLastMacroPositions = useStore(
    (state) => state.dashboard.isLoadingLastMacroPositions
  );
  // const isLoadingMacroAlterations = useStore(
  //   (state) => state.dashboard.isLoadingMacroAlterations
  // );

  useEffect(() => {
    if (location?.state?.openChangeCompanyModal) {
      openModal(<ChangeCompanyModal notClosable />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // getFleetEvolution();
    // getTimeAggregator();
    getLastMacroPositions();
    // getMacroAlterations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <DashboardStatusPanel />
      <div className="my-10">
        <ShadowBox>
          <LastMacroMap
            data={charts.data.lastMacroPositions}
            loading={isLoadingLastMacroPositions}
          />
        </ShadowBox>
      </div>
      {/* <div className="my-10">
        <ShadowBox>
          <LineChart
            title="Evolução do tamanho da frota ao longo do tempo"
            data={charts.data.fleetEvolution}
            loading={isLoadingFleetEvoluition}
          />
        </ShadowBox>
      </div>
      <div className="flex gap-5">
        <div className="flex-1">
          <ShadowBox>
            <PartitionedBarChart
              title="Quantidade de alterações solicitadas por período e por motoristas"
              data={charts.data.macroAlterations}
              loading={isLoadingMacroAlterations}
            />
          </ShadowBox>
        </div>
        <div className="flex-1">
          <ShadowBox>
            <SemiCirclePieChart
              title="Agregador de tempo máximo de evento de jornada"
              data={charts.data.timeAggregator}
              totalTitle="Total"
              loading={isLoadingTimeAggregator}
            />
          </ShadowBox>
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
