import Modal from "../../../components/Modal/Modal";
import Checkbox from "../../../components/FormComponents/Checkbox/Checkbox";
import { useStore } from "../../../store/useStore";
import { MacrosLinkVisibleColumnsKey } from "../../../store/types/macroState";
import { Setting2 } from "iconsax-react";
import { Colors } from "../../../utils/constants/style";

const labels = {
  macro_origin: "Origem",
  vehicle: "Veículo",
  integration_description: "Descrição",
  integration_journey_event_id: "ID do Evento",
  integration_macro_id: "ID da Macro",
  integration_user_id: "ID do Motorista",
  started_at: "Inicio",
  ended_at: "Fim",
};

const fixedColumns = {
  macro_origin: true,
  vehicle: true,
  integration_description: false,
  integration_journey_event_id: false,
  integration_macro_id: false,
  integration_user_id: false,
  started_at: false,
  ended_at: false
};

const OptionsMacroLinkModal: React.FC<{}> = () => {
  const toggleColumnVisibility = useStore(
    (state) => state.macro.macrosLinkList.toggleColumnVisibility
  );
  const columnsVisibility = useStore(
    (state) => state.macro.macrosLinkList.columnsVisibility
  );
  const isExporting = useStore((state) => state.macro.isExporting);

  const exportMacroTable = useStore((state) => state.macro.exportMacroTable);

  return (
    <Modal
      title="Opções"
      icon={<Setting2 size="32" variant="Bold" color={Colors.white}/>}
      className="min-w-[223px]"
    >
      <>
        <div className="pb-2 text-dark-blue">Exibir Colunas</div>
        <div className="flex flex-col gap-2 pl-3">
          {(Object.keys(columnsVisibility) as MacrosLinkVisibleColumnsKey[]).map(
            (key) => (
              <Checkbox
                key={key}
                text={labels[key]}
                checked={columnsVisibility[key]}
                onChange={(e) => {
                  if (!fixedColumns[key])
                    toggleColumnVisibility(key, columnsVisibility[key]);
                }}
              />
            )
          )}
        </div>
      </>
    </Modal>
  );
};

export default OptionsMacroLinkModal;
