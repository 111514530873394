export const Colors = {
  gray_0: "#F8F9FA",
  gray_05: "#F0F2F5",
  gray_08: "#E9EAED",
  gray_1: "#C7CCD0",
  gray_2: "#7B809A",
  gray_3: "#4F4F52",
  black: "#191919",
  "dark-blue": "#344767",
  white: "#fff",
  green: "#4CAF50",
  red: "#DA1E27",
  orange: "#FB8C00"
};
