import { StateCreator } from "zustand";
import { produce } from "immer";
import { toast } from "react-toastify";
import { useStore } from "../useStore";
import { JourneyRule, JourneyRuleState } from "../types/journeyRule";
import journeyRuleService from "../../services/journeyRuleService";

const initialJourneyRulesListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  filters: {
    name: "",
    description: "",
    sort_key: "name",
    sort_value: "asc",
  },
};

const initialParametersListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  filters: {
    name: "",
    description: "",
    sort_key: "name",
    sort_value: "asc",
  },
};

const initialPayRollsListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  filters: {
    name: "",
    description: "",
    sort_key: "name",
    sort_value: "asc",
  },
};

export const createJourneyRuleSlice: StateCreator<JourneyRuleState> = (
  set,
  get
) => ({
  journeyRules: {
    journeyRulesList: {
      ...initialJourneyRulesListData,
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.journeyRules.journeyRulesList.limitPerPage = limitPerPage;
            state.journeyRules.journeyRulesList.offset = 0;
            state.journeyRules.journeyRulesList.currentPage = 1;
          })
        );
        get().journeyRules.getJourneyRules();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.journeyRules.journeyRulesList.currentPage = page;
            state.journeyRules.journeyRulesList.offset = page - 1;
          })
        );
        get().journeyRules.getJourneyRules();
      },
      setFilters: (filters) => {
        set(
          produce((state) => {
            state.journeyRules.journeyRulesList.filters = filters;
            state.journeyRules.journeyRulesList.offset = 0;
            state.journeyRules.journeyRulesList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().journeyRules.getJourneyRules();
      },
    },
    parametersList: {
      ...initialParametersListData,
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.journeyRules.parametersList.limitPerPage = limitPerPage;
            state.journeyRules.parametersList.offset = 0;
            state.journeyRules.parametersList.currentPage = 1;
          })
        );
        get().journeyRules.getParameters();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.journeyRules.parametersList.currentPage = page;
            state.journeyRules.parametersList.offset = page - 1;
          })
        );
        get().journeyRules.getParameters();
      },
      setFilters: (filters) => {
        set(
          produce((state) => {
            state.journeyRules.parametersList.filters = filters;
            state.journeyRules.parametersList.offset = 0;
            state.journeyRules.parametersList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().journeyRules.getParameters();
      },
    },
    payRollsList: {
      ...initialPayRollsListData,
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.journeyRules.payRollsList.limitPerPage = limitPerPage;
            state.journeyRules.payRollsList.offset = 0;
            state.journeyRules.payRollsList.currentPage = 1;
          })
        );
        get().journeyRules.getPayRolls();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.journeyRules.payRollsList.currentPage = page;
            state.journeyRules.payRollsList.offset = page - 1;
          })
        );
        get().journeyRules.getPayRolls();
      },
      setFilters: (filters) => {
        set(
          produce((state) => {
            state.journeyRules.payRollsList.filters = filters;
            state.journeyRules.payRollsList.offset = 0;
            state.journeyRules.payRollsList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().journeyRules.getPayRolls();
      },
    },
    selectedPayRoll: null,
    selectedJourneyRule: null,
    journeyRuleToBeCreated: {
      parameters: [],
      journeyEvents: [],
      payRolls: [],
    },
    isLoadingPayRoll: false,
    selectedParameter: null,
    isLoadingJourneyRule: false,
    isLoadingParameter: false,
    isLoadingJourneyRulesList: false,
    isLoadingParametersList: false,
    isLoadingPaymentsRollsList: false,
    isCreatingJourneyRule: false,
    isUpdatingJourneyRule: false,
    resetSelectedPayRoll: () => {
      set(
        produce((state) => {
          state.journeyRules.selectedPayRoll = null;
        })
      );
    },
    resetSelectedParameter: () => {
      set(
        produce((state) => {
          state.journeyRules.selectedParameter = null;
        })
      );
    },
    resetSelectedJourneyRule: () => {
      set(
        produce((state) => {
          state.journeyRules.selectedJourneyRule = null;
        })
      );
    },
    createJourneyRule: (journeyRule: JourneyRule) => {
      set(
        produce((state) => {
          state.journeyRules.isCreatingJourneyRule = true;
        })
      );

      journeyRuleService
        .createJourneyRule(journeyRule)
        .then(() => {
          toast.success("Regra de jornada cadastrada com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().journeyRules.getJourneyRules();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.journeyRules.isCreatingJourneyRule = false;
            })
          );
        });
    },
    updateJourneyRule: (journeyRuleId: string, journeyRule: JourneyRule) => {
      set(
        produce((state) => {
          state.journeyRules.isUpdatingJourneyRule = true;
        })
      );

      journeyRuleService
        .updateJourneyRule(journeyRuleId, journeyRule)
        .then(() => {
          toast.success("Regra de jornada atualizada com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().journeyRules.getJourneyRules();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.journeyRules.isUpdatingJourneyRule = false;
            })
          );
        });
    },
    getJourneyRule: (journeyRuleId: string) => {
      set(
        produce((state) => {
          state.journeyRules.isLoadingJourneyRule = true;
        })
      );

      journeyRuleService
        .getJourneyRule(journeyRuleId)
        .then((data) => {
          set(
            produce((state) => {
              state.journeyRules.selectedJourneyRule = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.journeyRules.isLoadingJourneyRule = false;
            })
          );
        });
    },
    getJourneyRules: () => {
      set(
        produce((state) => {
          state.journeyRules.isLoadingJourneyRulesList = true;
        })
      );

      const filters = get().journeyRules.journeyRulesList.filters;
      const limit = get().journeyRules.journeyRulesList.limitPerPage;
      const offset = get().journeyRules.journeyRulesList.offset;

      journeyRuleService
        .getJourneyRules({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.journeyRules.journeyRulesList.data = data;
              state.journeyRules.journeyRulesList.totalItems = totalItems;
              state.journeyRules.journeyRulesList.totalPages = Math.ceil(
                totalItems / state.journeyRules.journeyRulesList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.journeyRules.isLoadingJourneyRulesList = false;
            })
          );
        });
    },
    resetJourneyRulesListState: () => {
      set(
        produce((state) => {
          state.journeyRules.journeyRulesList = {
            ...state.journeyRules.journeyRulesList,
            ...initialJourneyRulesListData,
          };
        })
      );
    },
    getParameter: (parameterId: string) => {
      set(
        produce((state) => {
          state.journeyRules.isLoadingParameter = true;
        })
      );

      journeyRuleService
        .getParameter(parameterId)
        .then((data) => {
          set(
            produce((state) => {
              state.journeyRules.selectedParameter = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.journeyRules.isLoadingParameter = false;
            })
          );
        });
    },
    getParameters: () => {
      set(
        produce((state) => {
          state.journeyRules.isLoadingParametersList = true;
        })
      );

      const filters = get().journeyRules.parametersList.filters;
      const limit = get().journeyRules.parametersList.limitPerPage;
      const offset = get().journeyRules.parametersList.offset;

      journeyRuleService
        .getParameters({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.journeyRules.parametersList.data = data;
              state.journeyRules.parametersList.totalItems = totalItems;
              state.journeyRules.parametersList.totalPages = Math.ceil(
                totalItems / state.journeyRules.parametersList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.journeyRules.isLoadingParametersList = false;
            })
          );
        });
    },
    resetParametersListState: () => {
      set(
        produce((state) => {
          state.journeyRules.parametersList = {
            ...state.journeyRules.parametersList,
            ...initialParametersListData,
          };
        })
      );
    },
    getPayRoll: (payRollId: string) => {
      set(
        produce((state) => {
          state.journeyRules.isLoadingPayRoll = true;
        })
      );

      journeyRuleService
        .getPayRoll(payRollId)
        .then((data) => {
          set(
            produce((state) => {
              state.journeyRules.selectedPayRoll = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.journeyRules.isLoadingPayRoll = false;
            })
          );
        });
    },
    getPayRolls: () => {
      set(
        produce((state) => {
          state.journeyRules.isLoadingPaymentsRollsList = true;
        })
      );

      const filters = get().journeyRules.payRollsList.filters;
      const limit = get().journeyRules.payRollsList.limitPerPage;
      const offset = get().journeyRules.payRollsList.offset;

      journeyRuleService
        .getPayRolls({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.journeyRules.payRollsList.data = data;
              state.journeyRules.payRollsList.totalItems = totalItems;
              state.journeyRules.payRollsList.totalPages = Math.ceil(
                totalItems / state.journeyRules.payRollsList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.journeyRules.isLoadingPaymentsRollsList = false;
            })
          );
        });
    },
    resetPayRollsListState: () => {
      set(
        produce((state) => {
          state.journeyRules.payRollsList = {
            ...state.journeyRules.payRollsList,
            ...initialPayRollsListData,
          };
        })
      );
    },
  },
});
