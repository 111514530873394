const Key = () => {
  return (
    <svg
      width="34"
      height="18"
      viewBox="0 0 34 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 12C7.85 12 6.5 10.65 6.5 9C6.5 7.35 7.85 6 9.5 6C11.15 6 12.5 7.35 12.5 9C12.5 10.65 11.15 12 9.5 12ZM17.9 6C16.7 2.55 13.4 0 9.5 0C4.55 0 0.5 4.05 0.5 9C0.5 13.95 4.55 18 9.5 18C13.4 18 16.7 15.45 17.9 12H23V18H29V12H33.5V6H17.9Z"
        fill="white"
      />
    </svg>
  );
};

export default Key;
