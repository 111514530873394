import { EditIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import { Colors } from "../../../utils/constants/style";
import useModal from "../../../hooks/useModal";
import DeleteSyndicateModal from "./DeleteSyndicateModal";
import { useEffect } from "react";
import { useStore } from "../../../store/useStore";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";

interface EditSyndicateModalProps {
  onClose: () => void;
}

const EditSyndicateModal: React.FC<EditSyndicateModalProps> = ({ onClose }) => {
  const { openModal } = useModal();
  const selectedSyndicate = useStore(
    (state) => state.conventions.selectedSyndicate
  );
  const updateSyndicate = useStore((state) => state.conventions.updateSyndicate);
  const isUpdatingSyndicate = useStore(
    (state) => state.conventions.isUpdatingSyndicate
  );
  const isLoadingSyndicate = useStore(
    (state) => state.conventions.isLoadingSyndicate
  );
  const hasPermissions = useStore((state) => state.admin.hasPermissions);

  const openDeleteSyndicateModal = () => openModal(<DeleteSyndicateModal />);

  return (
    <Modal
      title="Editar sindicato"
      icon={<EditIcon width={30} height={30} color={Colors.white} />}
      className="w-[447px]"
      onClose={onClose}
    >
      <Formik
        initialValues={{
          name: "",
          operation_area: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Campo obrigatório"),
          operation_area: Yup.string().required("Campo obrigatório"),
        })}
        onSubmit={(values) => {
          if (selectedSyndicate?._id)
            updateSyndicate(selectedSyndicate._id, values);
        }}
      >
        {(formik) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (selectedSyndicate) {
              const selectedCollaboratorCopy = {
                ...selectedSyndicate,
              };
              delete selectedCollaboratorCopy._id;
              delete selectedCollaboratorCopy.deleted_at;
              delete selectedCollaboratorCopy.updated_at;
              delete selectedCollaboratorCopy.created_at;
              delete selectedCollaboratorCopy.company;

              formik.setValues(selectedCollaboratorCopy);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [selectedSyndicate]);

          return (
            <Form>
              <div className="flex flex-col gap-8 pt-5">
                <Field
                  loading={isLoadingSyndicate}
                  placeholder="Nome"
                  name="name"
                  component={OutlinedInput}
                />
                <Field
                  loading={isLoadingSyndicate}
                  placeholder="Área de operação"
                  name="operation_area"
                  component={OutlinedInput}
                />
                <div className="flex gap-5">
                  {hasPermissions([PERMISSIONS.DELETE_SYNDICATE]) ? (
                    <div className="flex-1">
                      <OutlinedButton
                        onClick={openDeleteSyndicateModal}
                        className="w-full"
                      >
                        Excluir
                      </OutlinedButton>
                    </div>
                  ) : null}
                  <div className="flex-1">
                    <FilledButton
                      loading={isUpdatingSyndicate}
                      className="w-full"
                      type="submit"
                    >
                      Editar
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditSyndicateModal;
