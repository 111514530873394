import produce from "immer";
import { StateCreator } from "zustand";
import { ModalState } from "../types/modal";

export const createModalSlice: StateCreator<ModalState> = (set, get) => ({
  modal: {
    isModalOpen: false,
    modalContent: null,
    setIsModalOpen: (isOpen: boolean) => {
      set(
        produce((state: ModalState) => {
          state.modal.isModalOpen = isOpen;
        })
      );
    },
    setModalContent: (content: JSX.Element | null) => {
      set(
        produce((state: ModalState) => {
          state.modal.modalContent = content;
        })
      );
    },
  },
});
