import { SearchNormal1 } from "iconsax-react";
import { SearchIcon } from "../../../assets/imgs";
import GenericCompanyImage from "../../../components/GenericCompanyImage/GenericCompanyImage";
import InfoField from "../../../components/InfoField/InfoField";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import Modal from "../../../components/Modal/Modal";
import { useStore } from "../../../store/useStore";
import { Colors } from "../../../utils/constants/style";
import {
  formatCNPJ,
  formatDateTime,
} from "../../../utils/functions/formatters";

interface DetailsCompanyModalProps {
  onClose: () => void;
}

const DetailsCompanyModal: React.FC<DetailsCompanyModalProps> = ({
  onClose,
}) => {
  const selectedCompany = useStore((state) => state.company.selectedCompany);
  const isLoadingCompany = useStore((state) => state.company.isLoadingCompany);

  return (
    <Modal
      title="Detalhes da transportadora"
      icon={<SearchNormal1 size="30" color={Colors.white} variant="Linear" />}
      className="min-w-[479px]"
      onClose={onClose}
    >
      <div className="flex flex-col gap-5">
        <div className="flex">
          <div className="flex-1">
            <GenericCompanyImage
              logo={selectedCompany?.logo}
              width={50}
              height={50}
              loading={isLoadingCompany}
            />
          </div>
          <InfoField
            loading={isLoadingCompany}
            label="Nome"
            content={selectedCompany?.name}
          />
        </div>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCompany}
            label="CNPJ"
            content={formatCNPJ(selectedCompany?.cnpj ?? "")}
          />
          <InfoField
            loading={isLoadingCompany}
            label="País"
            content={selectedCompany?.address?.country}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCompany}
            label="Estado"
            content={selectedCompany?.address?.state}
          />
          <InfoField
            loading={isLoadingCompany}
            label="Cidade"
            content={selectedCompany?.address?.city}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCompany}
            label="Rua"
            content={selectedCompany?.address?.street}
          />
          <InfoField
            loading={isLoadingCompany}
            label="Número"
            content={selectedCompany?.address?.number}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCompany}
            label="Complemento"
            content={selectedCompany?.address?.neighbourhood}
          />
          <InfoField
            loading={isLoadingCompany}
            label="CEP"
            content={selectedCompany?.address.zipcode}
          />
        </InfoFieldBox>
        <InfoFieldBox>
          <InfoField
            loading={isLoadingCompany}
            label="Criado em"
            content={formatDateTime(selectedCompany?.created_at ?? "")}
          />
        </InfoFieldBox>
      </div>
    </Modal>
  );
};

export default DetailsCompanyModal;
