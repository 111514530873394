import Modal from "../../../components/Modal/Modal";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { Colors } from "../../../utils/constants/style";
import { useStore } from "../../../store/useStore";
import { Danger, TaskSquare, Trash } from "iconsax-react";
import { REPORT_ORIENTATIONS, REPORT_TYPES } from "../../../utils/constants/constants";
import { Formik } from "formik";
import SimpleSelect from "../../../components/SimpleSelect/SimpleSelect";
import Checkbox from "../../../components/FormComponents/Checkbox/Checkbox";

interface DeletePayRollModalProps {
  onClose: () => void;
}
const initialValues = {
  payrollGroupId: "" as string,
  unified: {} as Record<string, any>,
  orientation: REPORT_ORIENTATIONS[0].value as string,
};

const ClosePayRollModal: React.FC<DeletePayRollModalProps> = () => {
  const selectedPayRoll = useStore(
    (state) => state.timeManager.selectedPayRoll
  );
  const closePayRoll = useStore(
    (state) => state.timeManager.closePayRoll
  );
  const isClosingPayRoll = useStore(
    (state) => state.timeManager.isClosingPayRoll
  );
  return (
    <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          const unified = Object.keys(values.unified).filter(
            (k: string) => values.unified[k]
          );
          if(!selectedPayRoll?._id) return;
          closePayRoll(selectedPayRoll?._id as string, unified ?? [], values.orientation);
          resetForm();
        }}
      >
      {(formik) => (
        <Modal
          title="Fechar lote de folha de pagamento"
          icon={<TaskSquare size={30} variant="Bold" color={Colors.white} />}
          className="w-[600px]"
        >
          <div className="text-center flex flex-col items-center gap-5">
            <div className="flex gap-5">
              <div className="w-[320px]">
                <div className="text-dark-blue font-bold my-4">
                  Orientação da Folha
                </div>
                <div>
                  <SimpleSelect
                    options={REPORT_ORIENTATIONS}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'orientation',
                        e.target.value
                      )
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="text-dark-blue font-bold my-4">
                  Unificar Relatórios em PDF
                </div>
                <div className="flex flex-wrap items-start">
                  {
                    REPORT_TYPES.map((report_type: any) => {
                      return (
                        <div className="bg-gray_0 p-1 rounded-[7px] mr-2 mb-2">
                          <Checkbox
                            key={report_type.value}
                            onChange={() => {
                              formik.setFieldValue(
                                `unified.${report_type.value}`,
                                !formik.values['unified'][report_type.value]
                              )
                            }}
                            className="w-[14px] h-[14px]"
                            labelClassName="font-light font-base"
                            checked={formik.values?.unified?.[report_type.value] ?? false}
                            text={report_type.label}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <Danger size="72" variant="Bulk" color={Colors.red}/>
            <div className="px-5">Tem certeza que deseja fechar este lote de folha de pagamento?</div>
              {selectedPayRoll?.name ? (<div className="text-dark-blue font-bold">{selectedPayRoll?.name}</div>) : ''}
              <FilledButton 
                loading={isClosingPayRoll} 
                className="px-14"
                onClick={() => {
                  formik.submitForm();
                }}
              >
                Fechar
              </FilledButton>
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default ClosePayRollModal;
