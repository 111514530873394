export interface Report {
  users?: string[];
  periodStart: string;
  periodEnd: string;
  format?: string;
  payrollGroupId?: string;
}

export interface ConsolidatedPaymentHours {
  users?: string[];
  payrollGroupId: string;
  format?: string;
}

export enum ReportType {
  MACRO_MANUAL = "MACRO_MANUAL",
  MACRO_ALTERED = "MACRO_ALTERED",
  MACRO_INTERJOURNEY = "MACRO_INTERJOURNEY",
  LONGEST_WORKING_TIME = "LONGEST_WORKING_TIME",
  CREATE_CONSOLIDATED_REST_TIME_REPORT = "CREATE_CONSOLIDATED_REST_TIME_REPORT",
  CREATE_CONSOLIDATED_PAYMENT_HOURS_REPORT = "CREATE_CONSOLIDATED_PAYMENT_HOURS_REPORT",
  AEJ = "AEJ",
  AFD = "AFD",
  DAILIES = "DAILIES",
  JFW_CUSTOM_HOURS = "JFW_CUSTOM_HOURS",
  MACROS_LIST = 'MACROS_LIST'
}

export interface ReportState {
  report: {
    isDownloadingReport: boolean;
    reportType: ReportType | null;
    downloadEntityReport: (entity: string, filters: any) => void;
    downloadReport: (report?: Report | ConsolidatedPaymentHours) => void;
    downloadPayroll: (
      collaborators: string[], 
      payrollGroupId: string, 
      unified: string[],
      format?: string, 
      orientation?: string,
      consolidated?: boolean
    ) => void;
    setReportType: (string: ReportType) => void;
  };
}
