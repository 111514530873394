import { StateCreator } from "zustand";
import { produce } from "immer";
import { toast } from "react-toastify";
import { ConsolidatedPaymentHours, Report, ReportState, ReportType } from "../types/report";
import reportService from "../../services/reportService";
import { downloadURI } from "../../utils/functions/download";
import moment from "moment";
import { useStore } from "../useStore";

export const downloadReportStrategy = new Map([
  [
    ReportType.MACRO_MANUAL,
    'downloadManualMacrosReport',
  ],
  [
    ReportType.MACRO_ALTERED,
    'downloadAlteredMacrosReport',
  ],
  [
    ReportType.MACRO_INTERJOURNEY,
    'downloadInterjourneyMacrosReport',
  ],
  [
    ReportType.LONGEST_WORKING_TIME,
    'downloadLongestWorkingTimeReport'
  ],
  [
    ReportType.CREATE_CONSOLIDATED_REST_TIME_REPORT,
    'downloadCreateConsolidatedRestTimeReport'
  ],
  [
    ReportType.CREATE_CONSOLIDATED_PAYMENT_HOURS_REPORT,
    'downloadCreateConsolidatedPaymentHoursReport'
  ],
  [
    ReportType.AEJ,
    'downloadAEJReport'
  ],
  [
    ReportType.AFD,
    'downloadAFDReport'
  ],
  [
    ReportType.DAILIES,
    'downloadDailiesReport'
  ],
  [
    ReportType.JFW_CUSTOM_HOURS,
    'downloadJFWCustomReport'
  ],
  [
    ReportType.MACROS_LIST,
    'downloadMacrosReport'
  ]
]);

export const createReportSlice: StateCreator<ReportState> = (
  set,
  get
) => ({
  report: {
    isDownloadingReport: false,
    reportType: null,
    reportName: null,
    setReportType: (reportType: ReportType) => {
      set(
        produce((state) => {
          state.report.reportType = reportType;
        })
      );
    },
    downloadEntityReport: (entity: any, filters: any) => {
      let section;
      set(
        produce((state) => {
          state.report.isDownloadingReport = true;
          section = state.admin.data.section?._id;
        })
      );
      reportService.downloadEntitiesReport({ 
        entity, 
        filters: {
          ...filters,
          section
        }
      })
        .then((data: any) => {
          if(data?.fileUrl) {
            downloadURI(data.fileUrl, `${moment().format("YYYY-MM-DD")}.pdf`);
            toast.success("Documento baixado com sucesso");
            useStore.getState().modal.setIsModalOpen(false);
          } else {
            toast.success("Não há registros para os usuários selecionados");
            useStore.getState().modal.setIsModalOpen(false);
          }
        })
        .catch((error: Error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.report.isDownloadingReport = false;
            })
          );
        });
    },
    downloadReport: (report?: Report | ConsolidatedPaymentHours) => {
      const reportType = get().report.reportType;
      if(!reportType) return;
      const reportServiceMethod = downloadReportStrategy.get(reportType);
      if(!reportServiceMethod) return;

      set(
        produce((state) => {
          state.report.isDownloadingReport = true;
        })
      );
      reportService[reportServiceMethod](report)
        .then((data: any) => {
          if(data?.fileUrl) {
            downloadURI(data.fileUrl, `${moment().format("YYYY-MM-DD")}.pdf`);
            toast.success("Documento baixado com sucesso");
            useStore.getState().modal.setIsModalOpen(false);
          } else {
            toast.success("Não há registros para os usuários selecionados");
            useStore.getState().modal.setIsModalOpen(false);
          }
        })
        .catch((error: Error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.report.isDownloadingReport = false;
            })
          );
        });
    },
    downloadPayroll: (
      collaborators: string[], 
      payrollGroupId: string, 
      unified: string[], 
      format?: string,
      orientation?: string,
      consolidated?: boolean
    ) => {
      set(
        produce((state) => {
          state.report.isDownloadingReport = true;
        })
      );
      toast.promise(
        reportService.downloadPayroll(collaborators, payrollGroupId, unified, format, orientation, consolidated)
        .then((data: any) => {
            if(data?.fileUrl) {
              downloadURI(data.fileUrl, `${moment().format("YYYY-MM-DD")}.pdf`);
              toast.success("Donwloado do documento iniciado com sucesso");
            } else toast.error("Não foi possível baixar o documento");
          })
          .catch((error: Error) => {
            toast.error(error.message);
          })
          .finally(() => {
            set(
              produce((state) => {
                state.report.isDownloadingReport = false;
              })
            );
        }),
        { pending: 'Gerando seu relatório... isso pode levar alguns minutos, não atualize sua tela' }
      )

      useStore.getState().modal.setIsModalOpen(false);
    }
  },
});
