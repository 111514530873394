import { StateCreator } from "zustand";
import { toast } from "react-toastify";
import produce from "immer";
import { useStore } from "../useStore";
import { CollaboratorIntegration, CollaboratorIntegrationsState, Integration } from "../types/collaboratorIntegrations";
import collaboratorIntegrationService from "../../services/collaboratorIntegrationService";

const initialCollaboratorIntegrationsListData = {
  data: []
};
const initialIntegrationsListData = {
  data: []
};

export const createCollaboratorIntegrationsSlice: StateCreator<CollaboratorIntegrationsState> = (
  set,
  get
) => ({
  collaboratorIntegration: {
    collaboratorIntegrationsList: {
      ...initialCollaboratorIntegrationsListData
    },
    integrationsList: {
      ...initialIntegrationsListData
    },
    selectedCollaboratorIntegration: null,
    isLoadingCollaboratorIntegrationsList: false,
    isLoadingIntegrationsList: false,
    isCreatingCollaboratorIntegration: false,
    isDeletingCollaboratorIntegration: false,
    resetSelectedCollaboratorIntegration: () => {
      set(
        produce((state) => {
          state.collaboratorIntegration.selectedCollaboratorIntegration = null;
        })
      );
    },
    setSelectedCollaboratorIntegration: (collaboratorIntegration: CollaboratorIntegration) => {
      if (collaboratorIntegration === undefined) return;
      set(
        produce((state) => {
          state.collaboratorIntegration.selectedCollaboratorIntegration = collaboratorIntegration;
        })
      );
    },
    getCollaboratorIntegrations: (collaboratorId: string) => {
      set(
        produce((state) => {
          state.collaboratorIntegration.isLoadingCollaboratorIntegrationsList = true;
        })
      );

      collaboratorIntegrationService
        .getCollaboratorIntegrations(collaboratorId)
        .then(({ data }: any) => {
          set(
            produce((state) => {
              state.collaboratorIntegration.collaboratorIntegrationsList.data = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.collaboratorIntegration.isLoadingCollaboratorIntegrationsList = false;
            })
          );
        });
    },
    getIntegrations: () => {
      set(
        produce((state) => {
          state.collaboratorIntegration.isLoadingIntegrationsList = true;
        })
      );

      collaboratorIntegrationService
        .getIntegrations()
        .then(({ data }: any) => {
          set(
            produce((state) => {
              state.collaboratorIntegration.integrationsList.data = data.map((integration: Integration) => {
                return { label: integration.name, value: integration._id}
              });
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.collaboratorIntegration.isLoadingIntegrationsList = false;
            })
          );
        });
    },
    deleteCollaboratorIntegration: (
      collaboratorId: string,
      integrationId: string,
      integrationUser: string,
    ) => {
      set(
        produce((state) => {
          state.collaboratorIntegration.isDeletingCollaboratorIntegration = true;
        })
      );

      collaboratorIntegrationService
        .deleteCollaboratorIntegration(
          collaboratorId,
          integrationId,
          integrationUser,
        )
        .then(() => {
          toast.success("Integração excluída com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().collaboratorIntegration.getCollaboratorIntegrations(collaboratorId);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.collaboratorIntegration.isDeletingCollaboratorIntegration = false;
            })
          );
        });
    },
    createCollaboratorIntegration: (
      collaboratorId: string,
      integrationId: string,
      integrationUser: string,
    ) => {
      set(
        produce((state) => {
          state.collaboratorIntegration.isCreatingCollaboratorIntegration = true;
        })
      );

      collaboratorIntegrationService
        .createCollaboratorIntegration(
          collaboratorId,
          integrationId,
          integrationUser,
        ).then(() => {
          toast.success("Integração cadastrada com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().collaboratorIntegration.getCollaboratorIntegrations(collaboratorId);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.collaboratorIntegration.isCreatingCollaboratorIntegration = false;
            })
          );
        });
    }
  },
});
