import Modal from "../../../components/Modal/Modal";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { Colors } from "../../../utils/constants/style";
import { useStore } from "../../../store/useStore";
import MacroOrigin from "../../../components/MacroOrigin/MacroOrigin";
import { Danger, Trash } from "iconsax-react";

const DeleteCollaboratorIntegrationModal: React.FC<{}> = () => {
  const selectedCollaboratorIntegration = useStore(
    (state) => state.collaboratorIntegration.selectedCollaboratorIntegration
  );
  const deleteCollaboratorIntegration = useStore(
    (state) => state.collaboratorIntegration.deleteCollaboratorIntegration
  );
  const isDeletingCollaboratorIntegration = useStore(
    (state) => state.collaboratorIntegration.isDeletingCollaboratorIntegration
  );
  const selectedCollaborator = useStore(
    (state) => state.collaborator.selectedCollaborator
  );

  const handleDeleteCollaboratorIntegration = () => {
    if(
      selectedCollaborator?._id &&
      selectedCollaboratorIntegration?.integration?._id &&
      selectedCollaboratorIntegration?.integration_user
    )
      deleteCollaboratorIntegration(
        selectedCollaborator._id,
        selectedCollaboratorIntegration.integration._id,
        selectedCollaboratorIntegration.integration_user
      );
  };

  return (
    <Modal
      title="Remoção de integração"
      icon={<Trash size={30} variant="Bold" color={Colors.white} />}
      className="w-[361px]"
    >
      <div className="text-center flex flex-col items-center gap-5">
        <Danger size="72" variant="Bulk" color={Colors.red}/>
        <div className="px-5">Tem certeza que deseja remover a integração?</div>
        { 
          selectedCollaboratorIntegration?.integration?.type && selectedCollaboratorIntegration?.integration_user ? 
          <div className="flex items-center">
            <MacroOrigin origin={selectedCollaboratorIntegration.integration.type} /> 
            {selectedCollaboratorIntegration.integration_user}
          </div>
          : null
        }
        <FilledButton loading={isDeletingCollaboratorIntegration} onClick={handleDeleteCollaboratorIntegration} className="px-14">
          Excluir
        </FilledButton>
      </div>
    </Modal>
  );
};

export default DeleteCollaboratorIntegrationModal;
