interface NavbarLinkTopicProps {
  title: string;
  children: JSX.Element | JSX.Element[];
  borderBottom?: boolean;
}

const NavbarLinkTopic: React.FC<NavbarLinkTopicProps> = ({ title, children, borderBottom }) => {
  return (
    <div className={`pt-4 ${borderBottom ? "border-b border-gray_2" : ""}`}>
      <h2 className="text-base text-white font-bold">{title}</h2>
      {children}
    </div>
  );
};

export default NavbarLinkTopic;
