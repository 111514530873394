import Skeleton from "react-loading-skeleton";
import { getNestedValue } from "../../../utils/functions/functions";
import Checkbox from "../../FormComponents/Checkbox/Checkbox";

interface SelectSectionDataTableProps {
  data: any[];
  columns: {
    key: string;
    label: string;
    sortKey?: string;
    render?: (item: any) => React.ReactNode;
  }[];
  loading?: boolean;
  currentSectionId: string | undefined;
  onSelectSection: (item: any) => void;
}

const SelectSectionDataTable: React.FC<SelectSectionDataTableProps> = ({
  currentSectionId,
  data,
  columns,
  loading,
  onSelectSection,
}) => {
  return (
    <div className="w-full overflow-x-auto max-h-[400px] overflow-y-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th></th>
            {columns.map((column, columnIndex) => (
              <th
                key={column.key}
                className={`select-none ${
                  column.sortKey ? "cursor-pointer" : ""
                } px-2 py-2 text-lg font-light `}
              >
                <div className={`flex gap-2 items-center justify-center`}>
                  {column.label}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading
            ? [...Array(10)].map((row, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    {[...Array(columns.length + 1)].map((cell, cellIndex) => (
                      <td key={cellIndex} className="px-3 py-2">
                        <Skeleton height={16} />
                      </td>
                    ))}
                  </tr>
                );
              })
            : data.map((item, rowIndex) => (
                <tr
                  key={item.id}
                  className={`${
                    rowIndex % 2 === 0 ? "bg-gray_08" : "bg-white"
                  }`}
                >
                  <td className="px-2 py-3 font-light max-w-[120px]">
                    <div className="flex items-center justify-center h-full">
                      <Checkbox
                        checked={item._id === currentSectionId}
                        onChange={() => onSelectSection(item)}
                      />
                    </div>
                  </td>
                  {columns.map((column, columnIndex) => (
                    <td
                      key={column.key}
                      className={`px-2 py-3 font-light max-w-[120px] break-words text-center`}
                    >
                      {column.render
                        ? column.render(item) ?? "-"
                        : getNestedValue(item, column.key) ?? "-"}
                    </td>
                  ))}
                </tr>
              ))}
        </tbody>
      </table>
      {data.length === 0 && !loading && (
        <div className="flex items-center mt-4 text-gray_1 justify-center">
          Nenhum resultado encontrado
        </div>
      )}
    </div>
  );
};
export default SelectSectionDataTable;
