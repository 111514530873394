import { AxiosInstance } from "axios";

var MockAdapter = require("axios-mock-adapter");

export function initUserServiceMockPublic(apiPublic: AxiosInstance) {
  var mockAPIPublic = new MockAdapter(apiPublic, { delayResponse: 2000 });

  mockAPIPublic
    .onPost("/v1/auth/login", { cpf: "11111111111", password: "123" })
    .reply(200, {
      response_code: 1006,
      message: "User logged in successfully",
      data: {
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTEwZGE1ZTE4NTdjNTZiOTM5MDM1MDYiLCJuYW1lIjoiSnVkaXRoIFpib25jYWsiLCJjcGYiOiI0MTIyMTc5MTIzMCIsImNvbXBhbnkiOiI2NTEwZGE1YzE4NTdjNTZiOTM5MDM0OGMiLCJwb3NpdGlvbiI6eyJuYW1lIjoiTWVzdHJlIiwidHlwZSI6Ik1BU1RFUiJ9LCJwcm9qZWN0aW9uIjp7InVzZXIiOlsibmFtZSIsImVtYWlsIiwiY29tcGFueSIsInBvc2l0aW9uIiwicGhvbmUiLCJyZWdpc3RyYXRpb24iLCJjcGYiLCJyZyIsImNuaCIsImRlbGV0ZWRfYXQiLCJjcmVhdGVkX2F0IiwidXBkYXRlZF9hdCJdfSwicGVybWlzc2lvbnMiOlsiQ1JFQVRFX0NPTVBBTlkiLCJSRUFEX0NPTVBBTlkiLCJVUERBVEVfQ09NUEFOWSIsIkRFTEVURV9DT01QQU5ZIiwiTElTVF9DT01QQU5JRVMiLCJTV0lUQ0hfQ09NUEFOWSIsIlVQU0VSVF9DT01QQU5ZX0xPR08iLCJDUkVBVEVfTUFDUk8iLCJSRUFEX01BQ1JPIiwiVVBEQVRFX01BQ1JPIiwiREVMRVRFX01BQ1JPIiwiTElTVF9NQUNST1MiLCJDUkVBVEVfSE9MSURBWSIsIlJFQURfSE9MSURBWSIsIlVQREFURV9IT0xJREFZIiwiREVMRVRFX0hPTElEQVkiLCJMSVNUX0hPTElEQVlTIiwiQ1JFQVRFX1NFQ1RJT04iLCJSRUFEX1NFQ1RJT04iLCJVUERBVEVfU0VDVElPTiIsIkRFTEVURV9TRUNUSU9OIiwiTElTVF9TRUNUSU9OUyIsIkNSRUFURV9TWU5ESUNBVEUiLCJSRUFEX1NZTkRJQ0FURSIsIlVQREFURV9TWU5ESUNBVEUiLCJERUxFVEVfU1lORElDQVRFIiwiTElTVF9TWU5ESUNBVEVTIiwiQ1JFQVRFX1VTRVIiLCJSRUFEX1VTRVIiLCJVUERBVEVfVVNFUiIsIkRFTEVURV9VU0VSIiwiTElTVF9VU0VSUyIsIlVQREFURV9VU0VSX1BBU1NXT1JEIiwiVVBEQVRFX1VTRVJfT1dOX1BBU1NXT1JEIiwiQ1JFQVRFX1VTRVJTX1JFUE9SVCIsIkNSRUFURV9WRUhJQ0xFIiwiUkVBRF9WRUhJQ0xFIiwiVVBEQVRFX1ZFSElDTEUiLCJERUxFVEVfVkVISUNMRSIsIkxJU1RfVkVISUNMRVMiLCJDUkVBVEVfQ09NUEFOSUVTX1JFUE9SVCIsIlJFQURfVVNFUl9WRUhJQ0xFIiwiVVBEQVRFX1ZFSElDTEVfVVNFUiIsIkNSRUFURV9WRUhJQ0xFU19SRVBPUlQiLCJHRVRfSk9VUk5FWV9FVkVOVCIsIkxJU1RfSk9VUk5FWV9FVkVOVFMiXSwiaWF0IjoxNjk1OTMyNTcwLCJleHAiOjE2OTg1MjQ1NzB9.8OO_lZdY89hiKW4-5_MmH2JTMO7n9ywHbj8_Kp8FKE0",
      },
    });
}

export function initUserServiceMock(api: AxiosInstance) {
  var mockAPI = new MockAdapter(api, { delayResponse: 2000 });

  mockAPI.onGet("/v1/user").reply(
    200,
    {
      response_code: 1003,
      message: "Entity listed successfully",
      data: [
        {
          _id: "65049c8b6db41db44a13da84",
          name: "Alejandro DuBuque",
          email: "carlee.simonis64@gmail.com",
          phone: "+5551495480698",
          registration: "711137340",
          position: {
            name: "Human Resources",
            type: "HR",
          },
          cpf: "88967694928",
          rg: "430690661",
          cnh: "94833737703",
          deleted_at: null,
          created_at: "2023-09-15T18:03:55.812Z",
          updated_at: "2023-09-15T18:03:55.812Z",
        },
        {
          _id: "65049c8b6db41db44a13da7e",
          name: "Ed Walter III",
          email: "elmo_frami@hotmail.com",
          phone: "+5563118402236",
          registration: "828071801",
          position: {
            name: "Manager",
            type: "MANAGER",
          },
          cpf: "94074219594",
          rg: "219134478",
          cnh: "09551932551",
          deleted_at: null,
          created_at: "2023-09-15T18:03:55.812Z",
          updated_at: "2023-09-15T18:03:55.812Z",
        },
        {
          _id: "65049c8b6db41db44a13da8c",
          name: "Raymond Gerlach",
          email: "lourdes.okuneva23@yahoo.com",
          phone: "+5503978123482",
          registration: "409534496",
          position: {
            name: "Master",
            type: "MASTER",
          },
          cpf: "58401456077",
          rg: "299460905",
          cnh: "65476244924",
          deleted_at: null,
          created_at: "2023-09-15T18:03:55.816Z",
          updated_at: "2023-09-15T18:03:55.816Z",
        },
      ],
    },
    { "x-total-count": 3 }
  );

  mockAPI.onGet(/\/v1\/user\/[^\\]+/).reply(() => {
    const data = {
      response_code: 1002,
      message: "Entity read successfully",
      data: {
        _id: "64ede0fe71175e060410815d",
        name: "João da Silva",
        email: "joaodasilva@gmail.com",
        phone: "5531997883232",
        registration: "3891273892",
        position: {
          type: "MANAGER",
          name: "Gestor",
        },
        cpf: "47162767087",
        rg: "482200248",
        cnh: "46994067118",
        deleted_at: null,
        created_at: "2023-08-30T12:34:51.373Z",
        updated_at: "2023-08-30T12:34:51.373Z",
      },
    };

    return [200, data];
  });

  mockAPI.onPut(/\/v1\/user\/[^\\]+/).reply(() => {
    const data = {
      response_code: 1002,
      message: "Entity read successfully",
      data: {
        _id: "64ede0fe71175e060410815d",
        name: "João da Silva",
        email: "joaodasilva@gmail.com",
        phone: "5531997883232",
        registration: "3891273892",
        position: {
          type: "MANAGER",
          name: "Gestor",
        },
        cpf: "47162767087",
        rg: "482200248",
        cnh: "46994067119",
        deleted_at: null,
        created_at: "2023-08-30T12:34:51.373Z",
        updated_at: "2023-08-30T12:34:51.373Z",
      },
    };

    return [200, data];
  });
  mockAPI.onDelete(/\/v1\/user\/[^\\]+/).reply(200);
  mockAPI.onPatch(/\/v1\/user\/[^\\]+\/password/).reply(200);

  mockAPI.onGet("/v1/vehicle").reply(
    200,
    {
      response_code: 1003,
      message: "Entidade listada com sucesso",
      data: [
        {
          _id: "6523d2bb83cffdf58162b5f8",
          tag: "9089128",
          fleet: 1223124124,
          company: "6523d2701bbfef1fc5175b71",
          created_at: "2023-10-09T10:15:23.848Z",
          updated_at: "2023-10-09T10:15:23.848Z",
        },
      ],
    },
    { "x-total-count": 3 }
  );

  mockAPI.onGet(/\/v1\/vehicle\/[^\\]+/).reply(() => {
    const data = {
      response_code: 1002,
      message: "Entity read successfully",
      data: {
        _id: "6523d2bb83cffdf58162b5f8",
        tag: "9089128",
        fleet: 1223124124,
        company: "6523d2701bbfef1fc5175b71",
        created_at: "2023-10-09T10:15:23.848Z",
        updated_at: "2023-10-09T10:15:23.848Z",
      },
    };

    return [200, data];
  });

  mockAPI.onPut(/\/v1\/vehicle\/[^\\]+/).reply(200);
  mockAPI.onDelete(/\/v1\/vehicle\/[^\\]+/).reply(200);

  mockAPI.onGet(/\/v1\/company\/[^\\]+/).reply(() => {
    const data = {
      response_code: 1002,
      message: "Entity read successfully",
      data: {
        _id: "64ede0fe71175e060410815d",
        name: "Company A",
        cnpj: "81332034306614",
        logo: "http://logo.example.com",
        phone: "5535997813038",
        address: {
          street: "Micaela Ridges",
          number: "1241",
          neighbourhood: "Berkshire",
          city: "San Jose",
          state: "Illinois",
          country: "England",
          zipcode: "618169302",
        },
        deleted_at: null,
        created_at: "2023-08-30T12:34:51.373Z",
        updated_at: "2023-08-30T12:34:51.373Z",
      },
    };

    return [200, data];
  });

  mockAPI.onGet("/v1/company").reply(
    200,
    {
      response_code: 1003,
      message: "Entidade listada com sucesso",
      data: [
        {
          _id: "65162f4df662c4085022d688",
          name: "Bechtelar Group",
          cnpj: "93593871311966",
          address: {
            street: "Dickens Point",
            number: "5285",
            neighbourhood: "Madison County",
            city: "Starkstead",
            state: "West Virginia",
            country: "Chad",
            zipcode: "93633-5116",
          },
          created_at: "2023-09-29T01:58:37.185Z",
          updated_at: "2023-09-29T01:58:37.185Z",
        },
        {
          _id: "65162f4df662c4085022d68a",
          name: "Buckridge, Champlin and Hegmann",
          cnpj: "21217439334280",
          address: {
            street: "Leif Lock",
            number: "6553",
            neighbourhood: "Cumbria",
            city: "Morissetteview",
            state: "Delaware",
            country: "Liberia",
            zipcode: "66516",
          },
          created_at: "2023-09-29T01:58:37.185Z",
          updated_at: "2023-09-29T01:58:37.185Z",
        },
        {
          _id: "65162f4df662c4085022d68c",
          name: "Crist, Bosco and Towne",
          cnpj: "02485709563729",
          address: {
            street: "Morissette Summit",
            number: "457",
            neighbourhood: "County Londonderry",
            city: "Emelieworth",
            state: "Maryland",
            country: "United Arab Emirates",
            zipcode: "23950-2873",
          },
          created_at: "2023-09-29T01:58:37.185Z",
          updated_at: "2023-09-29T01:58:37.185Z",
        },
        {
          _id: "65162f4df662c4085022d68b",
          name: "Langworth - Hudson",
          cnpj: "22948140399254",
          address: {
            street: "Braulio View",
            number: "8226",
            neighbourhood: "Franklin County",
            city: "South Dessiebury",
            state: "Iowa",
            country: "Mali",
            zipcode: "24014",
          },
          created_at: "2023-09-29T01:58:37.185Z",
          updated_at: "2023-09-29T01:58:37.185Z",
        },
        {
          _id: "65162f4df662c4085022d689",
          name: "Runolfsdottir - Schimmel",
          cnpj: "50399489843424",
          address: {
            street: "Mills Fort",
            number: "54157",
            neighbourhood: "County Londonderry",
            city: "Fort Rhoda",
            state: "Idaho",
            country: "Romania",
            zipcode: "12343",
          },
          created_at: "2023-09-29T01:58:37.185Z",
          updated_at: "2023-09-29T01:58:37.185Z",
        },
      ],
    },
    { "x-total-count": 5 }
  );

  mockAPI.onPatch(/\/v1\/company\/[^\\]+\/switch/).reply(200, {
    data: {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTEwZGE1ZTE4NTdjNTZiOTM5MDM1MDYiLCJuYW1lIjoiSnVkaXRoIFpib25jYWsiLCJjcGYiOiI0MTIyMTc5MTIzMCIsImNvbXBhbnkiOiI2NTEwZGE1YzE4NTdjNTZiOTM5MDM0OGMiLCJwb3NpdGlvbiI6eyJuYW1lIjoiTWVzdHJlIiwidHlwZSI6Ik1BU1RFUiJ9LCJwcm9qZWN0aW9uIjp7InVzZXIiOlsibmFtZSIsImVtYWlsIiwiY29tcGFueSIsInBvc2l0aW9uIiwicGhvbmUiLCJyZWdpc3RyYXRpb24iLCJjcGYiLCJyZyIsImNuaCIsImRlbGV0ZWRfYXQiLCJjcmVhdGVkX2F0IiwidXBkYXRlZF9hdCJdfSwicGVybWlzc2lvbnMiOlsiQ1JFQVRFX0NPTVBBTlkiLCJSRUFEX0NPTVBBTlkiLCJVUERBVEVfQ09NUEFOWSIsIkRFTEVURV9DT01QQU5ZIiwiTElTVF9DT01QQU5JRVMiLCJTV0lUQ0hfQ09NUEFOWSIsIlVQU0VSVF9DT01QQU5ZX0xPR08iLCJDUkVBVEVfTUFDUk8iLCJSRUFEX01BQ1JPIiwiVVBEQVRFX01BQ1JPIiwiREVMRVRFX01BQ1JPIiwiTElTVF9NQUNST1MiLCJDUkVBVEVfSE9MSURBWSIsIlJFQURfSE9MSURBWSIsIlVQREFURV9IT0xJREFZIiwiREVMRVRFX0hPTElEQVkiLCJMSVNUX0hPTElEQVlTIiwiQ1JFQVRFX1NFQ1RJT04iLCJSRUFEX1NFQ1RJT04iLCJVUERBVEVfU0VDVElPTiIsIkRFTEVURV9TRUNUSU9OIiwiTElTVF9TRUNUSU9OUyIsIkNSRUFURV9TWU5ESUNBVEUiLCJSRUFEX1NZTkRJQ0FURSIsIlVQREFURV9TWU5ESUNBVEUiLCJERUxFVEVfU1lORElDQVRFIiwiTElTVF9TWU5ESUNBVEVTIiwiQ1JFQVRFX1VTRVIiLCJSRUFEX1VTRVIiLCJVUERBVEVfVVNFUiIsIkRFTEVURV9VU0VSIiwiTElTVF9VU0VSUyIsIlVQREFURV9VU0VSX1BBU1NXT1JEIiwiVVBEQVRFX1VTRVJfT1dOX1BBU1NXT1JEIiwiQ1JFQVRFX1VTRVJTX1JFUE9SVCIsIkNSRUFURV9WRUhJQ0xFIiwiUkVBRF9WRUhJQ0xFIiwiVVBEQVRFX1ZFSElDTEUiLCJERUxFVEVfVkVISUNMRSIsIkxJU1RfVkVISUNMRVMiLCJDUkVBVEVfQ09NUEFOSUVTX1JFUE9SVCIsIlJFQURfVVNFUl9WRUhJQ0xFIiwiVVBEQVRFX1ZFSElDTEVfVVNFUiIsIkNSRUFURV9WRUhJQ0xFU19SRVBPUlQiLCJHRVRfSk9VUk5FWV9FVkVOVCIsIkxJU1RfSk9VUk5FWV9FVkVOVFMiXSwiaWF0IjoxNjk1OTMyNTcwLCJleHAiOjE2OTg1MjQ1NzB9.8OO_lZdY89hiKW4-5_MmH2JTMO7n9ywHbj8_Kp8FKE0",
    },
  });

}

export function initPaymentsServiceMock(api: AxiosInstance) {
  var mockAPI = new MockAdapter(api, { delayResponse: 2000 });

  mockAPI.onGet("/v1/payrollgroup").reply(
    200,
    {
      response_code: 1002,
      message: "Entity read successfully",
      data: [
        {
          _id: "64ede0fe71175e060410815c",
          name: "Folha de pagamento 1",
          batch: "Lote 1",
          competence_start: "2023-01-03T22:00:51.244Z",
          competence_end: "2023-01-03T22:00:51.244Z",
          created_at: "2023-10-03T18:13:06.884Z",
          updated_at: "2023-10-03T18:13:06.884Z",
        },
      ],
    },
    { "x-total-count": 1 }
  );
  

  mockAPI.onGet(/\/v1\/payrollgroup\/[^\\]+/).reply(() => {
    const data = {
      response_code: 1002,
      message: "Entity read successfully",
      data: {
        _id: "64ede0fe71175e060410815c",
        name: "Folha de pagamento 1",
        batch: "Lote 1",
        competence_start: "2023-01-03T22:00:51.244Z",
        competence_end: "2023-01-03T22:00:51.244Z",
        users: [
          {
            _id: "65049c8b6db41db44a13da84",
            name: "João da Silva",
            cpf: "12345678901",
          },
        ],
        created_at: "2023-10-03T18:13:06.884Z",
        updated_at: "2023-10-03T18:13:06.884Z",
      },
    };

    return [200, data];
  });

  mockAPI.onPost("/v1/payrollgroup").reply(200);
  mockAPI.onPut(/\/v1\/payrollgroup\/[^\\]+/).reply(200);
  mockAPI.onPost(/\/v1\/payrollgroup\/[^\\]+\/download/).reply(200);
  mockAPI.onPost(/\/v1\/payrollgroup\/[^\\]+\/refresh/).reply(200);

  mockAPI.onGet("/v1/hourbank").reply(
    200,
    {
      response_code: 1002,
      message: "Entity read successfully",
      data: [
        {
          _id: "64ede0fe71175e060410815c",
          user: "Antônio Fagundes",
          type: "DEBIT",
          reference_date: "2023-10-03T18:13:06.884Z",
          amount: 3600,
          due_date: "2023-10-03T18:13:06.884Z",
          created_at: "2023-10-03T18:13:06.884Z",
          updated_at: "2023-10-03T18:13:06.884Z",
        },
      ],
    },
    { "x-total-count": 1 }
  );

  mockAPI.onGet(/\/v1\/hourbank\/[^\\]+/).reply(() => {
    const data = {
      response_code: 1002,
      message: "Entity read successfully",
      data: {
        _id: "64ede0fe71175e060410815c",
        user: "Antônio Fagundes",
        type: "DEBIT",
        reference_date: "2023-10-03T18:13:06.884Z",
        amount: 3600,
        due_date: "2023-10-03T18:13:06.884Z",
        created_at: "2023-10-03T18:13:06.884Z",
        updated_at: "2023-10-03T18:13:06.884Z",
      },
    };

    return [200, data];
  });

  mockAPI.onPost("/v1/hourbank").reply(200);
}

export function initVehicleServiceMock(api: AxiosInstance) {
  var mockAPI = new MockAdapter(api, { delayResponse: 2000 });

  mockAPI.onGet("/v1/vehicle").reply(
    200,
    {
      response_code: 1003,
      message: "Entidade listada com sucesso",
      data: [
        {
          _id: "6523d2bb83cffdf58162b5f8",
          tag: "9089128",
          fleet: 1223124124,
          last_journey_event: {
            name: "Parado para comer",
            type: "MEAL_STOP_END",
            max_duration: 3600,
            started_at: "2023-10-12T13:00:23.848Z",
          },
          company: "6523d2701bbfef1fc5175b71",
          created_at: "2023-10-09T10:15:23.848Z",
          updated_at: "2023-10-09T10:15:23.848Z",
        },
      ],
    },
    { "x-total-count": 3 }
  );

  mockAPI.onGet(/\/v1\/vehicle\/[^\\]+/).reply(() => {
    const data = {
      response_code: 1002,
      message: "Entity read successfully",
      data: {
        _id: "6523d2bb83cffdf58162b5f8",
        tag: "9089128",
        fleet: 1223124124,
        last_journey_event: {
          name: "Parado para comer",
          type: "MEAL_STOP_END",
          max_duration: 3600,
          started_at: "2023-10-12T13:00:23.848Z",
        },
        company: "6523d2701bbfef1fc5175b71",
        created_at: "2023-10-09T10:15:23.848Z",
        updated_at: "2023-10-09T10:15:23.848Z",
      },
    };

    return [200, data];
  });

  mockAPI.onPut(/\/v1\/vehicle\/[^\\]+/).reply(200);
  mockAPI.onDelete(/\/v1\/vehicle\/[^\\]+/).reply(200);

  mockAPI.onGet("/v1/journeyevent").reply(
    200,
    {
      response_code: 1000,
      message: "Entity listed successfully",
      data: [
        {
          _id: "64ede0fe71175e060410815c",
          type: "JOURNEY_START",
          name: "Journey Start",
        },
      ],
    },
    { "x-total-count": 1 }
  );

  mockAPI.onGet(/\/v1\/journeyevent\/[^\\]+/).reply(() => {
    const data = {
      response_code: 1002,
      message: "Entity read successfully",
      data: {
        _id: "64ede0fe71175e060410815c",
        type: "JOURNEY_START",
        name: "Journey Start",
      },
    };

    return [200, data];
  });

  mockAPI.onGet("/v1/macro").reply(
    200,
    {
      response_code: 1002,
      message: "Entity read successfully",
      data: [
        {
          _id: "64ede0fe71175e060410815c",
          vehicle: "RD20DDG",
          user: "Joseph Hans",
          journey_event: "Overnight Stop",
          coordinates: [5.046767955645919, 156.5363671258092],
          location: {
            street: "Wuckert Junction",
            number: "5956",
            neighbourhood: "Buckinghamshire",
            city: "Port Hubert",
            state: "Maryland",
            country: "Togo",
            zipcode: "62094-7866",
          },
          started_at: "2023-01-03T22:00:51.244Z",
          ended_at: "2023-01-03T23:00:51.244Z",
          duration: 3600,
          created_at: "2023-10-03T18:13:06.884Z",
          updated_at: "2023-10-03T18:13:06.884Z",
        },
      ],
    },
    { "x-total-count": 1 }
  );

  mockAPI.onGet(/\/v1\/macro\/[^\\]+/).reply(() => {
    const data = {
      response_code: 1002,
      message: "Entity read successfully",
      data: {
        _id: "64ede0fe71175e060410815c",
        vehicle: "RD20DDG",
        user: "Joseph Hans",
        journey_event: "Overnight Stop",
        coordinates: [5.046767955645919, 156.5363671258092],
        location: {
          street: "Wuckert Junction",
          number: "5956",
          neighbourhood: "Buckinghamshire",
          city: "Port Hubert",
          state: "Maryland",
          country: "Togo",
          zipcode: "62094-7866",
        },
        started_at: "2023-01-03T22:00:51.244Z",
        ended_at: "2023-01-03T23:00:51.244Z",
        duration: 3600,
        status: "ok",
        created_at: "2023-10-03T18:13:06.884Z",
        updated_at: "2023-10-03T18:13:06.884Z",
      },
    };

    return [200, data];
  });
}

export function initAnalyticsServiceMock(api: AxiosInstance) {
  var mockAPI = new MockAdapter(api, { delayResponse: 2000 });

  mockAPI.onGet("/v1/macro/dashboardstatus").reply(200, {
    response_code: 1030,
    message: "Query performed successfully",
    data: {
      WORKED_HOURS_LOW: 0,
      WORKED_HOURS_MEDIUM: 0,
      WORKED_HOURS_HIGH: 0,
      INTRADAY_REST_LOW: 0,
      INTRADAY_REST_MEDIUM: 0,
      INTRADAY_REST_HIGH: 0,
      INTERDAY_REST_LOW: 0,
      INTERDAY_REST_MEDIUM: 0,
      INTERDAY_REST_HIGH: 0,
      WAITING_HOURS_LOW: 0,
      WAITING_HOURS_MEDIUM: 0,
      WAITING_HOURS_HIGH: 0,
      EXTRA_HOUR_LOW: 0,
      EXTRA_HOUR_MEDIUM: 0,
      EXTRA_HOUR_HIGH: 0,
      AVAILABLE_LOW: 0,
      AVAILABLE_MEDIUM: 0,
      AVAILABLE_HIGH: 0,
      STOPPED_LOW: 0,
      STOPPED_MEDIUM: 0,
      STOPPED_HIGH: 0,
      NO_SIGNAL_LOW: 0,
      NO_SIGNAL_MEDIUM: 0,
      NO_SIGNAL_HIGH: 0,
    },
  });

  mockAPI.onGet("/v1/macro").reply(
    200,
    {
      response_code: 1002,
      message: "Entity read successfully",
      data: [
        {
          _id: "64ede0fe71175e060410815c",
          vehicle: "RD20DDG",
          vehicle_id: "64ede0fe71175e060420833c",
          user: "Joseph Hans",
          user_id: "64ede0fe71175e060420838c",
          journey_event: {
            name: "Parado para comer",
            type: "MEAL_STOP_END",
            max_duration: 3600,
            started_at: "2023-10-12T13:00:23.848Z",
          },
        },
      ],
    },
    { "x-total-count": 1 }
  );

  mockAPI.onGet(/\/v1\/macro\/user\/[^\\]+\/journey/).reply(200, {
    response_code: 1002,
    message: "Entity read successfully",
    data: [
      {
        type: "WORKED_HOURS",
        start_time: "2023-01-03T07:00:51.244Z",
        end_time: "2023-01-03T9:30:51.244Z",
        hours: 0.1,
      },
      {
        type: "WAITING_HOURS",
        start_time: "2023-01-03T09:30:51.244Z",
        end_time: "2023-01-03T10:00:51.244Z",
        hours: 0.5,
      },
      {
        type: "INTRADAY_REST",
        start_time: "2023-01-04T10:00:51.244Z",
        end_time: "2023-01-03T11:00:51.244Z",
        hours: 0.2,
      },
      {
        type: "WORKED_HOURS",
        start_time: "2023-01-03T11:00:51.244Z",
        end_time: "2023-01-03T18:00:51.244Z",
        hours: 90,
      },
      {
        type: "INTRADAY_REST",
        start_time: "2023-01-04T10:00:51.244Z",
        end_time: "2023-01-03T11:00:51.244Z",
        hours: 0.2,
      },
    ],
  });

  mockAPI.onGet("/v1/vehicle/positions").reply(200, {
    response_code: 1030,
    message: "Query performed successfully",
    data: [
      {
        vehicle: {
          _id: "64ede0fe71175e060420815c",
          tag: "AJD9812",
        },
        company: "64ede0fe71175e060420815c",
        position: [-22.4221013, -45.452756],
        journey_event: {
          _id: "64ede0fe71175e060420815c",
          type: "JOURNEY_STARTED",
          name: {
            "pt-br": "Jornada iniciada",
            "en-us": "Journey started",
            "es-es": "Jornada iniciada",
          },
        },
        user: {
          _id: "64ede0fe71175e060420815c",
          name: "John Doe",
        },
        date: "2023-10-01T03:00:00.000Z",
      },
    ],
  });

  mockAPI.onGet(/\/v1\/vehicle\/[^\\]+\/positions/).reply(200, {
    response_code: 1030,
    message: "Query performed successfully",
    data: {
      last_macro: {
        vehicle: {
          _id: "64ede0fe71175e060420815c",
          tag: "AJD9812",
        },
        position: [-22.4174, -45.444634],
        journey_event: {
          _id: "64ede0fe71175e060420815c",
          type: "JOURNEY_STARTED",
          name: {
            "pt-br": "Jornada iniciada",
            "en-us": "Journey started",
            "es-es": "Jornada iniciada",
          },
        },
        user: {
          _id: "64ede0fe71175e060420815c",
          name: "John Doe",
        },
        date: "2023-10-01T03:00:00.000Z",
      },
      geolocations: [
        {
          _id: "64ede0fe71175e060420815c",
          performed_at: "2023-08-30T00:00:00.000Z",
          coordinates: [-22.424163, -45.46033],
        },
        {
          _id: "64ede0fe71175e060420815c",
          performed_at: "2023-08-30T00:00:00.000Z",
          coordinates: [-22.431114, -45.453778],
        },
        {
          _id: "64ede0fe71175e060420815c",
          performed_at: "2023-08-30T00:00:00.000Z",
          coordinates: [-22.426123, -45.450939],
        },
        {
          _id: "64ede0fe71175e060420815c",
          performed_at: "2023-08-30T00:00:00.000Z",
          coordinates: [-22.4174, -45.444634],
        },
      ],
    },
  });
}

export function initTrackingServiceMock(api: AxiosInstance) {
  var mockAPI = new MockAdapter(api, { delayResponse: 2000 });
  
  mockAPI.onPost("/v1/macro/manual").reply(200);
}

export function initReportServiceMock(api: AxiosInstance) {
  var mockAPI = new MockAdapter(api, { delayResponse: 2000 });
  
  mockAPI.onPost("/v1/payroll/export").reply(200);
}