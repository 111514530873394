import { StateCreator } from "zustand";
import { DashboardState, GRANULARITY, TIME_RANGE } from "../types/dashboard";
import dashboardService from "../../services/dashboardService";
import { produce } from "immer";
import { toast } from "react-toastify";
import macroService from "../../services/macroService";

export const createDashboardSlice: StateCreator<DashboardState> = (
  set,
  get
) => ({
  
  dashboard: {
    statusPanel: {
      selectedMacro: {
        geolocations: [],
        user: "",
        vehicle: "",
        user_id: "",
        vehicle_id: "",
        journey_event: undefined,
        lastMacrosList: {
          data: [],
          currentPage: 1,
          totalItems: 1,
          totalPages: 1,
          limitPerPage: 10,
          offset: 0,
          filters: {
            user_id: "",
            sort_key: "started_at",
            sort_value: "desc",
          },
          setLimitPerPage: (limitPerPage: number) => {
            set(
              produce((state) => {
                state.dashboard.statusPanel.selectedMacro.lastMacrosList.limitPerPage =
                  limitPerPage;
                state.dashboard.statusPanel.selectedMacro.lastMacrosList.offset = 0;
                state.dashboard.statusPanel.selectedMacro.lastMacrosList.currentPage = 1;
              })
            );
            get().dashboard.getLastMacrosList();
          },
          setCurrentPage: (page: number) => {
            set(
              produce((state) => {
                state.dashboard.statusPanel.selectedMacro.lastMacrosList.currentPage =
                  page;
                state.dashboard.statusPanel.selectedMacro.lastMacrosList.offset =
                  page - 1;
              })
            );
            get().dashboard.getLastMacrosList();
          },
          setFilters: (filters: any) => {
            set(
              produce((state) => {
                state.dashboard.statusPanel.selectedMacro.lastMacrosList.filters =
                  filters;
              })
            );
            get().dashboard.getLastMacrosList();
          },
        },
      },
      status: {
        WORKED_HOURS_LOW: 0,
        WORKED_HOURS_MEDIUM: 0,
        WORKED_HOURS_HIGH: 0,
        INTRADAY_REST_LOW: 0,
        INTRADAY_REST_MEDIUM: 0,
        INTRADAY_REST_HIGH: 0,
        MEAL_LOW: 0,
        MEAL_MEDIUM: 0,
        MEAL_HIGH: 0,
        INTERDAY_REST_LOW: 0,
        INTERDAY_REST_MEDIUM: 0,
        INTERDAY_REST_HIGH: 0,
        INTERDAY_COMPENSATION_LOW: 0,
        INTERDAY_COMPENSATION_MEDIUM: 0,
        INTERDAY_COMPENSATION_HIGH: 0,
        WAITING_HOURS_LOW: 0,
        WAITING_HOURS_MEDIUM: 0,
        WAITING_HOURS_HIGH: 0,
        EXTRA_HOUR_LOW: 0,
        EXTRA_HOUR_MEDIUM: 0,
        EXTRA_HOUR_HIGH: 0,
        AVAILABLE_LOW: 0,
        AVAILABLE_MEDIUM: 0,
        AVAILABLE_HIGH: 0,
        STOPPED_LOW: 0,
        STOPPED_MEDIUM: 0,
        STOPPED_HIGH: 0,
        NO_SIGNAL_LOW: 0,
        NO_SIGNAL_MEDIUM: 0,
        NO_SIGNAL_HIGH: 0,
      },
      macrosList: {
        data: [],
        currentPage: 1,
        totalItems: 1,
        totalPages: 1,
        limitPerPage: 10,
        offset: 0,
        filters: {
          vehicle: "",
          user: "",
          dash_status: "WORKED_HOURS_LOW",
          sort_key: "user",
          sort_value: "asc",
        },
        setLimitPerPage: (limitPerPage: number) => {
          set(
            produce((state) => {
              state.dashboard.statusPanel.macrosList.limitPerPage =
                limitPerPage;
              state.dashboard.statusPanel.macrosList.offset = 0;
              state.dashboard.statusPanel.macrosList.currentPage = 1;
            })
          );
          get().dashboard.getDashboardStatusAndMacros();
        },
        setCurrentPage: (page: number) => {
          set(
            produce((state) => {
              state.dashboard.statusPanel.macrosList.currentPage = page;
              state.dashboard.statusPanel.macrosList.offset = page - 1;
            })
          );
          get().dashboard.getDashboardStatusAndMacros();
        },
        setFilters: (filters: any) => {
          set(
            produce((state) => {
              state.dashboard.statusPanel.macrosList.filters = filters;
              state.dashboard.statusPanel.macrosList.offset = 0;
              state.dashboard.statusPanel.macrosList.currentPage = 1;
            })
          );
          get().dashboard.getDashboardStatusAndMacros();
        },
      },
    },
    charts: {
      data: {
        lastMacroPositions: [],
        fleetEvolution: [],
        timeAggregator: [],
        macroAlterations: [],
      },
      filters: {
        time_range: TIME_RANGE.last_year,
        granularity: GRANULARITY.months,
      },
    },
    isLoadingLastMacros: false,
    isLoadingUserJourney: false,
    isLoadingVehiclePositions: false,
    isLoadingDashboardMacro: false,
    isLoadingDashboardStatus: false,
    isLoadingFleetEvoluition: false,
    isLoadingTimeAggregator: false,
    isLoadingLastMacroPositions: false,
    isLoadingMacroAlterations: false,
    setSelectedMacro: (macro: any) => {
      set(
        produce((state) => {
          state.dashboard.statusPanel.selectedMacro = {
            ...state.dashboard.statusPanel.selectedMacro,
            ...macro,
          };
        })
      );
    },
    resetSelectedMacro: () => {
      set(
        produce((state) => {
          state.dashboard.statusPanel.selectedMacro = {
            geolocations: [],
            user: "",
            vehicle: "",
            user_id: "",
            vehicle_id: "",
            journey_event: null,
            lastMacrosList: {
              data: [],
              currentPage: 1,
              totalItems: 1,
              totalPages: 1,
              limitPerPage: 10,
              offset: 0,
              filters: {
                user_id: "",
                sort_key: "started_at",
                sort_value: "desc",
              },
              setLimitPerPage: (limitPerPage: number) => {
                set(
                  produce((state) => {
                    state.dashboard.statusPanel.selectedMacro.lastMacrosList.limitPerPage =
                      limitPerPage;
                    state.dashboard.statusPanel.selectedMacro.lastMacrosList.offset = 0;
                    state.dashboard.statusPanel.selectedMacro.lastMacrosList.currentPage = 1;
                  })
                );
                get().dashboard.getLastMacrosList();
              },
              setCurrentPage: (page: number) => {
                set(
                  produce((state) => {
                    state.dashboard.statusPanel.selectedMacro.lastMacrosList.currentPage =
                      page;
                    state.dashboard.statusPanel.selectedMacro.lastMacrosList.offset =
                      page - 1;
                  })
                );
                get().dashboard.getLastMacrosList();
              },
              setFilters: (filters: any) => {
                set(
                  produce((state) => {
                    state.dashboard.statusPanel.selectedMacro.lastMacrosList.filters =
                      filters;
                  })
                );
                get().dashboard.getLastMacrosList();
              },
            },
          }
        })
      );
    },
    setChartFilters: (timeRange: TIME_RANGE) => {
      set(
        produce((state) => {
          state.dashboard.charts.filters.time_range = timeRange;
          if (
            timeRange === TIME_RANGE.last_week ||
            timeRange === TIME_RANGE.last_month
          ) {
            state.dashboard.charts.filters.granularity = GRANULARITY.days;
          } else {
            state.dashboard.charts.filters.granularity = GRANULARITY.months;
          }
        })
      );

      get().dashboard.getFleetEvolution();
      get().dashboard.getMacroAlterations();
    },
    getFleetEvolution: async () => {
      const { time_range, granularity } = get().dashboard.charts.filters;
      set(
        produce((state) => {
          state.dashboard.isLoadingFleetEvoluition = true;
        })
      );
      await dashboardService
        .getFleetEvolution({
          time_range,
          granularity,
        })
        .then((data) => {
          set(
            produce((state) => {
              state.dashboard.charts.data.fleetEvolution = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.dashboard.isLoadingFleetEvoluition = false;
            })
          );
        });
    },
    getTimeAggregator: async () => {
      set(
        produce((state) => {
          state.dashboard.isLoadingTimeAggregator = true;
        })
      );
      await dashboardService
        .getTimeAggregator()
        .then((data) => {
          set(
            produce((state) => {
              state.dashboard.charts.data.timeAggregator = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.dashboard.isLoadingTimeAggregator = false;
            })
          );
        });
    },
    getLastMacroPositions: async () => {
      let section;
      set(
        produce((state) => {
          state.dashboard.isLoadingLastMacroPositions = true;
          section = state.admin.data.section?._id;
        })
      );
      await dashboardService
        .getLastMacroPositions(section)
        .then((data) => {
          set(
            produce((state) => {
              state.dashboard.charts.data.lastMacroPositions = data.map(
                (d: any) => {
                  return {
                    position: { lat: d.position[0], lng: d.position[1] },
                    tag: d.vehicle.tag,
                    journeyEvent: d.journey_event.name["pt-br"],
                    user: d.user.name,
                    startedAt: d.date,
                  };
                }
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.dashboard.isLoadingLastMacroPositions = false;
            })
          );
        });
    },
    getMacroAlterations: async () => {
      const { time_range } = get().dashboard.charts.filters;

      set(
        produce((state) => {
          state.dashboard.isLoadingMacroAlterations = true;
        })
      );
      await dashboardService
        .getMacroAlterations({ time_range })
        .then((data) => {
          const dataFormatted = data
            .map((d: any) => ({
              user: d.user.name,
              actor: d.actor || "general", // Set actor to "general" if undefined
              value: d.value,
            }))
            .sort((a: any, b: any) => {
              // Custom sorting logic: managers first, then drivers, general, and by value in descending order
              const actorOrder = (actor: any) => {
                switch (actor) {
                  case "manager":
                    return 2;
                  case "driver":
                    return 1;
                  case "general":
                    return 0;
                  default:
                    return 0;
                }
              };

              const orderA = actorOrder(a.actor);
              const orderB = actorOrder(b.actor);

              if (orderA !== orderB) {
                return orderB - orderA; // Reverse order for descending order
              }

              return a.value - b.value;
            });

          set(
            produce((state) => {
              state.dashboard.charts.data.macroAlterations = dataFormatted;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.dashboard.isLoadingMacroAlterations = false;
            })
          );
        });
    },
    getDashboardStatusAndMacros: async () => {
      let section: any;
      set(
        produce((state) => {
          state.dashboard.isLoadingDashboardStatus = true;
          state.dashboard.isLoadingDashboardMacro = true;
          section = state.admin.data.section?._id;
        })
      );
      await dashboardService
        .getDashboardStatus(section)
        .then(async (data) => {
          set(
            produce((state) => {
              state.dashboard.statusPanel.status = data;
            })
          );

          const filters = get().dashboard.statusPanel.macrosList.filters;
          const limit = get().dashboard.statusPanel.macrosList.limitPerPage;
          const offset = get().dashboard.statusPanel.macrosList.offset;

          await dashboardService
            .getDashboardMacro({ filters, limit, offset }, section)
            .then(({ data, totalItems }: any) => {
              set(
                produce((state) => {
                  state.dashboard.statusPanel.macrosList.data = data;
                  state.dashboard.statusPanel.macrosList.totalItems =
                    totalItems;
                  state.dashboard.statusPanel.macrosList.totalPages = Math.ceil(
                    totalItems /
                      state.dashboard.statusPanel.macrosList.limitPerPage
                  );
                })
              );
            })
            .catch((error) => {
              toast.error(error.message);
            })
            .finally(() => {
              set(
                produce((state) => {
                  state.dashboard.isLoadingDashboardMacro = false;
                })
              );
            });
        })
        .catch((error) => {
          set(
            produce((state) => {
              state.dashboard.isLoadingDashboardStatus = false;
              state.dashboard.isLoadingDashboardMacro = false;
            })
          );
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.dashboard.isLoadingDashboardStatus = false;
            })
          );
        });
    },
    getUserJourney: async (id: string) => {
      set(
        produce((state) => {
          state.dashboard.isLoadingUserJourney = true;
        })
      );
      await dashboardService
        .getUserJourney(id)
        .then(async (data) => {
          get().dashboard.setSelectedMacro({
            ...get().dashboard.statusPanel.selectedMacro,
            journeyChartData: data,
          });
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.dashboard.isLoadingUserJourney = false;
            })
          );
        });
    },
    getVehiclePositions: async (id: string) => {
      set(
        produce((state) => {
          state.dashboard.isLoadingVehiclePositions = true;
        })
      );
      await dashboardService
        .getVehiclePositions(id)
        .then(async (data) => {
          get().dashboard.setSelectedMacro({
            ...get().dashboard.statusPanel.selectedMacro,
            position: data.last_macro.position,
            geolocations: data.geolocations,
          });
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.dashboard.isLoadingVehiclePositions = false;
            })
          );
        });
    },
    getLastMacrosList: () => {
      let section;
      set(
        produce((state) => {
          state.dashboard.isLoadingLastMacros = true;
          section = state.admin.data.section?._id;
        })
      );

      const filters = {
        ...get().dashboard.statusPanel.selectedMacro.lastMacrosList.filters,
        user_id: get().dashboard.statusPanel.selectedMacro?.user_id ?? "",
      };
      const limit =
        get().dashboard.statusPanel.selectedMacro.lastMacrosList.limitPerPage;
      const offset =
        get().dashboard.statusPanel.selectedMacro.lastMacrosList.offset;

      macroService
        .getMacros({ filters, limit, offset }, section)
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.dashboard.statusPanel.selectedMacro.lastMacrosList.data =
                data;
              state.dashboard.statusPanel.selectedMacro.lastMacrosList.totalItems =
                totalItems;
              state.dashboard.statusPanel.selectedMacro.lastMacrosList.totalPages =
                Math.ceil(
                  totalItems /
                    state.dashboard.statusPanel.selectedMacro.lastMacrosList
                      .limitPerPage
                );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.dashboard.isLoadingLastMacros = false;
            })
          );
        });
    },
    resetDashboardStatus: async () => {
      let section: any;
      set(
        produce((state) => {
          state.dashboard.isLoadingDashboardStatus = true;
          state.dashboard.isLoadingDashboardMacro = true;
          section = state.admin.data.section?._id;
        })
      );

      await dashboardService.resetDashboardStatus()
      .catch((error) => {
        toast.error(error.message);
      });

      await dashboardService
        .getDashboardStatus(section)
        .then(async (data) => {
          set(
            produce((state) => {
              state.dashboard.statusPanel.status = data;
            })
          );

          const filters = get().dashboard.statusPanel.macrosList.filters;
          const limit = get().dashboard.statusPanel.macrosList.limitPerPage;
          const offset = get().dashboard.statusPanel.macrosList.offset;

          await dashboardService
            .getDashboardMacro({ filters, limit, offset }, section)
            .then(({ data, totalItems }: any) => {
              set(
                produce((state) => {
                  state.dashboard.statusPanel.macrosList.data = data;
                  state.dashboard.statusPanel.macrosList.totalItems =
                    totalItems;
                  state.dashboard.statusPanel.macrosList.totalPages = Math.ceil(
                    totalItems /
                      state.dashboard.statusPanel.macrosList.limitPerPage
                  );
                })
              );
            })
            .catch((error) => {
              toast.error(error.message);
            })
            .finally(() => {
              set(
                produce((state) => {
                  state.dashboard.isLoadingDashboardMacro = false;
                })
              );
            });
        })
        .catch((error) => {
          set(
            produce((state) => {
              state.dashboard.isLoadingDashboardStatus = false;
              state.dashboard.isLoadingDashboardMacro = false;
            })
          );
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.dashboard.isLoadingDashboardStatus = false;
            })
          );
        });
    },
  },
});
