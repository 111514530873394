import React from "react";
import Skeleton from "react-loading-skeleton";
import { Map } from "@vis.gl/react-google-maps";
import PositionMarker from "./PositionMarker";

const LastMacroMap: React.FC<{ data: any; loading: boolean }> = ({
  data,
  loading,
}) => {
  return (
    <>
      <div className="text-dark-blue font-bold text-2xl mb-3">
        Localização da última macro da frota
      </div>
      {loading ? (
        <Skeleton height={400} />
      ) : (
        <Map
          style={{ width: "100%", height: "400px" }}
          defaultCenter={{ lat: -17.46, lng: -55.46 }}
          defaultZoom={4}
          mapId={"dashboard_map"}
          reuseMaps
        >
          {data.map((d: any, i: number) => (
            <PositionMarker data={data[i]} key={i} />
          ))}
        </Map>
      )}
    </>
  );
};

export default LastMacroMap;
