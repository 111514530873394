import Modal from "../../../components/Modal/Modal";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { Colors } from "../../../utils/constants/style";
import { useStore } from "../../../store/useStore";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import { MACRO_REMOVE_REASON_OPTIONS } from "../../../utils/constants/constants";
import { Danger, Trash } from "iconsax-react";

interface DeleteMacroModalProps {
    onClose: () => void;
  }

const DeleteMacrosModal: React.FC<DeleteMacroModalProps> = () => {
  const selectedMacros = useStore(
    (state) => state.macro.macrosList.macrosSelected
  );
  const deleteMacros = useStore(
    (state) => state.macro.deleteMacros
  );
  const isDeletingMacro = useStore(
    (state) => state.macro.isDeletingMacro
  );

  return (
    <Modal
      title="Remoção de macros"
      icon={<Trash size={30} variant="Bold" color={Colors.white} />}
      className="w-[400px]"
    >
      <Formik
        initialValues={{
          reason: "",
          description: "",
        }}
        validationSchema={Yup.object().shape({
          reason: Yup.string().required("Campo obrigatório"),
          description: Yup.string().required("Campo obrigatório")
        })}
        onSubmit={(values) => {
          deleteMacros(selectedMacros, values.reason, values.description);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className="flex flex-col gap-8 pt-5">
                <div className="flex flex-col items-center">
                  <Danger size="72" variant="Bulk" color={Colors.red}/>
                  <div className="px-5">
                    Tem certeza que deseja remover {String(selectedMacros.length)} macro{selectedMacros.length > 1 ? "s" : ""}?
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="flex-1">
                    <Field
                      name="reason"
                      component={OutlinedSelect}
                      placeholder="Motivo"
                      options={MACRO_REMOVE_REASON_OPTIONS}
                    />
                  </div>
                </div>
                <Field
                  placeholder="Observações"
                  name="description"
                  component={OutlinedInput}
                />
                <div className="text-center flex flex-col items-center gap-5">
                  <FilledButton
                    loading={isDeletingMacro}
                    className="px-14"
                    type="submit"
                  >Remover</FilledButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default DeleteMacrosModal;
