import { create } from "zustand";
import { createAuthSlice } from "./modules/auth";
import { createNavbarSlice } from "./modules/navbar";
import { createAdminSlice } from "./modules/admin";
import { createModalSlice } from "./modules/modal";
import { createCollaboratorSlice } from "./modules/collaborator";
import { createFleetSlice } from "./modules/fleet";
import { createProfileSlice } from "./modules/profile";
import { createChangeCompanyModalSlice } from "./modules/changeCompanyModal";
import { createCompanySlice } from "./modules/company";
import { createMacroSlice } from "./modules/macroState";
import { createJourneyRuleSlice } from "./modules/journeyRule";
import { createConventionSlice } from "./modules/convention";
import { createReportSlice } from "./modules/report";
import { createTimeManagerSlice } from "./modules/timeManager";
import { createDashboardSlice } from "./modules/dashboard";
import { createCollaboratorIntegrationsSlice } from "./modules/collaboratorIntegrations";

import { AuthState } from "./types/auth";
import { NavbarState } from "./types/navbar";
import { AdminState } from "./types/admin";
import { ModalState } from "./types/modal";
import { CollaboratorState } from "./types/collaborator";
import { FleetState } from "./types/fleet";
import { ProfileState } from "./types/profile";
import { ChangeCompanyModalState } from "./types/company";
import { CompanyState } from "./types/company";
import { MacroState } from "./types/macroState";
import { JourneyRuleState } from "./types/journeyRule";
import { ConventionState } from "./types/convention";
import { ReportState } from "./types/report";
import { TimeManagerState } from "./types/timeManager";
import { DashboardState } from "./types/dashboard";
import { CollaboratorIntegrationsState } from "./types/collaboratorIntegrations";

export type StoreState = AuthState &
  NavbarState &
  AdminState &
  ModalState &
  CollaboratorState &
  FleetState &
  ProfileState &
  ChangeCompanyModalState &
  CompanyState &
  MacroState &
  JourneyRuleState &
  ConventionState &
  TimeManagerState &
  ReportState &
  DashboardState &
  CollaboratorIntegrationsState;

export const useStore = create<StoreState>()((...a) => ({
  ...createAuthSlice(...a),
  ...createNavbarSlice(...a),
  ...createAdminSlice(...a),
  ...createModalSlice(...a),
  ...createCollaboratorSlice(...a),
  ...createFleetSlice(...a),
  ...createProfileSlice(...a),
  ...createChangeCompanyModalSlice(...a),
  ...createCompanySlice(...a),
  ...createMacroSlice(...a),
  ...createJourneyRuleSlice(...a),
  ...createConventionSlice(...a),
  ...createReportSlice(...a),
  ...createTimeManagerSlice(...a),
  ...createDashboardSlice(...a),
  ...createCollaboratorIntegrationsSlice(...a)
}));
