import Modal from "../../../components/Modal/Modal";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { Colors } from "../../../utils/constants/style";
import { useStore } from "../../../store/useStore";
import { Danger, Trash } from "iconsax-react";

interface RestoreMacroModalProps {
    onClose: () => void;
  }

const RestoreMacroModal: React.FC<RestoreMacroModalProps> = () => {
  const selectedMacros = useStore(
    (state) => state.macro.macrosRestoreList.macrosSelected
  );
  const restoreMacros = useStore(
    (state) => state.macro.restoreMacros
  );
  const isRestoringMacro = useStore(
    (state) => state.macro.isRestoringMacro
  );

  const handleRestoreRegister = () => {
    restoreMacros(selectedMacros);
  };

  return (
    <Modal
      title="Restauração de macros"
      icon={<Trash size={30} variant="Bold" color={Colors.white} />}
      className="w-[400px]"

    >
      <div className="text-center flex flex-col items-center gap-5">
        <Danger size="72" variant="Bulk" color={Colors.red}/>
        <div className="px-5">
          Tem certeza que deseja restaurar {selectedMacros.length} macros?
        </div>
        <FilledButton loading={isRestoringMacro} onClick={handleRestoreRegister} className="px-14">
          Restaurar
        </FilledButton>
      </div>
    </Modal>
  );
};

export default RestoreMacroModal;
