import Skeleton from "react-loading-skeleton";
import { getNestedValue } from "../../../utils/functions/functions";
import { TickCircle } from "iconsax-react";
import { Colors } from "../../../utils/constants/style";
import ShadowBox from "../../ShadowBox/ShadowBox";
import { formatCNPJ } from "../../../utils/functions/formatters";
import { Badge } from 'primereact/badge';

interface SelectCompanyViewportProps {
  data: any[];
  loading?: boolean;
  currentCompanyId: string | undefined;
  onSelectCompany: (item: any) => void;
}

const SelectCompanyViewport: React.FC<SelectCompanyViewportProps> = ({
  currentCompanyId,
  data,
  loading,
  onSelectCompany,
}) => {
  
  const handleSelectCompany = (item: any) => {
    onSelectCompany(item);
  };

  return (
    <div className="grid grid-cols-3 gap-4">
      {data.map((item, rowIndex) => (
          <>
          {item._id === currentCompanyId ? 
            <div className="py-5 px-8 rounded-[12px] border-solid border-2 border-gray_05 bg-gray_05 mb-5 text-center transition-all duration-200 ease-in-out" onClick={
              () => handleSelectCompany(item)
              }>
                <img className="h-36 object-content mb-5 mx-auto" style={{mixBlendMode: 'multiply'}} src={item.logo}  alt="Logo da empresa" />
                <p className="text-gray_2 text-base">{item.name}</p>
                <p className="text-gray_2 text-[11px] my-3 font-bold">{formatCNPJ(item.cnpj)}</p>
                <span className="w-fit rounded-[8px] text-sm font-bold px-2 py-1 bg-light-red text-red">Selecionado</span>
              </div>
            :
            <div className="py-5 px-8 rounded-[12px] border-solid border-2 border-gray_05 mb-5 text-center hover:bg-gray_05 hover:cursor-pointer transition-all duration-200 ease-in-out" onClick={
              () => handleSelectCompany(item)
              }>
                <img className="h-36 object-content mb-5 mx-auto" style={{mixBlendMode: 'multiply'}} src={item.logo}  alt="Logo da empresa" />
                <p className="text-gray_2 text-base">{item.name}</p>
                <p className="text-gray_2 text-[11px] my-2 font-bold">{formatCNPJ(item.cnpj)}</p>
              </div>}
          </>
          
        ))
      }

      {data.length === 0 && !loading && (
        <div className="flex items-center mt-4 text-gray_1 justify-center">
          Nenhum resultado encontrado
        </div>
      )}
    </div>
  );
};
export default SelectCompanyViewport;
