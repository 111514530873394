import Modal from "../../../components/Modal/Modal";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { Colors } from "../../../utils/constants/style";
import { useStore } from "../../../store/useStore";
import { Danger, Trash } from "iconsax-react";

interface DeleteCompensatoryTimeModalProps {
    onClose: () => void;
  }

const DeleteCompensatoryTimeModal: React.FC<DeleteCompensatoryTimeModalProps> = () => {
  const selectedCompensatoryTime = useStore(
    (state) => state.timeManager.selectedCompensatoryTime
  );
  const deleteCompensatoryTime = useStore(
    (state) => state.timeManager.deleteCompensatoryTime
  );
  const isDeletingCompensatoryTime = useStore(
    (state) => state.timeManager.isDeletingCompensatoryTime
  );

  const handleDeleteRegister = () => {
    if(selectedCompensatoryTime?._id)
      deleteCompensatoryTime(selectedCompensatoryTime._id);
  };

  return (
    <Modal
      title="Exclusão de registro de banco de horas"
      icon={<Trash size={30} variant="Bold" color={Colors.white} />}
      className="w-[400px]"

    >
      <div className="text-center flex flex-col items-center gap-5">
        <Danger size="72" variant="Bulk" color={Colors.red}/>
        <div className="px-5">
          Tem certeza que deseja remover este registro de banco de horas do colaborador {selectedCompensatoryTime?.user.name ?? '-'}?
        </div>
        <FilledButton loading={isDeletingCompensatoryTime} onClick={handleDeleteRegister} className="px-14">
          Excluir
        </FilledButton>
      </div>
    </Modal>
  );
};

export default DeleteCompensatoryTimeModal;
