import DataTable from "../../../components/DataTable/DataTable";
import useModal from "../../../hooks/useModal";
import {
  JourneyRule,
  REGIME_TRANSLATION,
} from "../../../store/types/journeyRule";
import { useStore } from "../../../store/useStore";
import { formatDateTime } from "../../../utils/functions/formatters";
import DetailsJourneyRuleModal from "./DetailsJourneyRuleModal";
import EditJourneyRuleModal from "./EditJourneyRuleModal";

const JourneyRulesTable = () => {
  const journeyRules = useStore(
    (state) => state.journeyRules.journeyRulesList.data
  );
  const setFilters = useStore(
    (state) => state.journeyRules.journeyRulesList.setFilters
  );
  const filters = useStore(
    (state) => state.journeyRules.journeyRulesList.filters
  );
  const isLoadingJourneyRulesList = useStore(
    (state) => state.journeyRules.isLoadingJourneyRulesList
  );
  const resetSelectedJourneyRule = useStore(
    (state) => state.journeyRules.resetSelectedJourneyRule
  );
  const getJourneyRule = useStore((state) => state.journeyRules.getJourneyRule);
  const { openModal } = useModal();

  const openDetailsJourneyRuleModal = (journeyRuleId: string) => {
    getJourneyRule(journeyRuleId);
    openModal(<DetailsJourneyRuleModal onClose={resetSelectedJourneyRule} />);
  };

  const openEditJourneyRuleModal = (journeyRuleId: string) => {
    getJourneyRule(journeyRuleId);
    openModal(<EditJourneyRuleModal onClose={resetSelectedJourneyRule} />);
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "name", label: "Nome", sortKey: "name" },
    { key: "description", label: "Descrição" },
    {
      key: "regime",
      label: "Regime",
      render: (item: JourneyRule) => REGIME_TRANSLATION[item.regime],
    },
    {
      key: "created_at",
      label: "Criado em",
      render: (item: JourneyRule) => formatDateTime(item.created_at),
    },
    {
      key: "updated_at",
      label: "Atualizado em",
      render: (item: JourneyRule) => formatDateTime(item.updated_at),
    },
  ];

  return (
    <DataTable
      data={journeyRules}
      columns={columns}
      onDetails={openDetailsJourneyRuleModal}
      onEdit={openEditJourneyRuleModal}
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingJourneyRulesList}
    />
  );
};

export default JourneyRulesTable;
