import { useEffect } from "react";
import DataPanel from "../../../components/DataPanel/DataPanel";
import Pagination from "../../../components/Pagination/Pagination";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import useModal from "../../../hooks/useModal";
import { useStore } from "../../../store/useStore";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import Skeleton from "react-loading-skeleton";
import FilterCompensatoryTimeModal from "./FilterCompensatoryTimeModal";
import CompensatoryTimeTable from "./CompensatoryTimeTable";
import CreateCompensatoryTimeModal from "./CreateCompensatoryTimeModal";
import { AddCircle, Filter } from "iconsax-react";

const CompensatoryTimeGeneral: React.FC<{}> = () => {
  const { openModal } = useModal();
  const getCompensatoryTimes = useStore((state) => state.timeManager.getCompensatoryTimes);
  const compensatoryTimeList = useStore((state) => state.timeManager.compensatoryTimeList);
  const isLoadingCompensatoryTimesList = useStore(
    (state) => state.timeManager.isLoadingCompensatoryTimesList
  );
  useEffect(() => {
    getCompensatoryTimes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openFilterCompensatoryTimeModal = () => openModal(<FilterCompensatoryTimeModal />);
  const openCreateMacroModal = () =>
    openModal(<CreateCompensatoryTimeModal />);

  return (
    <>
      <div className="mt-9">
        <DataPanel className="px-3 pt-8 flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="pl-1">
              {/* <SearchInput placeholder="Buscar registro" onChange={() => {}} /> */}
            </div>
            <div className="pr-1 flex gap-4">
              <RoundedFilledButton
                onClick={openCreateMacroModal}
                icon={<AddCircle />}
                text="Novo"
              />
              <RoundedFilledButton
                onClick={openFilterCompensatoryTimeModal}
                icon={<Filter size={24} />}
                text="Filtros"
              />
            </div>
          </div>
          <CompensatoryTimeTable />
          <div className="flex justify-between items-center">
            <div className="text-gray_1">
              Mostrando{" "}
              {!isLoadingCompensatoryTimesList ? (
                compensatoryTimeList.data.length +
                compensatoryTimeList.offset * compensatoryTimeList.limitPerPage
              ) : (
                <Skeleton inline width={20} />
              )}{" "}
              de {compensatoryTimeList.totalItems} registros
            </div>
            <div className="flex gap-5 items-center">
              <LimitPerPage
                onLimitChange={(e: any) =>
                  compensatoryTimeList.setLimitPerPage(e.target.value)
                }
                total={compensatoryTimeList.totalItems}
              />
              <Pagination
                currentPage={compensatoryTimeList.currentPage}
                totalPages={compensatoryTimeList.totalPages}
                onPageChange={compensatoryTimeList.setCurrentPage}
              />
            </div>
          </div>
        </DataPanel>
      </div>
    </>
  );
};

export default CompensatoryTimeGeneral;
