import React from "react";
import { ChevronIcon } from "../../assets/imgs";
import { Colors } from "../../utils/constants/style";
import Skeleton from "react-loading-skeleton";
import { getNestedValue } from "../../utils/functions/functions";
import Checkbox from "../FormComponents/Checkbox/Checkbox";
import { Edit2, Refresh, SearchNormal1, Trash, TruckRemove } from "iconsax-react";
import Tippy from "@tippyjs/react";
import { useStore } from "../../store/useStore";
import * as Icons from "../../assets/imgs";

interface DataTableProps {
  data: any[];
  columns: {
    key: string;
    label: string;
    sortKey?: string;
    render?: (item: any, index?: number) => React.ReactNode;
  }[];
  onDetails?: ((itemId: string) => void) | null;
  onEdit?: ((itemId: string) => void) | null;
  onUnlink?: ((itemId: string) => void) | null;
  onDelete?: ((item: any) => void) | null;
  onRestore?: ((item_id: string) => void) | null;
  onSelect?: ((item: any) => void) | null;
  onReprocess?: ((item: any) => void) | null;
  onSelectAll?: (() => void) | null;
  fullObjOnParameters?: boolean;
  isAllChecked?: () => boolean;
  isChecked?: ((item: any) => boolean) | null;
  onOrder?: (key: string) => void;
  onDetailsIcon?: JSX.Element;
  sortKey?: string;
  sortValue?: string;
  loading?: boolean;
  showEditByItem?: (item: any) => boolean;
  showDeleteByItem?: (item?: any) => boolean;
  showRestoreByItem?: (item: any) => boolean;
  showUnlinkByItem?: (item: any) => boolean;
  showReprocessByItem?: (item?: any) => boolean;
  containerClassName?: string;
}

const DataTable: React.FC<DataTableProps> = ({
  data,
  columns,
  onDetails,
  fullObjOnParameters = false,
  onDetailsIcon,
  onEdit,
  onDelete,
  onRestore,
  onReprocess,
  onOrder,
  onSelect,
  onUnlink,
  onSelectAll,
  isAllChecked,
  isChecked,
  sortKey,
  sortValue,
  loading,
  showEditByItem,
  showDeleteByItem,
  showRestoreByItem,
  showUnlinkByItem,
  showReprocessByItem,
  containerClassName = "min-h-[410px]",
}) => {
  const hasActionPermission = onDetails || onEdit || (showDeleteByItem?.() && onDelete) || onRestore || onUnlink || (showReprocessByItem?.() && onReprocess);
  const isRefreshingPayRoll = useStore((state) => state.timeManager.isRefreshingPayRoll);
  
  const handleSelectTableHeader = () => {
    if (onSelect && !onSelectAll) return <th></th>;
    else if (onSelectAll && !loading)
      return (
        <th className="pl-2">
          <Checkbox
            checked={isAllChecked ? isAllChecked() : false}
            onChange={() => onSelectAll()}
          />
        </th>
      );
    else return null;
  };

  return (
    <div className={`w-full overflow-x-auto ${containerClassName}`}>
      <table className="w-full border-collapse">
        <thead>
          <tr>
            {handleSelectTableHeader()}
            {columns.map((column, columnIndex) => (
              <th
                onClick={
                  column?.sortKey && onOrder
                    ? () => onOrder(column.sortKey ?? "")
                    : undefined
                }
                key={column.key}
                className={`select-none ${
                  column.sortKey ? "cursor-pointer" : ""
                } px-2 py-2 text-lg font-light `}
              >
                <div
                  className={`flex gap-2 items-center ${
                    columnIndex === 0 ? "justify-start" : "justify-center"
                  }`}
                >
                  <span className={`font-semibold`}>{column.label}</span>
                  {column.sortKey ? (
                    <ChevronIcon
                      color={Colors.gray_2}
                      direction={
                        sortKey === column.sortKey && sortValue === "desc"
                          ? "up"
                          : "down"
                      }
                    />
                  ) : null}
                </div>
              </th>
            ))}
            {hasActionPermission ? (
              <th className={`px-5 py-2 text-lg font-semibold text-right`}>
                Ações
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {loading
            ? [...Array(10)].map((row, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    {[...Array(columns.length + 1)].map((cell, cellIndex) => (
                      <td key={cellIndex} className="px-3 py-2">
                        <Skeleton height={16} />
                      </td>
                    ))}
                  </tr>
                );
              })
            : data.map((item, rowIndex) => (
                <tr
                  key={item.id}
                  className={`${
                    rowIndex % 2 === 0 ? "bg-gray_08" : "bg-white"
                  }`}
                >
                  {onSelect ? (
                    <td className="pl-2">
                      <Checkbox
                        checked={isChecked ? isChecked(item) : false}
                        onChange={() => onSelect(item)}
                      />
                    </td>
                  ) : null}
                  {columns.map((column, columnIndex) => (
                    <td
                      key={column.key}
                      className={`px-2 py-3 font-light max-w-[120px] break-words ${
                        columnIndex === 0 ? "text-left" : "text-center"
                      }`}
                    >
                      {column.render
                        ? column.render(item, rowIndex) ?? "-"
                        : getNestedValue(item, column.key) ?? "-"}
                    </td>
                  ))}
                  {hasActionPermission ? (
                    <td className={`px-5 py-3 font-light text-right`}>
                      <div className="flex gap-4 w-fit ml-auto justify-end px-3 py-2 bg-gray_0 rounded-[16px]">
                        {onUnlink &&
                          (showUnlinkByItem ? showUnlinkByItem(item) : true) ? (
                            <div
                              onClick={() =>
                                onUnlink(fullObjOnParameters ? item : item._id)
                              }
                              className="cursor-pointer"
                            >
                              <Tippy content="Liberar veículo" className="px-2 py-1 bg-gray_3 text-sm rounded-md text-white font-medium">
                                <TruckRemove size="24" color={Colors.gray_3} variant="Bulk" />
                              </Tippy>
                            </div>
                        ) : null}
                        {onEdit &&
                        (showEditByItem ? showEditByItem(item) : true) ? (
                          <div
                            onClick={() =>
                              onEdit(fullObjOnParameters ? item : item._id)
                            }
                            className="cursor-pointer"
                          >
                            <Tippy content="Editar" className="px-2 py-1 bg-gray_3 text-sm rounded-md text-white font-medium transition">
                              <Edit2 size="24" color={Colors.gray_3} variant="Bulk" />
                            </Tippy>
                          </div>
                        ) : null}
                        {onDetails ? (
                          <div
                            onClick={() =>
                              onDetails(fullObjOnParameters ? item : item._id)
                            }
                            className="cursor-pointer"
                          >
                            <Tippy content={onDetailsIcon ? "Ver jornada" : "Ver mais"} className="px-2 py-1 bg-gray_3 text-sm rounded-md text-white font-medium">
                              {onDetailsIcon ? onDetailsIcon : <SearchNormal1 size="24" color={Colors.gray_2} variant="Linear" />}
                            </Tippy>
                          </div>
                        ) : null}
                        {onReprocess &&
                        (showReprocessByItem ? showReprocessByItem(item) : true) ? (
                          <div
                            onClick={() => onReprocess(fullObjOnParameters ? item : item._id)}
                            className="cursor-pointer"
                          >
                            { 
                              isRefreshingPayRoll[item._id] ? 
                            <Icons.LoadingIcon color={'#7B809A'} width={24} height={24}/>
                            : 
                            <Tippy content={"Reprocessar Folha"} className="px-2 py-1 bg-gray_3 text-sm rounded-md text-white font-medium">
                                <Refresh size="24" color={Colors.gray_2} variant="Linear" />
                            </Tippy>
                          }
                          </div>
                        ) : null}
                        {onDelete &&
                        (showDeleteByItem ? showDeleteByItem(item) : true) ? (
                          <div
                            onClick={() =>
                              onDelete(fullObjOnParameters ? item : item._id)
                            }
                            className="cursor-pointer"
                          >
                            <Tippy content="Remover" className="px-2 py-1 bg-gray_3 text-sm rounded-md text-white font-medium">
                              <Trash size="24" color={Colors.gray_3} variant="Bulk" />
                            </Tippy>
                          </div>
                        ) : null}
                        {onRestore &&
                        (showRestoreByItem ? showRestoreByItem(item) : true) ? (
                          <div
                            onClick={() =>
                              onRestore(fullObjOnParameters ? item : item._id)
                            }
                            className="cursor-pointer"
                          >
                            <Tippy content="Atualizar" className="px-2 py-1 bg-gray_3 text-sm rounded-md text-white font-medium">
                              <Refresh size="24" color={Colors.gray_3} variant="Bulk" />
                            </Tippy>
                          </div>
                        ) : null}
                      </div>
                    </td>
                  ) : null}
                </tr>
              ))}
        </tbody>
      </table>
      {data.length === 0 && !loading && (
        <div className="flex items-center mt-4 text-gray_1 justify-center">
          Nenhum resultado encontrado
        </div>
      )}
    </div>
  );
};

export default DataTable;
