import { useEffect } from "react";
import { ChevronIcon } from "../../../assets/imgs";
import useModal from "../../../hooks/useModal";
import { ROUTES } from "../../../routes/routes";
import GenericCompanyImage from "../../GenericCompanyImage/GenericCompanyImage";
import ChangeCompanyModal from "./ChangeCompanyModal";
import NavbarLink from "./NavbarLink";
import NavbarLinkTopic from "./NavbarLinkTopic";
import { useStore } from "../../../store/useStore";
import Skeleton from "react-loading-skeleton";
import { Building4 } from "iconsax-react";

const MasterArea: React.FC<{}> = () => {
  const { openModal } = useModal();

  const getCompany = useStore((state) => state.navbar.getCompany);
  const isLoadingCompany = useStore((state) => state.navbar.isLoadingCompany);
  const company = useStore((state) => state.navbar.company);
  const adminCompanyId = useStore((state) => state.admin.data.company);

  const openShippingCompanyModal = () => {
    openModal(<ChangeCompanyModal />);
  };

  useEffect(() => {
    if (adminCompanyId) getCompany(adminCompanyId);
  }, [getCompany, adminCompanyId]);

  return (
    <div>
      <div
        onClick={openShippingCompanyModal}
        className="cursor-pointer flex justify-between items-center py-4 border-b border-gray_2 px-3"
      >
        <div className="flex gap-3 items-center">
          {isLoadingCompany ? (
            <Skeleton
              borderRadius={"100%"}
              containerClassName="mt-[-3px]"
              width={30}
              height={30}
            />
          ) : (
            <GenericCompanyImage
              logo={company?.logo}
              width={"30px"}
              height={"30px"}
              className="min-w-[30px]"
            />
          )}
          <div>
            <span className="text-white text-[11px] font-bold">
              {isLoadingCompany ? (
                <Skeleton inline width={120} />
              ) : (
                company?.name ?? "-"
              )}
            </span>
            <br />
            <span className="text-white text-[10px] font-light">
              Clique para alterar
            </span>
          </div>
          
        </div>
      </div>
      <NavbarLinkTopic title="ÁREA MASTER" borderBottom>
        <div className="py-3">
          <NavbarLink
            to={ROUTES.SHIPPING_COMPANIES.PATH}
            icon={<Building4 size="24" variant="TwoTone" />}
            text={ROUTES.SHIPPING_COMPANIES.NAME}
          />
        </div>
      </NavbarLinkTopic>
    </div>
  );
};

export default MasterArea;
