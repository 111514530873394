import React from "react";

const BurgerIcon: React.FC<{}> = () => {
  return (
    <svg
      width="21"
      height="12"
      viewBox="0 0 21 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 1H21" stroke="#7B809A" strokeWidth="2" />
      <path d="M0 6H21" stroke="#7B809A" strokeWidth="2" />
      <path d="M0 11H21" stroke="#7B809A" strokeWidth="2" />
    </svg>
  );
};

export default BurgerIcon;
