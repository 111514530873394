import { COMPENSATORY_TIME_TYPE, REASON_TYPE } from "../../utils/constants/constants";
import { Collaborator } from "./collaborator";

export interface PayRoll {
  _id?: string;
  name: string;
  batch: string;
  users?: any[];
  layout?: {
    columns: any[];
    totalizers: any[];
  };
  status?: {
    type: string;
    name: string;
  }
  competence_start?: string;
  competence_end?: string;
  payroll_total?: string;
  deleted_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

export enum PayRollGroupSatus {
  CLOSED = "CLOSED",
  CLOSING = "CLOSING",
  OPEN = "OPEN",
  ERROR = "ERROR"
}

export enum PayRollGroupProcessStatus {
  OK = "OK",
  ERROR = "ERROR"
}

export interface CompensatoryTime {
  _id?: string;
  user: {
    _id: string;
    name: string;
  };
  company: string;
  type: {
    type: COMPENSATORY_TIME_TYPE;
    name: string;
  };
  category: {
    type: string;
    name: string;
  };
  amount: number;
  deadline: string;
  reference_date?: string;
  reference_date_start?: string;
  reference_date_end?: string;
  manual: {
    reason: {
      type: REASON_TYPE;
      name: string;
    };
    description: string;
  };
  created_at?: string;
  updated_at?: string;
}

export interface TimeManagerState {
  timeManager: {
    payRollsList: {
      data: PayRoll[];
      currentPage: number;
      totalItems: number;
      totalPages: number;
      limitPerPage: number;
      offset: number;
      filters: PayRollFilter;
      setLimitPerPage: (limitPerPage: number) => void;
      setCurrentPage: (page: number) => void;
      setFilters: (filters: PayRollFilter) => void;
    };
    compensatoryTimeList: {
      data: CompensatoryTime[];
      currentPage: number;
      totalItems: number;
      totalPages: number;
      limitPerPage: number;
      offset: number;
      filters: CompensatoryTimeFilter;
      setLimitPerPage: (limitPerPage: number) => void;
      setCurrentPage: (page: number) => void;
      setFilters: (filters: CompensatoryTimeFilter) => void;
    };
    collaborators: {
      collaboratorsSelected: Collaborator[]
    },
    resetSelectedCollaborator: () => void;
    selectCollaborator: (collaboratorId: string | undefined) => void;
    unselectCollaborator: (collaboratorId: string | undefined) => void;
    selectedPayRoll: PayRoll | null;
    isLoadingPayRollsList: boolean;
    isDownloadingPayRoll: boolean;
    isRefreshingPayRoll: Record<string, boolean>;
    isLoadingPayRoll: boolean;
    isCreatingPayRoll: boolean;
    isDeletingPayRoll: boolean;
    isUpdatingPayRoll: boolean;
    isClosingPayRoll: boolean;
    getPayRolls: () => void;
    getPayRollsWithLimit: (limit: number) => void;
    getPayRoll: (id: string) => void;
    downloadPayRoll: (params: {collaborators: string[], payrollGroupId: string}) => void;
    refreshPayRoll: (params: {collaboratorId: string, payRollGroupId: string}) => void;
    createPayRoll: (PayRoll: PayRoll) => void;
    updatePayRoll: (PayRollId: string, PayRoll: PayRoll) => void;
    resetPayRollsListState: () => void;
    resetSelectedPayRoll: () => void;
    deleteCompensatoryTime: (compensatoryTimeId: string) => void;
    deletePayRoll: (payrollGroupId: string) => void;
    closePayRoll: (payrollGroupId: string, unified: string[], orientation: string) => void;
    isDeletingCompensatoryTime: boolean;
    selectedCompensatoryTime: CompensatoryTime | null;
    isLoadingCompensatoryTimesList: boolean;
    isLoadingCompensatoryTime: boolean;
    isCreatingCompensatoryTimeRegister: boolean;
    getCompensatoryTimes: () => void;
    getCompensatoryTime: (id: string) => void;
    setSelectedCompensatoryTime: (compensatoryTime: CompensatoryTime) => void;
    resetCompensatoryTimesListState: () => void;
    resetSelectedCompensatoryTime: () => void;
    createCompensatoryTimeRegister: (data: any) => void;
  };
}

export interface PayRollFilter {
  name: string;
  batch: string;
  competence_start: string;
  competence_end: string;
  payroll_total: string;
  created_at: string;
  sort_key: string;
  sort_value: string;
}

export interface CompensatoryTimeFilter {
  user: {
    value: string;
    label: string;
  };
  type: string;
  manual: string | boolean;
  category: string;
  reason: string;
  reference_date_start: string;
  reference_date_end: string;
  deadline_start: string;
  deadline_end: string;
  created_at: string;
  sort_key: string;
  sort_value: string;
}
