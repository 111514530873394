import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { useState } from "react";
import { formatDateTime } from "../../../utils/functions/formatters";
import { isValidPosition } from "../../../utils/functions/functions";
import { TruckFast } from "iconsax-react";

const PositionMarker: React.FC<{ data: any }> = ({ data }) => {
  const [showInfoWindowByIndex, setShowInfoWindowByIndex] = useState(false);

  const [markerRef, marker] = useAdvancedMarkerRef();
  
  return isValidPosition(data?.position) ? (
    <>
      <AdvancedMarker
        ref={markerRef}
        position={data.position}
        onClick={() => setShowInfoWindowByIndex(true)}
      >
        <div
          className="w-fit"
          onMouseOver={() => setShowInfoWindowByIndex(true)}
          onMouseOut={() => setShowInfoWindowByIndex(false)}
        >
          <img
            src={process.env.PUBLIC_URL + "/fleetIcon.svg"}
            alt="posicao ultima macro"
            width={56}
            height={56}
            style={{ transform: "translateY(26px)" }}
          />
        </div>
      </AdvancedMarker>
      {showInfoWindowByIndex && (
        <InfoWindow
          pixelOffset={[0, 30]}
          anchor={marker}
          maxWidth={200}
          headerDisabled
        >
          <span className="font-semibold">Placa:</span> {data?.tag}
          <br />
          <span className="font-semibold">Motorista:</span> {data?.user}
          <br />
          <span className="font-semibold">Evento:</span> {data?.journeyEvent}
          <br />
          <span className="font-semibold">Início em:</span>{" "}
          {formatDateTime(data?.startedAt)}
        </InfoWindow>
      )}
    </>
  ) : null;
};

export default PositionMarker;
