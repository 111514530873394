import './footer-style.css';
import { JSatLogoBranco, PlayStoreButton, AppStoreButton } from "../../../../assets/imgs";

export default function Footer(props: { contentClass: string;}) {
  const clazz = props.contentClass + " padding-footer";
  const year = new Date().getFullYear();

  if(props.contentClass == "content-mobile") {
    return (
      <section id="footer">
        <div  style={{
          backgroundColor: `#000`,
        }}>
          <div className={clazz} style={{paddingBottom: 32}}>
            <div className='footer-content-mobile'>
              <div className='footer-content-left'>
                <img
                  src={JSatLogoBranco}
                  alt="Logotipo Jornada Sat"
                  width={100}
                />
                  <p className='footer-signature'>© {year} Jornada Sat. Todos os direitos reservados</p>
              </div>
              <div className='footer-content-right'>
                <a href="#">
                  <img
                    src={PlayStoreButton}
                    alt="Playstore Button"
                    className='store-button-footer'
                  />
                </a>
                <a href="#">
                  <img
                    src={AppStoreButton}
                    alt="App Store Button"
                    className='store-button-footer'
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section id="footer">
      <div  style={{
        backgroundColor: `#000`,
      }}>
        <div className={clazz} style={{paddingBottom: 32}}>
          <div className='footer-content'>
            <div className='footer-content-left'>
              <img
                src={JSatLogoBranco}
                alt="Logotipo Jornada Sat"
                width={100}
              />
              <nav className="navbar-footer">
                <ul className="nav-list-footer">
                    <li className="nav-item-footer">
                        <a href="#home">Home</a> 
                    </li>
                    <li className="nav-item-footer">
                        <a href="#features">Funcionalidades</a>
                    </li>
                    <li className="nav-item-footer">
                        <a href="#testimonials">Depoimentos</a>
                    </li>
                    <li className="nav-item-footer">
                        <a href="#management">Gerencie</a>
                    </li>
                  </ul>
                </nav>
                <p className='footer-signature'>© {year} Jornada Sat. Todos os direitos reservados</p>
            </div>
            <div className='footer-content-right'>
              <a href="#">
                <img
                  src={PlayStoreButton}
                  alt="Playstore Button"
                  className='store-button-footer'
                />
              </a>

              <a href="#">
                <img
                  src={AppStoreButton}
                  alt="App Store Button"
                  className='store-button-footer'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}