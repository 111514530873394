import { fleetAPI, trackingAPI } from "../api/api";
import { handleServiceError } from "./handleServiceError";
import { removeEmptyProperties } from "../utils/functions/functions";
import {
  JourneyEvent,
  JourneyEventFilter,
  Macro,
  MacroFilter,
  MacrosVisibleColumnsKey,
} from "../store/types/macroState";

export interface CheckMacroBody {
  user: string;
  vehicle: string;
  journey_event: string;
  started_at: string | null;
}
export interface MacrosLinkBody {
  user_id: string;
  macro_ids: string[];
}

export interface CreateMacrosDataBody {
  user: string;
  vehicle: string;
  macro_origin: string;
  reason: {
    type: string;
    description: string;
  };
  journey_event: string;
  started_at: string;
  location: {
    city: string;
    state: string;
  }
}

export interface DeleteMacrosDataBody {
  macros: string[];
  reason: string;
  description: string;
}

export interface RestoreMacrosDataBody {
  macros: string[];
}

const macroService = {
  getMacro: async (id: string) => {
    try {
      const response = await fleetAPI.get(`/v1/macro/${id}`, {
        params: { borders: true },
      });
      const data: Macro[] = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getMacros: async (
    {
      filters,
      limit,
      offset,
    }: {
      filters: MacroFilter | {};
      limit: number;
      offset: number;
    },
    section?: string
  ) => {
    try {
      const response = await fleetAPI.get(`/v1/macro`, {
        params: { ...removeEmptyProperties(filters), limit, offset },
        headers: { section }
      });
      const data: Macro[] = response.data.data;
      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getJourneyEvent: async (id: string) => {
    try {
      const response = await fleetAPI.get(`/v1/journeyevent/${id}`, {
        params: { borders: true },
      });
      const data: JourneyEvent = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getJourneyEvents: async ({
    filters,
    limit,
    offset,
    headers,
  }: {
    filters: JourneyEventFilter | {};
    limit: number;
    offset: number;
    headers?: any | {};
  }) => {
    try {
      const response = await fleetAPI.get(`/v1/journeyevent`, {
        params: { ...removeEmptyProperties(filters), limit, offset },
        headers,
      });
      const data: JourneyEvent[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  exportMacro: async (
    format: "csv" | "pdf",
    columns: MacrosVisibleColumnsKey[]
  ) => {
    try {
      const response = await fleetAPI.post("/v1/macro/report", {
        format,
        columns,
      });
      return response.data.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  updateMacro: async (macroId: string, macroDataToUpdate: CheckMacroBody) => {
    try {
      const response = await fleetAPI.put(
        `/v1/macro/${macroId}`,
        macroDataToUpdate
      );
      return response.data.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  linkMacros: async (macrosDataToLink: MacrosLinkBody) => {
    try {
      const response = await fleetAPI.patch(`/v1/macro/link`, macrosDataToLink);
      return response.data.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  checkMacro: async (macroId: string, macroDataToUpdate: CheckMacroBody) => {
    try {
      const response = await fleetAPI.put(
        `/v1/macro/${macroId}/check`,
        macroDataToUpdate
      );
      return response.data.data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  createMacros: async (createMacrosData: CreateMacrosDataBody[]) => {
    try {
      await trackingAPI.post(`/v1/macro/manual`, createMacrosData);
    } catch (error) {
      handleServiceError(error);
    }
  },
  deleteMacros: async (deleteMacrosDataBody: DeleteMacrosDataBody) => {
    try {
      await fleetAPI.delete(`/v1/macro`, { 
        data: {
          macros: deleteMacrosDataBody.macros,
          reason: {
            type: deleteMacrosDataBody.reason,
            description: deleteMacrosDataBody.description
          }
        }
      });
    } catch (error) {
      handleServiceError(error);
    }
  },
  restoreMacros: async (restoreMacrosDataBody: RestoreMacrosDataBody) => {
    try {
      await fleetAPI.patch(`/v1/macro/restore`, restoreMacrosDataBody);
    } catch (error) {
      handleServiceError(error);
    }
  },
};

export default macroService;
