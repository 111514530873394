import { useState } from "react";
import Divider from "../../../components/Divider/Divider";
import Modal from "../../../components/Modal/Modal";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import {
  Macro,
  MacroHistoryItem,
  MacroStatus,
} from "../../../store/types/macroState";
import { useStore } from "../../../store/useStore";
import { Colors } from "../../../utils/constants/style";
import { calcDuration, sortMacroHistoryByCreatedAt } from "../../../utils/functions/functions";
import MacroDetails from "./MacroDetails";
import InfoFieldBox from "../../../components/InfoFieldBox/InfoFieldBox";
import InfoField from "../../../components/InfoField/InfoField";
import { formatDateTime } from "../../../utils/functions/formatters";
import { SearchNormal1 } from "iconsax-react";

const findMacroDeletedInfo = (macro: Macro | null) => {
  if (!macro || !macro.deleted_at) return null;
  const historyRecords = macro?.alteration_flow?.history?.filter((h) => h.type === MacroStatus.DELETED);
  const historyRecord = historyRecords?.[historyRecords.length - 1];
  if(!historyRecord) return null;
  return {
    deletedBy: historyRecord?.actor?.name,
    deletedAt: macro?.deleted_at,
    reason: historyRecord?.reason?.name,
    description: historyRecord?.description
  }
}

const findMacroCreatedInfo = (macro: Macro | null) => {
  if (!macro || !macro.created_at || macro?.status?.type !== MacroStatus.MANUAL) return null;

  return {
    createdBy: macro?.manual?.actor?.name,
    createdAt: macro?.created_at
  }
}

const findPreviousMacro = (macro: Macro | null): Macro | null => {
  if (!macro) return null;

  let previousMacroHistory: MacroHistoryItem | null | undefined = null;
  if (macro.alteration_flow?.suggestion) {
    previousMacroHistory = macro.alteration_flow.suggestion;
  } else if (macro.alteration_flow?.history) {
    previousMacroHistory = macro.alteration_flow.history
      .filter((item) => item.type === MacroStatus.ALTERATION_APPROVED)
      .sort(sortMacroHistoryByCreatedAt)
      .shift();
  } else return null;

  if (!previousMacroHistory) return null;

  const previousMacro: Macro = {
    ...macro,
    vehicle: previousMacroHistory.vehicle,
    user: previousMacroHistory.user,
    journey_event: previousMacroHistory.journey_event,
    started_at: previousMacroHistory.started_at,
    ended_at: previousMacroHistory.ended_at,
    duration: calcDuration(
      previousMacroHistory.started_at,
      previousMacroHistory.ended_at
    ),
    status: {
      type:
        previousMacroHistory.type === MacroStatus.ALTERATION_APPROVED
          ? MacroStatus.ALTERATION_APPROVED
          : MacroStatus.ALTERATION_PENDING,
      name:
        previousMacroHistory.type === MacroStatus.ALTERATION_APPROVED
          ? "Alteração Aprovada"
          : "Alteração Pendente",
    },
  };

  return previousMacro;
};
interface DetailsMacroModalProps {
  onClose: () => void;
}

const DetailsMacroModal: React.FC<DetailsMacroModalProps> = ({ onClose }) => {
  const selectedMacro = useStore((state) => state.macro.selectedMacro);
  const isLoadingMacro = useStore((state) => state.macro.isLoadingMacro);
  const [showPreviousMacro, setShowPreviousMacro] = useState<boolean>(false);

  const handleShowPreviousMacro = () => setShowPreviousMacro(true);

  const previousMacro = findPreviousMacro(selectedMacro);
  const isMacroLoadingOrStatusOk = isLoadingMacro || !previousMacro;
  const macroDeletedInfo = findMacroDeletedInfo(selectedMacro);
  const macroCreatedInfo = findMacroCreatedInfo(selectedMacro);

  return (
    <Modal
      title="Detalhes da macro"
      icon={<SearchNormal1 size="30" color={Colors.white} variant="Linear" />}
      className="min-w-[479px]"
      onClose={onClose}
    >
      <div>
        {macroDeletedInfo?.deletedAt ? (
          <div>
            <div className="text-dark-blue font-bold pb-5">
              Dados da Remoção
            </div>
            <div className="flex flex-col gap-5">
              <InfoFieldBox>
                <InfoField
                  loading={isLoadingMacro}
                  label="Removida Por"
                  content={macroDeletedInfo.deletedBy}
                />
                <InfoField
                  loading={isLoadingMacro}
                  label="Data de remoção"
                  content={macroDeletedInfo?.deletedAt ? formatDateTime(macroDeletedInfo?.deletedAt) : "-"}
                />
              </InfoFieldBox>
            </div>
            <div className="flex flex-col gap-5 mt-5">
              <InfoFieldBox>
                <InfoField
                  loading={isLoadingMacro}
                  label="Motivo"
                  content={macroDeletedInfo.reason}
                />
                <InfoField
                  loading={isLoadingMacro}
                  label="Observação"
                  content={macroDeletedInfo?.description}
                />
              </InfoFieldBox>
            </div>
            <Divider className="mb-5 mt-5" />
          </div>
        ) : null}
        {macroCreatedInfo?.createdAt ? (
          <div>
            <div className="text-dark-blue font-bold pb-5">
              Dados de criação manual
            </div>
            <div className="flex flex-col gap-5">
              <InfoFieldBox>
                <InfoField
                  loading={isLoadingMacro}
                  label="Criada Por"
                  content={macroCreatedInfo.createdBy}
                />
                <InfoField
                  loading={isLoadingMacro}
                  label="Data de criação"
                  content={macroCreatedInfo?.createdAt ? formatDateTime(macroCreatedInfo?.createdAt) : "-"}
                />
              </InfoFieldBox>
            </div>
            <Divider className="mb-5 mt-5" />
          </div>
        ) : null}
        <MacroDetails
          title={showPreviousMacro ? "Registro Original" : ""}
          loading={isLoadingMacro}
          macro={selectedMacro}
        />
        {isMacroLoadingOrStatusOk ? null : <Divider className="pb-5" />}
        {showPreviousMacro ? (
          <div className="pt-5">
            <MacroDetails
              title={showPreviousMacro ? "Alteração Proposta" : ""}
              macro={previousMacro}
              macroStatus={selectedMacro?.status}
            />
          </div>
        ) : null}
        {isMacroLoadingOrStatusOk || showPreviousMacro ? null : (
          <div className="flex flex-col items-center text-center">
            <div className="py-5 w-[350px]">
              Esta macro sofreu alterações. Para ver o histórico, clique no
              botão abaixo
            </div>
            <OutlinedButton
              onClick={handleShowPreviousMacro}
              className="w-fit px-5 py-2"
            >
              VER ALTERAÇÕES
            </OutlinedButton>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DetailsMacroModal;
