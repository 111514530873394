import { StateCreator } from "zustand";
import {
  Fleet,
  FleetFilter,
  FleetState,
  FleetsVisibleColumnsKey,
} from "../types/fleet"; 
import fleetService from "../../services/fleetService"; 
import { toast } from "react-toastify";
import produce from "immer";
import { useStore } from "../useStore";
import { downloadURI } from "../../utils/functions/download";

const initialFleetListData = {
  data: [],
  currentPage: 1,
  totalItems: 1,
  totalPages: 1,
  limitPerPage: 10,
  offset: 0,
  columnsVisibility: {
    tag: true,
    fleet: true,
    created_at: true,
    user: true,
    last_journey_event: true,
  },
  filters: {
    tag: "",
    fleet: "",
    sort_key: "tag",
    sort_value: "asc",
  }
}

export const createFleetSlice: StateCreator<FleetState> = (
  set,
  get
) => ({
  fleet: {
    fleetsList: {
      ...initialFleetListData,
      toggleColumnVisibility: (key: string, value: boolean) => {
        set(
          produce((state) => {
            state.fleet.fleetsList.columnsVisibility[key] = !value;
          })
        );
      },
      setLimitPerPage: (limitPerPage: number) => {
        set(
          produce((state) => {
            state.fleet.fleetsList.limitPerPage = limitPerPage;
            state.fleet.fleetsList.offset = 0;
            state.fleet.fleetsList.currentPage = 1;
          })
        );
        get().fleet.getFleets();
      },
      setCurrentPage: (page: number) => {
        set(
          produce((state) => {
            state.fleet.fleetsList.currentPage = page;
            state.fleet.fleetsList.offset = page - 1;
          })
        );
        get().fleet.getFleets();
      },
      setFilters: (filters: FleetFilter) => {
        set(
          produce((state) => {
            state.fleet.fleetsList.filters = filters;
            state.fleet.fleetsList.offset = 0;
            state.fleet.fleetsList.currentPage = 1;
          })
        );
        useStore.getState().modal.setIsModalOpen(false);
        get().fleet.getFleets();
      },
    },
    selectedFleet: null,
    isLoadingFleetsList: false,
    isLoadingFleet: false,
    isCreatingFleet: false,
    isDeletingFleet: false,
    isUpdatingFleet: false,
    isExporting: { csv: false, pdf: false },
    resetSelectedFleet: () => {
      set(
        produce((state) => {
          state.fleet.selectedFleet = null;
        })
      );
    },
    getFleets: () => {
      set(
        produce((state) => {
          state.fleet.isLoadingFleetsList = true;
        })
      );

      const filters = get().fleet.fleetsList.filters;
      const limit = get().fleet.fleetsList.limitPerPage;
      const offset = get().fleet.fleetsList.offset;

      fleetService
        .getFleets({ filters, limit, offset })
        .then(({ data, totalItems }: any) => {
          set(
            produce((state) => {
              state.fleet.fleetsList.data = data;
              state.fleet.fleetsList.totalItems = totalItems;
              state.fleet.fleetsList.totalPages = Math.ceil(
                totalItems / state.fleet.fleetsList.limitPerPage
              );
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.fleet.isLoadingFleetsList = false;
            })
          );
        });
    },
    getFleetOptions: async (tag: string) => {
      try {
        const { data }: any = await fleetService
          .getFleets({ filters: { tag }, limit: 20, offset: 0 })
        return data;
      } catch (error: any) {
        toast.error(error.message);
      }
    },
    getFleet: (fleetId: string) => {
      set(
        produce((state) => {
          state.fleet.isLoadingFleet = true;
        })
      );

      fleetService
        .getFleet(fleetId)
        .then((data) => {
          set(
            produce((state) => {
              state.fleet.selectedFleet = data;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.fleet.isLoadingFleet = false;
            })
          );
        });
    },
    deleteFleet: (fleetId: string) => {
      set(
        produce((state) => {
          state.fleet.isDeletingFleet = true;
        })
      );

      fleetService
        .deleteFleet(fleetId)
        .then(() => {
          toast.success("Veículo excluído com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().fleet.getFleets();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.fleet.isDeletingFleet = false;
            })
          );
        });
    },
    unlinkFleet: (fleetId: string) => {
      set(
        produce((state) => {
          state.fleet.isUpdatingFleet = true;
        })
      );

      fleetService
        .unlinkFleet(fleetId)
        .then(() => {
          toast.success("Veículo desassociado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().fleet.getFleets();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.fleet.isUpdatingFleet = false;
            })
          );
        });
    },
    updateFleet: (
      fleetId: string,
      fleet: Fleet
    ) => {
      set(
        produce((state) => {
          state.fleet.isUpdatingFleet = true;
        })
      );

      fleetService
        .updateFleet(fleetId, fleet)
        .then(() => {
          toast.success("Veículo atualizado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().fleet.getFleets();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.fleet.isUpdatingFleet = false;
            })
          );
        });
    },
    createFleet: (fleet: Fleet) => {
      set(
        produce((state) => {
          state.fleet.isCreatingFleet = true;
        })
      );

      fleetService
        .createFleet(fleet)
        .then(() => {
          toast.success("Veículo cadastrado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
          get().fleet.getFleets();
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.fleet.isCreatingFleet = false;
            })
          );
        });
    },
    exportFleetTable: (format: "csv" | "pdf") => {
      set(
        produce((state) => {
          state.fleet.isExporting[format] = true;
        })
      );

      const columnsVisibility =
        get().fleet.fleetsList.columnsVisibility;
      const columnsToExport: FleetsVisibleColumnsKey[] = Object.keys(
        columnsVisibility
      )
        .filter(
          (key) => columnsVisibility[key as FleetsVisibleColumnsKey]
        )
        .map((key) => key as FleetsVisibleColumnsKey);

      fleetService
        .exportFleet(format, columnsToExport)
        .then((data) => {
          downloadURI(data.fileUrl, data.fileName);
          toast.success("Documento exportado com sucesso");
          useStore.getState().modal.setIsModalOpen(false);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.fleet.isExporting[format] = false;
            })
          );
        });
    },
    resetFleetListState: () => {
      set(
        produce((state) => {
          state.fleet.fleetsList = {...state.fleet.fleetsList, ...initialFleetListData};
        })
      );
    },
  },
});
