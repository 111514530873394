import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "./routes";
import Navbar from "../components/Navbar/Navbar";
import Header from "../components/Header/Header";
import { getAuthToken } from "../utils/functions/auth";
import { useStore } from "../store/useStore";
// import { TIME_RANGE } from "../store/types/dashboard";

interface RadioProps {
  text?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  labelClassName?: string;
}

// const RadioButton: React.FC<RadioProps> = ({
//   text,
//   checked,
//   onChange,
//   className = "w-[18px] h-[18px]",
//   labelClassName = "",
// }) => {
//   return (
//     <div className={`flex items-center ${text ? "gap-2" : ""} `}>
//         <input
//           className={`accent-red rounded-[4px] ${className}`}
//           onChange={onChange}
//           type="radio"
//           checked={checked}
//         />
//         <label className={labelClassName}>{text}</label>
//     </div>
//   );
// };

const ProtectedRoute: React.FC<{ redirectPath?: string }> = ({
  redirectPath = ROUTES.LOGIN.PATH,
}) => {
  const fillAdminDataFromToken = useStore(
    (state) => state.auth.fillAdminDataFromToken
  );
  // const setChartFilters = useStore(
  //   (state) => state.dashboard.setChartFilters
  // );
  // const timeRangeFilter = useStore(
  //   (state) => state.dashboard.charts.filters.time_range
  // );
  // const location = useLocation();

  const token = getAuthToken();
  if (!token) {
    return <Navigate to={redirectPath} replace />;
  } else {
    fillAdminDataFromToken(token);
  }

  return (
    <div className="flex bg-gray_05">
      <Navbar />
      <div className="w-full">
        <Header />
        <div className="relative" style={{
          marginTop: '4%'
        }}>
          {/* {location.pathname === "/" ? (
            <div className="h-[60px] pl-5 flex gap-5 bg-white w-full sticky top-0 shadow-bottom border-t border-t-gray_05 z-10">
              <RadioButton text="Última semana" checked={timeRangeFilter === TIME_RANGE.last_week} onChange={() => setChartFilters(TIME_RANGE.last_week)} />
              <RadioButton text="Último mês" checked={timeRangeFilter === TIME_RANGE.last_month} onChange={() => setChartFilters(TIME_RANGE.last_month)} />
              <RadioButton text="Últimos 3 meses" checked={timeRangeFilter === TIME_RANGE.last_quarter} onChange={() => setChartFilters(TIME_RANGE.last_quarter)} />
              <RadioButton text="Último ano" checked={timeRangeFilter === TIME_RANGE.last_year} onChange={() => setChartFilters(TIME_RANGE.last_year)} />
            </div>
          ) : null} */}
          <div className="px-10 py-10">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProtectedRoute;
