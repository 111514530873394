interface ClockIconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
}
const ClockIcon: React.FC<ClockIconProps> = ({
  width= 10,
  height= 10,
  color= "#191919"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 9C6.06087 9 7.07828 8.57857 7.82843 7.82843C8.57857 7.07828 9 6.06087 9 5C9 3.93913 8.57857 2.92172 7.82843 2.17157C7.07828 1.42143 6.06087 1 5 1C3.93913 1 2.92172 1.42143 2.17157 2.17157C1.42143 2.92172 1 3.93913 1 5C1 6.06087 1.42143 7.07828 2.17157 7.82843C2.92172 8.57857 3.93913 9 5 9ZM5 0C5.65661 0 6.30679 0.129329 6.91342 0.380602C7.52005 0.631876 8.07124 1.00017 8.53553 1.46447C8.99983 1.92876 9.36812 2.47995 9.6194 3.08658C9.87067 3.69321 10 4.34339 10 5C10 6.32608 9.47322 7.59785 8.53553 8.53553C7.59785 9.47322 6.32608 10 5 10C2.235 10 0 7.75 0 5C0 3.67392 0.526784 2.40215 1.46447 1.46447C2.40215 0.526784 3.67392 0 5 0ZM5.25 2.5V5.125L7.5 6.46L7.125 7.075L4.5 5.5V2.5H5.25Z"
        fill={color}
      />
    </svg>
  );
};

export default ClockIcon;