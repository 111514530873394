type SimpleSelectOption = { value: string | number; label: string };
interface SimpleSelectProps {
  options: SimpleSelectOption[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
}

const SimpleSelect: React.FC<SimpleSelectProps> = ({
  options,
  onChange,
  className = "py-1 px-1.5 rounded-[4px] bg-white border border-gray_1",
}) => {
  return (
    <div>
      <select
        onChange={onChange}
        className={className}
      >
        {options.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
};

export default SimpleSelect;
