import Modal from "../../../components/Modal/Modal";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { Colors } from "../../../utils/constants/style";
import { useStore } from "../../../store/useStore";
import { Danger, Trash } from "iconsax-react";

const DeleteHolidayModal: React.FC<{}> = () => {
  const selectedHoliday = useStore(
    (state) => state.conventions.selectedHoliday
  );
  const deleteHoliday = useStore(
    (state) => state.conventions.deleteHoliday
  );
  const isDeletingHoliday = useStore(
    (state) => state.conventions.isDeletingHoliday
  );

  const handleDeleteVehicle = () => {
    if(selectedHoliday?._id)
      deleteHoliday(selectedHoliday._id);
  };

  return (
    <Modal
      title="Exclusão de feriado"
      icon={<Trash size={30} variant="Bold" color={Colors.white} />}
      className="w-[361px]"
    >
      <div className="text-center flex flex-col items-center gap-5">
        <Danger size="72" variant="Bulk" color={Colors.red}/>
        <div className="px-5">
          Tem certeza que deseja remover o feriado de nome {selectedHoliday?.name ?? '-'}?
        </div>
        <FilledButton loading={isDeletingHoliday} onClick={handleDeleteVehicle} className="px-14">
          Excluir
        </FilledButton>
      </div>
    </Modal>
  );
};

export default DeleteHolidayModal;
