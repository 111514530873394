import { Field } from "formik";
import DataTable from "../../../components/DataTable/DataTable";
import { JourneyEvent } from "../../../store/types/macroState";
import TagsInput from "../../../components/TagsInput/TagsInput";

interface ChangeJourneyEventsDataTableProps {
  data?: any[];
  loading: boolean;
}

const ChangeJourneyEventsDataTable: React.FC<
  ChangeJourneyEventsDataTableProps
> = ({ data, loading }) => {

  const journeyEventsColumns = [
    { key: "name", label: "Nome" },
    {
      key: "joruneyEvents.codes",
      label: "Códigos de integração",
      render: (item: JourneyEvent, index?: number) => (
        <div className="flex justify-center items-center">
          <Field name={`journeyEvents[${index}].codes`} component={TagsInput} />
          {/* <Field name={`journeyEvents[${index}].codes`} component={} placeholder="Custom placeholder" /> */}
        </div>
      ),
    },
  ];

  return (
    <DataTable
      data={data ?? []}
      columns={journeyEventsColumns}
      onDetails={null}
      onEdit={null}
      loading={loading}
    />
  );
};

export default ChangeJourneyEventsDataTable;
