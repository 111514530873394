import { EditIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Colors } from "../../../utils/constants/style";
import { useState } from "react";
import { useStore } from "../../../store/useStore";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

interface EditMacroModalProps {
  onClose: () => void;
}
const TOTAL_STEPS = 3;

const EditMacroModal: React.FC<EditMacroModalProps> = ({ onClose }) => {
  const selectedMacro = useStore((state) => state.macro.selectedMacro);
  const isLoadingMacro = useStore((state) => state.macro.isLoadingMacro);
  const [currentStep, setCurrentStep] = useState<number>(1);

  return (
    <Modal
      title="Edição de macro"
      icon={<EditIcon width={30} height={30} color={Colors.white} />}
      className="w-[547px]"
      onClose={onClose}
    >
      <>
        <Step1
          currentStep={currentStep}
          show={currentStep === 1}
          setCurrentStep={setCurrentStep}
          totalSteps={TOTAL_STEPS}
          selectedMacro={selectedMacro}
          isLoadingMacro={isLoadingMacro}
        />
        <Step2
          currentStep={currentStep}
          show={currentStep === 2}
          setCurrentStep={setCurrentStep}
          totalSteps={TOTAL_STEPS}
        />
      {currentStep === 3 ? (
        <Step3
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          totalSteps={TOTAL_STEPS}
        />
      ) : null}
      </>
    </Modal>
  );
};

export default EditMacroModal;
