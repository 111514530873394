import { EditIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import { Colors } from "../../../utils/constants/style";
import useModal from "../../../hooks/useModal";
import DeleteHolidayModal from "./DeleteHolidayModal";
import { useEffect } from "react";
import { useStore } from "../../../store/useStore";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import { HOLIDAY_TYPE } from "../../../store/types/convention";
import DayAndMonthPicker from "../../../components/FormComponents/DayAndMonthPicker/DayAndMonthPicker";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";

interface EditHolidayModalProps {
  onClose: () => void;
}

const EditHolidayModal: React.FC<EditHolidayModalProps> = ({ onClose }) => {
  const { openModal } = useModal();
  const selectedHoliday = useStore((state) => state.conventions.selectedHoliday);
  const updateHoliday = useStore((state) => state.conventions.updateHoliday);
  const isUpdatingHoliday = useStore(
    (state) => state.conventions.isUpdatingHoliday
  );
  const isLoadingHoliday = useStore((state) => state.conventions.isLoadingHoliday);
  const hasPermissions = useStore((state) => state.admin.hasPermissions);

  const openDeleteHolidayModal = () => openModal(<DeleteHolidayModal />);

  return (
    <Modal
      title="Editar feriado"
      icon={<EditIcon width={30} height={30} color={Colors.white} />}
      className="w-[447px]"
      onClose={onClose}
    >
      <Formik
        initialValues={{
          name: "",
          type: "",
          date: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Campo obrigatório"),
          type: Yup.string().required("Campo obrigatório"),
          date: Yup.string().required("Campo obrigatório"),
        })}
        onSubmit={(values) => {
          if (selectedHoliday?._id) updateHoliday(selectedHoliday._id, values);
        }}
      >
        {(formik) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (selectedHoliday) {
              const selectedCollaboratorCopy = {
                ...selectedHoliday,
              };
              delete selectedCollaboratorCopy._id;
              delete selectedCollaboratorCopy.deleted_at;
              delete selectedCollaboratorCopy.updated_at;
              delete selectedCollaboratorCopy.created_at;
              delete selectedCollaboratorCopy.company;

              formik.setValues(selectedCollaboratorCopy);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [selectedHoliday]);

          return (
            <Form>
              <div className="flex flex-col gap-8 pt-5">
                <Field
                  loading={isLoadingHoliday}
                  placeholder="Nome"
                  name="name"
                  component={OutlinedInput}
                />
                <Field
                  loading={isLoadingHoliday}
                  name="type"
                  component={OutlinedSelect}
                  placeholder="Tipo"
                  options={[
                    { label: "Nacional", value: HOLIDAY_TYPE.NATIONAL },
                    { label: "Estadual", value: HOLIDAY_TYPE.STATE },
                    { label: "Municipal", value: HOLIDAY_TYPE.CITY },
                  ]}
                />
                <Field
                  loading={isLoadingHoliday}
                  placeholder="Data"
                  name="date"
                  component={DayAndMonthPicker}
                />
                <div className="flex gap-5">
                  {hasPermissions([PERMISSIONS.DELETE_HOLIDAY]) ? (
                    <div className="flex-1">
                      <OutlinedButton
                        onClick={openDeleteHolidayModal}
                        className="w-full"
                      >
                        Excluir
                      </OutlinedButton>
                    </div>
                  ) : null}
                  <div className="flex-1">
                    <FilledButton
                      loading={isUpdatingHoliday}
                      className="w-full"
                      type="submit"
                    >
                      Editar
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditHolidayModal;
