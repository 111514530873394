import Modal from "../../../components/Modal/Modal";
import FilledButton from "../../../components/FilledButton/FilledButton";
import { Colors } from "../../../utils/constants/style";
import { useStore } from "../../../store/useStore";
import { Danger, Trash } from "iconsax-react";

const DeleteCompanyModal: React.FC<{}> = () => {
  const selectedCompany = useStore(
    (state) => state.company.selectedCompany
  );
  const deleteCompany = useStore(
    (state) => state.company.deleteCompany
  );
  const isDeletingCompany = useStore(
    (state) => state.company.isDeletingCompany
  );

  const handleDeleteCompany = () => {
    if(selectedCompany?._id)
      deleteCompany(selectedCompany._id);
  };

  return (
    <Modal
      title="Exclusão de transportadora"
      icon={<Trash size={30} variant="Bold" color={Colors.white} />}
      className="w-[361px]"
    >
      <div className="text-center flex flex-col items-center gap-5">
        <Danger size="72" variant="Bulk" color={Colors.red}/>
        <div className="px-5">
          Tem certeza que deseja remover a transportadora {selectedCompany?.name ?? '-'}?
        </div>
        <FilledButton loading={isDeletingCompany} onClick={handleDeleteCompany} className="px-14">
          Excluir
        </FilledButton>
      </div>
    </Modal>
  );
};

export default DeleteCompanyModal;
