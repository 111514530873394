import { useEffect } from "react";
import {
  FilterIcon,
  OptionsIcon,
  PlusIcon,
} from "../../../assets/imgs";
import DataPanel from "../../../components/DataPanel/DataPanel";
import Pagination from "../../../components/Pagination/Pagination";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import SearchInput from "../../../components/SearchInput/SeachInput";
import useModal from "../../../hooks/useModal";
import CompaniesTable from "./CompaniesTable";
import CreateCompanyModal from "./CreateCompanyModal";
import FilterCompanyModal from "./FilterCompanyModal";
import OptionsCompanyModal from "./OptionsCompanyModal";
import { useStore } from "../../../store/useStore";
import LimitPerPage from "../../../components/LimitPerPage/LimitPerPage";
import Skeleton from "react-loading-skeleton";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import { AddCircle, Filter, Setting2 } from "iconsax-react";

const CompaniesGeneral: React.FC<{}> = () => {
  const { openModal } = useModal();
  const getCompanies = useStore((state) => state.company.getCompanies);
  const companiesList = useStore(
    (state) => state.company.companiesList
  );
  const isLoadingCompanysList = useStore(
    (state) => state.company.isLoadingCompaniesList
  );
    const hasPermissions = useStore(
    (state) => state.admin.hasPermissions
  );


  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateCompanyModal = () =>
    openModal(<CreateCompanyModal />);
  const openFilterCompanyModal = () =>
    openModal(<FilterCompanyModal />);
  const openOptionsCompanyModal = () =>
    openModal(<OptionsCompanyModal />);

  return (
    <>
      <div className="mt-9">
        <DataPanel className="px-3 pt-8 flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="pl-1">
              {/* <SearchInput placeholder="Buscar registro" onChange={() => {}} /> */}
            </div>
            <div className="pr-1 flex gap-4">
              <RoundedFilledButton
                onClick={openCreateCompanyModal}
                icon={<AddCircle />}
                text="Novo"
                show={hasPermissions([PERMISSIONS.CREATE_COMPANY])}
              />
              <RoundedFilledButton
                onClick={openFilterCompanyModal}
                icon={<Filter />}
                text="Filtros"
              />
              <RoundedFilledButton
                onClick={openOptionsCompanyModal}
                icon={<Setting2 />}
                text="Opções"
              />
            </div>
          </div>
          <CompaniesTable />
          <div className="flex justify-between items-center">
            <div className="text-gray_1">
              Mostrando{" "}
              {!isLoadingCompanysList ? (companiesList.data.length + companiesList.offset * companiesList.limitPerPage) : <Skeleton inline width={20} />}{" "}
              de {companiesList.totalItems} registros
            </div>
            <div className="flex gap-5 items-center">
              <LimitPerPage
                onLimitChange={(e: any) =>
                  companiesList.setLimitPerPage(e.target.value)
                }
              />
              <Pagination
                currentPage={companiesList.currentPage}
                totalPages={companiesList.totalPages}
                onPageChange={companiesList.setCurrentPage}
              />
            </div>
          </div>
        </DataPanel>
      </div>
    </>
  );
};

export default CompaniesGeneral;
