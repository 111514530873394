import { useEffect, useState } from "react";
import { useStore } from "../../store/useStore";
import HolidaysGeneral from "./Components/HolidaysGeneral";
import SyndicatesGeneral from "./Components/SyndicatesGeneral";
import Tabs from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Components/Tab";
import TabPanel from "../../components/Tabs/Components/TabPanel";
import SectionGeneral from "./Components/SectionGeneral";
import { Calendar, Location, People } from "iconsax-react";

const Conventions: React.FC<{}> = () => {
  const [tab, setTab] = useState<number>(0);

  const resetHolidaysListState = useStore(
    (state) => state.conventions.resetHolidaysListState
  );
  const resetSyndicatesListState = useStore(
    (state) => state.conventions.resetSyndicatesListState
  );

  useEffect(() => {
    return () => {
      resetHolidaysListState();
      resetSyndicatesListState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Tabs tab={tab} setTab={setTab}>
        <Tab label="Seções" icon={<Location variant="Bulk" />} />
        <Tab label="Sindicatos" icon={<People variant="Bulk" />} />
        <Tab label="Feriados" icon={<Calendar variant="Bulk" />} />
      </Tabs>
      <TabPanel tab={tab} index={0}>
        <SectionGeneral />
      </TabPanel>
      <TabPanel tab={tab} index={1}>
        <SyndicatesGeneral />
      </TabPanel>
      <TabPanel tab={tab} index={2}>
        <HolidaysGeneral />
      </TabPanel>
    </div>
  );
};

export default Conventions;
