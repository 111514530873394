
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik, FormikProps } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import { useStore } from "../../../store/useStore";
import { useEffect } from "react";
import CustomDatePicker from "../../../components/FormComponents/CustomDatePicker/CustomDatePicker";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import { Collaborator } from "../../../store/types/collaborator";
import AsyncOutlinedSelect from "../../../components/AsyncOutlinedSelect/AsyncOutlinedSelect";
import { COMPENSATORY_TIME_CATEGORY_OPTIONS, COMPENSATORY_TIME_TYPE_OPTIONS, REASON_OPTIONS } from "../../../utils/constants/constants";
import { Colors } from "../../../utils/constants/style";
import { Filter } from "iconsax-react";

type FilterFields = {
  user: {
    value: string,
    label: string,
  },
  type: string,
  category: string,
  reason: string,
  manual: string | boolean,
  reference_date_start: string,
  reference_date_end: string,
  deadline_start: string,
  deadline_end: string,
  created_at: string,
};

const initialValues: FilterFields = {
  user: {
    value: "",
    label: "",
  },
  type: "",
  category: "",
  reason: "",
  manual: "",
  reference_date_start: "",
  reference_date_end: "",
  deadline_start: "",
  deadline_end: "",
  created_at: "",
};

const FilterCompensatoryTimeModal: React.FC<{}> = () => {
  const setFilters = useStore(
    (state) => state.timeManager.compensatoryTimeList.setFilters
  );
  const filters = useStore(
    (state) => state.timeManager.compensatoryTimeList.filters
  );
  const clearFilter = (formik: FormikProps<typeof initialValues>) => {
    formik.resetForm();
  };
  const getCollaboratorOptions = useStore(
    (state) => state.collaborator.getCollaboratorOptions
  );

  const getCollaboratorOptionsCallback = async (inputValue: string) => {
    const options = (await getCollaboratorOptions(inputValue)).map(
      (c: Collaborator) => {
        return { label: c.name, value: c._id };
      }
    );
    return options;
  };

  return (
    <Modal
      title="Filtros de banco de horas"
      icon={<Filter size="32" variant="Bold" color={Colors.white}/>}
      className="w-[447px]"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          user: Yup.object().shape({
            value: Yup.string(),
            label: Yup.string(),
          }),
          type: Yup.string(),
          manual: Yup.string(),
          category: Yup.string(),
          reason: Yup.string(),
          reference_date_start: Yup.string(),
          reference_date_end: Yup.string(),
          deadline_start: Yup.string(),
          deadline_end: Yup.string(),
          created_at: Yup.string(),
        })}
        onSubmit={(values) => {
          if(values.manual === 'manual') values.manual = true; 
          else if(values.manual === 'automatic') values.manual = false; 
          else if(values.manual === '') values.manual = ''; 
          setFilters({ ...filters, ...values });
        }}
      >
        {(formik) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (filters) {
              let newFilters = {...filters};
              if(filters.manual === true) newFilters.manual = 'manual'
              else if(filters.manual === false) newFilters.manual = 'automatic'
              else if(filters.manual === '') newFilters.manual = ''
              formik.setValues(newFilters);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [filters]);

          return (
            <Form>
              <div className="flex flex-col gap-8 pt-5">
                <div className="flex gap-8">
                  <div className="flex-1 flex flex-col gap-8">
                    <Field
                      name="user"
                      component={AsyncOutlinedSelect}
                      callback={getCollaboratorOptionsCallback}
                      value={{
                        value: formik.values.user.value,
                        label: formik.values.user.label,
                      }}
                      onChange={(option: any) =>
                        formik.setFieldValue("user", option)
                      }
                      placeholder="Colaborador"
                    />
                    <Field
                        name="category"
                        component={OutlinedSelect}
                        placeholder="Categoria"
                        options={COMPENSATORY_TIME_CATEGORY_OPTIONS}
                      />
                      <Field
                        name="manual"
                        component={OutlinedSelect}
                        placeholder="Registro"
                        options={[
                          { label: "Manual", value: 'manual' },
                          { label: "Automático", value: 'automatic' }
                        ]}
                    />
                    <Field
                      placeholder="Data de vencimento inicial"
                      name="deadline_start"
                      component={CustomDatePicker}
                    />
                    <Field
                      placeholder="Data de referência inicial"
                      name="reference_date_start"
                      component={CustomDatePicker}
                    />
                  </div>
                  <div className="flex-1 flex flex-col gap-8">
                    <Field
                      name="type"
                      component={OutlinedSelect}
                      placeholder="Tipo"
                      options={COMPENSATORY_TIME_TYPE_OPTIONS}
                    />
                    <Field
                        name="reason"
                        component={OutlinedSelect}
                        placeholder="Motivo"
                        options={REASON_OPTIONS}
                      />
                    <Field
                      placeholder="Data de vencimento final"
                      name="deadline_end"
                      component={CustomDatePicker}
                    />
                    <Field
                      placeholder="Data de referência final"
                      name="reference_date_end"
                      component={CustomDatePicker}
                    />
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="flex-1">
                    <OutlinedButton
                      onClick={() => clearFilter(formik)}
                      className="w-full"
                      type="button"
                    >
                      Limpar Filtros
                    </OutlinedButton>
                  </div>
                  <div className="flex-1">
                    <FilledButton className="w-full" type="submit">
                      Filtrar
                    </FilledButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default FilterCompensatoryTimeModal;
