import { chartsAPI } from "../api/api";
import { removeEmptyProperties } from "../utils/functions/functions";
import { handleServiceError } from "./handleServiceError";

const dashboardService = {

  getFleetEvolution: async (filters: any) => {
    try {
      const response = await chartsAPI.get(`/v1/analytics/vehicle/fleet-evo`, {params: filters });
      const data: any = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getTimeAggregator: async () => {
    try {
      const response = await chartsAPI.get(`/v1/analytics/vehicle/time-aggregator`);
      const data: any = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getLastMacroPositions: async (section?: string) => {
    try {
      const response = await chartsAPI.get(`/v1/vehicle/positions`, { headers: { section } });
      const data: any = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getMacroAlterations: async (filters: any) => {
    try {
      const response = await chartsAPI.get(`/v1/analytics/macro/alterations`, { params: filters });
      const data: any = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getDashboardStatus: async (section?: string) => {
    try {
      const response = await chartsAPI.get(`/v1/macro/dashboardstatus`, { headers: { section } });
      const data: any = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getDashboardMacro: async ({ filters, limit, offset }: { filters: any | {}, limit: number, offset: number }, section: string) => {
    try {
      const response = await chartsAPI.get("/v1/macro", 
        { 
          params: { ...removeEmptyProperties(filters), limit, offset },
          headers: { section }
      });
      const data: any[] = response.data.data;

      const totalItems = Number(response.headers["x-total-count"]);

      return { data, totalItems };
    } catch (error) {
      handleServiceError(error);
    }
  },
  getUserJourney: async (id: string) => {
    try {

      const response = await chartsAPI.get(`/v1/macro/user/${id}/journey`);
      const data: any = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  getVehiclePositions: async (id: string) => {
    try {
      const response = await chartsAPI.get(`/v1/vehicle/${id}/positions`);
      const data: any = response.data.data;

      return data;
    } catch (error) {
      handleServiceError(error);
    }
  },
  resetDashboardStatus: async () => {
    try {
      await chartsAPI.delete(`/v1/macro/dashboardstatus/reset`);
    } catch (error) {
      handleServiceError(error);
    }
  },
};

export default dashboardService;
