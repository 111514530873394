interface DataPanelHeaderProps {
  title: string;
  icon: JSX.Element;
}

const DataPanelHeader: React.FC<DataPanelHeaderProps> = ({ icon, title }) => {
  return (
    <div>
      <div className="bg-red w-[64px] h-[64px] ml-5 mt-[-20px] absolute z-60 rounded-[4px] flex items-center justify-center">
        {icon}
      </div>
      <div className="relative pt-3 pb-6">
        <h1 className="text-dark-blue font-bold ml-[95px]">{title}</h1>
      </div>
    </div>
  );
};
interface DataPanelProps {
  children: JSX.Element | JSX.Element[];
  header?: DataPanelHeaderProps;
  className?: string;
  show?: boolean;
}

const DataPanel: React.FC<DataPanelProps> = ({
  children,
  header,
  className = "",
  show = true,
}) => {
  return show ? (
    <div className={`shadow-base rounded-[8px] bg-white pb-5 ${className}`}>
      {header ? (
        <DataPanelHeader title={header.title} icon={header.icon} />
      ) : null}
      {children}
    </div>
  ) : null;
};

export default DataPanel;
