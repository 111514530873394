import DataTable from "../../../components/DataTable/DataTable";
import IntegrationCode from "../../../components/IntegrationCode/IntegrationCode";
import MacroOrigin from "../../../components/MacroOrigin/MacroOrigin";
import useModal from "../../../hooks/useModal";
import {
  Collaborator,
  CollaboratorsVisibleColumns,
} from "../../../store/types/collaborator";
import { useStore } from "../../../store/useStore";
import { PERMISSIONS } from "../../../utils/constants/permissions";
import {
  formatCPF,
  formatDateTime,
  formatPhoneNumber,
} from "../../../utils/functions/formatters";
import DetailsCollaboratorModal from "./DetailsCollaboratorModal";
import EditCollaboratorModal from "./EditCollaboratorModal";

const CollaboratorsTable = () => {
  const { openModal } = useModal();

  const getCollaborator = useStore(
    (state) => state.collaborator.getCollaborator
  );
  const resetSelectedCollaborator = useStore(
    (state) => state.collaborator.resetSelectedCollaborator
  );
  const collaborators = useStore(
    (state) => state.collaborator.collaboratorsList.data
  );
  const setFilters = useStore(
    (state) => state.collaborator.collaboratorsList.setFilters
  );
  const filters = useStore(
    (state) => state.collaborator.collaboratorsList.filters
  );
  const columnsVisibility = useStore(
    (state) => state.collaborator.collaboratorsList.columnsVisibility
  );
  const isLoadingCollaboratorsList = useStore(
    (state) => state.collaborator.isLoadingCollaboratorsList
  );
  const hasPermissions = useStore((state) => state.admin.hasPermissions);
  const userPropertiesProjection = useStore(
    (state) => state.admin.data.projection.user
  );
  const getCollaboratorIntegrations = useStore(
    (state) => state.collaboratorIntegration.getCollaboratorIntegrations
  );

  const openDetailsCollaboratorModal = (collaboratorId: string) => {
    getCollaborator(collaboratorId);
    openModal(<DetailsCollaboratorModal onClose={resetSelectedCollaborator} />);
  };

  const openEditCollaboratorModal = (collaboratorId: string) => {
    getCollaborator(collaboratorId);
    getCollaboratorIntegrations(collaboratorId);
    openModal(<EditCollaboratorModal onClose={resetSelectedCollaborator} />);
  };

  const handleTableOrder = (key: string) => {
    let sort_value = "asc";
    if (filters.sort_key === key) {
      sort_value = filters.sort_value === "asc" ? "desc" : "asc";
    }
    setFilters({ ...filters, sort_key: key, sort_value });
  };

  const columns = [
    { key: "name", label: "Nome", sortKey: "name" },
    { key: "email", label: "Email", sortKey: "email" },
    {
      key: "phone",
      label: "Telefone",
      render: (item: Collaborator) => item.phone ? formatPhoneNumber(item.phone) : '-',
    },
    { key: "registration", label: "Matrícula" },
    { key: "position.name", label: "Cargo", sortKey: "position" },
    {
      key: "cpf",
      label: "CPF",
      render: (item: Collaborator) => formatCPF(item.cpf),
    },
    { key: "rg", label: "RG" },
    { key: "cnh", label: "CNH" },
    {
      key: "created_at",
      label: "Criado em",
      render: (item: Collaborator) => formatDateTime(item.created_at),
      sortKey: "created_at",
    },
    {
      key: "integration_user",
      label: "Integrações",
      render: (item: Collaborator) =>  {
          return item?.integration_user?.length ? item?.integration_user?.map((integration_user) => {
            return (
              <div className="flex items-center mb-1">
                <IntegrationCode origin={integration_user?.integration} code={integration_user?.code} />
              </div>
            )
          }) : '-';
      }
    },
  ];

  const columnsVisible = columns.filter((column) => {
    if (column.key === "position.name") return columnsVisibility["position"];
    return columnsVisibility[column.key as keyof CollaboratorsVisibleColumns];
  });

  const columnsFilteredByPermission = columnsVisible.filter((column) => {
    if (column.key === "position.name")
      return userPropertiesProjection.includes("position");
    return userPropertiesProjection.includes(column.key);
  });

  return (
    <DataTable
      data={collaborators}
      columns={columnsFilteredByPermission}
      onDetails={
        hasPermissions([PERMISSIONS.READ_USER])
          ? openDetailsCollaboratorModal
          : null
      }
      onEdit={
        hasPermissions([PERMISSIONS.UPDATE_USER])
          ? openEditCollaboratorModal
          : null
      }
      onOrder={handleTableOrder}
      sortKey={filters.sort_key}
      sortValue={filters.sort_value}
      loading={isLoadingCollaboratorsList}
    />
  );
};

export default CollaboratorsTable;
