import { useEffect, useState } from "react";
import {
  EditProfileIcon,
  KeyIcon,
  NotificationIcon,
  UserIcon,
} from "../../assets/imgs";
import Tab from "../../components/Tabs/Components/Tab";
import TabPanel from "../../components/Tabs/Components/TabPanel";
import Tabs from "../../components/Tabs/Tabs";
import { Colors } from "../../utils/constants/style";
import InfoFieldBox from "../../components/InfoFieldBox/InfoFieldBox";
import InfoField from "../../components/InfoField/InfoField";
import FilledButton from "../../components/FilledButton/FilledButton";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import OutlinedInput from "../../components/FormComponents/OutlinedInput/OutlinedInput";
import DataPanel from "../../components/DataPanel/DataPanel";
import useModal from "../../hooks/useModal";
import { useStore } from "../../store/useStore";
import {
  formatCPF,
  formatPhoneNumber,
  formatCNPJ,
} from "../../utils/functions/formatters";
import EditProfileModal from "./Components/EditProfileModal";
import GenericCompanyImage from "../../components/GenericCompanyImage/GenericCompanyImage";
import Skeleton from "react-loading-skeleton";
import { PERMISSIONS } from "../../utils/constants/permissions";

const Profile: React.FC<{}> = () => {
  const [tab, setTab] = useState<number>(0);

  const getProfile = useStore((state) => state.profile.getProfile);
  const profile = useStore((state) => state.profile.profile);
  const company = useStore((state) => state.profile.company);
  const updatePassword = useStore((state) => state.profile.updatePassword);
  const isUpdatingPassword = useStore(
    (state) => state.profile.isUpdatingPassword
  );
  const isLoadingProfile = useStore((state) => state.profile.isLoadingProfile);
  const isLoadingCompany = useStore((state) => state.profile.isLoadingCompany);
  const hasPermissions = useStore((state) => state.admin.hasPermissions);

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateCollaboratorModal = () => openModal(<EditProfileModal />);

  const { openModal } = useModal();

  return (
    <div>
      <Tabs tab={tab} setTab={setTab}>
        <Tab
          label="Perfil"
          icon={<UserIcon width={18} height={18} color={Colors["dark-blue"]} />}
        />
        {/* <Tab label="Notificações" icon={<NotificationIcon />} /> */}
      </Tabs>
      <TabPanel tab={tab} index={0}>
        <div className="mt-9 flex gap-6">
          <DataPanel
            header={{ title: "Dados pessoais", icon: <EditProfileIcon /> }}
            className="flex-[2] flex flex-col"
          >
            <div className="ml-5 h-full flex flex-col">
              <div className="flex flex-col gap-5">
                <InfoFieldBox>
                  <InfoField
                    label="Nome"
                    content={profile?.name}
                    loading={isLoadingProfile}
                  />
                  <InfoField
                    label="CPF"
                    content={formatCPF(profile?.cpf ?? "")}
                    loading={isLoadingProfile}
                  />
                </InfoFieldBox>
                <InfoFieldBox>
                  <InfoField
                    label="Cargo"
                    content={profile?.position?.name}
                    loading={isLoadingProfile}
                  />
                  <InfoField
                    label="RG"
                    content={profile?.rg}
                    loading={isLoadingProfile}
                  />
                </InfoFieldBox>
                <InfoFieldBox>
                  <InfoField
                    label="Email"
                    content={profile?.email}
                    loading={isLoadingProfile}
                  />
                  <InfoField
                    label="CNH"
                    content={profile?.cnh}
                    loading={isLoadingProfile}
                  />
                </InfoFieldBox>
                <InfoFieldBox>
                  <InfoField
                    label="Telefone"
                    content={formatPhoneNumber(profile?.phone ?? "")}
                    loading={isLoadingProfile}
                  />
                </InfoFieldBox>
              </div>
              <div className="flex justify-center pt-5 mt-auto">
                <FilledButton
                  className="px-5"
                  onClick={openCreateCollaboratorModal}
                >
                  ATUALIZAR INFORMAÇÕES
                </FilledButton>
              </div>
            </div>
          </DataPanel>
          <div className="flex-1 flex flex-col gap-10">
            <DataPanel>
              <div className="p-5 pb-0 relative flex justify-center pt-[75px]">
                <div className="bg-white shadow-base absolute top-[-75px] rounded-full w-[150px] h-[150px] flex justify-center items-center">
                  {isLoadingCompany ? (
                    <div
                      className={`rounded-full flex justify-center items-center`}
                    >
                      <Skeleton
                        style={{ width: 150, height: 150 }}
                        borderRadius={"100%"}
                        containerClassName="mt-[-3px]"
                      />
                    </div>
                  ) : (
                    <GenericCompanyImage
                      width={150}
                      height={150}
                      logo={company?.logo}
                    />
                  )}
                </div>
                <div>
                  <div className="text-center border-b border-[#EEE] py-5">
                    <div className="font-light">
                      {!isLoadingCompany ? (
                        company?.name ?? "-"
                      ) : (
                        <Skeleton width={150} />
                      )}
                    </div>
                    <div className="text-sm">
                      {!isLoadingCompany ? (
                        formatCNPJ(company?.cnpj ?? "-")
                      ) : (
                        <Skeleton width={100} />
                      )}
                    </div>
                  </div>
                  <div className="text-center flex flex-col pt-5">
                    <div className="font-light text-lg">
                      {!isLoadingCompany ? (
                        profile?.name ?? "-"
                      ) : (
                        <Skeleton width={150} />
                      )}
                    </div>
                    <div className="text-red font-bold">
                      {!isLoadingCompany ? (
                        profile?.position?.name ?? "-"
                      ) : (
                        <Skeleton width={100} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </DataPanel>
            <DataPanel
              header={{ title: "Senha", icon: <KeyIcon /> }}
              show={hasPermissions([PERMISSIONS.UPDATE_USER_OWN_PASSWORD])}
            >
              <Formik
                initialValues={{
                  currentPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={Yup.object().shape({
                  currentPassword: Yup.string().required("Campo obrigatório"),
                  newPassword: Yup.string().required("Campo obrigatório"),
                  confirmPassword: Yup.string()
                    .oneOf([Yup.ref("newPassword")], "Senha incorreta")
                    .required("Campo obrigatório"),
                })}
                onSubmit={(values, { resetForm }) => {
                  updatePassword({
                    currentPassword: values.currentPassword,
                    newPassword: values.newPassword,
                  });
                  resetForm();
                }}
              >
                <Form>
                  <div className="flex flex-col gap-8 px-5 pt-3">
                    <Field
                      placeholder="Senha Atual"
                      name="currentPassword"
                      type="password"
                      component={OutlinedInput}
                    />
                    <Field
                      placeholder="Nova Senha"
                      name="newPassword"
                      type="password"
                      component={OutlinedInput}
                    />
                    <Field
                      placeholder="Confirmação da Nova Senha"
                      name="confirmPassword"
                      type="password"
                      component={OutlinedInput}
                    />
                    <div className="flex justify-center">
                      <FilledButton
                        loading={isUpdatingPassword}
                        className="px-8"
                        type="submit"
                      >
                        ATUALIZAR SENHA
                      </FilledButton>
                    </div>
                  </div>
                </Form>
              </Formik>
            </DataPanel>
          </div>
        </div>
      </TabPanel>
    </div>
  );
};

export default Profile;
