export const PERMISSIONS = {
  CREATE_COMPANY: "CREATE_COMPANY",
  READ_COMPANY: "READ_COMPANY",
  UPDATE_COMPANY: "UPDATE_COMPANY",
  DELETE_COMPANY: "DELETE_COMPANY",
  LIST_COMPANIES: "LIST_COMPANIES",
  SWITCH_COMPANY: "SWITCH_COMPANY",
  UPSERT_COMPANY_LOGO: "UPSERT_COMPANY_LOGO",
  CREATE_MACRO: "CREATE_MACRO",
  CREATE_MANUAL_MACRO: "CREATE_MANUAL_MACRO",
  READ_MACRO: "READ_MACRO",
  UPDATE_MACRO: "UPDATE_MACRO",
  REMOVE_MACRO: "REMOVE_MACRO",
  RESTORE_MACRO: "RESTORE_MACRO",
  DELETE_MACRO: "DELETE_MACRO",
  LIST_MACROS: "LIST_MACROS",
  CREATE_HOLIDAY: "CREATE_HOLIDAY",
  READ_HOLIDAY: "READ_HOLIDAY",
  UPDATE_HOLIDAY: "UPDATE_HOLIDAY",
  DELETE_HOLIDAY: "DELETE_HOLIDAY",
  LIST_HOLIDAYS: "LIST_HOLIDAYS",
  CREATE_SECTION: "CREATE_SECTION",
  READ_SECTION: "READ_SECTION",
  UPDATE_SECTION: "UPDATE_SECTION",
  DELETE_SECTION: "DELETE_SECTION",
  LIST_SECTIONS: "LIST_SECTIONS",
  CREATE_SYNDICATE: "CREATE_SYNDICATE",
  READ_SYNDICATE: "READ_SYNDICATE",
  UPDATE_SYNDICATE: "UPDATE_SYNDICATE",
  DELETE_SYNDICATE: "DELETE_SYNDICATE",
  LIST_SYNDICATES: "LIST_SYNDICATES",
  CREATE_USER: "CREATE_USER",
  READ_USER: "READ_USER",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",
  LIST_USERS: "LIST_USERS",
  UPDATE_USER_PASSWORD: "UPDATE_USER_PASSWORD",
  UPDATE_USER_OWN_PASSWORD: "UPDATE_USER_OWN_PASSWORD",
  CREATE_USERS_REPORT: "CREATE_USERS_REPORT",
  CREATE_VEHICLE: "CREATE_VEHICLE",
  READ_VEHICLE: "READ_VEHICLE",
  UPDATE_VEHICLE: "UPDATE_VEHICLE",
  DELETE_VEHICLE: "DELETE_VEHICLE",
  LIST_VEHICLES: "LIST_VEHICLES",
  CREATE_COMPANIES_REPORT: "CREATE_COMPANIES_REPORT",
  READ_USER_VEHICLE: "READ_USER_VEHICLE",
  UPDATE_VEHICLE_USER: "UPDATE_VEHICLE_USER",
  CREATE_VEHICLES_REPORT: "CREATE_VEHICLES_REPORT",
  GET_JOURNEY_EVENT: "GET_JOURNEY_EVENT",
  LIST_JOURNEY_EVENTS: "LIST_JOURNEY_EVENTS",
  GET_DASH_MACROS: "GET_DASH_MACROS",
  CREATE_HOUR_BANK_RECORD: "CREATE_HOUR_BANK_RECORD",
  CREATE_MANUAL_MACRO_REPORT: "CREATE_MANUAL_MACRO_REPORT",
  CREATE_ALTERED_MACRO_REPORT: "CREATE_ALTERED_MACRO_REPORT",
  LIST_INTEGRATIONS: "LIST_INTEGRATIONS",
  CREATE_INTEGRATION_USER: "CREATE_INTEGRATION_USER",
  LIST_INTEGRATIONS_USERS: "LIST_INTEGRATIONS_USERS",
  DELETE_INTEGRATION_USER: "DELETE_INTEGRATION_USER",
  RESET_MACRO_DASH_STATUS: "RESET_MACRO_DASH_STATUS",
  CREATE_AFD_REPORT: "CREATE_AFD_REPORT",
  CREATE_AEJ_REPORT: "CREATE_AEJ_REPORT"
}

export const POSITION_OPTIONS_MASTER = [
  { label: "Gestor", value: "MANAGER" },
  { label: "Recursos Humanos", value: "HR" },
  { label: "Motorista", value: "DRIVER" }
];

export const POSITION_OPTIONS_MANAGER = [
  { label: "Gestor", value: "MANAGER" },
  { label: "Recursos Humanos", value: "HR" },
  { label: "Motorista", value: "DRIVER" },
];

export const POSITION_OPTIONS_HR = [
  { label: "Gestor", value: "MANAGER" },
  { label: "Recursos Humanos", value: "HR" },
  { label: "Motorista", value: "DRIVER" },
];

export const POSITION_OPTIONS = {
  HR: POSITION_OPTIONS_HR,
  MANAGER: POSITION_OPTIONS_MANAGER,
  MASTER: POSITION_OPTIONS_MASTER,
  DRIVER: [],
  "": [],
};
