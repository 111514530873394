import { CSVIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import Checkbox from "../../../components/FormComponents/Checkbox/Checkbox";
import RoundedFilledButton from "../../../components/RoundedFilledButton/RoundedFilledButton";
import { useStore } from "../../../store/useStore";
import { MacrosVisibleColumnsKey } from "../../../store/types/macroState";
import { Setting2 } from "iconsax-react";
import { Colors } from "../../../utils/constants/style";

const labels = {
  macro_origin: "Origem",
  vehicle: "Veículo",
  user: "Motorista",
  journey_event: "Evento de Jornada",
  status: "Status",
  location: "Endereço",
  started_at: "Inicio",
  description: "Descrição",
  ended_at: "Fim",
  duration: "Duração",
};

const fixedColumns = {
  macro_origin: true,
  vehicle: true,
  user: false,
  journey_event: false,
  status: false,
  location: false,
  description: false,
  started_at: false,
  duration: false,
};

const OptionsMacroModal: React.FC<{}> = () => {
  const toggleColumnVisibility = useStore(
    (state) => state.macro.macrosList.toggleColumnVisibility
  );
  const columnsVisibility = useStore(
    (state) => state.macro.macrosList.columnsVisibility
  );
  const downloadEntityReport = useStore((state) => state.report.downloadEntityReport);
  const macroFilters = useStore((state) => state.macro.macrosList.filters);
  const isDownloadingReport = useStore((state) => state.report.isDownloadingReport);

  const handleEntityReport = () => {
    downloadEntityReport('macros', macroFilters);
  };

  return (
    <Modal
      title="Opções"
      icon={<Setting2 size="32" variant="Bold" color={Colors.white}/>}
      className="min-w-[223px]"
    >
      <>
        <div className="pb-2 text-dark-blue">Exibir Colunas</div>
        <div className="flex flex-col gap-2 pl-3">
          {(Object.keys(columnsVisibility) as MacrosVisibleColumnsKey[]).map(
            (key) => (
              <Checkbox
                key={key}
                text={labels[key]}
                checked={columnsVisibility[key]}
                onChange={(e) => {
                  if (!fixedColumns[key])
                    toggleColumnVisibility(key, columnsVisibility[key] ?? false);
                }}
              />
            )
          )}
        </div>
        <>
          <div className="pt-4 pb-2 text-dark-blue">Exportar</div>
          <div className="flex gap-3">
            <div className="flex-1">
              <RoundedFilledButton
                className="w-full"
                onClick={() => handleEntityReport()}
                loading={isDownloadingReport}
                icon={<CSVIcon />}
                text="CSV"
              />
            </div>
          </div>
        </>
      </>
    </Modal>
  );
};

export default OptionsMacroModal;
