import Button from '../../components/button';
import './management-style.css';
import { MockManagement } from "../../../../assets/imgs";

import { Fade, Slide } from 'react-awesome-reveal';

export default function Management(props: { contentClass: string; whatsappLink: string}) {
  const clazz = props.contentClass + " padding-management";

  if(props.contentClass == "content-mobile") {
    return (
      <section id="management">
        <div className={clazz} style={{ backgroundColor: `#ED3A3A` }}>
            <div className='management-content-mobile'>
              <Slide cascade damping={0.2} direction='right'>
                <h1 className="title-mobile">Gerencie toda a sua frota de onde estiver</h1>
                <p className="subtitle-management-mobile">Com o aplicativo, seus motoristas terão acesso às folhas de pagamento e as macros. Com o desktop, você consegue gerenciá-los de maneira rápida e segura.</p>
              </Slide>
              <Fade>
                <Slide duration={2000}>
                  <img src={MockManagement} alt="Mockup Management" style={{
                    borderTop: "1px solid #000",
                    width: 1000
                  }}/>
                </Slide>
              </Fade>
              
            </div>
        </div>
      </section>
    );
  }

  return (
    <section id="management">
      <div  style={{ backgroundColor: `#ED3A3A`,
      }}>
        <div className={clazz}>
          <div className='management-content'>
            <div className='management-content-left'>
              <Fade>
                <Slide duration={2000}>
                  <img src={MockManagement} alt="Mockup Management" style={{
                    width: 1000
                  }}/>
                </Slide>
              </Fade>
            </div>
            <div className='management-content-right'>
              <Fade>
                <Slide cascade damping={0.2} direction='right'>
                  <h1 className="management-title">Gerencie toda a sua frota de onde estiver</h1>
                  <p>Com o aplicativo, seus motoristas terão acesso às folhas de pagamento e as macros. Com o desktop, você consegue gerenciá-los de maneira rápida e segura.</p>
                </Slide>
              </Fade>
              <Fade delay={100} duration={2000}>
                <Button 
                  className="btn-seccondary-wht"
                  label='Faça uma cotação'
                  link={props.whatsappLink} />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}