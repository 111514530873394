
export default function Button(props: { link: string; label: string; className: string;}) {
  return (
    <a href={props.link} target="_blank">
      <div className={props.className}>
        <p>{props.label}</p>
      </div>
    </a>
  );
}
