interface DrivingIconProps {
  width?: string | number;
  height?: string | number;
  color?: string;
}

const DrivingIcon: React.FC<DrivingIconProps> = ({
  width = 17,
  height = 17,
  color = "#4F4F52",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49991 9.91666H9.20824C9.98741 9.91666 10.6249 9.27916 10.6249 8.49999V1.41666H4.24991C3.18741 1.41666 2.2595 2.00456 1.77783 2.86873"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.41675 12.0417C1.41675 13.2175 2.36591 14.1667 3.54175 14.1667H4.25008C4.25008 13.3875 4.88758 12.75 5.66675 12.75C6.44592 12.75 7.08342 13.3875 7.08342 14.1667H9.91675C9.91675 13.3875 10.5542 12.75 11.3334 12.75C12.1126 12.75 12.7501 13.3875 12.7501 14.1667H13.4584C14.6342 14.1667 15.5834 13.2175 15.5834 12.0417V9.91666H13.4584C13.0688 9.91666 12.7501 9.59791 12.7501 9.20832V7.08332C12.7501 6.69374 13.0688 6.37499 13.4584 6.37499H14.3721L13.1609 4.25708C12.9059 3.81791 12.4384 3.54166 11.9284 3.54166H10.6251V8.49999C10.6251 9.27916 9.98758 9.91666 9.20842 9.91666H8.50008"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.66667 15.5833C6.44907 15.5833 7.08333 14.9491 7.08333 14.1667C7.08333 13.3843 6.44907 12.75 5.66667 12.75C4.88426 12.75 4.25 13.3843 4.25 14.1667C4.25 14.9491 4.88426 15.5833 5.66667 15.5833Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3334 15.5833C12.1158 15.5833 12.7501 14.9491 12.7501 14.1667C12.7501 13.3843 12.1158 12.75 11.3334 12.75C10.551 12.75 9.91675 13.3843 9.91675 14.1667C9.91675 14.9491 10.551 15.5833 11.3334 15.5833Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5833 8.5V9.91667H13.4583C13.0687 9.91667 12.75 9.59792 12.75 9.20833V7.08333C12.75 6.69375 13.0687 6.375 13.4583 6.375H14.3721L15.5833 8.5Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.41675 5.66666H5.66675"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.41675 7.79166H4.25008"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.41675 9.91666H2.83341"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DrivingIcon;
