import { PlusIcon } from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import { useStore } from "../../../store/useStore";
import OutlinedMaskedInput from "../../../components/FormComponents/OutlinedMaskedInput/OutlinedMaskedInput";
import { tagMask } from "../../../utils/constants/masks";

const CreateFleetModal: React.FC<{}> = () => {
  const createFleet = useStore(
    (state) => state.fleet.createFleet
  );
  const isCreatingFleet = useStore(
    (state) => state.fleet.isCreatingFleet
  );
  return (
    <Modal
      title="Cadastro de novo veículo"
      icon={<PlusIcon width={30} height={30} />}
      className="w-[447px]"
    >
      <Formik
        initialValues={{
          tag: "",
          fleet: "",
        }}
        validationSchema={Yup.object().shape({
          tag: Yup.string().length(7, "A placa deve conter exatamente 7 caracteres").required("Campo obrigatório"),
          fleet: Yup.string().required("Campo obrigatório"),
        })}
        onSubmit={(values) => {
          createFleet(values);
        }}
      >
        <Form>
          <div className="flex flex-col gap-8 pt-5">
            <Field
              placeholder="Placa"
              name="tag"
              mask={tagMask}
              component={OutlinedMaskedInput}
            />
            <Field
              placeholder="Frota"
              name="fleet"
              mask="9999999999"
              component={OutlinedMaskedInput}
            />
            <div className="flex gap-5">
              <div className="flex-1"></div>
              <div className="flex-1">
                <FilledButton
                  loading={isCreatingFleet}
                  className="w-full"
                  type="submit"
                >
                  Cadastrar
                </FilledButton>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default CreateFleetModal;
