import { useEffect, useState } from "react";
import Tab from "../../components/Tabs/Components/Tab";
import TabPanel from "../../components/Tabs/Components/TabPanel";
import Tabs from "../../components/Tabs/Tabs";
import JourneyRulesGeneral from "./Components/JourneyRulesGeneral";
import JourneyEvents from "../Macros/Components/JourneyEvents";
import Parameters from "./Components/Parameters";
import { useStore } from "../../store/useStore";
import PayRolls from "./Components/PayRolls";
import { ArchiveBook, Data, DocumentFavorite, Home } from "iconsax-react";

const JourneyRules: React.FC<{}> = () => {
  const [tab, setTab] = useState<number>(0);
  const resetJourneyRulesListState = useStore(
    (state) => state.journeyRules.resetJourneyRulesListState
  );
  const resetJourneyEventsListState = useStore(
    (state) => state.macro.resetJourneyEventsListState
  );
  const resetParametersListState = useStore(
    (state) => state.journeyRules.resetParametersListState
  );
  const resetPayRollsListState = useStore(
    (state) => state.journeyRules.resetPayRollsListState
  );

  useEffect(() => {
    return () => {
      resetJourneyEventsListState();
      resetJourneyRulesListState();
      resetParametersListState();
      resetPayRollsListState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Tabs tab={tab} setTab={setTab}>
        <Tab label="Visão Geral" icon={<Home variant="Bulk" />} />
        <Tab label="Parâmetros" icon={<Data variant="Bulk" />} />
        <Tab label="Eventos de Jornada" icon={<ArchiveBook variant="Bulk" />} />
        <Tab label="Eventos de Folha" icon={<DocumentFavorite variant="Bulk" />} />
      </Tabs>
      <TabPanel tab={tab} index={0}>
        <JourneyRulesGeneral />
      </TabPanel>
      <TabPanel tab={tab} index={1}>
        <Parameters />
      </TabPanel>
      <TabPanel tab={tab} index={2}>
        <JourneyEvents />
      </TabPanel>
      <TabPanel tab={tab} index={3}>
        <PayRolls />
      </TabPanel>

    </div>
  );
};

export default JourneyRules;
