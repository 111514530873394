import './integrations-style.css';
import { Fade, Slide } from 'react-awesome-reveal';
import { LogosIntegrations } from "../../../../assets/imgs";


export default function Integrations(props: { contentClass: string;}) {
  const clazz = props.contentClass + " margin-t-full margin-b-full";

  if(props.contentClass == "content-mobile") {
      return(
        <section id="conquests">
          <div className={clazz}>
            <div className='integration-content-mobile'>
                <Slide cascade damping={0.2} direction='up'>
                  <h1 className='title-integrations-mobile'>Estamos integrados</h1>
                  <p className='subtitle-integrations-mobile'>Estamos conectados com as principais gerenciadoras de risco do país, confira abaixo</p>
                </Slide>
                <center>
                  <Fade duration={2000}>
                    <img
                      src={LogosIntegrations}
                      width={400}
                      alt="Integrações Logo"
                    />
                  </Fade>
                </center>
              </div>
          </div>
        </section>
      );
    }

  return (
    <section id="integrations">
      <div className={clazz}>
        <div className='integration-content'>
          <div className='integration-content-left'>
            <Slide cascade damping={0.2} direction='up'>
              <h1 className='title-integrations'>Estamos integrados</h1>
              <p className='subtitle-integrations'>Estamos conectados com as principais gerenciadoras de risco do país, confira ao lado</p>
            </Slide>
          </div>
          <div className='integration-content-right'>
            <center>
              <Fade duration={2000}>
                <img
                  src={LogosIntegrations}
                  width={400}
                  alt="Integrações Logo"
                />
              </Fade>
            </center>
          </div>
        </div>
      </div>
    </section>
  );
}
